import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/lms/features/questions';
import Button from 'components/CustomButtons/Button';
import ReadingQuestionsArticles from 'features/editQuestions/EditQReadingArticles';
import EditQList from 'features/editQuestions/EditQList';
import { useDispatch, useSelector } from 'react-redux';
import {
  editQuestionStatusSelector,
  sectionTypeSelector,
  editingQuestionSelector,
} from 'features/editQuestions/editSlice';
import { tokenSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import CustomStepper from 'components/Stepper/CustomStepper';
import { getCategoriesAsync, getDiplomasAsync, getGradesAsync, getLevelsAsync } from 'features/admin/slices/adminSlice';
import Primary from 'components/Typography/Primary';
import { editAsync } from './editSlice';
import EditQTitleAndCategories from './EditQTitleAndCategories';
import EditQAudio from './EditQAudio';
import FormikDoc from 'components/Chips/Chips';

const useStyles = makeStyles(styles);

const getStepContent = (step: number, questionType: Types.API.Question.QuestionSectionType) => {
  if (step === 0) return <EditQTitleAndCategories />;
  switch (questionType) {
    case 'Reading':
      switch (step) {
        case 1:
          return <ReadingQuestionsArticles />;
        case 2:
          return <EditQList />;
        case 3:
          return (
            <h6>
              <Primary>All Steps Completed, You should see the student sheet preview here</Primary>
            </h6>
          );
      }
      break;
    case 'Listening':
      switch (step) {
        case 1:
          return <EditQAudio />;
        case 2:
          return <EditQList />;
        case 3:
          return (
            <h6>
              <Primary>All Steps Completed, You should see the student sheet preview here</Primary>
            </h6>
          );
      }
      break;
    case 'Speaking':
    case 'Writing':
      switch (step) {
        case 1:
          return <EditQList />;
        case 2:
          return (
            <h6>
              <Primary>All Steps Completed, You should see the student sheet preview here</Primary>
            </h6>
          );
      }
      break;
    case 'Vocab':
      switch (step) {
        case 1:
          return <FormikDoc edit={true} />;
        case 2:
          return <EditQList />;
        case 3:
          return (
            <h6>
              <Primary>All Steps Completed, You should see the student sheet preview here</Primary>
            </h6>
          );
      }
      break;
  }
  return null;
};

const getSteps = (questionType: Types.API.Question.QuestionSectionType) => {
  switch (questionType) {
    case 'Reading':
      return ['Question Title & Categories', 'Reading Articles', 'Simple Questions', 'Review & Submit'].map(
        (item, index) => <span key={index}>{item}</span>,
      );
    case 'Listening':
      return ['Question Title & Categories', 'Audio File', 'Simple Questions', 'Review & Submit'].map((item, index) => (
        <span key={index}>{item}</span>
      ));
    case 'Speaking':
      return ['Question Title & Categories', 'Simple Questions', 'Review & Submit'].map((item, index) => (
        <span key={index}>{item}</span>
      ));
    case 'Writing':
      return ['Question Title & Categories', 'Simple Questions', 'Review & Submit'].map((item, index) => (
        <span key={index}>{item}</span>
      ));
    case 'Vocab':
      return ['Question Title & Categories', 'Vocabs', 'Simple Questions', 'Review & Submit'].map((item, index) => (
        <span key={index}>{item}</span>
      ));
  }
  return [];
};

interface IEditQuestion {
  submitSuccessCallback: Function;
}
const EditQuestion: React.FC<IEditQuestion> = props => {
  const editQuestionStatus = useSelector(editQuestionStatusSelector);
  useReqListener({
    status: editQuestionStatus.status,
    successMessage: 'Question Modified Successfully to the database',
    loadingMessage: 'Modifying the question in the database',
    errorMessage: editQuestionStatus.error,
  });

  const sectionType = useSelector(sectionTypeSelector);
  const token = useSelector(tokenSelector);
  const editingQuestion = useSelector(editingQuestionSelector);

  const steps = getSteps(sectionType ? (sectionType as Types.API.Question.QuestionSectionType) : 'Reading');

  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  useEffect(() => {
    // Fetch data
    if (dispatch && token) {
      dispatch(getCategoriesAsync({ token }));
      dispatch(getGradesAsync({ token }));
      dispatch(getLevelsAsync({ token }));
      dispatch(getDiplomasAsync({ token }));
    }
  }, [dispatch, token]);

  const classes = useStyles();
  return (
    <div>
      <CustomStepper steps={steps} activeStep={activeStep} />
      <div className={classes.paper}>
        {getStepContent(activeStep, sectionType)}
        <div className={classes.stepperButtons}>
          <Button className={classes.NextButton} disabled={activeStep === 0} onClick={handleBack} color="github">
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              color="success"
              onClick={_ => {
                dispatch(
                  editAsync({
                    token,
                    question: editingQuestion,
                    successCallback: props.submitSuccessCallback,
                  }),
                );
              }}
              size="lg"
            >
              Submit Question
            </Button>
          ) : (
            <Button className={classes.BackButton} color="primary" onClick={handleNext}>
              {activeStep === steps.length - 2 ? 'Review & Submit' : 'Next'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditQuestion;

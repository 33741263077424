import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
} from '@material-ui/core';
import React from 'react';
import Notification from 'features/notifications/Notification';
import { useReqListener } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TransitionProps } from '@material-ui/core/transitions';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface Props {
  dialogText: string;
  dialogTitle?: string;
  open: boolean;
  onClose(): void;
  onSubmitClick(): void;
  width?: 'sm' | 'xl';
  fullScreen?: boolean;
  asyncSubmitReq?: {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    successMessage: string;
    loadingMessage: string;
    errorMessage: string | null;
  };
}
const SubmitDialog: React.FC<Props> = ({
  open,
  onClose,
  onSubmitClick,
  dialogText,
  dialogTitle = 'Submit Quiz?',
  width = 'sm',
  fullScreen,
  asyncSubmitReq = { status: 'idle', successMessage: '', errorMessage: '', loadingMessage: '' },
}) => {
  useReqListener({ ...asyncSubmitReq });
  return (
    <Dialog open={open} onClose={onClose} maxWidth={width} fullScreen={fullScreen} TransitionComponent={Transition}>
      <DialogTitle id="alert-dialog-slide-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{dialogText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <IconButton onClick={onClose}>
          <FontAwesomeIcon size="2x" icon="times-circle" />
        </IconButton>
        <IconButton onClick={onSubmitClick}>
          <FontAwesomeIcon size="2x" icon="check-circle" />
        </IconButton>
      </DialogActions>
      <Notification />
    </Dialog>
  );
};

export default SubmitDialog;

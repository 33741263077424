import React from 'react';
import Button from 'components/CustomButtons/Button';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import { batch, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'features';
import {
  addTextToFillInBlanks,
  addGapToFillInBlanks,
  updateTextFillInBlanksAt,
  updateGapFillInBlanksAt,
  setFillInBlanksMark,
  updateGapFillInBlanksMarkAt,
  addNewLineToFillInBlanks,
  removeTextFromFillInBlanks,
  deleteSelector,
  setDelete,
} from '../createSlice';
import WhyFillInBlanks from './WhyFillInBlanks';
import Button1 from '@mui/material/Button';
import { Delete } from '@material-ui/icons';

import { TextField } from '@material-ui/core';
import { NewLine } from 'components/NewLine/NewLine';
import DeleteDialog from 'components/Dialog/DeleteDialog';
import shortUUID from 'short-uuid';
const FIGNewLine: React.FC<{uuid: string; index: number; uuid2:string}> = props => {
  const dispatch = useDispatch();
  const [deleteElementDialogOpen, setDeleteElementDialogOpen] = React.useState(false);
  const handleDelete = ()=>{
    setDeleteElementDialogOpen(true)
  }
  const removeQuestionElement = () => {
    
    setDeleteElementDialogOpen(false)
    dispatch(setDelete({isDelete:true, index:props.index, uuid:props.uuid2}))
  };
  return (
  <>
  <NewLine style={{ color: 'gray', fontStyle: 'italic', userSelect: 'none' }}>
    <Button1  startIcon={<Delete/>} onClick = {handleDelete}></Button1>
    {`<--new line-->`}
    </NewLine>
    < DeleteDialog
            dialogText="Are you sure you want to delete this entry?"
            onSubmitClick={removeQuestionElement}
            open={deleteElementDialogOpen}
            onClose={() => setDeleteElementDialogOpen(false)}
    />
  </>
);
  }
const TextArea: React.FC<{ uuid: string; index: number; value: string; uuid2:string }> = props => {
  
  const dispatch = useDispatch();
  const [textFieldSize, setTextFieldSize] = React.useState(10);
  const [deleteElementDialogOpen, setDeleteElementDialogOpen] = React.useState(false);
  const handleDelete = ()=>{
    setDeleteElementDialogOpen(true)
  }
  const removeQuestionElement = () => {
    
    setDeleteElementDialogOpen(false)
    dispatch(setDelete({isDelete:true, index:props.index, uuid:props.uuid2}))
  };
  return (
    <>
      <CustomInput
        style={{ width: `${textFieldSize}rem`, minWidth: '1rem' }}
        labelText="Question Text"
        id="text"
        inputProps={{
          type: 'text',
          rows: 1,
          defaultValue: props.value,
          onChange: e => {
            batch(() => {
              dispatch(
                updateTextFillInBlanksAt({
                  uuid: props.uuid,
                  value: e.target.value,
                  index: props.index,
                }),
              );
              if (e.target.value.length > 0 && e.target.value.length < textFieldSize && textFieldSize > 10) {
                setTextFieldSize(textFieldSize - 0.3);
              } else if (e.target.value.length >= textFieldSize) {
                setTextFieldSize(textFieldSize + 0.3);
              } else setTextFieldSize(10);
            });
          },
          endAdornment: (
              <div>
                <Button1  startIcon={<Delete/>} onClick = {handleDelete}></Button1>
              </div>
            ),
        }}
        
      />
     < DeleteDialog
            dialogText="Are you sure you want to delete this entry?"
            onSubmitClick={removeQuestionElement}
            open={deleteElementDialogOpen}
            onClose={() => setDeleteElementDialogOpen(false)}
          />
  </>
  );
};

const GapArea: React.FC<{ uuid: string; index: number; value: string; mark: number; uuid2:string }> = props => {
  const dispatch = useDispatch();
  const [deleteElementDialogOpen, setDeleteElementDialogOpen] = React.useState(false);
  const handleDelete = ()=>{
    setDeleteElementDialogOpen(true)
  }
  const removeQuestionElement = () => {
    setDeleteElementDialogOpen(false)
    dispatch(setDelete({isDelete:true, index:props.index, uuid:props.uuid2}))
  };
  return (
    <>
    <CustomInput
      labelText="Gap Area"
      id="text"
      inputProps={{
        type: 'text',
        multiline: true,
        rows: 1,
        defaultValue: props.value,
        onChange: e =>
          dispatch(
            updateGapFillInBlanksAt({
              uuid: props.uuid,
              value: e.target.value,
              index: props.index,
            }),
          ),
        placeholder: 'Write down the correct answers comma separated (ex. a,b,c) for this gap area..',
        endAdornment: (
          <div style={{ display: 'inline-flex' }}>
            <TextField
              size="small"
              defaultValue={props.mark}
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              onChange={e =>
                dispatch(
                  updateGapFillInBlanksMarkAt({
                    uuid: props.uuid,
                    value: parseInt(e.target.value, 10),
                    index: props.index,
                  }),
                )
              }
              variant="outlined"
            />
            <WhyFillInBlanks
              uuid={props.uuid}
              gapIndex={props.index}
              id={`gapWhyPart ${props.uuid + props.index + 1}`}
            />
            <div>
              <Button1  startIcon={<Delete/>} onClick = {handleDelete}></Button1>
            </div>
          </div>
        ),
      }}
    />
    <DeleteDialog
            dialogText="Are you sure you want to delete this entry?"
            onSubmitClick={removeQuestionElement}
            open={deleteElementDialogOpen}
            onClose={() => setDeleteElementDialogOpen(false)}
          />
    </>
  );
};

const FillInTheGapsQuestion: React.FC<{ uuid: string }> = (props: { uuid: string }) => {
  const deleteData = useSelector(deleteSelector);
  const gaps = useSelector(
    (state: RootState) =>
      (state.createQuestion.curQuestion.questions[props.uuid] as Types.API.Question.IFillInBlanksCreateInput)
        .correction,
  );
  var texts = useSelector(
    (state: RootState) =>
      (state.createQuestion.curQuestion.questions[props.uuid] as Types.API.Question.IFillInBlanksCreateInput).texts,
  );
  const dispatch = useDispatch();

  const totalLength = texts.length;

  const initQuestionElements = () => {
    let elements = [];
    for (let i = 0, k = 0, g=0; i < texts.length - 1; i++) {
      const uuid2 = shortUUID.generate()
      if (texts[i] === ('___GAP___' as Types.API.Question.FillInBlanksFieldType)) {
        elements.push(
          <GapArea mark={gaps.marks[k]} uuid={props.uuid} key={`gap-${k + 1}`} index={k} value={gaps.answer[k]} uuid2= {uuid2} />,
        );
        k++;
      } else if (texts[i] === ('___NEWLINE___' as Types.API.Question.FillInBlanksFieldType)) {
        elements.push(<FIGNewLine uuid={props.uuid} index={g} uuid2= {uuid2} />);
        g++;
      } else elements.push(<TextArea uuid={props.uuid} key={`text-${i + 1}`} value={texts[i]} index={i} uuid2={uuid2} />);
    }
    return elements;
  };
  const [updateState, setUpdateState] = React.useState(0);
  const [questionElements, setQuestionElements] = React.useState<{
    textIndex: number;
    gapIndex: number;
    elements: any[];
  }>({
    textIndex: texts.length,
    gapIndex: gaps.answer.length,
    elements: initQuestionElements(),
  });
  const updateQuestionElements = () => {
    const uuid2 = shortUUID.generate()
    let questionsElements = questionElements.elements;
    let j = questionElements.textIndex;
    let k = questionElements.gapIndex;
    let g = 0
    if (texts[texts.length - 1] === ('___GAP___' as Types.API.Question.FillInBlanksFieldType)) {
      questionsElements.push(
        <GapArea mark={gaps.marks[k]} uuid={props.uuid} key={`gap-${k + 1}`} index={k} value={gaps.answer[k]} uuid2= {uuid2} />,
      );
      k++;
    } else if (texts[texts.length - 1] === ('___NEWLINE___' as Types.API.Question.FillInBlanksFieldType)) {
        questionsElements.push(<FIGNewLine uuid={props.uuid}  index={g} uuid2= {uuid2} />);
        g++;
    } else questionsElements.push(<TextArea uuid={props.uuid} key={`text-${j + 1}`} value={texts[j]} index={j} uuid2={uuid2} />);

    j++;

    setQuestionElements({
      textIndex: j,
      gapIndex: k,
      elements: questionsElements,
    });
  };

  const removeQuestionElement = (index2:number, uuid: string, type: string) => {
    let questionsElements = questionElements.elements;
    const index =questionsElements.findIndex(x => x['props']['uuid2'] === uuid)
    let j = questionElements.textIndex;
    let k = questionElements.gapIndex;
    if(index <=questionElements.textIndex){
      if (texts[index] === ('___GAP___' as Types.API.Question.FillInBlanksFieldType)) {
        k--;
      }
      j--;
      questionsElements.splice(index,1);
      setQuestionElements({
        textIndex: j,
        gapIndex: k,
        elements: questionsElements,
      });
      dispatch(removeTextFromFillInBlanks({ uuid: props.uuid, index:index }));
      dispatch(setDelete({isDelete:false, index:index, uuid:uuid}));
    }
  };
  React.useEffect(()=>{
    if(deleteData['isDelete'])
      removeQuestionElement(deleteData['index'],deleteData['uuid'], 'text')
  },[deleteData])
  React.useEffect(() => {
    if (updateState > 0) updateQuestionElements();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  return (
    <div>
      <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
        <CardBody style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', alignItems: 'flex-end' }}>
          {totalLength > 0 && questionElements.elements.map(e => e)}
        </CardBody>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '1rem' }}>
          {totalLength > 0 && (
            <div style={{ height: '0%', flex: '1', justifySelf: 'end' }}>

            </div>
          )}
          <div style={{ height: '0%' }}>
            <Button
              color="white"
              onClick={_ => {
                dispatch(addTextToFillInBlanks({ uuid: props.uuid }));
                setUpdateState(updateState + 1);
              }}
              size="sm"
            >
              Add Text
            </Button>
          </div>
          <div style={{ height: '0%' }}>
            <Button
              color="white"
              onClick={_ => {
                dispatch(addGapToFillInBlanks({ uuid: props.uuid }));
                setUpdateState(updateState + 1);
              }}
              size="sm"
            >
              Add Gap
            </Button>
          </div>

          {totalLength > 0 && (
            <div style={{ height: '0%' }}>
              <Button
                color="white"
                onClick={_ => {
                  dispatch(addNewLineToFillInBlanks({ uuid: props.uuid }));
                  setUpdateState(updateState + 1);
                }}
                size="sm"
              >
                Add Newline
              </Button>
            </div>
          )}

          <CustomInput
            id="mark"
            labelText="Mark"
            inputProps={{
              type: 'number',
              inputProps: { min: 0, max: 10 },
              defaultValue: 1,
              onChange: (c: any) => dispatch(setFillInBlanksMark({ value: c.target.value, uuid: props.uuid })),
            }}
          />
        </div>
       
      </CardBody>
    </div>
  );
};

export default FillInTheGapsQuestion;

import React, { useEffect } from 'react';
import CustomDropzone from 'components/CustomDropzone/CustomDropzone';
import { Backdrop, Button, Card, CircularProgress, Divider, IconButton, TextField } from '@material-ui/core';
import CustomInput from 'components/CustomInput/CustomInput';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from '../../../config';
import {
  availableAdminFilesToDownloadSelector,
  availableStudentFilesToDownloadSelector,
  deleteFileAsync,
  downloadAdminFileAsync,
  downloadStudentFileAsync,
  isQuizCorrectionPublished,
  setTriggerSaveQuizAnswers,
  setWritingContentGrade,
  setWritingLanguageGrade,
  // setWritingText,
  subAnswerWithIndexSelector,
  updateInsructorCommentsForSpeakingQuestion,
  updateStudentComment,
  uploadStudentFilesAsync,
} from '../updateSlice';
import { RootState } from 'features';
import { useReqListener } from 'hooks';
import Notification from 'features/notifications/Notification';
import { getMessagesAsync, userSelector } from 'features/auth/authSlice';
import CardBody from 'components/Card/CardBody';
import ShowComments from './ShowComments';
import { GetApp } from '@material-ui/icons';
import { OpenInNew } from '@material-ui/icons';
import { studentsSelector, getStudentsAsync } from '../../admin/slices/adminSlice';
import { height, margin } from '@mui/system';
import { subQuestionWithIndexSelector } from '../updateSlice';
import ShowChat from './ShowChat';

export interface Props {}

const UpdateStudentWriting: React.FC<Props> = () => {
  const [open, setOpen] = React.useState(false);
  const [downloadURL, setDownloadURL] = React.useState('');
  const [downloadURLAdmin, setDownloadURLAdmin] = React.useState('');
  const [downloadURLAdminAudio, setDownloadURLAdminAudio] = React.useState('');
  const [openChat, setOpenChat] = React.useState(false);

  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const getStudentsReq = useSelector((state: RootState) => state.admin.getStudentsReq);
  const students = useSelector(studentsSelector);
  const token = useSelector((state: RootState) => state.auth.token);
  const questionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.questionIndex);

  const subQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.subQuestionIndex);
  const question = useSelector(
    subQuestionWithIndexSelector(questionIndex, subQuestionIndex),
  ) as Types.API.Question.IWritingEssaysQModel;
  const answer = useSelector(
    subAnswerWithIndexSelector(questionIndex, subQuestionIndex),
  ) as Types.API.Answer.IAnswerWritingModel;
  const answerId = answer._id;
  const uploadedStudentFiles = useSelector(availableStudentFilesToDownloadSelector);
  const currentQuestionStudentFiles = uploadedStudentFiles.filter(
    file =>
      file &&
      answer.files.some(f => {
        const id = (f as any).file ? (f as any).file._id : f._id ? f._id : f.toString();
        return id === file._id;
      }),
  );
  const activeQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.questionIndex);
  const activeSubQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.subQuestionIndex);

  const uploadedAdminFiles = useSelector(availableAdminFilesToDownloadSelector);
  const currentQuestionAdminFiles = uploadedAdminFiles.filter(
    file =>
      file &&
      answer.instructorFiles.some(f => {
        const id = (f as any).file ? (f as any).file._id : f._id ? f._id : f.toString();
        return id === file._id;
      }),
  );
  const userRole = useSelector(userSelector).role;
  const isPublished = useSelector(isQuizCorrectionPublished);
  const showInstructorFiles = isPublished && (userRole === 'student' || userRole === 'parent');

  useEffect(() => {
    if (dispatch && getStudentsReq.status === 'idle') {
      token && dispatch(getStudentsAsync({ token }));
    }
  }, [getStudentsReq.status, token, dispatch]);

  useEffect(() => {
    answer.files.forEach(file => {
      if (file) {
        const id = (file as any).file ? (file as any).file._id : file._id ? file._id : file.toString();
        if (!currentQuestionStudentFiles.some(f => f._id === id))
          dispatch(
            downloadStudentFileAsync({
              token: token,
              fileId: id,
            }),
          );
      }
    });

    if (showInstructorFiles) {
      answer.instructorFiles.forEach(file => {
        if (file) {
          const id = (file as any).file ? (file as any).file._id : file._id ? file._id : file.toString();
          if (!currentQuestionAdminFiles.some(f => f._id === id))
            dispatch(
              downloadAdminFileAsync({
                token: token,
                fileId: id,
              }),
            );
        }
      });
    }
  }, [answer]);

  const studentOtherFiles = currentQuestionStudentFiles.filter(file => {
    return file.filename && !file.filename.endsWith('mp3');
  });
  useEffect(() => {
    if (studentOtherFiles && studentOtherFiles[0] && studentOtherFiles[0].awsUrl)
      setDownloadURL(config.apiBaseUrl.replace('api', '') + 'media' + studentOtherFiles[0].awsUrl.split('data')[1]);
  }, [studentOtherFiles]);

  const getDownloadURL = (index: number) => {
    return config.apiBaseUrl.replace('api', '') + 'media' + studentOtherFiles[index].awsUrl.split('data')[1];
  };

  const adminOtherFiles = currentQuestionAdminFiles.filter(file => file.filename && !file.filename.endsWith('mp3'));
  useEffect(() => {
    if (adminOtherFiles && adminOtherFiles[0] && adminOtherFiles[0].awsUrl)
      setDownloadURLAdmin(config.apiBaseUrl.replace('api', '') + 'media' + adminOtherFiles[0].awsUrl.split('data')[1]);
  }, [adminOtherFiles]);

  const adminAudioFiles = currentQuestionAdminFiles.filter(file => file.filename && file.filename.endsWith('mp3'));
  useEffect(() => {
    if (adminAudioFiles && adminAudioFiles[0] && adminAudioFiles[0].awsUrl)
      setDownloadURLAdminAudio(
        config.apiBaseUrl.replace('api', '') + 'media' + adminAudioFiles[0].awsUrl.split('data')[1],
      );
  }, [adminAudioFiles]);

  const filesUploadReq = useSelector((state: RootState) => state.updateQuiz.studentFilesUploadsReq[answerId]);
  const filesDownloadReq = useSelector((state: RootState) => state.updateQuiz.downloadStudentFileReq);

  useReqListener({
    status: filesUploadReq.status,
    successMessage: 'Successfully uploaded the files to the storage',
    loadingMessage: 'Uploading your files to our storage',
    errorMessage: filesUploadReq.error,
  });

  useReqListener({
    status: filesDownloadReq.status,
    successMessage: 'Successfully loaded files',
    loadingMessage: 'Loading files..',
    errorMessage: filesDownloadReq.error,
  });

  const onCommentInstructorChange = (index: number) => (c: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    dispatch(
      updateInsructorCommentsForSpeakingQuestion({
        comment: c.target.value,
        questionNumber: questionIndex,
        subQuestionNumber: subQuestionIndex,
        commentIndex: index,
      }),
    );

  const onCommentStudentChange = (index: number) => (c: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    dispatch(
      updateStudentComment({
        studentComment: c.target.value,
        questionNumber: questionIndex,
        subQuestionNumber: subQuestionIndex,
        index,
      }),
    );

  const onSelectFilesClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleSave = (acceptedFiles: File[]) => {
    acceptedFiles.length &&
      dispatch(
        uploadStudentFilesAsync({
          files: acceptedFiles,
          token,
          answerId,
          subQuestionIndex: subQuestionIndex,
          questionIndex: questionIndex,
        }),
      );
    console.log('Here');
    slowTrigger(500);
    setOpen(false);
  };
  function slowTrigger(delay: number) {
    return new Promise(res => setTimeout(triggerSave, delay));
  }
  const triggerSave = () => {
    dispatch(setTriggerSaveQuizAnswers({ save: true }));
  };

  let name = '';
  if (userRole === 'student') {
    name = user.name;
  } else if (userRole === 'admin' && studentOtherFiles.length > 0) {
    name = students.filter(s => s.id === studentOtherFiles[0].user)[0]
      ? students.filter(s => s.id === studentOtherFiles[0].user)[0].name
      : '';
  }
  const handleShowChatClick = () => {
    const quizID = localStorage.getItem('quizID') || ' ';
    const studentID = localStorage.getItem('studentID') || ' ';

    if (studentID && quizID && question._id) {
      dispatch(
        getMessagesAsync({
          token,
          questionID: question._id || '',
          studentID: studentID || '',
          quizID: quizID || '',
        }),
      );
    }
    setOpenChat(true);
  };

  return (
    <>
      {/* {question && question?.modelType === 'WritingEssays' && (
        <div
          dangerouslySetInnerHTML={{
            __html: question?.text.replace(/\n/g, '<br/>'),
          }}
          style={{
            color: '#000',
            backgroundColor: '#fff',
            padding: '1rem',
          }}
        />
      )} */}

      <CardBody>
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'space-around',
            width: '100%',
            flexDirection: 'row',
          }}
        >
          {/* <Card>
          <h4 style={{ textAlign: 'center', fontWeight: 400 }}>Student Writing Space</h4>
        </Card>
        <CustomInput
          labelText={`Writing Space`}
          id="text"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            multiline: true,
            rows: 12,
            value: answer.text,
            readOnly: userRole !== 'student' || isPublished,
            onChange: ev => {
              dispatch(
                setWritingText({
                  text: ev.target.value,
                  questionNumber: questionIndex,
                  subQuestionNumber: subQuestionIndex,
                }),
              )
              // triggerSave()
            }
            ,
          }}
        /> */}
          {/* <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
          }}
        ></div> */}
          <div
            style={{
              minHeight: '250px',
              border: '3px #aaa solid',
              padding: '1%',
              borderRadius: '1rem',
            }}
          >
            <Card>
              <h4 style={{ textAlign: 'center', fontWeight: 400 }}>Student Files</h4>
            </Card>
            <h6 style={{ textAlign: 'center', fontWeight: 400, color: 'black' }}>** Student Uploaded Files **</h6>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
              }}
            >
              {studentOtherFiles.length !== 0 &&
                studentOtherFiles.map((file, i) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <a style={{ margin: '10px' }} href={getDownloadURL(i)} download target="_blank">
                      {file.filename}
                    </a>
                    <a href={getDownloadURL(i)} target="_blank">
                      <OpenInNew />
                    </a>
                    <a href={getDownloadURL(i)} download target="_blank">
                      <GetApp />
                    </a>
                    {userRole === 'student' && !isPublished && (
                      <IconButton
                        onClick={() => {
                          dispatch(
                            deleteFileAsync({
                              fileId: file._id,
                              token,
                              fileName: file.filename,
                              questionIndex,
                              subQuestionIndex,
                              isAdmin: false,
                            }),
                          );
                          triggerSave();
                        }}
                      >
                        <FontAwesomeIcon size="sm" icon="trash" />
                      </IconButton>
                    )}
                  </div>
                ))}
            </div>
            {userRole === 'student' && (
              <h6 style={{ fontWeight: 400, color: 'black' }}>** Accepted Files: audio/*, image/*, application/pdf</h6>
            )}
            {userRole === 'student' && !isPublished && (
              <Button variant="contained" fullWidth onClick={onSelectFilesClick}>
                Select your files
              </Button>
            )}
          </div>
          {userRole === 'admin' && (
            <React.Fragment>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  padding: '1rem',
                  alignItems: 'center',
                  border: '3px #aaa solid',
                  borderRadius: '1rem',
                }}
              >
                <Card>
                  <h4 style={{ textAlign: 'center', fontWeight: 400, padding: '0.2rem' }}>Instructor Grades</h4>
                </Card>
                <TextField
                  value={answer.contentMark}
                  label="Content Grade"
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 100,
                      min: 0,
                    },
                  }}
                  onChange={e =>
                    dispatch(
                      setWritingContentGrade({
                        questionNumber: activeQuestionIndex,
                        subQuestionNumber: activeSubQuestionIndex,
                        mark: +e.target.value,
                      }),
                    )
                  }
                  fullWidth={true}
                  variant="outlined"
                  style={{ margin: '15px' }}
                />
                <TextField
                  value={answer.languageMark}
                  label="Language Grade"
                  type="number"
                  InputProps={{
                    inputProps: {
                      max: 100,
                      min: 0,
                    },
                  }}
                  onChange={e =>
                    dispatch(
                      setWritingLanguageGrade({
                        questionNumber: activeQuestionIndex,
                        subQuestionNumber: activeSubQuestionIndex,
                        mark: +e.target.value,
                      }),
                    )
                  }
                  variant="outlined"
                  fullWidth={true}
                  style={{ margin: '15px' }}
                />
                <TextField
                  value={answer.languageMark + answer.contentMark}
                  label="Total"
                  type="number"
                  disabled
                  InputProps={{
                    inputProps: {
                      max: 200,
                      min: 0,
                    },
                  }}
                  variant="outlined"
                  fullWidth={true}
                  style={{ margin: '15px' }}
                />
              </div>
            </React.Fragment>
          )}
          {showInstructorFiles && (
            <div
              style={{
                border: '3px #ab05dd solid',
                borderRadius: '1rem',
                padding: '1rem',
                alignContent: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              ></div>
              <Card>
                <h4 style={{ textAlign: 'center', fontWeight: 400 }}>Teacher Files</h4>
              </Card>
              <h6 style={{ textAlign: 'center', fontWeight: 400, color: 'black' }}>** Teacher Uploaded Files **</h6>

              <h6 style={{ fontWeight: 400, color: 'black', visibility: 'hidden' }}>
                ** Accepted Files: audio/*, image/*, application/pdf
              </h6>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '10px',
                }}
              >
                {adminOtherFiles.length !== 0 &&
                  adminOtherFiles.map(file => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <a
                        style={{ margin: '10px' }}
                        href={config.apiBaseUrl.replace('api', '') + 'media' + file.awsUrl.split('data')[1]}
                        download
                        target="_blank"
                      >
                        {file.filename}
                      </a>
                      <a
                        href={config.apiBaseUrl.replace('api', '') + 'media' + file.awsUrl.split('data')[1]}
                        download
                        target="_blank"
                      >
                        <GetApp />
                      </a>
                    </div>
                  ))}
              </div>
              {answer.instructorComments.map((comment, i) => (
                <CustomInput
                  labelText={`Comment #${i + 1}`}
                  id="text"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    multiline: true,
                    rows: 8,
                    value: comment,
                    readOnly: userRole === 'student' || userRole === 'parent',
                  }}
                />
              ))}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '10px',
                }}
              >
                {adminAudioFiles.length !== 0 &&
                  adminAudioFiles.map(file => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <audio controls preload="auto">
                        <source src={downloadURLAdminAudio} />
                      </audio>
                    </div>
                  ))}
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '10px' }}>
                <div style={{ alignSelf: 'center' }}> </div>
              </div>
            </div>
          )}
          {filesUploadReq.status !== 'loading' && (
            <CustomDropzone
              filesLimit={20}
              onClose={onClose}
              open={open}
              initialFiles={[]}
              clearOnUnmount={false}
              accept={['audio/*', 'image/*', 'application/pdf']}
              onSave={handleSave}
            />
          )}
          <Notification />
        </div>
        <Backdrop
          color="#fff"
          style={{
            zIndex: 1000,
            color: '#fff',
            display: 'flex',
            flexDirection: 'column',
          }}
          open={filesUploadReq.status === 'loading'}
        >
          <CircularProgress color="inherit" style={{ marginBottom: '20px' }} />
          <p>UPLOADING...</p>
        </Backdrop>
      </CardBody>
      {showInstructorFiles && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '5%',
            height: '5%',
          }}
        >
          <Card style={{ width: '34%', margin: '0.5%', border: ' 1px gray solid' }}>
            <div style={{ padding: '10px' }}>
              <h5 style={{ fontWeight: 400, fontSize: '30px' }}>
                {`Content : `}
                <span style={{ border: '#3f51b5 1px solid', padding: '5px', margin: '2px' }}>{answer.contentMark}</span>
              </h5>
              <h5 style={{ fontWeight: 400, fontSize: '30px ' }}>
                {`Language : `}
                <span style={{ border: '#3f51b5 1px solid', padding: '5px', margin: '2px' }}>
                  {answer.languageMark}
                </span>
              </h5>
              <h5 style={{ fontWeight: 400, fontSize: '30px' }}>
                {`Total : `}
                <span style={{ border: '#3f51b5 1px solid', padding: '5px', margin: '2px' }}>
                  {answer.contentMark + answer.languageMark}
                </span>
              </h5>
            </div>
          </Card>

          <Card style={{ width: '34%', margin: '0.5%', border: ' 1px gray solid' }}>
            <h4 style={{ textAlign: 'center', fontWeight: 400, fontSize: '30px' }}>Teacher Comments</h4>
            <div>
              {answer.instructorComments.map((comment, i) => (
                <CustomInput
                  labelText={`Comment #${i + 1}`}
                  id="text"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    multiline: true,
                    rows: 8,
                    value: comment,
                    readOnly: userRole === 'student' || userRole === 'parent',
                  }}
                />
              ))}
            </div>
          </Card>
          {userRole === 'student' && (
            <>
              <Button
                style={{ background: '#00b4d8', width: '30%', margin: '0.5%', fontSize: '30px' }}
                onClick={() => handleShowChatClick()}
              >
                Talk to the teacher
              </Button>
              <ShowChat
                open={openChat}
                setOpen={setOpenChat}
                questionID={question._id}
                quizID={localStorage.getItem('quizID') || ' '}
                senderName={localStorage.getItem('studentName') || ' '}
                studentID={localStorage.getItem('studentID') || ' '}
                questionNumber={questionIndex + 1}
                subQuestionNumber={subQuestionIndex + 1}
                modelType={question.modelType}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default UpdateStudentWriting;

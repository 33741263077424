import React from 'react';

// nodejs library that concatenates classes
import classNames from 'classnames';

// @material-ui/core components
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
// core components
import buttonStyle from '../../assets/jss/lms/components/buttonStyle';

const useButtonStyles = makeStyles(() => buttonStyle);

const RegularButton: React.FC<Props> = ({
  color = 'white',
  round,
  children,
  fullWidth,
  disabled,
  simple,
  size = 'lg',
  block,
  link,
  justIcon,
  className = '',
  tooltip,
  ...rest
}) => {
  const classes = useButtonStyles();

  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className,
  });

  if (tooltip)
    return (
      <Tooltip arrow title={tooltip}>
        <span>
          <Button {...rest} className={btnClasses}>
            {children}
          </Button>
        </span>
      </Tooltip>
    );

  return (
    <Button {...rest} className={btnClasses}>
      {children}
    </Button>
  );
};

export interface Props {
  color?:
    | 'primary'
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'rose'
    | 'white'
    | 'facebook'
    | 'twitter'
    | 'google'
    | 'github'
    | 'transparent';
  size?: 'xsm' | 'sm' | 'lg';
  onClick?(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  simple?: boolean;
  round?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  block?: boolean;
  link?: boolean;
  justIcon?: boolean;
  className?: string;
  href?: string;
  target?: string;
  rel?: string;
  tooltip?: string;
}

export default RegularButton;

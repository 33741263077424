import React from 'react';
import UpdateFIG from '../UpdateSimpleAnswers/UpdateFIG';
import UpdateMCQ from '../UpdateSimpleAnswers/UpdateMCQ';
import NoteTakingAnswer from '../UpdateSimpleAnswers/UpdateNT';
import UpdateSA from '../UpdateSimpleAnswers/UpdateSA';
import UpdateSM from './UpdateSM';
import UpdateVocab from './UpdateVocab';
import UpdateRubric from './UpdateRubric';

interface Props {
  questionIndex: number;
  subQuestionIndex: number;
  questionType: Types.API.Question.QuestionModelType;
  sectionType?: Types.API.Question.QuestionSectionType;
}
const UpdateSimpleAnswer: React.FC<Props> = ({ questionIndex, subQuestionIndex, questionType, sectionType }) => {
  switch (questionType) {
    case 'FillInBlanks':
      return <UpdateFIG questionIndex={questionIndex} subQuestionIndex={subQuestionIndex} />;
    case 'Vocab':
      return <UpdateVocab questionIndex={questionIndex} subQuestionIndex={subQuestionIndex} />;
    case 'MCQ':
      return <UpdateMCQ questionIndex={questionIndex} subQuestionIndex={subQuestionIndex} />;
    case 'NoteTaking':
      return <NoteTakingAnswer questionIndex={questionIndex} subQuestionIndex={subQuestionIndex} />;
    case 'ShortAnswers':
      return <UpdateSA questionIndex={questionIndex} subQuestionIndex={subQuestionIndex} />;
    case 'SpeakerMatching':
      return <UpdateSM questionIndex={questionIndex} subQuestionIndex={subQuestionIndex} />;
    case 'Rubric':
      return <UpdateRubric questionIndex={questionIndex} subQuestionIndex={subQuestionIndex} />;
    default:
      return null;
  }
};

export default UpdateSimpleAnswer;

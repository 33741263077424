import React from 'react';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'features';
import {
  setSpeakerMatchingName,
  setSpeakerMatchingOpinion,
  setSpeakerMatchingWrongOpinion,
  setSpeakerMatchingMark,
  increaseSpeakerMatchingSpeakersCount,
  decreaseSpeakerMatchingSpeakersCount,
} from '../createSlice';
import WhySpeakerMatching from './WhySpeakerMatching';

const SpeakerMatchingQuestion: React.FC<{ uuid: string }> = (props: { uuid: string }) => {
  const speakers = useSelector(
    (state: RootState) =>
      state.createQuestion.curQuestion.questions[props.uuid] as Types.API.Question.ISpeakerMatchingCreateInput,
  ).speakers;
  const speakersCount = useSelector(
    (state: RootState) =>
      state.createQuestion.curQuestion.questions[props.uuid] as Types.API.Question.ISpeakerMatchingCreateInput,
  ).speakersCount;

  const mark = useSelector(
    (state: RootState) =>
      state.createQuestion.curQuestion.questions[props.uuid] as Types.API.Question.ISpeakerMatchingCreateInput,
  ).mark;

  const dispatch = useDispatch();

  return (
    <CardBody>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CustomInput
          labelText="Speakers Count"
          id="SpeakerMatchingSpeakersCount"
          inputProps={{
            type: 'number',
            inputProps: { min: 1, max: 20 },
            value: speakersCount,
            onChange: c => {
              const newValue = parseInt(c.target.value, 10);
              if (newValue > speakersCount) {
                dispatch(increaseSpeakerMatchingSpeakersCount({ uuid: props.uuid }));
              } else {
                dispatch(decreaseSpeakerMatchingSpeakersCount({ uuid: props.uuid }));
              }
            },
          }}
        />
        {speakers.map(
          (speaker, i) =>
            i !== 0 && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', float: 'right' }}>
                  <CustomInput
                    labelText={'Speaker: ' + i}
                    id={'speaker' + i}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      value: speaker.name,
                      multiline: true,
                      rows: 1,
                      onChange: e =>
                        dispatch(setSpeakerMatchingName({ text: e.target.value, uuid: props.uuid, index: i })),
                    }}
                  />
                  <CustomInput
                    labelText={'Opinion of speaker: ' + i}
                    id={'opinion' + i}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'text',
                      value: speaker.opinion,
                      multiline: true,
                      rows: 1,
                      onChange: e =>
                        dispatch(setSpeakerMatchingOpinion({ text: e.target.value, uuid: props.uuid, index: i })),
                    }}
                  />
                </div>
                <WhySpeakerMatching uuid={props.uuid} index={i} id={`WhySpeakerMatching input${props.uuid + i + 1}`} />
              </div>
            ),
        )}
        <CustomInput
          labelText="Wrong opinion"
          id={'opinion 0'}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            value: speakers[0].opinion,
            multiline: true,
            rows: 1,
            onChange: e => dispatch(setSpeakerMatchingWrongOpinion({ text: e.target.value, uuid: props.uuid })),
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'row', float: 'right' }}>
          <CustomInput
            id="mark"
            labelText="Mark"
            inputProps={{
              type: 'number',
              inputProps: { min: 0, max: 20 },
              value: mark,
              onChange: c =>
                dispatch(setSpeakerMatchingMark({ value: parseInt(c.target.value, 10), uuid: props.uuid })),
            }}
          />
        </div>
      </div>
    </CardBody>
  );
};

export default SpeakerMatchingQuestion;

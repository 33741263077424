import { useEffect } from 'react';
import {
  userSelector,
  verifyTokenSelector,
  tokenSelector,
  verifyTokenAsync,
  loggedInSelector,
} from 'features/auth/authSlice';
import { AdminRoutesPrefix } from 'layouts/AdminLayout';
import { AssistantRoutesPrefix } from 'layouts/AssistantLayout';
import { LandingRoutesPrefix } from 'layouts/LandingLayout';
import { ParentRoutesPrefix } from 'layouts/ParentLayout';
import { StudentRoutesPrefix } from 'layouts/StudentLayout';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const useViewAuth = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const isLoggedIn = useSelector(loggedInSelector);
  const token = useSelector(tokenSelector);
  const verifyTokenStatus = useSelector(verifyTokenSelector);
  useEffect(() => {
    if (verifyTokenStatus === 'idle' && token !== '' && dispatch) {
      dispatch(verifyTokenAsync({ token }));
    }
  }, [token, verifyTokenStatus, dispatch]);
  // useEffect(() => {
  //   if (!isLoggedIn && verifyTokenStatus === 'succeeded') {
  //     history.push(LandingRoutesPrefix + '/home');
  //   }
  // }, [history, isLoggedIn, verifyTokenStatus]);
  const pathname = history.location.pathname;

  if (verifyTokenStatus === 'succeeded' || verifyTokenStatus === 'failed') {
    if (!isLoggedIn) {
      history.push(LandingRoutesPrefix + '/home');
    } else {
      switch (user.role) {
        case 'student':
          if (!pathname.startsWith(StudentRoutesPrefix)) history.push(StudentRoutesPrefix);
          break;
        case 'parent':
          if (!pathname.startsWith(ParentRoutesPrefix)) history.push(ParentRoutesPrefix);
          break;
        case 'assistant':
          if (!pathname.startsWith(AssistantRoutesPrefix)) history.push(AssistantRoutesPrefix);
          break;
        case 'admin':
          // I believe we can let admin, navigate through all pages;
          if (!pathname.startsWith(AdminRoutesPrefix)) history.push(AdminRoutesPrefix);
          break;
        default:
          history.push(LandingRoutesPrefix);
      }
    }
  }

  if (verifyTokenStatus === 'idle') {
    if (!isLoggedIn && token === '') {
      history.push(LandingRoutesPrefix + '/home');
      return;
    }
  }
};

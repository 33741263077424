import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import CustomInput from 'components/CustomInput/CustomInput';

import styles from 'assets/jss/lms/views/landing/login';

import { resetPasswordAsync, resetPasswordSelector, tokenSelector, userSelector } from 'features/auth/authSlice';

import { useInputForm, useReqListener } from 'hooks';

const useStyles = makeStyles(styles);

const ResetPassword: React.FC = props => {
  const [cardAnimation, setCardAnimation] = React.useState('cardHidden');
  const [passOldState, onPassOldFormChange] = useInputForm({});
  const [passNewState, onPassNewFormChange] = useInputForm({});
  const [passConfirmState, onPassConfirmFormChange] = useInputForm({});
  const resetPasswordReq = useSelector(resetPasswordSelector);
  const user = useSelector(userSelector);
  const token = useSelector(tokenSelector);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(
      resetPasswordAsync({
        userId: user.id,
        token,
        oldPassword: passOldState.inputValue,
        newPassword: passNewState.inputValue,
        confirmPassword: passConfirmState.inputValue,
      }),
    );
  };

  useEffect(() => {
    setTimeout(function () {
      setCardAnimation('');
    }, 300);
  }, []);

  useReqListener({
    status: resetPasswordReq.status,
    successMessage: 'Password Changed Successfully',
    loadingMessage: 'Changing Password',
    errorMessage: resetPasswordReq.error,
  });

  useEffect(() => {
    if (resetPasswordReq.status === 'succeeded') window.location.reload();
  }, [resetPasswordReq.status]);

  const classes = useStyles();

  return (
    <div>
      <div>
        <div
        //  className={classes.container}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card plain={false} carousel={false} className={classes[cardAnimation]}>
                <form className={classes.form}>
                  <Box mt={2}>
                    <Typography align="center" variant="h6" color="textSecondary">
                      First time to login ?
                    </Typography>
                    <Typography align="center" variant="h6" color="textSecondary">
                      Please Change Your Password
                    </Typography>
                  </Box>
                  <CardBody>
                    <CustomInput
                      labelText="Old Password"
                      id="pass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'password',
                        autoComplete: 'off',
                        onChange: onPassOldFormChange,
                        value: passOldState.inputValue,
                      }}
                    />
                    <CustomInput
                      labelText="New Password"
                      id="pass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'password',
                        autoComplete: 'off',
                        onChange: onPassNewFormChange,
                        value: passNewState.inputValue,
                      }}
                    />
                    <CustomInput
                      labelText="Confirm Password"
                      id="pass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'password',
                        autoComplete: 'off',
                        onChange: onPassConfirmFormChange,
                        value: passConfirmState.inputValue,
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button onClick={handleSubmit} color="primary" size="lg">
                      Change Password
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

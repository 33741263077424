import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import notificationReducer from './notifications/notificationSlice';
import createQuestionReducer from './questions/createSlice';
import editQuestionReducer from './editQuestions/editSlice';
import adminReducer from './admin/slices/adminSlice';
import studentReducer from './student/studentSlice';
import updateQuizReducer from './quiz/updateSlice';
import adminViewReducer from './admin/slices/adminViewSlice';
import parentViewReducer from './parent/parentSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    notification: notificationReducer,
    createQuestion: createQuestionReducer,
    admin: adminReducer,
    student: studentReducer,
    editQuestion: editQuestionReducer,
    updateQuiz: updateQuizReducer,
    adminView: adminViewReducer,
    parent: parentViewReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

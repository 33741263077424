import { makeStyles } from '@material-ui/core';
import styles from 'assets/jss/lms/features/questions';
import React from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import AdminRightScreen from './UpdateSimpleAnswers/AdminRightScreen';
import AnswerRightScreen from './UpdateSimpleAnswers/AnswerRightScreen';
import AnswerLeftScreen from './UpdateSimpleAnswers/AnswerLeftScreen';
import { useSelector } from 'react-redux';
import { userSelector } from 'features/auth/authSlice';
import { quizTakenSelector } from './updateSlice';
import { RootState } from 'features';
import UpdateRubric from './UpdateSimpleAnswers/UpdateRubric';
import { questionWithIndexSelector } from './updateSlice';

const useStyles = makeStyles(styles);

const AnswerRenderer: React.FC = props => {
  const classes = useStyles();
  const userRole = useSelector(userSelector).role;
  const isFetchDone = useSelector(quizTakenSelector).answers.length !== 0;
  const activeQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.questionIndex);
  const activeSubQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.subQuestionIndex);
  const questions = useSelector(questionWithIndexSelector(activeQuestionIndex)).questions;
  const rubric = questions.filter(q => q.modelType === 'Rubric');

  let isWritingPhotoDescription: Boolean = false;
  let isEssay: Boolean = false;
  questions.forEach(function (x) {
    isWritingPhotoDescription = x.modelType === 'WritingPhotoDescription' ? true : false;
    isEssay = x.modelType === 'WritingEssays' ? true : false;
  });
  return (
    <div className={classes.container}>
      {isFetchDone && (
        <GridContainer>
          {(userRole === 'student' || userRole === 'parent') && rubric.length !== 0 && (
            <GridItem>
              {<UpdateRubric questionIndex={activeQuestionIndex} subQuestionIndex={activeSubQuestionIndex} />}
            </GridItem>
          )}
          <GridItem
            xs={isWritingPhotoDescription || isEssay ? 12 : 6}
            sm={isWritingPhotoDescription || isEssay ? 12 : 6}
            md={isWritingPhotoDescription || isEssay ? 12 : 6}
            lg={isWritingPhotoDescription || isEssay ? 12 : 6}
          >
            {/* AnswerLeftScreen is the question header and image */}
            <AnswerLeftScreen />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {/* AnswerRightScreen is the Files to be uploaded */}
            <AnswerRightScreen />
            {userRole === 'admin' && <AdminRightScreen />}
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
};

export default AnswerRenderer;

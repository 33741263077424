import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';

import SwipeableViews from 'react-swipeable-views';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import styles from 'assets/jss/lms/components/navPillsStyle';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';

const useStyles = makeStyles(styles);

export default function NavPills(props: Props) {
  const { tabs, direction, color = '', horizontal, alignCenter, setExpanded } = props;
  const [active, setActive] = React.useState(props.active);
  const handleChange = (event: React.ChangeEvent<{}>, active: number) => {
    setActive(active);
    setExpanded && setExpanded(false);
  };
  const handleChangeIndex = (index: number) => {
    setActive(index);
  };
  const classes = useStyles();
  const flexContainerClasses = classNames({
    [classes.flexContainer]: true,
    [classes.horizontalDisplay]: horizontal !== undefined,
  });

  const tabButtons = (
    <Tabs
      classes={{
        root: classes.root,
        fixed: classes.fixed,
        flexContainer: flexContainerClasses,
        indicator: classes.displayNone,
      }}
      value={active}
      onChange={handleChange}
      centered={alignCenter}
      orientation={props.orientation ?? 'horizontal'}
    >
      {tabs.map((prop, key) => {
        var icon = {};
        if (prop.tabIcon !== undefined) {
          icon['icon'] = <prop.tabIcon className={classes.tabIcon} />;
        }
        const pillsClasses = classNames({
          [classes.pills]: true,
          [classes.horizontalPills]: horizontal !== undefined,
          [classes.pillsWithIcons]: prop.tabIcon !== undefined,
        });
        return (
          <Tab
            label={prop.tabButton}
            key={key}
            {...icon}
            classes={{
              root: pillsClasses,
              selected: classes[color],
              wrapper: classes.tabWrapper,
            }}
          />
        );
      })}
    </Tabs>
  );
  const tabContent = (
    <div className={classes.contentWrapper}>
      <SwipeableViews axis={direction === 'rtl' ? 'x-reverse' : 'x'} index={active} onChangeIndex={handleChangeIndex}>
        {tabs.map((prop, key) => {
          return <div key={key}>{prop.tabContent}</div>;
        })}
      </SwipeableViews>
    </div>
  );
  return horizontal !== undefined ? (
    <GridContainer>
      <GridItem {...horizontal.tabsGrid}>{tabButtons}</GridItem>
      <GridItem {...horizontal.contentGrid}>{tabContent}</GridItem>
    </GridContainer>
  ) : (
    <div>
      {tabButtons}
      {tabContent}
    </div>
  );
}

NavPills.defaultProps = {
  active: 0,
  color: 'primary',
};

interface Props {
  // index of the default active pill
  active: number;
  tabs: {
    tabButton: string;
    tabIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    tabContent: React.ReactNode;
  }[];
  color?: 'primary' | 'warning' | 'danger' | 'success' | 'info' | 'rose';
  direction?: string;
  horizontal?: {
    tabsGrid: object;
    contentGrid: object;
  };
  alignCenter: boolean;
  orientation?: 'horizontal' | 'vertical';
  setExpanded?: React.Dispatch<React.SetStateAction<string | false>>;
}

import { Grid } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import { RootState } from 'features';
import {
  setWritingPhotoDescriptionText,
  setContentMarksWritingPhotoDescription,
  setLanguageMarksWritingPhotoDescription,
} from '../createSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateQImage from './CreateQImage';
interface Props {
  uuid: string;
}
const WritingPhotoDQuestion: React.FC<Props> = ({ uuid }) => {
  const dispatch = useDispatch();
  const inputData = useSelector(
    (state: RootState) => state.createQuestion.curQuestion.questions[uuid],
  ) as Types.API.Question.IWritingPhotoDescriptionCreateInput;
  return (
    <CardBody>
      <Grid container direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={8} md={10}>
          <CustomInput
            labelText="Question Text"
            id="text"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: 'text',
              value: inputData.text,
              multiline: true,
              rows: 3,
              onChange: e => dispatch(setWritingPhotoDescriptionText({ text: e.target.value, uuid })),
            }}
            allowEdit={true}
            uuid = {uuid}
            setText = {setWritingPhotoDescriptionText}
          />
        </Grid>
        <Grid item>
          <CustomInput
            labelText="Content Marks"
            id={`ContentMarks${uuid}`}
            inputProps={{
              type: 'number',
              disableUnderline: true,
              inputProps: { min: 1, max: 100 },
              value: inputData.contentMark,
              onChange: c =>
                dispatch(
                  setContentMarksWritingPhotoDescription({
                    value: parseInt(c.target.value, 10),
                    uuid,
                  }),
                ),
            }}
          />
        </Grid>
        <Grid item>
          <CustomInput
            labelText="Language Marks"
            id={`LanguageMarks${uuid}`}
            inputProps={{
              type: 'number',
              disableUnderline: true,
              inputProps: { min: 1, max: 100 },
              value: inputData.languageMark,
              onChange: c =>
                dispatch(
                  setLanguageMarksWritingPhotoDescription({
                    value: parseInt(c.target.value, 10),
                    uuid,
                  }),
                ),
            }}
          />
        </Grid>
      </Grid>
      <CreateQImage uuid={uuid} />
    </CardBody>
  );
};

export default WritingPhotoDQuestion;

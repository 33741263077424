import { Grid } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import { RootState } from 'features';
import { setWritingEssaysText, setContentMarksWritingEssays, setLanguageMarksWritingEssays } from '../editSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import CreateQImage from './EditQImage';
interface Props {
  uuid: string;
}
const WritingEssaysQuestion: React.FC<Props> = ({ uuid }) => {
  const dispatch = useDispatch();
  const inputData = useSelector(
    (state: RootState) => state.editQuestion.editingQuestion.questions[uuid],
    ) as Types.API.Question.IWritingEssaysQModel;
      console.log(inputData.text)

  return (
    <CardBody>
      <Grid container direction="row"
        justify="space-between"
        alignItems="center"
      >
        <div dangerouslySetInnerHTML={{ __html: inputData.text.replace(/\n/g, '<br>') }} />

        <Grid item xs={8} md={10}>
          <CustomInput
            labelText="Essay Question Text"
            id="text"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: 'text',
              value: inputData.text,
              multiline: true,
              rows: 3,
              onChange: e => dispatch(setWritingEssaysText({ text: e.target.value, uuid })),
            }}
            allowEdit={true}
            uuid = {uuid}
            setText = {setWritingEssaysText}
          />
        </Grid>
        <Grid item>
          <CustomInput
            labelText="Content Marks"
            id={`ContentMarks${uuid}`}
            inputProps={{
              type: 'number',
              disableUnderline: true,
              inputProps: { min: 1, max: 100 },
              value: inputData.contentMark,
              onChange: c =>
                dispatch(
                  setContentMarksWritingEssays({
                    value: parseInt(c.target.value, 10),
                    uuid,
                  }),
                ),
            }}
          />
        </Grid>
        <Grid item>
          <CustomInput
            labelText="Language Marks"
            id={`LanguageMarks${uuid}`}
            inputProps={{
              type: 'number',
              disableUnderline: true,
              inputProps: { min: 1, max: 100 },
              value: inputData.languageMark,
              onChange: c =>
                dispatch(
                  setLanguageMarksWritingEssays({
                    value: parseInt(c.target.value, 10),
                    uuid,
                  }),
                ),
            }}
          />
        </Grid>
      </Grid>
      <h4>Rubric Image Upload</h4>
      {/* <CreateQImage uuid={uuid} /> */}
      <div style={{ float: 'right', width: 'auto', zIndex: 1000, position: 'relative' }}>
        
      </div>
    </CardBody>
  );
};

export default WritingEssaysQuestion;

import React from 'react';

import { Accordion, AccordionSummary, AccordionDetails, Box, Paper } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import MonthContent from './MonthContent';
import { formateMonthDataPair, sortMonths } from 'features/helpers';
import CustomCircularProgress from 'components/Progress/CustomCircularProgress';

interface HomeworkAccordionContentProps {
  data: {
    homeworks: Types.API.Quiz.IQuizInfo[];
    expiredHomeworks: Types.API.Quiz.IQuizInfo[];
    runningHomeworks: Types.API.QuizTaken.IQuizTakenInfo[];
    // finishedHomeworks not used
    finishedHomeworks: Types.API.QuizTaken.IQuizTakenInfo[];
    gradePublishedHomeworks: Types.API.QuizTaken.IQuizTakenInfo[];
  };
  type: 'allData' | 'running' | 'available' | 'published' | 'late' | 'vdo-lect' | 'vdo-speaking'; // in progress | new | finished | late
  expanded: string | false;
  handleExpansion: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  panel: string;
  isLoading?: boolean;
}
interface QuizAccordionContentProps {
  data: {
    quizzes: Types.API.Quiz.IQuizInfo[];
    expiredQuizzes: Types.API.Quiz.IQuizInfo[];
    runningQuizzes: Types.API.QuizTaken.IQuizTakenInfo[];
    // finishedQuizzes not used
    finishedQuizzes: Types.API.QuizTaken.IQuizTakenInfo[];
    gradePublishedQuizzes: Types.API.QuizTaken.IQuizTakenInfo[];
  };
  type: 'allData' | 'running' | 'available' | 'published' | 'late' | 'vdo-lect' | 'vdo-speaking'; // in progress | new | finished | late
  expanded: string | false;
  handleExpansion: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  panel: string;
  isLoading?: boolean;
}
export const HomeworkAccordionContent: React.FC<HomeworkAccordionContentProps> = ({
  data,
  type,
  expanded,
  handleExpansion,
  panel,
  isLoading = false,
}) => {
  const allData = [
    ...data.homeworks.map(homework => ({ ...homework, type: 'available' })),
    ...data.expiredHomeworks.map(homework => ({ ...homework, type: 'late' })),
    ...data.runningHomeworks.map(homework => ({ ...homework, type: 'running' })),
    ...data.gradePublishedHomeworks.map(homework => ({ ...homework, type: 'published' })),
  ];
  const renderData = () => {
    if (isLoading) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <CustomCircularProgress />
          Loading...
        </div>
      );
    }
    if (allData?.length !== 0)
      return (
        <MonthContent
          type={type}
          monthDataPair={formateMonthDataPair(allData, 'vdo-lect')}
          sortedMonths={sortMonths(formateMonthDataPair(allData, 'vdo-lect'))}
        />
      );
    else {
      return <h2>There is no content here</h2>;
    }
  };

  return (
    <Box mb={2}>
      <Paper variant="outlined" elevation={0}>
        <Accordion expanded={expanded === panel} onChange={handleExpansion(panel)}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
            <b>Homework</b>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>{allData && renderData()}</AccordionDetails>
        </Accordion>
      </Paper>
    </Box>
  );
};

export const QuizAccordionContent: React.FC<QuizAccordionContentProps> = ({
  data,
  type,
  expanded,
  handleExpansion,
  panel,
  isLoading = false,
}) => {
  const allData = [
    ...data.quizzes.map(quiz => ({ ...quiz, type: 'available' })),
    ...data.expiredQuizzes.map(quiz => ({ ...quiz, type: 'late' })),
    ...data.runningQuizzes.map(quiz => ({ ...quiz, type: 'running' })),
    ...data.gradePublishedQuizzes.map(quiz => ({ ...quiz, type: 'published' })),
  ];
  const renderData = () => {
    if (isLoading) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <CustomCircularProgress />
          Loading...
        </div>
      );
    }
    if (allData?.length !== 0)
      return (
        <MonthContent
          type={type}
          monthDataPair={formateMonthDataPair(allData, 'vdo-lect')}
          sortedMonths={sortMonths(formateMonthDataPair(allData, 'vdo-lect'))}
        />
      );
    else {
      return <h2>There is no content here</h2>;
    }
  };

  return (
    <Box mb={2}>
      <Paper variant="outlined" elevation={0}>
        <Accordion expanded={expanded === panel} onChange={handleExpansion(panel)}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
            <b>Quizzes</b>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>{allData && renderData()}</AccordionDetails>
        </Accordion>
      </Paper>
    </Box>
  );
};

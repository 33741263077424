import { createStyles } from '@material-ui/core/styles';
import { title, container } from '../../lms';

const adminLayout = createStyles({
  container: {
    zIndex: 12,
    color: '#FFFFFF',
    ...container,
  },
  title: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    color: '#FFFFFF',
    textDecoration: 'none',
  },
  subtitle: {
    fontSize: '1.313rem',
    maxWidth: '500px',
    margin: '10px auto 0',
  },
  main: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: 3,
    margin: '50px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },

  productSection: {
    padding: '70px 0',
    textAlign: 'center',
  },
  productTitle: {
    ...title,
    marginBottom: '1rem',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
  },
  productDescription: {
    color: '#999',
  },
  requestSection: {
    padding: '70px 0',
  },
  requestTitle: {
    ...title,
    marginBottom: '50px',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
    textAlign: 'center',
  },
  requestDescription: {
    color: '#999',
    textAlign: 'center',
  },
  requestTextCenter: {
    textAlign: 'center',
  },
  requestTextArea: {
    marginRight: '15px',
    marginLeft: '15px',
  },
});

export default adminLayout;

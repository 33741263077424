import * as React from 'react';
import Button from '../../components/CustomButtons/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/lms/components/headerLinksStyle';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import { viewAsUsers } from './client';
import { useDispatch } from 'react-redux';
import { loginAsync } from './authSlice';

const useStyles = makeStyles(styles);

const ViewAsMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color="transparent"
        className={classes.navLink}
      >
        <PeopleAltIcon className={classes.icons} /> View As
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {viewAsUsers
          .filter(user => user.email !== localStorage.getItem('email'))
          .map(user => (
            <MenuItem
              key={user.id}
              onClick={() => {
                dispatch(loginAsync({ email: user.email, password: user.password }));
              }}
            >
              {user.name}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export default ViewAsMenu;

import React, { FC } from 'react';
import config from '../../config'
import GridContainer from 'components/Grid/GridContainer';
import { useDispatch, useSelector } from 'react-redux';
import { uploadSingleFileAsync } from './editSlice';
import CustomDropzone from 'components/CustomDropzone/CustomDropzone';
import { useReqListener } from 'hooks/useReqListener';
import { RootState } from 'features';
import { Backdrop, Button, CircularProgress } from '@material-ui/core';
import { tokenSelector } from 'features/auth/authSlice';

const EditQAudio: FC = () => {
  const uploadedFilesReq = useSelector((state: RootState) => state.editQuestion.files.uploadedFiles.req);
  const token = useSelector(tokenSelector);
  const [open, setOpen] = React.useState(false);
  const curAudioFile = useSelector((state: RootState) => state.editQuestion.editingQuestion.listeningAudio);
  const initialFiles = curAudioFile && [curAudioFile.awsUrl];
  var downloadURL = ""
  if(initialFiles)
    downloadURL = config.apiBaseUrl.replace('api','')+'public'+initialFiles[0].split('public')[1]
  const dispatch = useDispatch();
  const handleSave = (acceptedFiles: File[]) => {
    acceptedFiles.length && dispatch(uploadSingleFileAsync({ file: acceptedFiles[0], token }));
    setOpen(false);
  };
  useReqListener({
    status: uploadedFilesReq.status,
    successMessage: 'The Audio file/s was/were inserted into our storage successfully',
    loadingMessage: 'Adding audio file/s into our storage',
    errorMessage: uploadedFilesReq.error || 'Error not added successfully',
  });

  const onUploadClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <GridContainer
        justify="center"
        style={{ width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '2rem' }}
      >
        <CustomDropzone
          onClose={onClose}
          open={open}
          initialFiles={initialFiles}
          accept={['audio/*']}
          onSave={handleSave}
          filesLimit={1}
        />
        {uploadedFilesReq.status === 'succeeded' && <h5>Files uploaded successfully</h5>}
        {uploadedFilesReq.status === 'failed' && <h5>Something went wrong, try again!</h5>}
        {!open && uploadedFilesReq.status !== 'loading' && (
          <>
            <a href = {downloadURL} download target="_blank">
              <Button variant="contained" fullWidth>
                Download Sound Track
              </Button>
            </a>
            <br/>
            <Button variant="contained" fullWidth onClick={onUploadClick}>
              Edit Uploaded Sound Track
            </Button>
          </>
        )}
        {uploadedFilesReq.status === 'loading' && (
          <div>
            <CircularProgress />
            <h5>Still Uploading the Files</h5>
          </div>
        )}
      </GridContainer>
      <Backdrop
        color="#fff"
        style={{
          zIndex: 1000,
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
        }}
        open={uploadedFilesReq.status === 'loading'}
      >
        <CircularProgress color="inherit" style={{ marginBottom: '20px' }} />
        <p>UPLOADING...</p>
      </Backdrop>
    </div>
  );
};

export default EditQAudio;

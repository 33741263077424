/*eslint-disable*/
import React, { useEffect } from 'react';
import Lock from '@material-ui/icons/Lock';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';

// react components for routing our app without refresh
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@mui/material/Badge';
// @material-ui/icons

// core components
import Button from '../CustomButtons/Button';

import styles from '../../assets/jss/lms/components/headerLinksStyle';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNotificationsAsync,
  loggedInSelector,
  loginAsync,
  logoutUser,
  notificationsSelector,
  tokenSelector,
  userSelector,
} from 'features/auth/authSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import ViewAsMenu from 'features/auth/ViewAsMenu';
import { masterKey, viewAsUsers } from 'features/auth/client';
import NotificationsIcon from '@material-ui/icons/Notifications';

const useStyles = makeStyles(styles);

const HeaderLinks: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const userLoggedIn = useSelector(loggedInSelector);
  const masterEmail = localStorage.getItem('master');
  const token = useSelector(tokenSelector);
  const notifications = useSelector(notificationsSelector);
  let notificationsLength = notifications.length;
  console.log('🚀 ~ file: HeaderLinks.tsx:38 ~ notifications:', notificationsLength);
  useEffect(() => {
    dispatch(getNotificationsAsync({ token, userId: localStorage.getItem('studentID') || '' }));
  }, [token]);
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button color="transparent" target="_blank" className={classes.navLink}>
          <QuestionAnswer className={classes.icons} /> Learn more
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button color="transparent" target="_blank" className={classes.navLink}>
          <QuestionAnswer className={classes.icons} /> About Me
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button color="transparent" target="_blank" className={classes.navLink}>
          <QuestionAnswer className={classes.icons} /> Book a Course
        </Button>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Link to="/landing/assistant">
          <Button color="transparent" target="_blank" className={classes.navLink}>
            <QuestionAnswer className={classes.icons} /> Assistant Register
          </Button>
        </Link>
      </ListItem> */}
      {masterEmail && userLoggedIn && (
        <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            className={classes.navLink}
            onClick={() => {
              dispatch(loginAsync({ email: masterEmail, password: masterKey }));
              localStorage.removeItem('master');
            }}
          >
            <Lock className={classes.icons} />
            Admin Dashboard
          </Button>
        </ListItem>
      )}
      {/* {user.role === 'student' && !masterEmail && userLoggedIn && (
        <ListItem className={classes.listItem}>
          <Button color="white" className={classes.navLink}>
            <Link to="/student">
              <FontAwesomeIcon className={classes.icons} size="2x" icon="user-graduate" /> Student Home
            </Link>
          </Button>
        </ListItem>
      )} */}
      {user.role === 'student' && userLoggedIn && (
        <ListItem className={classes.listItem}>
          <></>
          <Button color="white" className={classes.navLink}>
            <Link to="/student/notifications" target="_blank">
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <NotificationsIcon className={classes.icons} /> Notifications
                {notificationsLength > 0 && (
                  <Badge
                    badgeContent={notificationsLength.toString()}
                    color="secondary"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    style={{ position: 'relative', top: '-40%', right: '-10%', fontWeight: 'bold' }}
                  ></Badge>
                )}
              </div>
            </Link>
          </Button>
        </ListItem>
      )}

      {viewAsUsers.find(u => u.email === user.email) && userLoggedIn && (
        <ListItem className={classes.listItem}>
          <ViewAsMenu />
        </ListItem>
      )}

      {userLoggedIn && (
        <ListItem className={classes.listItem}>
          <IconButton className={classes.navLink} onClick={() => dispatch(logoutUser())}>
            <FontAwesomeIcon className={classes.icons} size="2x" icon="sign-out-alt" /> Logout
          </IconButton>
        </ListItem>
      )}
      {!userLoggedIn && (
        <>
          <ListItem className={classes.listItem}>
            <Button color="white" className={classes.navLink}>
              <Link to="/landing/login">
                <Lock className={classes.icons} /> Login
              </Link>
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Tooltip
              id="instagram-twitter"
              title="Follow us on twitter"
              placement={window.innerWidth > 959 ? 'top' : 'left'}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                href="https://twitter.com/CreativeTim?ref=creativetim"
                target="_blank"
                color="transparent"
                className={classes.navLink}
              >
                <i className={classes.socialIcons + ' fab fa-twitter'} />
              </Button>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Tooltip
              id="instagram-facebook"
              title="Follow us on facebook"
              placement={window.innerWidth > 959 ? 'top' : 'left'}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color="transparent"
                href="https://www.facebook.com/CreativeTim?ref=creativetim"
                target="_blank"
                className={classes.navLink}
              >
                <i className={classes.socialIcons + ' fab fa-facebook'} />
              </Button>
            </Tooltip>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Tooltip
              id="instagram-tooltip"
              title="Follow us on instagram"
              placement={window.innerWidth > 959 ? 'top' : 'left'}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                color="transparent"
                href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
                target="_blank"
                className={classes.navLink}
              >
                <i className={classes.socialIcons + ' fab fa-instagram'} />
              </Button>
            </Tooltip>
          </ListItem>
        </>
      )}
    </List>
  );
};

export default HeaderLinks;

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import Autocomplete, { OptionType } from 'components/Autocomplete/Autocomplete';
import styles from 'assets/jss/lms/features/questions';
import MultipleChoiceQuestion from 'features/questions/SimpleQuestions/MultipleChoiceQuestion';
import CardBody from 'components/Card/CardBody';
import ShortAnswerQuestion from 'features/questions/SimpleQuestions/ShortAnswerQuestion';
import Rubric from 'features/questions/SimpleQuestions/Rubric';
import Button1 from '@mui/material/Button';
import FillInTheGapsQuestion from 'features/questions/SimpleQuestions/FillInTheGapsQuestion';
import VocabQuestion from 'features/questions/SimpleQuestions/VocabQuestion';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteQuestion,
  sectionTypeSelector,
  singleQuestionModelSelector,
  updateSingleQuestionType,
  reorderQuestion,
} from '../createSlice';
import NoteTakingQuestion from './NoteTakingQuestion';
import SpeakerMatchingQuestion from './SpeakerMatchingQuestion';
import SpeakingPhotoDQuestion from './SpeakingPhotoDescription';
import SpeakingQA from './SpeakingQA';
import WritingPhotoDQuestion from './WritingPhotoDQuestion';
import WritingEssaysQuestion from './WritingEssaysQuestion';
import WritingSummariesQuestion from './WritingSummariesQuestion';
import DeleteDialog from 'components/Dialog/DeleteDialog';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

const useStyles = makeStyles(styles);

const questionsOptions = {
  speaking: [
    { id: 'Rubric', name: 'Rubric' },
    { id: 'SpeakingPhotoDescription', name: 'SpeakingPhotoDescription' },
    { id: 'SpeakingQA', name: 'SpeakingQA' },
  ],
  writing: [
    { id: 'Rubric', name: 'Rubric' },
    { id: 'WritingSummaries', name: 'WritingSummaries' },
    { id: 'WritingEssays', name: 'WritingEssays' },
    { id: 'WritingPhotoDescription', name: 'WritingPhotoDescription' },
  ],
  reading: [
    { id: 'Rubric', name: 'Rubric' },
    { id: 'MCQ', name: 'MCQ' },
    { id: 'ShortAnswers', name: 'ShortAnswers' },
    { id: 'FillInBlanks', name: 'FillInBlanks' },
    { id: 'Vocab', name: 'Vocab' },
    { id: 'NoteTaking', name: 'NoteTaking' },
    { id: 'SpeakerMatching', name: 'SpeakerMatching' },
  ],
  listening: [
    { id: 'Rubric', name: 'Rubric' },
    { id: 'MCQ', name: 'MCQ' },
    { id: 'ShortAnswers', name: 'ShortAnswers' },
    { id: 'FillInBlanks', name: 'FillInBlanks' },
    { id: 'Vocab', name: 'Vocab' },
    { id: 'NoteTaking', name: 'NoteTaking' },
    { id: 'SpeakerMatching', name: 'SpeakerMatching' },
  ],
  vocab: [
    { id: 'MCQ', name: 'MCQ' },
    { id: 'FillInBlanks', name: 'FillInBlanks' },
  ],
};

const getSectionOptions = (sectionType: Types.API.Question.QuestionSectionType) => {
  switch (sectionType) {
    case 'Reading':
      return questionsOptions.reading;
    case 'Listening':
      return questionsOptions.listening;
    case 'Speaking':
      return questionsOptions.speaking;
    case 'Writing':
      return questionsOptions.writing;
    case 'Vocab':
      return questionsOptions.vocab;
    default:
      return [];
  }
};
const CreateQSimpleCard: React.FC<QuestionCardProps> = props => {
  const dispatch = useDispatch();
  const curQuestionType = useSelector(singleQuestionModelSelector(props.uuid));
  const sectionType = useSelector(sectionTypeSelector).id as Types.API.Question.QuestionSectionType;
  const [cardAnimaton, setCardAnimation] = useState('cardHidden');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const moveup = () => {
    console.log(props.uuid);
    console.log('up');
    dispatch(reorderQuestion({ uuid: props.uuid, direction: true }));
  };
  const movedown = () => {
    console.log(props.uuid);
    console.log('down');
    dispatch(reorderQuestion({ uuid: props.uuid, direction: false }));
  };
  setTimeout(function () {
    setCardAnimation('');
  }, 200);
  const classes = useStyles();
  return (
    <Card key={`card-${props.uuid}`} plain={false} carousel={false} className={classes[cardAnimaton]}>
      <form className={classes.form}>
        <CardHeader color="primary" className={classes.cardHeader}>
          <h4>Create Question</h4>
        </CardHeader>
        <CardBody>
          <Button1 onClick={moveup} startIcon={<ArrowUpward />}></Button1>

          <Autocomplete
            labelText="Question Type"
            id="questionType"
            formControlProps={{
              fullWidth: true,
            }}
            value={{ id: curQuestionType, name: curQuestionType }}
            onChange={(ev, val: OptionType) => {
              val &&
                dispatch(
                  updateSingleQuestionType({
                    uuid: props.uuid,
                    modelType: val.id as Types.API.Question.QuestionModelType,
                  }),
                );
            }}
            options={getSectionOptions(sectionType)}
          />
        </CardBody>
        {curQuestionType === 'MCQ' && <MultipleChoiceQuestion uuid={props.uuid} />}
        {curQuestionType === 'Rubric' && <Rubric uuid={props.uuid} />}
        {curQuestionType === 'NoteTaking' && <NoteTakingQuestion uuid={props.uuid} />}
        {curQuestionType === 'ShortAnswers' && <ShortAnswerQuestion uuid={props.uuid} />}
        {curQuestionType === 'FillInBlanks' && <FillInTheGapsQuestion uuid={props.uuid} />}
        {curQuestionType === 'Vocab' && <VocabQuestion uuid={props.uuid} />}
        {curQuestionType === 'SpeakerMatching' && <SpeakerMatchingQuestion uuid={props.uuid} />}
        {curQuestionType === 'SpeakingPhotoDescription' && <SpeakingPhotoDQuestion uuid={props.uuid} />}
        {curQuestionType === 'SpeakingQA' && <SpeakingQA uuid={props.uuid} />}
        {curQuestionType === 'WritingPhotoDescription' && <WritingPhotoDQuestion uuid={props.uuid} />}
        {curQuestionType === 'WritingEssays' && <WritingEssaysQuestion uuid={props.uuid} />}
        {curQuestionType === 'WritingSummaries' && <WritingSummariesQuestion uuid={props.uuid} />}
        <Button1 onClick={movedown} startIcon={<ArrowDownward />}></Button1>

        <CardFooter className={classes.cardFooter}>
          <Button color="danger" size="lg" onClick={(_: any) => setDeleteDialogOpen(true)}>
            Delete
          </Button>
        </CardFooter>
      </form>
      <DeleteDialog
        dialogText="Are you sure you want to delete this question?"
        onSubmitClick={() => {
          setDeleteDialogOpen(false);
          dispatch(deleteQuestion({ uuid: props.uuid }));
        }}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      />
    </Card>
  );
};

interface QuestionCardProps {
  uuid: any;
}

export default CreateQSimpleCard;

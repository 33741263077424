import { InputAdornment } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import { RootState } from 'features';
import {
  setSpeakingQAQuestionText,
  setCommunicationMarksSpeakingQA,
  setLanguageKnowledgeMarksSpeakingQA,
  setPrononciationIntonationMarksSpeakingQA,
  setSpontaneityFluencyMarksSpeakingQA
} from '../createSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
interface Props {
  uuid: string;
}
const SpeakingQA: React.FC<Props> = ({ uuid }) => {
  const dispatch = useDispatch();
  const inputData = useSelector(
    (state: RootState) => state.createQuestion.curQuestion.questions[uuid],
  ) as Types.API.Question.ISpeakingQCreateInput;
  return (
    <CardBody>
      <CustomInput
        labelText="QA Question Description"
        id="text"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'text',
          value: inputData.question,
          multiline: true,
          rows: 1,
          onChange: e => dispatch(setSpeakingQAQuestionText({ text: e.target.value, uuid })),
          endAdornment: (
            <InputAdornment position="start">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  alignContent: 'center',
                  flexWrap: 'wrap'
                }}
              >
                <CustomInput
                  labelText="Communication Marks"
                  id={`CommunicationMarks${uuid}`}
                  inputProps={{
                    type: 'number',
                    inputProps: { min: 1, max: 100 },
                    value: inputData.communicationMark,
                    onChange: c =>
                      dispatch(
                        setCommunicationMarksSpeakingQA({
                          value: parseInt(c.target.value, 10),
                          uuid,
                        }),
                      ),
                  }}
                />
                <CustomInput
                  labelText="Knowledge and Use of Language Marks"
                  id={`LanguageMarks${uuid}`}
                  inputProps={{
                    type: 'number',
                    inputProps: { min: 1, max: 100 },
                    value: inputData.languageKnowledgeMark,
                    onChange: c =>
                      dispatch(
                        setLanguageKnowledgeMarksSpeakingQA({
                          value: parseInt(c.target.value, 10),
                          uuid,
                        }),
                      ),
                  }}
                />
                <CustomInput
                  labelText="Pronounciation and Intonation Marks"
                  id={`PronounciationIntonationMarks${uuid}`}
                  inputProps={{
                    type: 'number',
                    inputProps: { min: 1, max: 100 },
                    value: inputData.pronounciationIntonationMark,
                    onChange: c =>
                      dispatch(
                        setPrononciationIntonationMarksSpeakingQA({
                          value: parseInt(c.target.value, 10),
                          uuid,
                        }),
                      ),
                  }}
                />
                <CustomInput
                  labelText="Spontaneity and Fluency Marks"
                  id={`SpontaneityFluencyMarks${uuid}`}
                  inputProps={{
                    type: 'number',
                    inputProps: { min: 1, max: 100 },
                    value: inputData.spontaneityFluencyMark,
                    onChange: c =>
                      dispatch(
                        setSpontaneityFluencyMarksSpeakingQA({
                          value: parseInt(c.target.value, 10),
                          uuid,
                        }),
                      ),
                  }}
                />
              </div>
            </InputAdornment>
          ),
        }}
      />
    </CardBody>
  );
};

export default SpeakingQA;

import React, { useState } from 'react';
import CardBody from 'components/Card/CardBody';
import {
  vocabSubAnswerSelector,
  subQuestionWithIndexSelector,
  updateAnswerForVocabQuestion,
  updateCorrectionForVocabQuestion,
  updateStudentComment,
  // setTriggerSaveQuizAnswers,
} from 'features/quiz/updateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import { getMessagesAsync, tokenSelector, userSelector, verifyTokenSelector } from 'features/auth/authSlice';
import ShowComments from './ShowComments';
import ShowWhy from './ShowWhy';
import { RootState } from 'features';
import { TokenAnnotator } from 'react-text-annotate';
import { NewLine } from 'components/NewLine/NewLine';
import { isOldFIGPattern } from 'utils/detectOldFIGPattern';
import CustomButton from 'components/CustomButtons/Button';
import ShowChat from './ShowChat';

const hashCode = function (s: string) {
  return s.split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

const UpdateVocab: React.FC<{ questionIndex: number; subQuestionIndex: number }> = props => {
  const dispatch = useDispatch();
  const { questionIndex, subQuestionIndex } = props;
  const question = useSelector(
    subQuestionWithIndexSelector(questionIndex, subQuestionIndex),
  ) as Types.API.Question.IVocabModel;
  const studentAnswer = useSelector(vocabSubAnswerSelector(questionIndex, subQuestionIndex)).answer;
  const answersWhy = useSelector(vocabSubAnswerSelector(questionIndex, subQuestionIndex)).why;
  const studentMarks = useSelector(vocabSubAnswerSelector(questionIndex, subQuestionIndex)).marks;
  const studentComments = useSelector(vocabSubAnswerSelector(questionIndex, subQuestionIndex)).comments;
  const instructorComments = useSelector(vocabSubAnswerSelector(questionIndex, subQuestionIndex)).instructorComments;
  const quizTakenStatus = useSelector((state: RootState) => state.updateQuiz.quizTaken.status);

  const userRole = useSelector(userSelector).role;
  // const triggerSave = ()=>{
  //   dispatch(setTriggerSaveQuizAnswers({ save: true }));

  // }
  const onCommentInstructorChange = (index: number) => (c: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    dispatch(
      updateCorrectionForVocabQuestion({
        instructorComment: c.target.value,
        questionNumber: questionIndex,
        subQuestionNumber: subQuestionIndex,
        gapNumber: index,
      }),
    );
  const onCommentStudentChange = (index: number) => (c: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    dispatch(
      updateStudentComment({
        studentComment: c.target.value,
        questionNumber: questionIndex,
        subQuestionNumber: subQuestionIndex,
        index: index,
      }),
    );
  const onMarkChange = (index: number) => (c: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    dispatch(
      updateCorrectionForVocabQuestion({
        mark: parseInt(c.target.value, 10),
        questionNumber: questionIndex,
        subQuestionNumber: subQuestionIndex,
        gapNumber: index,
      }),
    );
  const Gap = (i: number) => {
    const [textFieldSize, setTextFieldSize] = React.useState(10);

    const [openChat, setOpenChat] = useState(false);
    const token = useSelector(verifyTokenSelector);
    const handleShowChatClick = () => {
      const quizID = localStorage.getItem('quizID') || ' ';
      const studentID = localStorage.getItem('studentID') || ' ';

      if (studentID && quizID && question._id) {
        dispatch(
          getMessagesAsync({
            token,
            questionID: question._id || '',
            studentID: studentID || '',
            quizID: quizID || '',
          }),
        );
      }
      setOpenChat(true);
    };
    const talkToTheTeacherButton = () => {
      return (
        <CustomButton size="sm" color="twitter" onClick={() => handleShowChatClick()}>
          <strong>chat with the teacher</strong>
        </CustomButton>
      );
    };
    return (
      <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
        <TextField
          label={
            userRole === 'admin' ||
            userRole === 'assistant' ||
            ((userRole === 'student' || userRole === 'parent') && quizTakenStatus === 'auto-corrected')
              ? question.correction.marks[i] > 0 && studentMarks[i] < question.correction.marks[i]
                ? question.correction.answer[i]
                : undefined
              : undefined
          }
          style={{ width: `${textFieldSize}rem`, minWidth: '1rem' }}
          disabled={userRole !== 'student' || quizTakenStatus !== 'started'}
          rows={1}
          value={studentAnswer[i]}
          onDragOver={enableDropping}
          onDrop={handleDrop}
          onChange={c => {
            dispatch(
              updateAnswerForVocabQuestion({
                answer: c.target.value,
                questionNumber: questionIndex,
                subQuestionNumber: subQuestionIndex,
                gapNumber: i,
              }),
            );
            if (c.target.value.length > 0 && c.target.value.length <= studentAnswer[i].length && textFieldSize > 10) {
              setTextFieldSize(textFieldSize - 0.5);
            } else if (c.target.value.length >= textFieldSize) {
              setTextFieldSize(textFieldSize + 0.5);
            } else setTextFieldSize(10);
            // triggerSave()
          }}
          variant="standard"
          InputLabelProps={{
            style: { color: 'red' },
          }}
          InputProps={{
            style: { marginTop: '7px' },
          }}
        />
        {(quizTakenStatus === 'auto-corrected' || quizTakenStatus === 'ended') && (
          <>
            {userRole === 'student' && (
              <>
                {talkToTheTeacherButton()}
                <ShowChat
                  open={openChat}
                  setOpen={setOpenChat}
                  questionID={question._id}
                  quizID={localStorage.getItem('quizID') || ' '}
                  senderName={localStorage.getItem('studentName') || ' '}
                  studentID={localStorage.getItem('studentID') || ' '}
                  questionNumber={questionIndex + 1}
                  subQuestionNumber={subQuestionIndex + 1}
                  modelType={question.modelType}
                />
              </>
            )}

            {/* <ShowComments
              useSmallVersion
              role={userRole}
              studentComment={studentComments[i]}
              instructorComment={instructorComments[i]}
              modelType={question.modelType}
              index={i}
              onCommentChange={
                userRole === 'student' || userRole === 'parent'
                  ? onCommentStudentChange
                  : userRole === 'admin' || userRole === 'assistant'
                  ? onCommentInstructorChange
                  : undefined
              }
            /> */}
            {answersWhy && <ShowWhy role={userRole} why={answersWhy[i]} />}

            <label style={{ position: 'absolute', bottom: ' -19px', right: 0 }}>
              <input
                style={{
                  border: 'none',
                  borderBottom: '1px solid',
                  width: '30px',
                  borderBottomColor:
                    question.correction.marks[i] > 0 && studentMarks[i] === question.correction.marks[i]
                      ? 'green'
                      : 'red',
                }}
                disabled={userRole !== 'assistant' && userRole !== 'admin'}
                placeholder={studentMarks[i] + ''}
                onChange={onMarkChange(i)}
              ></input>
              /{question.correction.marks[i]}
            </label>
          </>
        )}
      </div>
    );
  };

  const QuestionText = (props: { text: string }) => {
    const text = props.text;
    const highlightStateKey = hashCode(text) + '';
    const storedHighlightState = sessionStorage.getItem(highlightStateKey);
    const [highlightState, setHighlightState] = React.useState<any>(
      storedHighlightState !== null ? JSON.parse(storedHighlightState) : { value: [], key: highlightStateKey },
    );
    if (highlightState.key !== highlightStateKey) {
      setHighlightState(
        storedHighlightState !== null ? JSON.parse(storedHighlightState) : { value: [], key: highlightStateKey },
      );
    }

    return (
      <TokenAnnotator
        style={{
          fill: 'black',
          color: 'black',
          lineHeight: 2,
          fontWeight: 400,
          fontSize: 20,
        }}
        tokens={text.split(' ')}
        value={highlightState.value}
        onChange={value => {
          setHighlightState({ value, key: highlightStateKey });
          sessionStorage.setItem(hashCode(text) + '', JSON.stringify({ value, key: highlightStateKey }));
        }}
        getSpan={span => ({
          ...span,
          color: '#ff0',
        })}
      />
    );
  };
  const getQuestionArrayForNewPattern = (question: Types.API.Question.IVocabModel) => {
    const elements = [];
    let index = 0;

    for (let i = 0; i < question.texts.length; i++) {
      if (question.texts[i] === ('___GAP___' as Types.API.Question.VocabFieldType)) {
        elements.push(Gap(index));
        index++;
      } else if (question.texts[i] === ('___NEWLINE___' as Types.API.Question.VocabFieldType)) {
        elements.push(<NewLine />);
      } else elements.push(<QuestionText text={question.texts[i]} />);
    }
    return elements;
  };
  const getQuestionArrayForOldPattern = (question: Types.API.Question.IVocabModel) => {
    const elements = [];
    let index = 0;

    if (question.firstInserted === 'gap') {
      elements.push(Gap(index));
      index++;
    }
    for (let i = 0; i < question.texts.length; i++) {
      elements.push(<QuestionText text={question.texts[i]} />);
      if (i < question.texts.length - 1 || question.lastInserted === 'gap') {
        elements.push(Gap(index));
        index++;
      }
    }
    return elements;
  };
  const getTotalMarks = (question: Types.API.Question.IVocabModel) => {
    const elements = [];
    let marks = 0;
    for (let i = 0; i < studentMarks.length; i++) {
      marks += studentMarks[i];
    }
    elements.push(
      <div style={{ alignSelf: 'flex-end' }}>
        <h4 style={{ color: 'black' }}>
          Total Mark:
          <label style={{ color: '#1a0dab', fontWeight: 'bold' }}>
            {marks}/{question.mark}
          </label>
        </h4>
      </div>,
    );
    return elements;
  };

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('text', event.currentTarget.id);
  };
  const enableDropping = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const id = event.dataTransfer.getData('text');
    console.log(`Somebody dropped an element with id: ` + id);
    event.preventDefault();
    event.currentTarget.appendChild(document.getElementById(id) as HTMLFormElement);
  };
  const allVocabs = question.vocabs + '';
  const vocabs = allVocabs.split('-');
  return (
    <>
      <div style={{ color: 'black' }} id="d1" draggable="true" onDragStart={handleDragStart}>
        <ul
          role="tablist"
          style={{ listStyleType: 'none', margin: '0', padding: '0', overflow: 'hidden', backgroundColor: 'lightgrey' }}
        >
          {vocabs.map(vocab => (
            <li style={{ float: 'left' }}>
              <a
                style={{ display: 'block', color: 'black', textAlign: 'center', padding: '16px' }}
                role="tab"
                data-toggle="tab"
              >
                {vocab}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <CardBody style={{ padding: 0 }}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            gap: '1rem',
            alignItems: 'flex-end',
          }}
        >
          {isOldFIGPattern(question.texts)
            ? getQuestionArrayForOldPattern(question).map(e => e)
            : getQuestionArrayForNewPattern(question).map(e => e)}
        </div>
      </CardBody>
      {getTotalMarks(question)}
    </>
  );
};

export default UpdateVocab;

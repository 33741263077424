import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { IQuestionState } from 'features/admin/slices/slicesTypes';
import { GridColDef } from '@mui/x-data-grid';

interface Props {
  rows: IQuestionState[];
  allRows: IQuestionState[];
  columns: GridColDef[];
  setRows: React.Dispatch<React.SetStateAction<IQuestionState[]>>;
  setAllRows: React.Dispatch<React.SetStateAction<IQuestionState[]>>;
}

const DraggableTable: React.FC<Props> = ({ rows, columns, setRows, allRows, setAllRows }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleDragStart = (e: React.DragEvent<HTMLTableRowElement>, index: number) => {
    e.dataTransfer.setData('text/plain', index.toString());
  };

  const handleDragOver = (e: React.DragEvent<HTMLTableRowElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLTableRowElement>, index: number) => {
    e.preventDefault();
    const dragIndex = Number(e.dataTransfer.getData('text/plain'));
    const dragRow = rows[dragIndex];
    let newRows = [...rows].filter((row, i) => {
      return i !== dragIndex;
    });
    newRows.splice(index, 0, dragRow);
    newRows = newRows
      .filter(row => row.categoryType === dragRow.categoryType)
      .map((row, i) => {
        return { ...row, priority: row.categoryType + '#' + (i + 1) };
      });
    let allNewRows: IQuestionState[] = [];
    const subtractedArr = allRows.filter(item1 => !newRows.some(item2 => item1.id === item2.id));
    allNewRows = newRows.concat(subtractedArr);
    setRows(allNewRows);
    setAllRows(allNewRows);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <ImportExportIcon />
            </TableCell>
            {columns
              .filter(col => col.field !== 'id')
              .map(col => (
                <TableCell key={col.field} align="left">
                  {col.headerName}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map(
            (row, index) => (
              <TableRow
                hover
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                draggable
                onDragStart={e => handleDragStart(e, index)}
                onDragOver={handleDragOver}
                onDrop={e => handleDrop(e, index)}
              >
                <TableCell padding="checkbox" sx={{ cursor: 'move' }}>
                  <DragIndicatorIcon />
                </TableCell>
                <TableCell align="left">{row.title}</TableCell>
                <TableCell align="left">{row.diplomaType}</TableCell>
                <TableCell align="left">{row.gradeType}</TableCell>
                <TableCell align="left">{row.levelType}</TableCell>
                <TableCell align="left">{row.categoryType}</TableCell>
                <TableCell align="left">{row.priority}</TableCell>
              </TableRow>
            ),
          )}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100, { value: -1, label: 'All' }]}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default DraggableTable;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
interface NotificationState {
  message: string;
  variant: 'success' | 'failure' | 'loading';
  open: boolean;
}

const initialState: NotificationState = {
  message: '',
  variant: 'success',
  open: false,
};

export const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // Sync updates to the store
    openSuccess: (state, action: PayloadAction<{ message: string }>) => {
      state.message = action.payload.message;
      state.variant = 'success';
    },
    openFailure: (state, action: PayloadAction<{ message: string }>) => {
      state.message = action.payload.message;
      state.variant = 'failure';
    },
    openLoader: (state, action: PayloadAction<{ message: string }>) => {
      state.message = action.payload.message;
      state.variant = 'loading';
      state.open = true;
    },
    close: state => {
      state.open = false;
    },
  },
});

export const { openSuccess, openFailure, openLoader, close } = slice.actions;

export const notificationOpenSelector = (state: RootState) => state.notification.open;
export const notificationVariantSelector = (state: RootState) => state.notification.variant;
export const notificationMessageSelector = (state: RootState) => state.notification.message;
export default slice.reducer;

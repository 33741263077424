import { useEffect, useState, useRef } from 'react';
import { Dialog } from '@material-ui/core';
import { messagesSelector, setNotificationAsReadAsync, tokenSelector, userSelector } from 'features/auth/authSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMessagesAsync, postMessagesAsync } from '../../auth/authSlice';
//import { io } from 'socket.io-client';
import './ShowChat.scss';
import config from 'config';
import ChatAudioRecorder from './ChatAudioRecorder';

interface Props {
  quizID: string | null;
  senderName: string | null;
  studentID: string | null;
  receiverID?: string | null;
  questionNumber: number;
  subQuestionNumber?: number;
  questionID: string;
  notificationID?: string;
  modelType: Types.API.Question.QuestionModelType;
  useSmallVersion?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
//const socket = io(`${config.socketBaseUrl}`);

type MessageData = {
  questionID: string;
  quizID: string;
  studentID: string;
  questionNumber: number;
  subQuestionNumber?: number;
  messages: {
    sender: string;
    content: string;
    isVoice?: boolean;
    receiver?: string | undefined;
    timestamp?: Date;
  }[];
};

const ShowChat: React.FC<Props> = ({
  quizID,
  modelType,
  senderName,
  studentID,
  questionNumber,
  questionID,
  receiverID,
  subQuestionNumber,
  notificationID,
  useSmallVersion = false,
  open,
  setOpen,
}) => {
  const onClose = () => setOpen(false);

  const [currentMessage, setCurrentMessage] = useState('');
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const senderData = useSelector(userSelector);
  const sender = senderData.id;
  const senderRole = senderData.role;
  const allMessageSelector = useSelector(messagesSelector);
  //recorder
  const [openRecorder, setOpenRecorder] = useState(false);

  const recorderWidgetVisibility = (recorderVisibilityState: boolean) => {
    setOpenRecorder(recorderVisibilityState);
  };

  const handleMessageSubmit = async (msgParameter: string = '', isVoice: boolean = false) => {
    const content = isVoice ? msgParameter : currentMessage;
    if (content && studentID && quizID && questionID && questionNumber) {
      const std = { std_id: (senderRole === 'student' ? sender : studentID) || '' };
      console.log('🚀 ~ handleMessageSubmit ~ subQuestionNumber:', subQuestionNumber);

      const messageData: MessageData =
        receiverID !== null && receiverID !== undefined
          ? {
              questionID: questionID || '',
              studentID: std.std_id || '',
              quizID: quizID || '',
              questionNumber: questionNumber || 0,
              subQuestionNumber: subQuestionNumber || 0,
              messages: [
                {
                  content,
                  isVoice,
                  receiver: receiverID,
                  sender: sender!,
                  timestamp: new Date(),
                },
              ],
            }
          : {
              questionID: questionID || '',
              studentID: std.std_id || '',
              quizID: quizID || '',
              questionNumber: questionNumber || 0,
              subQuestionNumber: subQuestionNumber || 0,
              messages: [{ content, isVoice, sender: sender!, timestamp: new Date() }],
            };

      // socket.emit('send_message', messageData);
      dispatch(
        postMessagesAsync({
          token,
          ...messageData,
        }),
      );
      setCurrentMessage('');
    } else {
      console.log('error while sending the message 😂😂😂😂');
    }
  };
  useEffect(() => {
    if (studentID && quizID && questionID) {
      const std = { std_id: (senderRole === 'student' ? senderData.id : studentID) || '' };
      dispatch(
        getMessagesAsync({
          token,
          questionID: questionID || '',
          studentID: std.std_id,
          quizID: quizID || '',
        }),
      );
    }
  }, [dispatch, questionID, quizID, sender, senderData, senderRole, studentID, token]);
  const chatBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current?.lastElementChild?.scrollIntoView();
    }
  }, [allMessageSelector]);

  // useEffect(() => {
  //   socket.on('receive_message', data => {});

  //   return () => {
  //     socket.off('receive_message');
  //   };
  // }, []);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth={true}>
        <div className="chat-window">
          <div className="chat-header">
            <p>Chat</p>
            {
              <>
                {senderRole === 'admin' && (
                  <button
                    className="view-quiz-button"
                    onClick={() => {
                      window.open(
                        `${config.frontBaseUrl}/correctQuiz/${quizID}/#HQ${questionNumber}/#Q${subQuestionNumber}`,
                        '_blank',
                      );
                    }}
                  >
                    View Quiz
                  </button>
                )}
                {senderRole === 'student' && (
                  <button
                    className="view-quiz-button"
                    onClick={() => {
                      window.open(
                        `${config.frontBase}/student/viewPublishedQuiz/${quizID}/#HQ${questionNumber}/#Q${subQuestionNumber}`,
                        '_blank',
                      );
                    }}
                  >
                    View Quiz 📜
                  </button>
                )}
                <span className="question-number">{`question:  ${questionNumber}`}</span>
                <span className="question-number">{`point:  ${subQuestionNumber || 'NA'}`}</span>
                <button
                  className="marks-as-read-button"
                  onClick={() => {
                    dispatch(
                      setNotificationAsReadAsync({
                        token,
                        userId: sender,
                        notificationID: notificationID || '',
                        quizID: quizID || '',
                        questionID: questionID || '',
                        studentID: studentID || '',
                      }),
                    );
                  }}
                >
                  Mark as read
                </button>
              </>
            }
            <span className="close-button" onClick={onClose}>
              ❌
            </span>
          </div>
          <div className="chat-body-container">
            <div className="chat-body" ref={chatBodyRef}>
              {allMessageSelector.length !== 0 &&
                allMessageSelector.map((message, index) => {
                  const messageClass = sender === message.sender ? 'you' : 'other';
                  return (
                    <div key={index} className={`message ${messageClass}`}>
                      <div>
                        {/* if it is not a voice => text message */}
                        {!message.isVoice && (
                          <div className="message-content">
                            <p>{message.content}</p>
                          </div>
                        )}
                        {message.isVoice && (
                          <div
                            className="message-content"
                            style={{
                              background: 'transparent',
                              margin: '0',
                            }}
                          >
                            <audio
                              style={{
                                margin: senderRole === 'admin' ? (sender === message.sender ? '0% -2%' : '0% -6%') : '',
                                borderRight: '3px transparent solid',
                                borderLeft: '3px transparent  solid',
                                borderRadius: '20px',
                                background: sender === message.sender ? '#43a047' : 'cornflowerblue',
                                padding: '1.5%',
                              }}
                              id={message.content}
                              controls
                              controlsList="nodownload"
                              preload="metadata"
                            >
                              <source
                                id="audioSource"
                                src={config.apiBaseUrl.replace('api', '') + 'media' + message.content.split('data')[1]}
                                type="audio/mp3"
                              />
                            </audio>
                          </div>
                        )}
                        <div className="message-meta">
                          <p id="time">{`${
                            message.timestamp ? new Date(message.timestamp).getHours() : message.timestamp
                          }:${message.timestamp ? new Date(message.timestamp).getMinutes() : 'mmm'}`}</p>
                          <p id="author">
                            {message.sender === sender ? senderName : senderRole === 'admin' ? 'Student' : 'Teacher'}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="chat-box-footer">
            <input
              className="chat-input"
              type="text"
              placeholder="Enter your message here..."
              value={currentMessage}
              onChange={event => {
                setCurrentMessage(event.target.value);
              }}
              onKeyPress={event => {
                event.key === 'Enter' && handleMessageSubmit();
              }}
            ></input>
            <button className="send-button">
              <svg viewBox="0 0 24 24" onClick={() => handleMessageSubmit()}>
                <path fill="#006ae3" d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
              </svg>
            </button>
            <button className="send-voice" onClick={() => recorderWidgetVisibility(true)}>
              Record Audio
            </button>
          </div>
        </div>
      </Dialog>
      <Dialog open={openRecorder} onClose={() => recorderWidgetVisibility(false)} maxWidth={'sm'} fullWidth={true}>
        <div className="chat-window">
          <div className="chat-header">
            <p>Voice Recorder </p>
            <span className="close-button" onClick={() => recorderWidgetVisibility(false)}>
              ❌
            </span>
          </div>
          <ChatAudioRecorder handleSave={() => {}} handleMessageSubmit={handleMessageSubmit} />
        </div>
      </Dialog>
    </>
  );
};

export default ShowChat;

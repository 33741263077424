import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector, userRoleSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks';
import { StudentRoutesPrefix } from 'layouts/StudentLayout';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CustomButton from 'components/CustomButtons/Button';
import { takeQuizAsync, takeHomeworkAsync, takeQuizStateSelector } from './studentSlice';
import Primary from 'components/Typography/Primary';
import styles from 'assets/jss/lms/components/typographyStyle';
import { makeStyles, Typography } from '@material-ui/core';
import { getStudentMarkFromAnswerModels, getTotalMarkFromAnswerModels } from 'features/helpers';
interface QuizCardProps {
  name: string;
  id: string;
  quizCardType: 'running' | 'available' | 'published' | 'late' | 'vdo-lect';
  quizDuration?: number;
  dueDate: Date;
  isHomework: boolean;
  isLate?: boolean;
  answers?: Types.API.Answer.IAnswerModel[];
}
const useTypoStyles = makeStyles(styles);
const QuizInfoCard: React.FC<QuizCardProps> = ({
  name,
  id,
  quizCardType,
  quizDuration,
  isHomework,
  isLate,
  dueDate,
  answers,
}) => {
  const typoClasses = useTypoStyles();
  const takeQuizState = useSelector(takeQuizStateSelector);
  useReqListener({
    status: takeQuizState.req.status,
    successMessage: 'Successfully Started the Quiz',
    loadingMessage: 'Starting Your Quiz',
    errorMessage: takeQuizState.req.error,
  });
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const userRole = useSelector(userRoleSelector);
  const studentMark = answers ? getStudentMarkFromAnswerModels(answers) : undefined;
  const totalMark = answers ? getTotalMarkFromAnswerModels(answers) : undefined;

  const handleQuizTakeSubmit = () => {
    dispatch(
      takeQuizAsync({
        quizId: id,
        token,
      }),
    );
  };
  const handleHomeworkTakeSubmit = () => {
    dispatch(
      takeHomeworkAsync({
        quizId: id,
        token,
      }),
    );
  };
  const handleQuizContinue = () => {
    const win = window.open(StudentRoutesPrefix + '/' + id + '/quiz', '_blank');
    win?.focus();
  };
  const handleHomeworkContinue = () => {
    const win = window.open(StudentRoutesPrefix + '/' + id + '/homework', '_blank');
    win?.focus();
  };
  const handleViewPublishedQuiz = () => {
    localStorage.setItem('publishedQuiz', JSON.stringify(answers));
    const win = window.open(userRole + '/viewPublishedQuiz/' + id, '_blank');
    // const win = window.open(StudentRoutesPrefix + '/viewPublishedQuiz/' + id, '_blank');
    win?.focus();
  };
  return (
    <Card style={{ padding: '1.5rem', background: '#ddd' }}>
      <CardHeader color="rose">
        <h6>
          <Primary>{name}</Primary>
        </h6>
        {isLate && <h6 style={{ color: 'red' }}>Late</h6>}
        {<h6 style={{ color: 'Indigo' }}>{quizCardType}</h6>}
      </CardHeader>
      <CardBody style={{ background: '#ddd' }}>
        {(quizCardType === 'available' || quizCardType === 'late') && !isHomework && (
          <div className={typoClasses.typo}>
            <h4>Duration: {quizDuration || ' -1'} minutes</h4>
          </div>
        )}
        {(quizCardType === 'available' || quizCardType === 'running' || quizCardType === 'late') && (
          <div className={typoClasses.typo}>
            <h5>
              <b>Due Date:{new Date(dueDate).toUTCString().substring(0, 25)}</b>
            </h5>
          </div>
        )}
        {quizCardType === 'published' && (
          <>
            <Typography align="center" variant="h4">
              {studentMark} / {totalMark}
            </Typography>
          </>
        )}
        {(quizCardType === 'available' || quizCardType === 'late') && !isHomework && (
          <CustomButton
            color="primary"
            onClick={handleQuizTakeSubmit}
            disabled={userRole !== 'student'}
            tooltip="You must be student to take this quiz"
          >
            Take Quiz
          </CustomButton>
        )}
        {(quizCardType === 'available' || quizCardType === 'late') && isHomework && (
          <CustomButton
            color="primary"
            onClick={handleHomeworkTakeSubmit}
            disabled={userRole !== 'student'}
            tooltip="You must be student to take this homework"
          >
            Take Homework
          </CustomButton>
        )}
        {quizCardType === 'running' && !isHomework && (
          <CustomButton
            color="success"
            onClick={handleQuizContinue}
            disabled={userRole !== 'student'}
            tooltip="You must be student to take this quiz"
          >
            Continue This Quiz
          </CustomButton>
        )}
        {quizCardType === 'running' && isHomework && (
          <CustomButton
            color="success"
            onClick={handleHomeworkContinue}
            disabled={userRole !== 'student'}
            tooltip="You must be student to take this homework"
          >
            Continue This Homework
          </CustomButton>
        )}
        {quizCardType === 'published' && !isHomework && (
          <CustomButton onClick={handleViewPublishedQuiz} color="info">
            Show Quiz Grades
          </CustomButton>
        )}
        {quizCardType === 'published' && isHomework && (
          <CustomButton onClick={handleViewPublishedQuiz} color="info">
            Show Homework Grades
          </CustomButton>
        )}
      </CardBody>
    </Card>
  );
};

export default QuizInfoCard;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  destroyWhatsappAsync,
  getWhatsappStatusAsync,
  initializeWhatsappAsync,
  isWhatsappReadySelector,
  whatsappQrCodeSelector,
} from '../slices/adminSlice';
import { tokenSelector } from 'features/auth/authSlice';
import { Button, IconButton, Tooltip } from '@mui/material';
import WhatsappIcon from '@material-ui/icons/WhatsApp';
import io, { Socket } from 'socket.io-client';
import { QRCodeCanvas } from 'qrcode.react';
import config from 'config';
import { set } from 'lodash';

// Define the type for the socket
let socket: Socket = io(config.socketBaseUrl); // Change the port if necessary;

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);

  const isWhatsappReady = useSelector(isWhatsappReadySelector);
  const whatsappQrCode = useSelector(whatsappQrCodeSelector);
  console.log('🚀 ~ file: AdminDashboard.tsx:28 ~ AdminDashboard ~ whatsappQrCode:', whatsappQrCode);

  const [qrCode, setQrCode] = useState<string | null>(null);
  console.log('🚀 ~ file: AdminDashboard.tsx:28 ~ AdminDashboard ~ qrCode:', qrCode);
  const [isConnected, setIsConnected] = useState(false);
  console.log('🚀 ~ file: AdminDashboard.tsx:30 ~ AdminDashboard ~ isConnected:', isConnected);

  const toggleWhatsappConnection = () => {
    if (!isWhatsappReady) dispatch(initializeWhatsappAsync({ token }));
    else dispatch(destroyWhatsappAsync({ token }));
  };

  const toggleWhatsappConnectionIO = () => {
    if (!isWhatsappReady) connectToWhatsApp();
    else disconnectWhatsApp();
  };

  const connectToWhatsApp = () => {
    console.log('🚀 ~ file: AdminDashboard.tsx:50 ~ connectToWhatsApp ~ connectToWhatsApp:');

    // Send a request to the server for the QR code
    socket.emit('request-qr');

    // Listen for the QR code from the server
    socket.on('whatsapp-qr', (qr: string) => {
      console.log('🚀 ~ file: AdminDashboard.tsx:46 ~ socket.on ~ qr:', qr);
      setQrCode(qr); // Set the QR code in state
    });
  };

  const disconnectWhatsApp = () => {
    if (socket) {
      socket.emit('destroy-whatsapp-client');
      socket.on('whatsapp-client-destroyed', () => {
        setQrCode(null);
        setIsConnected(false);
        console.log('🚀 disconnectWhatsApp:');
      });
    }
  };

  useEffect(() => {
    dispatch(getWhatsappStatusAsync({ token }));
  }, [isConnected, qrCode]);

  useEffect(() => {
    setQrCode(whatsappQrCode);
  }, [whatsappQrCode]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     dispatch(initializeWhatsappAsync({ token }));
  //     setQrCode(whatsappQrCode);
  //   }, 30000);

  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    // Send a request to the server for the QR code
    socket.emit('request-is-connected');

    // Listen for the is connected state from the server
    socket.on('whatsapp-is-connected', (isConnected: boolean) => {
      setIsConnected(isConnected);
    });
  }, [isConnected, qrCode]);

  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <h1 style={{ textAlign: 'center', fontSize: '2.5rem', fontWeight: 'bold', color: '#333', marginBottom: '1rem' }}>
        Welcome to the Admin Dashboard
      </h1>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', paddingTop: '70px' }}>
        <h3 style={{ marginRight: '30px', fontSize: '1.4rem', color: '#444', fontWeight: 'bold' }}>
          WhatsApp Status:{' '}
          <span style={{ color: isWhatsappReady ? '#4CAF50' : '#F44336', fontWeight: 'normal' }}>
            {isWhatsappReady ? 'Connected' : 'Disconnected'}
          </span>
        </h3>
        <Button size="large" variant="contained" color="success" onClick={toggleWhatsappConnectionIO}>
          {isWhatsappReady ? 'Disconnect' : 'Connect'} <WhatsappIcon style={{ marginLeft: '8px' }} />
        </Button>
      </div>

      {qrCode && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '15px',
          }}
        >
          <QRCodeCanvas value={qrCode} size={256} />
          <h3 style={{ margin: 0, marginTop: 7 }}>Scan the QR code:</h3>
        </div>
      )}

      <div
        style={{
          textAlign: 'center',
          fontSize: '18px',
          marginTop: '20px',
          padding: '10px',
          backgroundColor: '#f0f0f0',
          borderRadius: '5px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          color: '#555',
          fontWeight: 'bold',
          maxWidth: '600px',
          margin: '20px auto',
        }}
      >
        For better performance, please don't forget to disconnect after use.
        <br />
        Refresh the page if you have any errors.
      </div>
    </div>
  );
};

export default AdminDashboard;

import API from 'api';

export const addComment = async (input: {
  data: Types.API.VdoCipher.IThreadInputs;
  videoId: string;
  token: string;
}) => {
  await API.post(`/users/video/addComment/${input.videoId}`, input.data, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
};

export const addReply = async (input: { data: Types.API.VdoCipher.IThreadInputs; threadId: string; token: string }) => {
  await API.post(`/users/video/addReply/${input.threadId}`, input.data, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
};

export const editComment = async (input: {
  data: { text?: string; videoTime?: string };
  threadId: string;
  token: string;
}) => {
  await API.put(`/users/video/editComment/${input.threadId}`, input.data, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
};

export const deleteComment = async (input: { threadId: string; token: string }) => {
  await API.delete(`/users/video/deleteComment/${input.threadId}`, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
};

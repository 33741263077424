import React from 'react';

// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import Input, { InputProps } from '@material-ui/core/Input';
import autosize from 'autosize';
import styles from '../../assets/jss/lms/components/customInputStyle';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import TextEditor from 'components/TextEditor/TextEditor';

const useStyles = makeStyles(styles);

const CustomInput: React.FC<CustomInputProps> = props => {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    style,
    allowEdit,
    uuid,
    setText,
  } = props;

  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const marginTop = classNames({
    ['' + inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(formControlProps.className, classes.formControl);
  } else {
    formControlClasses = classes.formControl;
  }
  autosize(document.getElementById('text') as Element);

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {allowEdit ? (
        <TextEditor setText={setText} labelText={labelText} uuid={uuid} />
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {labelText !== undefined ? (
              <InputLabel className={classes.labelRoot + ' ' + labelClasses} htmlFor={id} {...labelProps}>
                {labelText}
              </InputLabel>
            ) : null}
          </div>

          <Input
            classes={{
              input: inputClasses,
              root: marginTop,
              disabled: classes.disabled,
              underline: underlineClasses,
            }}
            id={uuid ? uuid : id}
            {...inputProps}
            multiline
            rowsMin={10}
            style={style}
          />
        </>
      )}
    </FormControl>
  );
};

// const CustomSelect: React.FC<CustomSelectProps> = (props) => {
// 	const classes = useStyles();
// 	const {
// 		formControlProps,
// 		labelText,
// 		id,
// 		labelProps,
// 		inputProps,
// 		error,
// 		// white,
// 		// inputRootCustomClasses,
// 		success,
// 		options,
// 		value,
// 		onChange,
// 	} = props;

// 	const labelClasses = classNames({
// 		[' ' + classes.labelRootError]: error,
// 		[' ' + classes.labelRootSuccess]: success && !error,
// 	});
// 	var formControlClasses;
// 	if (formControlProps !== undefined) {
// 		formControlClasses = classNames(formControlProps.className, classes.formControl);
// 	} else {
// 		formControlClasses = classes.formControl;
// 	}
// 	return (
// 		<FormControl {...formControlProps} className={formControlClasses}>
// 			{labelText !== undefined ? (
// 				<InputLabel className={classes.labelRoot + ' ' + labelClasses} htmlFor={id} {...labelProps}>
// 					{labelText}
// 				</InputLabel>
// 			) : null}
// 			<Select
// 				id={id}
// 				{...inputProps}
// 				onChange={(e: any) => {
// 					if (onChange) onChange(e.target.value);
// 				}}
// 				value={value}>
// 				{options.map((o) => (
// 					<MenuItem key={o.value} value={o.value}>
// 						{o.label}
// 					</MenuItem>
// 				))}
// 			</Select>
// 		</FormControl>
// 	);
// };

interface CustomInputProps {
  labelText?: string;
  // labelText?: React.ReactNode;
  labelProps?: InputLabelProps;
  id: string;
  inputProps?: InputProps;
  formControlProps?: FormControlProps;
  inputRootCustomClasses?: string;
  error?: boolean;
  success?: boolean;
  white?: boolean;
  style?: React.CSSProperties;
  allowEdit?: boolean;
  uuid?: string;
  setText?: any;
}

// interface CustomSelectProps extends CustomInputProps {
// 	options: any[];
// 	onChange?: Function;
// 	value?: string | number;
// }

export default CustomInput;
// export { CustomSelect };

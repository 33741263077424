import { Grid } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import { RootState } from 'features';
import {
  setWritingPhotoDescriptionText,
  setContentMarksWritingPhotoDescription,
  setLanguageMarksWritingPhotoDescription,
  updateImageFileAsync,
} from '../editSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from 'config';
import CustomDropzone from 'components/CustomDropzone/CustomDropzone';
import { tokenSelector } from 'features/auth/authSlice';
import { Button } from '@mui/material';

interface Props {
  uuid: string;
}
const WritingPhotoDQuestion: React.FC<Props> = ({ uuid }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const inputData = useSelector(
    (state: RootState) => state.editQuestion.editingQuestion.questions[uuid],
  ) as Types.API.Question.IWritingPhotoDescriptionQModel;
  var downloadURL = '';
  var imageName = '';
  if (inputData['image']) {
    downloadURL = config.apiBaseUrl.replace('api', '') + 'media' + inputData['image']['awsUrl'].split('data')[1];
    imageName = inputData['image']['filename'];
  }
  const onUploadClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const token = useSelector(tokenSelector);
  const handleSave = (acceptedFiles: File[]) => {
    acceptedFiles.length &&
      dispatch(updateImageFileAsync({ file: acceptedFiles[acceptedFiles.length - 1], token, id: uuid }));
    setOpen(false);
  };

  return (
    <CardBody>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item xs={8} md={10}>
          <CustomInput
            labelText="Question Text"
            id="text"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: 'text',
              value: inputData.text,
              multiline: true,
              rows: 3,
              onChange: e => dispatch(setWritingPhotoDescriptionText({ text: e.target.value, uuid })),
            }}
            allowEdit={true}
            uuid={uuid}
            setText={setWritingPhotoDescriptionText}
          />
        </Grid>
        <Grid item>
          <CustomInput
            labelText="Content Marks"
            id={`ContentMarks${uuid}`}
            inputProps={{
              type: 'number',
              disableUnderline: true,
              inputProps: { min: 1, max: 100 },
              value: inputData.contentMark,
              onChange: c =>
                dispatch(
                  setContentMarksWritingPhotoDescription({
                    value: parseInt(c.target.value, 10),
                    uuid,
                  }),
                ),
            }}
          />
        </Grid>
        <Grid item>
          <CustomInput
            labelText="Language Marks"
            id={`LanguageMarks${uuid}`}
            inputProps={{
              type: 'number',
              disableUnderline: true,
              inputProps: { min: 1, max: 100 },
              value: inputData.languageMark,
              onChange: c =>
                dispatch(
                  setLanguageMarksWritingPhotoDescription({
                    value: parseInt(c.target.value, 10),
                    uuid,
                  }),
                ),
            }}
          />
        </Grid>
      </Grid>
      <div style={{ float: 'right', width: 'auto', zIndex: 1000, position: 'relative' }}></div>
      <a href={downloadURL} download target="_blank">
        <Button variant="contained">
          Download Image
          <br />
          {imageName}
        </Button>
      </a>
      <img src={downloadURL} alt="img" style={{ paddingLeft: '10%', width: '30%', height: '20%' }} />
      <br />
      <br />
      <Button variant="contained" onClick={onUploadClick}>
        Edit Uploaded Image
      </Button>
      <div style={{ float: 'right', width: 'auto', zIndex: 1000, position: 'relative' }}></div>
      <CustomDropzone onClose={onClose} open={open} accept={['image/*']} onSave={handleSave} filesLimit={1} />
    </CardBody>
  );
};

export default WritingPhotoDQuestion;

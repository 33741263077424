import React, { useState } from 'react';
import CardBody from 'components/Card/CardBody';
import {
  updateCorrectionForShortAnswersQuestion,
  subQuestionWithIndexSelector,
  updateAnswerForShortAnswersQuestion,
  shortAnswersSubAnswerSelector,
  updateStudentComment,
  // setTriggerSaveQuizAnswers,
} from '../updateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import { getMessagesAsync, tokenSelector, userSelector } from 'features/auth/authSlice';
import ShowWhy from './ShowWhy';
import { RootState } from 'features';
import { TokenAnnotator } from 'react-text-annotate';
import ShowChat from './ShowChat';
import CustomButton from 'components/CustomButtons/Button';

const ShortAnswerAnswer: React.FC<{ questionIndex: number; subQuestionIndex: number }> = props => {
  const dispatch = useDispatch();
  // const triggerSave = ()=>{
  //   dispatch(setTriggerSaveQuizAnswers({ save: true }));

  // }
  const { questionIndex, subQuestionIndex } = props;
  const question = useSelector(
    subQuestionWithIndexSelector(questionIndex, subQuestionIndex),
  ) as Types.API.Question.IShortAnswersModel;
  const correction = useSelector(shortAnswersSubAnswerSelector(questionIndex, subQuestionIndex));
  const studentAnswers = correction.answer;
  const studentMarks = correction.marks;
  // const answersWhy = correction.why;
  const answersWhy = question.correction.why;
  const userRole = useSelector(userSelector).role;
  const quizTakenStatus = useSelector((state: RootState) => state.updateQuiz.quizTaken.status);
  const [highlightState, setHighlightState] = React.useState<any>({ value: [] });
  const onMarkChange = (index: number) => (c: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    dispatch(
      updateCorrectionForShortAnswersQuestion({
        mark: parseInt(c.target.value, 10),
        questionNumber: questionIndex,
        subQuestionNumber: subQuestionIndex,
        shortAnswerNumber: index,
      }),
    );
  const getTotalMarks = (question: Types.API.Question.IShortAnswersModel) => {
    const elements = [];
    let marks = 0;
    for (let i = 0; i < studentMarks.length; i++) {
      marks += studentMarks[i];
    }
    elements.push(
      <div>
        <h4 style={{ color: 'black' }}>
          Total Mark:
          <label style={{ color: '#1a0dab', fontWeight: 'bold' }}>
            {' '}
            {marks}/{question.mark}
          </label>
        </h4>
      </div>,
    );
    return elements;
  };
  const [openChat, setOpenChat] = useState(false);
  const token = useSelector(tokenSelector);
  const handleShowChatClick = () => {
    const quizID = localStorage.getItem('quizID') || ' ';
    const studentID = localStorage.getItem('studentID') || ' ';

    if (studentID && quizID && question._id) {
      dispatch(
        getMessagesAsync({
          token,
          questionID: question._id || '',
          studentID: studentID || '',
          quizID: quizID || '',
        }),
      );
    }
    setOpenChat(true);
  };
  const talkToTheTeacherButton = () => {
    return (
      <CustomButton size="sm" color="twitter" onClick={() => handleShowChatClick()}>
        <strong>chat with the teacher</strong>
      </CustomButton>
    );
  };
  return (
    <div key={`question-${questionIndex}-${subQuestionIndex}`}>
      <CardBody>
        <TokenAnnotator
          style={{
            fill: 'black',
            color: 'black',
            marginRight: '25px',
            minWidth: '20vw',
            minHeight: '6vh',
            whiteSpace: 'pre-line',
            lineHeight: 2,
            fontWeight: 400,
            fontSize: 20,
          }}
          tokens={question.text.split(' ')}
          value={highlightState.value}
          onChange={value => {
            setHighlightState({ value });
          }}
          getSpan={span => ({
            ...span,
            color: '#ff0',
          })}
        />
        {studentAnswers.map((_, i) => (
          <div>
            <TextField
              disabled={userRole !== 'student' || quizTakenStatus !== 'started'}
              multiline
              rows={3}
              value={studentAnswers[i]}
              onChange={c => {
                dispatch(
                  updateAnswerForShortAnswersQuestion({
                    answer: c.target.value,
                    questionNumber: questionIndex,
                    subQuestionNumber: subQuestionIndex,
                    shortAnswerNumber: i,
                  }),
                );
                // triggerSave()
              }}
              variant="outlined"
              fullWidth
            />
            {(quizTakenStatus === 'auto-corrected' || quizTakenStatus === 'ended') && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: '50px',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}
              >
                <label>
                  Student Mark:
                  <input
                    style={{
                      border: '2px solid',
                      borderRadius: '5px',
                      width: '30px',
                      borderColor: studentMarks[i] > 0 ? 'green' : 'red',
                    }}
                    disabled={userRole !== 'assistant' && userRole !== 'admin'}
                    placeholder={studentMarks[i] + ''}
                    onChange={onMarkChange(i)}
                  ></input>
                </label>
                {answersWhy && <ShowWhy role={userRole} why={answersWhy[i]} />}
                {userRole === 'student' && (
                  <>
                    {talkToTheTeacherButton()}
                    <ShowChat
                      open={openChat}
                      setOpen={setOpenChat}
                      questionID={question._id}
                      quizID={localStorage.getItem('quizID') || ' '}
                      senderName={localStorage.getItem('studentName') || ' '}
                      studentID={localStorage.getItem('studentID') || ' '}
                      questionNumber={questionIndex + 1}
                      subQuestionNumber={subQuestionIndex + 1}
                      modelType={question.modelType}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        ))}
        {(userRole === 'admin' ||
          userRole === 'assistant' ||
          ((userRole === 'student' || userRole === 'parent') &&
            (quizTakenStatus === 'auto-corrected' || quizTakenStatus === 'ended'))) && (
          <div>
            <h4 style={{ color: 'black' }}>
              Model Answer:
              <label style={{ color: '#1a0dab', fontWeight: 'bold' }}> {question.correction.answer}</label>
            </h4>
          </div>
        )}
      </CardBody>
      {getTotalMarks(question)}
    </div>
  );
};

export default ShortAnswerAnswer;

import { InputAdornment } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import { RootState } from 'features';
import {
  setSpeakingPhotoDescriptionText,
  setLanguageKnowledgeMarksSpeakingPhotoDescription,
  setCommunicationMarksSpeakingPhotoDescription,
} from '../createSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateQImage from './CreateQImage';
interface Props {
  uuid: string;
}

const SpeakingPhotoDQuestion: React.FC<Props> = ({ uuid }) => {
  const dispatch = useDispatch();
  const inputData = useSelector(
    (state: RootState) => state.createQuestion.curQuestion.questions[uuid],
  ) as Types.API.Question.ISpeakingPhotoDescriptionCreateInput;


  return (
    <CardBody>
      <CustomInput
        labelText="Question Text"
        id="text"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'text',
          value: inputData.text,
          multiline: true,
          rows: 1,
          onChange: e => dispatch(setSpeakingPhotoDescriptionText({ text: e.target.value, uuid })),
          endAdornment: (
            <InputAdornment position="start">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  alignContent: 'center',
                }}
              >
                <CustomInput
                  labelText="Communication Marks"
                  id={`CommunicationMarks${uuid}`}
                  inputProps={{
                    type: 'number',
                    inputProps: { min: 1, max: 100 },
                    value: inputData.communicationMark,
                    onChange: c =>
                      dispatch(
                        setCommunicationMarksSpeakingPhotoDescription({
                          value: parseInt(c.target.value, 10),
                          uuid,
                        }),
                      ),
                  }}
                />
                <CustomInput
                  labelText="Knowledge and Use of Language Marks"
                  id={`LanguageMarks${uuid}`}
                  inputProps={{
                    type: 'number',
                    inputProps: { min: 1, max: 100 },
                    value: inputData.languageKnowledgeMark,
                    onChange: c =>
                      dispatch(
                        setLanguageKnowledgeMarksSpeakingPhotoDescription({
                          value: parseInt(c.target.value, 10),
                          uuid,
                        }),
                      ),
                  }}
                />
              </div>
            </InputAdornment>
          ),
        }}
      />
      <h4>Photo Description Image Upload</h4>
      <CreateQImage uuid={uuid} />

    </CardBody>
  );
};

export default SpeakingPhotoDQuestion;

import React, { FC } from 'react';
import GridContainer from 'components/Grid/GridContainer';
// import GridItem from 'components/Grid/GridItem';
import { useDispatch, useSelector } from 'react-redux';
import { uploadAudioFileAsync, uploadFileReqSelector } from './createSlice';

import CustomDropzone from 'components/CustomDropzone/CustomDropzone';
import { tokenSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import { Backdrop, Button, CircularProgress } from '@material-ui/core';

const CreateQAudio: FC = () => {
  const [open, setOpen] = React.useState(false);
  const [uploaded, setUploaded] = React.useState(false);
  const [fileName, setFileName] = React.useState("");
  const uploadFileReq = useSelector(uploadFileReqSelector);
  const token = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const onSave = (acceptedFiles: File[]) => {
    console.log('accepted files', acceptedFiles);
    acceptedFiles.length && dispatch(uploadAudioFileAsync({ file: acceptedFiles[0], token }));
    setOpen(false);
    setUploaded(true)
    setFileName(acceptedFiles[0]['name'])
  };
  useReqListener({
    status: uploadFileReq.status,
    successMessage: 'The Audio file was inserted in our storage successfully',
    loadingMessage: 'Adding audio file to the storage',
    errorMessage: uploadFileReq.error,
  });

  const onUploadClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <GridContainer
        justify="center"
        style={{ width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '2rem' }}
      >
        {! uploaded && (<Button variant="contained" fullWidth onClick={onUploadClick}>
          Upload your soundtrack
        </Button>)}
        { uploaded && (<Button variant="contained"
         fullWidth onClick={onUploadClick}>
         Uploaded: {fileName} <br/>
         Click to change
        </Button>)}
        <CustomDropzone
          onClose={onClose}
          open={open}
          filesLimit={1}
          initialFiles={[]}
          accept={['audio/*']}
          onSave={onSave}
        />
      </GridContainer>
      <Backdrop
        color="#fff"
        style={{
          zIndex: 1000,
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
        }}
        open={uploadFileReq.status === 'loading'}
      >
        <CircularProgress color="inherit" style={{ marginBottom: '20px' }} />
        <p>UPLOADING...</p>
      </Backdrop>
    </div>
  );
};

export default CreateQAudio;

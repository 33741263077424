import React, { useState } from 'react';
// import CardBody from 'components/Card/CardBody';
import { FormLabel, FormControl, Divider } from '@material-ui/core';
import {
  // setTriggerSaveQuizAnswers,
  speakerMatchingSubAnswerSelector,
  subQuestionWithIndexSelector,
  updateAnswerForSMQuestion,
  updateCorrectionForSMQuestion,
  updateStudentComment,
} from '../updateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getMessagesAsync, tokenSelector, userSelector } from 'features/auth/authSlice';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown';
import ShowWhy from './ShowWhy';
import { RootState } from 'features';
import { TokenAnnotator } from 'react-text-annotate';
import ShowChat from './ShowChat';
import CustomButton from 'components/CustomButtons/Button';

const hashCode = function (s: string) {
  return s.split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

const UpdateSM: React.FC<{ questionIndex: number; subQuestionIndex: number }> = props => {
  const dispatch = useDispatch();
  const { questionIndex, subQuestionIndex } = props;
  const userRole = useSelector(userSelector).role;
  // const triggerSave = ()=>{
  //   dispatch(setTriggerSaveQuizAnswers({ save: true }));

  // }
  const quizTakenStatus = useSelector((state: RootState) => state.updateQuiz.quizTaken.status);
  const question = useSelector(
    subQuestionWithIndexSelector(questionIndex, subQuestionIndex),
  ) as Types.API.Question.ISpeakerMatchingModel;
  const correction = useSelector(speakerMatchingSubAnswerSelector(questionIndex, subQuestionIndex));
  const studentMarks = correction.marks;
  const answersWhy = correction.why;
  const studentAnswer = correction.answer;
  const [shuffeledOpinions, setShuffeledOpinions] = React.useState<{ opinion: string; realIndex: number }[]>([]);
  React.useEffect(() => {
    const tempOpinions = question.opinions.map((o, i) => {
      return { opinion: o, realIndex: i };
    });
    for (let i = tempOpinions.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [tempOpinions[i], tempOpinions[j]] = [tempOpinions[j], tempOpinions[i]];
    }
    setShuffeledOpinions(tempOpinions);
  }, []);
  const onMarkChange = (index: number) => (c: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    dispatch(
      updateCorrectionForSMQuestion({
        mark: parseInt(c.target.value, 10),
        questionNumber: questionIndex,
        subQuestionNumber: subQuestionIndex,
        optionIndex: index,
      }),
    );
  const getTotalMarks = (question: Types.API.Question.ISpeakerMatchingModel) => {
    const elements = [];
    let marks = 0;
    for (let i = 0; i < studentMarks.length; i++) {
      marks += studentMarks[i];
    }
    elements.push(
      <div>
        <h4 style={{ color: 'black', fontWeight: 'bold' }}>
          Total Mark:
          <label style={{ color: '#1a0dab', fontWeight: 'bold', fontSize: '20px' }}>
            {' '}
            {marks}/{question.mark}
          </label>
        </h4>
      </div>,
    );
    return elements;
  };

  const SpeakerMatchingForm = (props: { opinionObject: { opinion: string; realIndex: number } }) => {
    const opinionObject = props.opinionObject;
    const highlightStateKey = hashCode(opinionObject.opinion) + '';
    const storedHighlightState = sessionStorage.getItem(highlightStateKey);
    const [highlightState, setHighlightState] = React.useState<any>(
      storedHighlightState !== null ? JSON.parse(storedHighlightState) : { value: [], key: highlightStateKey },
    );
    if (highlightState.key !== highlightStateKey) {
      setHighlightState(
        storedHighlightState !== null ? JSON.parse(storedHighlightState) : { value: [], key: highlightStateKey },
      );
    }

    const [openChat, setOpenChat] = useState(false);
    const token = useSelector(tokenSelector);
    const handleShowChatClick = () => {
      const quizID = localStorage.getItem('quizID') || ' ';
      const studentID = localStorage.getItem('studentID') || ' ';

      if (studentID && quizID && question._id) {
        dispatch(
          getMessagesAsync({
            token,
            questionID: question._id || '',
            studentID: studentID || '',
            quizID: quizID || '',
          }),
        );
      }
      setOpenChat(true);
    };
    const talkToTheTeacherButton = () => {
      return (
        <CustomButton size="sm" color="twitter" onClick={() => handleShowChatClick()}>
          <strong>chat with the teacher</strong>
        </CustomButton>
      );
    };
    return (
      <FormControl
        disabled={userRole !== 'student' || quizTakenStatus !== 'started'}
        key={`opinion-${opinionObject.realIndex}`}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <FormLabel>
            <TokenAnnotator
              style={{
                fill: 'black',
                color: 'black',
                marginRight: '25px',
                minWidth: '20vw',
                minHeight: '6vh',
                whiteSpace: 'pre-line',
                lineHeight: 2,
                fontWeight: 400,
                fontSize: 20,
              }}
              tokens={opinionObject.opinion.split(' ')}
              value={highlightState.value}
              onChange={value => {
                setHighlightState({ value, key: highlightStateKey });
                sessionStorage.setItem(
                  hashCode(opinionObject.opinion) + '',
                  JSON.stringify({ value, key: highlightStateKey }),
                );
              }}
              getSpan={span => ({
                ...span,
                color: '#ff0',
              })}
            />
          </FormLabel>
          {/* <CardBody> */}
          <CustomDropdown
            buttonText={studentAnswer[opinionObject.realIndex].name}
            dropdownList={question.speakers.map(s => {
              return {
                value: s.name,
                id: s.name,
              };
            })}
            onClick={v => {
              dispatch(
                updateAnswerForSMQuestion({
                  answer: { name: v.id ?? '', opinion: opinionObject.opinion },
                  questionNumber: questionIndex,
                  subQuestionNumber: subQuestionIndex,
                  index: opinionObject.realIndex,
                }),
              );
              // triggerSave()
            }}
          />
          {(quizTakenStatus === 'auto-corrected' || quizTakenStatus === 'ended') && <Divider /> && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginLeft: '50px',
              }}
            >
              <label>
                Student Mark:
                <input
                  style={{
                    border: '2px solid',
                    borderRadius: '5px',
                    width: '30px',
                    borderColor:
                      question.correction.marks[opinionObject.realIndex] > 0 &&
                      studentMarks[opinionObject.realIndex] === question.correction.marks[opinionObject.realIndex]
                        ? 'green'
                        : 'red',
                  }}
                  disabled={userRole !== 'assistant' && userRole !== 'admin'}
                  placeholder={studentMarks[opinionObject.realIndex] + ''}
                  onChange={onMarkChange(opinionObject.realIndex)}
                ></input>
                /{question.correction.marks[opinionObject.realIndex]}
              </label>
              {answersWhy && <ShowWhy role={userRole} why={answersWhy[opinionObject.realIndex]} />}
              {userRole === 'student' && (
                <>
                  {talkToTheTeacherButton()}
                  <ShowChat
                    open={openChat}
                    setOpen={setOpenChat}
                    questionID={question._id}
                    quizID={localStorage.getItem('quizID') || ' '}
                    senderName={localStorage.getItem('studentName') || ' '}
                    studentID={localStorage.getItem('studentID') || ' '}
                    questionNumber={questionIndex + 1}
                    subQuestionNumber={subQuestionIndex + 1}
                    modelType={question.modelType}
                  />
                </>
              )}
            </div>
          )}
          {/* </CardBody> */}
        </div>
      </FormControl>
    );
  };

  return (
    <div>
      <form key={`question-${questionIndex}-${subQuestionIndex}`}>
        <h5 style={{ fill: 'black', color: 'black' }}>
          <b>Please select a speaker for each of the following opinions</b>
        </h5>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {shuffeledOpinions.map(opinionObject => (
            <SpeakerMatchingForm opinionObject={opinionObject} />
          ))}
          {(userRole === 'admin' ||
            userRole === 'assistant' ||
            ((userRole === 'student' || userRole === 'parent') &&
              (quizTakenStatus === 'auto-corrected' || quizTakenStatus === 'ended'))) && (
            <div
              style={{
                marginTop: '20px',
                fontWeight: 'bold',
                fontSize: '20px',
                color: 'black',
              }}
            >
              <div>Model Answer:</div>
              <ol>
                {question.speakers.map((speaker, noteIndex) => {
                  return (
                    <li style={{ color: '#1a0dab', fontWeight: 'bold', fontSize: '20px' }} key={`speaker-${noteIndex}`}>
                      {speaker.name} : <label style={{ color: 'black', fontSize: '20px' }}> {speaker.opinion}</label>
                    </li>
                  );
                })}
              </ol>
            </div>
          )}
        </div>
      </form>
      {getTotalMarks(question)}
    </div>
  );
};

export default UpdateSM;

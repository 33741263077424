import { makeStyles } from '@material-ui/core';
import styles from 'assets/jss/lms/features/questions';
import { questionWithIndexSelector } from '../updateSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import UpdateSimpleAnswer from '../UpdateSimpleAnswers/UpdateSimpleAnswer';
import { RootState } from 'features';

const useStyles = makeStyles(styles);

const OtherAnswer: React.FC = props => {
  const activeQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.questionIndex);
  const question = useSelector(questionWithIndexSelector(activeQuestionIndex));
  const classes = useStyles();
  return (
    <div>
      {question && <h3>{question.title}</h3>}
      <div className={classes.container}>
        {question &&
          question.questions.map((_: any, index: number) => {
            return (
              <div id={`Q${index + 1}`} key={`question-number-${index}`}>
                <h4 style={{ fill: 'black', color: 'black', marginTop: 10 }}>Question {index + 1}:</h4>
                <UpdateSimpleAnswer
                  questionIndex={activeQuestionIndex}
                  subQuestionIndex={index}
                  questionType={question.questions[index].modelType}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default OtherAnswer;

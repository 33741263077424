import React from 'react';
import { ColDef, DataGrid } from '@material-ui/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import {
  availableDiplomasSelector,
  createDiplomaAsync,
  getDiplomasAsync,
  setCreateDiplomaName,
} from '../slices/adminSlice';
import { RootState } from 'features';
import styles from 'assets/jss/lms/features/questions';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';

const columns: ColDef[] = [
  { field: 'id', headerName: 'id', width: 300, resizable: true, hide: true },
  { field: 'name', headerName: 'Diploma Name', width: 300 },
];
const useStyles = makeStyles(styles);

interface Props {}
const DiplomasManagement: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector(tokenSelector);
  const getDiplomasReq = useSelector((state: RootState) => state.admin.getDiplomasReq);
  const diplomas = useSelector(availableDiplomasSelector);
  const createDiplomaReq = useSelector((state: RootState) => state.admin.createDiploma.req);
  const createDiplomaName = useSelector((state: RootState) => state.admin.createDiploma.name);

  useReqListener({
    status: getDiplomasReq.status,
    successMessage: 'Successfully fetched all the diplomas from the backend',
    loadingMessage: 'Fetching diplomas from the backend',
    errorMessage: getDiplomasReq.error,
  });
  useReqListener({
    status: createDiplomaReq.status,
    successMessage: 'Successfully ADDED a diploma to the DB',
    loadingMessage: 'Adding Diploma to the DB',
    errorMessage: createDiplomaReq.error,
  });
  React.useEffect(() => {
    // if (token && dispatch) {
    token && dispatch(getDiplomasAsync({ token }));
    // }
  }, [token, dispatch]);
  const handleDiplomaSubmit = () => {
    dispatch(createDiplomaAsync({ diploma: { name: createDiplomaName }, token }));
  };
  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <div className={classNames(classes.container, classes.categoryAutocomplete)}>
        <CustomInput
          id="categories-submit-button"
          labelText="New Diploma Name"
          formControlProps={{ fullWidth: true }}
          inputProps={{
            className: classes.margin20right,
            value: createDiplomaName,
            onChange: ev => dispatch(setCreateDiplomaName({ name: ev.target.value })),
          }}
        />
        <Button color="primary" onClick={handleDiplomaSubmit}>
          {' '}
          Add Diploma
        </Button>
      </div>
      <DataGrid
        pagination
        rows={diplomas}
        columns={columns}
        checkboxSelection
        loading={getDiplomasReq.status === 'loading'}
      />
    </div>
  );
};

export default DiplomasManagement;

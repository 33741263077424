import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector, userRoleSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import { createBrowserHistory } from 'history';
import {
  getQuestionsAsync,
  getGroupsAsync,
  getQuestionsReqSelector,
  questionsSelector,
  setCreateQuizName,
  setCreateQuizActivity,
  setCreateQuizTime,
  setCreateQuizSelection,
  createQuizAsync,
  createHomeworkAsync,
  setCreateQuizDueDate,
  quizGroupsSelector,
  // getQuizQuestionsSelectionSelector,
  CreateQuizReqSelector,
  setCreateQuizGroups,
  deleteQuestionReqSelector,
  deleteQuestionAsync,
  activeOptions,
  editQuestionPriorityAsync,
  totalQuestionsSelector,
} from '../slices/adminSlice';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import classNames from 'classnames';
import styles from 'assets/jss/lms/features/questions';
import { makeStyles, TextField } from '@material-ui/core';
import { RootState } from 'features';
import CustomAutocomplete, { OptionType } from 'components/Autocomplete/Autocomplete';
import {
  getQuestionToEditAsync,
  getQuestionToEditReqSelector,
  resetGetQuestionState,
} from 'features/editQuestions/editSlice';
import EditQuestion from 'features/editQuestions/EditQuestion';
import { Divider } from '@material-ui/core';
import DeleteDialog from 'components/Dialog/DeleteDialog';
import { IQuestionState } from '../slices/slicesTypes';
import DraggableTable from '../../../components/DraggableTable/DraggableTable';
const columns: GridColDef[] = [
  { field: 'id', headerName: 'Question ID', resizable: true, hide: true },
  { field: 'title', headerName: 'Question Title', resizable: true, flex: 2 },
  { field: 'diplomaType', headerName: 'Diploma', resizable: true, flex: 1 },
  { field: 'gradeType', headerName: 'Grade', resizable: true, flex: 1 },
  { field: 'levelType', headerName: 'Level', resizable: true, flex: 1 },
  { field: 'categoryType', headerName: 'Category', resizable: true, flex: 1 },
  { field: 'priority', headerName: 'Priority', resizable: true, flex: 1 },
];

interface Props {}

const useStyles = makeStyles(styles);

const AllQuestionsManagment: React.FC<Props> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const userRole = useSelector(userRoleSelector);
  const getQuestionsReq = useSelector(getQuestionsReqSelector);
  const questions = useSelector(questionsSelector);
  const totalQuestions = useSelector(totalQuestionsSelector);
  const createQuizName = useSelector((state: RootState) => state.admin.createQuiz.name);
  const createQuizActivity = useSelector((state: RootState) => state.admin.createQuiz.active);
  const createQuizTIme = useSelector((state: RootState) => state.admin.createQuiz.durationInMinutes);
  const createQuizDueDate = useSelector((state: RootState) => state.admin.createQuiz.dueDate);
  const deleteQuestionReq = useSelector(deleteQuestionReqSelector);
  const [selectedQuestions, setSelectedQuestions] = React.useState<string[]>([]);
  const createQuizReq = useSelector(CreateQuizReqSelector);
  const groups = useSelector((state: RootState) => state.admin.createQuiz.groups);
  const getGroupsReq = useSelector((state: RootState) => state.admin.getGroupsReq);
  const allGroups = useSelector((state: RootState) => state.admin.groups);
  const selectedGroups = useSelector(quizGroupsSelector);
  const questionToEditState = useSelector(getQuestionToEditReqSelector);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDraggable, setIsDraggable] = useState(false);
  const [questionNameFilter, setQuestionNameFilter] = React.useState<string>('');
  const [categoryFilter, setCategoryFilter] = React.useState<string>('');
  const [levelFilter, setLevelFilter] = React.useState<string>('');
  const [selectionModel, setSelectionModel] = React.useState<string[]>([]);
  const [tempFilter, setTempFilter] = React.useState<string[]>([]);
  const [filteredIDs, setFilteredIDs] = React.useState<string[]>([]);

  const [rows, setRows] = React.useState<IQuestionState[]>([]);
  const [allRows, setAllRows] = React.useState<IQuestionState[]>([]);

  const [pageState, setPageState] = useState({
    page: 0,
    pageSize: 25,
  });

  const [filters, setFilters] = useState({
    title: '',
    category: '',
    level: '',
  });

  let filteredQuestions: IQuestionState[] = [...rows];
  if (questionNameFilter && questionNameFilter !== '') {
    filteredQuestions = filteredQuestions.filter(s => s.title.toLowerCase().includes(questionNameFilter.toLowerCase()));
  }
  if (categoryFilter && categoryFilter !== '') {
    filteredQuestions = filteredQuestions.filter(s =>
      s.categoryType?.toString().toLowerCase().includes(categoryFilter.toLowerCase()),
    );
  }
  if (levelFilter && levelFilter !== '') {
    filteredQuestions = filteredQuestions.filter(s =>
      s.levelType?.toString().toLowerCase().includes(levelFilter.toLowerCase()),
    );
  }
  useReqListener({
    status: getQuestionsReq.status,
    successMessage: 'Successfully fetched all the questions from the backend',
    loadingMessage: 'Fetching the questions from the backend',
    errorMessage: getQuestionsReq.error,
  });

  useReqListener({
    status: createQuizReq.status,
    successMessage: 'Successfully ADDED Quiz',
    loadingMessage: 'Submitting Quiz to the backend',
    errorMessage: createQuizReq.error,
  });

  useReqListener({
    status: deleteQuestionReq.status,
    successMessage: 'Successfully deleted the question',
    loadingMessage: 'Deleting the question',
    errorMessage: deleteQuestionReq.error,
  });

  React.useEffect(() => {
    setRows(questions);
    setAllRows(questions);
  }, [questions]);

  React.useEffect(() => {
    if (filteredIDs.length !== filteredQuestions.length) {
      setFilteredIDs(filteredQuestions.map(el => el.id));
    }
  }, [filteredQuestions]);
  React.useEffect(() => {
    setSelectionModel(selectedQuestions);
    let difference = selectedQuestions.filter(x => !tempFilter.includes(x));
    let revDiff = tempFilter.filter(x => !selectedQuestions.includes(x));
    setTempFilter(tempFilter?.concat(difference).filter(e => !(filteredIDs.includes(e) && revDiff.includes(e))));
  }, [selectedQuestions]);
  React.useEffect(() => {
    dispatch(setCreateQuizSelection({ questions: tempFilter as string[] }));
  }, [tempFilter]);
  React.useEffect(() => {
    setIsEditing(false);
  }, []);
  // React.useEffect(() => {
  //   if (createQuizReq.status === 'succeeded') {
  //     localStorage.setItem('pageIndex', '5');
  //     window.location.reload();
  //   }
  // }, [createQuizReq]);
  React.useEffect(() => {
    if (dispatch && getGroupsReq.status === 'idle') {
      token && dispatch(getGroupsAsync({ token }));
    }
  }, [token, getGroupsReq.status, dispatch]);

  //====================================================================================
  const getQuestionsIfStatusIs = (status: 'idle' | 'succeeded') => {
    if (getQuestionsReq.status === status) {
      token && dispatch(getQuestionsAsync({ token, filters, limit: pageState.pageSize, offset: pageState.page }));
    }
  };

  React.useEffect(() => {
    if (isDraggable) {
      token && dispatch(getQuestionsAsync({ token, filters, limit: 4000, offset: 0 }));
    }
  }, [isDraggable]);

  React.useEffect(() => {
    getQuestionsIfStatusIs('idle');
  }, [getQuestionsReq.status]);

  React.useEffect(() => {
    getQuestionsIfStatusIs('succeeded');
  }, [pageState.page, pageState.pageSize]);

  React.useEffect(() => {
    const debounceSearch = setTimeout(() => {
      getQuestionsIfStatusIs('succeeded');
    }, 800);

    setPageState(old => ({ ...old, page: 0 }));

    return () => {
      clearTimeout(debounceSearch);
    };
  }, [filters]);

  //====================================================================================

  const handleQuizSubmit = () => {
    dispatch(
      createQuizAsync({
        quiz: {
          name: createQuizName,
          active: createQuizActivity.id === 'true',
          durationInMinutes: createQuizTIme,
          questionsIds: tempFilter,
          groupsIds: selectedGroups.map(g => g.id),
          dueDate: new Date(createQuizDueDate),
        },
        token,
      }),
    );
  };

  const handleHomeworkSubmit = () => {
    dispatch(
      createHomeworkAsync({
        homework: {
          name: createQuizName,
          active: createQuizActivity.id === 'true',
          questionsIds: tempFilter,
          groupsIds: selectedGroups.map(g => g.id),
          dueDate: new Date(createQuizDueDate),
        },
        token,
      }),
    );
  };

  const handleDeleteQuestion = () => {
    dispatch(deleteQuestionAsync({ token, ids: selectedQuestions }));
    setDeleteDialogOpen(false);
  };

  const handleEditQuestionPriority = () => {
    setIsDraggable(!isDraggable);
    if (isDraggable) {
      const changedRows = [...rows].filter(item1 =>
        [...questions].some(item2 => item1.id === item2.id && item1.priority !== item2.priority),
      );
      changedRows.forEach(r => {
        if (r.priority) dispatch(editQuestionPriorityAsync({ token, id: r.id, priority: r.priority }));
      });
    }
  };

  const sortByPriority = function (a: IQuestionState, b: IQuestionState) {
    if (!a || !a.priority) return 1;
    if (!b || !b.priority) return -1;
    if (Number(a.priority.split('#')[1]) < Number(b.priority.split('#')[1])) return -1;
    if (Number(a.priority.split('#')[1]) > Number(b.priority.split('#')[1])) return 1;
    return 0;
  };

  const browserHistory = createBrowserHistory();
  browserHistory.listen(location => {
    if (location.action === 'POP') {
      setIsEditing(false);
    }
  });
  const handleEditQuestion = () => {
    // localStorage.setItem('pageIndex', '4');
    browserHistory.push('admin');
    dispatch(resetGetQuestionState({}));
    dispatch(
      getQuestionToEditAsync({
        quesitonId: selectedQuestions[0],
        token,
      }),
    );
    setIsEditing(true);
    window.scroll({ behavior: 'smooth', top: 1000 });
  };

  const onEditingSuccess = () => {
    setIsEditing(false);
    localStorage.removeItem('protectBack');
    // localStorage.setItem('pageIndex', '4');
  };

  return (
    <div style={{ height: '80vh', width: '100%', marginBottom: '10vh' }}>
      {!isEditing && (
        <div className={classNames(classes.container, classes.categoryAutocomplete)}>
          <form noValidate className={classes.container}>
            <TextField
              id="datetime-local"
              label="Due Date"
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={c => dispatch(setCreateQuizDueDate({ dueDate: new Date(c.target.value) }))}
            />
          </form>
          <CustomInput
            id="categories-submit-button"
            labelText="Quiz Title"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              className: classes.margin20right,
              value: createQuizName,
              onChange: ev => dispatch(setCreateQuizName({ name: ev.target.value })),
            }}
          />
          <CustomInput
            id="QuizTime"
            labelText="Time"
            inputProps={{
              type: 'number',
              inputProps: { min: 0, max: 200 },
              value: createQuizTIme,
              onChange: c => dispatch(setCreateQuizTime({ durationInMinutes: parseInt(c.target.value, 10) })),
            }}
          />
        </div>
      )}
      {!isEditing && (
        <div className={classNames(classes.container, classes.categoryAutocomplete)}>
          <CustomAutocomplete
            value={groups}
            multiple
            placeholder="Groups"
            onChange={(event: React.ChangeEvent<{}>, value: OptionType[]) =>
              dispatch(setCreateQuizGroups({ groups: value }))
            }
            id="Groups"
            labelText="Groups"
            options={allGroups}
          />

          <CustomAutocomplete
            placeholder="Active"
            onChange={(event: React.ChangeEvent<{}>, value: OptionType) =>
              value && dispatch(setCreateQuizActivity({ active: value }))
            }
            id="Active"
            labelText="Active"
            options={activeOptions}
            value={createQuizActivity}
          />

          <Button color="primary" onClick={handleQuizSubmit}>
            Create Quiz
          </Button>
          <Button color="primary" onClick={handleHomeworkSubmit}>
            Create Homework
          </Button>
        </div>
      )}

      <Divider style={{ marginTop: '40px' }} />
      <div style={{ height: '100%' }}>
        {!isEditing && (
          <div style={{ float: 'right', width: 'auto', zIndex: 1000, position: 'relative' }}>
            <Button
              color="info"
              onClick={handleEditQuestionPriority}
              size="sm"
              disabled={selectedQuestions?.length > 0}
            >
              {isDraggable ? 'Save' : 'Rearrange Questions'}
            </Button>

            {userRole === 'admin' && (
              <Button
                color="danger"
                onClick={() => setDeleteDialogOpen(true)}
                size="sm"
                disabled={selectedQuestions?.length < 1}
              >
                Delete Question
              </Button>
            )}
            <Button color="primary" onClick={handleEditQuestion} size="sm" disabled={selectedQuestions?.length !== 1}>
              Edit Question
            </Button>
          </div>
        )}
        {isEditing && (
          <div style={{ float: 'right' }}>
            <Button fullWidth onClick={() => setIsEditing(false)} color="danger">
              Cancel Editing
            </Button>
          </div>
        )}
        <div style={{ display: isEditing ? 'block' : 'none' }}>
          {questionToEditState === 'succeeded' && <EditQuestion submitSuccessCallback={onEditingSuccess} />}
        </div>
        {!isEditing && (
          <div>
            <div>
              <TextField
                style={{ marginLeft: '20px' }}
                label="Question Name Filter"
                onChange={e => {
                  if (e.target.value.length < questionNameFilter.length) {
                    setSelectedQuestions(tempFilter);
                  }
                  setQuestionNameFilter(e.target.value);
                  setFilters(prevFilters => ({ ...prevFilters, title: e.target.value }));
                }}
              />
              <TextField
                style={{ marginLeft: '20px' }}
                label="Category Filter"
                onChange={e => {
                  setCategoryFilter(e.target.value);
                  setFilters(prevFilters => ({ ...prevFilters, category: e.target.value }));
                  if (e.target.value.length < categoryFilter.length) {
                    setSelectedQuestions(tempFilter);
                  }
                }}
              />
              <TextField
                style={{ marginLeft: '20px' }}
                label="Level Filter"
                onChange={e => {
                  setLevelFilter(e.target.value);
                  setFilters(prevFilters => ({ ...prevFilters, level: e.target.value }));
                  if (e.target.value.length < levelFilter.length) {
                    setSelectedQuestions(tempFilter);
                  }
                }}
              />
            </div>
            {isDraggable ? (
              <DraggableTable
                rows={filteredQuestions.sort(sortByPriority)}
                setRows={setRows}
                allRows={allRows}
                setAllRows={setAllRows}
                columns={columns}
              />
            ) : (
              <DataGrid
                pagination
                paginationMode="server"
                page={pageState.page}
                onPageChange={newPage => setPageState(old => ({ ...old, page: newPage }))}
                pageSize={pageState.pageSize}
                onPageSizeChange={newPageSize => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                rows={rows}
                // rows={filteredQuestions.sort(sortByPriority)}
                rowCount={totalQuestions}
                rowsPerPageOptions={[25, 50, 75, 100]}
                columns={columns}
                checkboxSelection
                selectionModel={selectionModel}
                onSelectionModelChange={newSelection => {
                  setSelectedQuestions(newSelection as string[]);
                  if (isEditing) setIsEditing(false);
                }}
                autoHeight
                loading={getQuestionsReq.status === 'loading'}
              />
            )}
          </div>
        )}
      </div>
      <DeleteDialog
        dialogText="Are you sure you want to delete this question?"
        onSubmitClick={handleDeleteQuestion}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      />
    </div>
  );
};

export default AllQuestionsManagment;

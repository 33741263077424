import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, InputAdornment } from '@material-ui/core';
import Autocomplete, { OptionType } from 'components/Autocomplete/Autocomplete';
import CustomInput from 'components/CustomInput/CustomInput';
import { tokenSelector } from 'features/auth/authSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGradesAsync,
  getSchoolsAsync,
  getSchoolsReqSelector,
  getGradesReqSelector,
  getGroupsAsync,
  availableGroupsSelector,
  getGroupsReqSelector,
} from '../slices/adminSlice';

interface Props {
  name: string;
  email: string;
  password: string;
  phone: string;
  parentPhone: string;
  grade: string;
  group?: OptionType;
  active?: boolean;
  onNameChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  onPasswordChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  onEmailChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  onPhoneChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  onParentPhoneChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  onGradeChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  onGroupChange?(e: React.ChangeEvent, value: OptionType): void;
  onActiveChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}
const StudentInputs: React.FC<Props> = ({
  name,
  email,
  password,
  phone,
  parentPhone,
  grade,
  group,
  active,
  onNameChange,
  onEmailChange,
  onPhoneChange,
  onParentPhoneChange,
  onGradeChange,
  onPasswordChange,
  onGroupChange,
  onActiveChange,
}) => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const groups = useSelector(availableGroupsSelector);
  const schoolReq = useSelector(getSchoolsReqSelector);
  const gradesReq = useSelector(getGradesReqSelector);
  const groupsReq = useSelector(getGroupsReqSelector);
  React.useEffect(() => {
    if (token && dispatch && gradesReq.status === 'idle') {
      token && dispatch(getGradesAsync({ token }));
    }
    if (token && dispatch && schoolReq.status === 'idle') {
      token && dispatch(getSchoolsAsync({ token }));
    }
    if (token && dispatch && groupsReq.status === 'idle') {
      token && dispatch(getGroupsAsync({ token }));
    }
  }, [token, dispatch, schoolReq.status, gradesReq.status, groupsReq.status]);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CustomInput
        labelText="Student Name..."
        id="name"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'text',
          endAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon="user" />
            </InputAdornment>
          ),
          required: true,
          autoFocus: true,
          onChange: onNameChange,
          value: name,
        }}
      />
      <CustomInput
        labelText="Email..."
        id="email"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'email',
          endAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon="envelope" />
            </InputAdornment>
          ),
          required: true,
          autoFocus: true,
          onChange: onEmailChange,
          value: email,
        }}
      />
      <CustomInput
        labelText="Password"
        id="pass"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'password',
          endAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon="lock" />
            </InputAdornment>
          ),
          autoComplete: 'off',
          onChange: onPasswordChange,
          value: password,
        }}
      />
      <CustomInput
        labelText="Phone Number"
        id="pass"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'text',
          endAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon="phone" />
            </InputAdornment>
          ),
          autoComplete: 'off',
          onChange: onPhoneChange,
          value: phone,
        }}
      />
      <CustomInput
        labelText="Parent Phone Number"
        id="pass"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'text',
          endAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon="phone" />
            </InputAdornment>
          ),
          autoComplete: 'off',
          onChange: onParentPhoneChange,
          value: parentPhone,
        }}
      />
      <CustomInput
        labelText="Grade"
        formControlProps={{
          fullWidth: true,
        }}
        id="grade"
        inputProps={{
          type: 'text',
          endAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon="graduation-cap" />
            </InputAdornment>
          ),
          value: grade,
          onChange: onGradeChange,
        }}
      />
      {group && onGroupChange && (
        <Autocomplete labelText="Student Group" id="group" value={group} onChange={onGroupChange} options={groups} />
      )}
      {active && onActiveChange && <Checkbox checked={active} onChange={onActiveChange} />}
    </div>
  );
};

export default StudentInputs;

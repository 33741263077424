import { Card, Divider, makeStyles } from '@material-ui/core';
import styles from 'assets/jss/lms/features/questions';
import { questionWithIndexSelector } from '../updateSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import UpdateSimpleAnswer from '../UpdateSimpleAnswers/UpdateSimpleAnswer';
import { RootState } from 'features';
import VocabBox from '../UpdateSimpleAnswers/VocabBox';

const useStyles = makeStyles(styles);

const VocabAnswer: React.FC = props => {
  const activeQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.questionIndex);
  const question = useSelector(questionWithIndexSelector(activeQuestionIndex));
  const classes = useStyles();
  return (
    <>
      {question.vocabs && question.vocabs.length > 0 && <VocabBox vocabs={question.vocabs} />}

      <Card style={{ margin: '1vh 10vw', padding: '30px', minHeight: '85vh' }}>
        {question && (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'start' }}>
            <div className={classes.container}>
              {question.questions.map((_: any, index: number) => {
                return (
                  <div
                    id={`Q${index + 1}`}
                    key={`question-number-${index}`}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '1rem',
                    }}
                  >
                    {index !== 0 && <Divider />}
                    <h4 style={{ fill: 'black', color: 'black', marginTop: 10 }}>
                      <b>Question {index + 1}</b>:
                    </h4>
                    <UpdateSimpleAnswer
                      questionType={question.questions[index].modelType}
                      questionIndex={activeQuestionIndex}
                      subQuestionIndex={index}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default VocabAnswer;

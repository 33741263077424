import React, { useState } from 'react';
import CardBody from 'components/Card/CardBody';
import {
  noteTakingAnswerSelector,
  // setTriggerSaveQuizAnswers,
  subQuestionWithIndexSelector,
  updateAnswerForNoteTakingQuestion,
  updateCorrectionForNoteTakingQuestion,
} from '../updateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import { getMessagesAsync, tokenSelector, userSelector } from 'features/auth/authSlice';
import ShowWhy from './ShowWhy';
import { RootState } from 'features';
import { TokenAnnotator } from 'react-text-annotate';
import ShowChat from './ShowChat';
import CustomButton from 'components/CustomButtons/Button';

const hashCode = function (s: string) {
  return s.split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

const SingleNoteQuestion = (props: {
  note: Types.API.Question.ISingleNoteTaking;
  noteIndex: number;
  questionIndex: number;
  subQuestionIndex: number;
  questionID: string;
}) => {
  const { note, noteIndex, questionIndex, subQuestionIndex, questionID } = props;
  const dispatch = useDispatch();
  const correction = useSelector(noteTakingAnswerSelector(questionIndex, subQuestionIndex));
  const studentAnswersArray = correction.answer;
  const quizTakenStatus = useSelector((state: RootState) => state.updateQuiz.quizTaken.status);
  const studentMarksArray = correction.marks;
  const answersWhy = correction.why;
  const userRole = useSelector(userSelector).role;
  const highlightStateKey = hashCode(note.text) + '';
  const storedHighlightState = sessionStorage.getItem(highlightStateKey);
  const [highlightState, setHighlightState] = React.useState<any>(
    storedHighlightState !== null ? JSON.parse(storedHighlightState) : { value: [], key: highlightStateKey },
  );
  if (highlightState.key !== highlightStateKey) {
    setHighlightState(
      storedHighlightState !== null ? JSON.parse(storedHighlightState) : { value: [], key: highlightStateKey },
    );
  }

  const question = useSelector(
    subQuestionWithIndexSelector(questionIndex, subQuestionIndex),
  ) as Types.API.Question.INoteTakingModel;

  const onMarkChange =
    (noteIndex: number, answerIndex: number) => (c: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      dispatch(
        updateCorrectionForNoteTakingQuestion({
          mark: parseInt(c.target.value, 10),
          questionNumber: questionIndex,
          subQuestionNumber: subQuestionIndex,
          noteIndex,
          noteAnswerIndex: answerIndex,
        }),
      );
  // const triggerSave = ()=>{
  //   dispatch(setTriggerSaveQuizAnswers({ save: true }));

  // }
  const [openChat, setOpenChat] = useState(false);
  const token = useSelector(tokenSelector);
  const handleShowChatClick = () => {
    const quizID = localStorage.getItem('quizID') || ' ';
    const studentID = localStorage.getItem('studentID') || ' ';

    if (studentID && quizID && questionID) {
      dispatch(
        getMessagesAsync({
          token,
          questionID: questionID || '',
          studentID: studentID || '',
          quizID: quizID || '',
        }),
      );
    }
    setOpenChat(true);
  };
  const talkToTheTeacherButton = () => {
    return (
      <CustomButton size="sm" color="twitter" onClick={() => handleShowChatClick()}>
        <strong>chat with the teacher</strong>
      </CustomButton>
    );
  };

  return (
    <div
      key={`question-${questionIndex}-${subQuestionIndex}-${noteIndex}`}
      style={{ display: 'flex', flexDirection: 'column', margin: '10px 0px 10px 0p' }}
    >
      <TokenAnnotator
        style={{
          whiteSpace: 'pre-line',
          maxWidth: 500,
          lineHeight: 1.5,
          fontWeight: 200,
          fontSize: 20,
          color: 'black',
        }}
        tokens={note.text.split(' ')}
        value={highlightState.value}
        onChange={value => {
          setHighlightState({ value, key: highlightStateKey });
          sessionStorage.setItem(hashCode(note.text) + '', JSON.stringify({ value, key: highlightStateKey }));
        }}
        getSpan={span => ({
          ...span,
          color: '#ff0',
        })}
      />
      {Array.from(Array(note.answersCount).keys()).map((_, answerIndex) => (
        <CardBody key={`note-question-${subQuestionIndex}-${noteIndex}`}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <TextField
              disabled={quizTakenStatus !== 'started'}
              multiline
              rows={2}
              value={studentAnswersArray[noteIndex][answerIndex]}
              onChange={c => {
                dispatch(
                  updateAnswerForNoteTakingQuestion({
                    answer: c.target.value,
                    questionNumber: questionIndex,
                    subQuestionNumber: subQuestionIndex,
                    noteIndex: noteIndex,
                    noteAnswerIndex: answerIndex,
                  }),
                );
                // triggerSave()
              }}
              placeholder={`Note ${answerIndex + 1}...`}
              variant="outlined"
              fullWidth
            />
            {(quizTakenStatus === 'auto-corrected' || quizTakenStatus === 'ended') && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  marginLeft: '50px',
                }}
              >
                <label>
                  Mark:
                  <input
                    style={{
                      border: '2px solid',
                      borderRadius: '5px',
                      width: '30px',
                      borderColor:
                        question.correction.marks[noteIndex][answerIndex] > 0 &&
                        studentMarksArray[noteIndex][answerIndex] === question.correction.marks[noteIndex][answerIndex]
                          ? 'green'
                          : 'red',
                    }}
                    disabled={userRole !== 'assistant' && userRole !== 'admin'}
                    placeholder={studentMarksArray[noteIndex][answerIndex] + ''}
                    onChange={onMarkChange(noteIndex, answerIndex)}
                  ></input>
                  /{question.correction.marks[noteIndex][answerIndex]}
                </label>
                {answersWhy && <ShowWhy role={userRole} why={answersWhy[noteIndex][answerIndex]} />}
              </div>
            )}
          </div>
        </CardBody>
      ))}

      {(userRole === 'admin' ||
        userRole === 'assistant' ||
        ((userRole === 'student' || userRole === 'parent') &&
          (quizTakenStatus === 'auto-corrected' || quizTakenStatus === 'ended'))) && (
        <div>
          <ol style={{ color: 'black' }}>
            Model Answer:
            {question.correction.answer[noteIndex].map((note, answer_index) => {
              return <li style={{ color: '#1a0dab', fontWeight: 'bold' }}>{note}</li>;
            })}
          </ol>
          {
            <div style={{ position: 'absolute', right: '20px', marginTop: '-10%' }}>
              {userRole === 'student' && (
                <>
                  {talkToTheTeacherButton()}
                  <ShowChat
                    open={openChat}
                    setOpen={setOpenChat}
                    questionID={questionID}
                    quizID={localStorage.getItem('quizID') || ' '}
                    senderName={localStorage.getItem('studentName') || ' '}
                    studentID={localStorage.getItem('studentID') || ' '}
                    questionNumber={questionIndex + 1}
                    subQuestionNumber={subQuestionIndex + 1}
                    modelType={question.modelType}
                  />
                </>
              )}
            </div>
          }
        </div>
      )}
    </div>
  );
};

const NoteTakingAnswer: React.FC<{ questionIndex: number; subQuestionIndex: number }> = props => {
  const { questionIndex, subQuestionIndex } = props;

  const question = useSelector(
    subQuestionWithIndexSelector(questionIndex, subQuestionIndex),
  ) as Types.API.Question.INoteTakingModel;
  const studentMarksArray = useSelector(noteTakingAnswerSelector(questionIndex, subQuestionIndex)).marks;
  console.log(question);
  //console.log(studentMarksArray);

  const getTotalMarks = (question: Types.API.Question.INoteTakingModel) => {
    const elements = [];
    let marks = 0;
    for (let i = 0; i < studentMarksArray.length; i++) {
      for (let j = 0; j < studentMarksArray[i].length; j++) {
        marks += studentMarksArray[i][j];
      }
    }
    elements.push(
      <div>
        <h4 style={{ color: 'black' }}>
          Total Mark:
          <label style={{ color: '#1a0dab', fontWeight: 'bold' }}>
            {marks}/{question.mark}
          </label>
        </h4>
      </div>,
    );
    return elements;
  };

  return (
    <div>
      <CardBody>
        <h5 style={{ fill: 'black', color: 'black' }}>
          <b>{question.text}</b>
        </h5>
        <div style={{ display: 'flex', float: 'left', flexWrap: 'wrap', flexDirection: 'column', width: '100%' }}>
          {question.notes.map((note, noteIndex) => (
            <SingleNoteQuestion
              questionID={note._id ? note._id : ''}
              note={note}
              noteIndex={noteIndex}
              questionIndex={questionIndex}
              subQuestionIndex={subQuestionIndex}
            />
          ))}
        </div>
      </CardBody>
      {getTotalMarks(question)}
    </div>
  );
};

export default NoteTakingAnswer;

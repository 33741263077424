import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import VideoWithText from 'features/video/VideoWithText';

interface Props {}
export const VideoRoutesPrefix = '/video';

const VideoLayout: React.FC<Props> = () => {

  return (
    <Switch>
      <Route component={VideoWithText} path={`${VideoRoutesPrefix}/:videoId`} />
      <Route path={VideoRoutesPrefix}>Video Url is incorrectNot Found Video</Route>
      <Redirect exact from={VideoRoutesPrefix} to="/video/home" />
    </Switch>
  );
};

export default VideoLayout;

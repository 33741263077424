import {
  sigin,
  verifyToken,
  resetPassword,
  sendResetCode,
  forgotPassword,
  getMessages,
  getNotifications,
  postMessage,
  getRoomData,
  setNotificationAsRead,
} from './client';
import { nullUser } from './types';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '..';
import { stat } from 'fs';
interface UserState {
  token: string;
  user: Types.API.User.IUserInfo;
  messages: Types.API.User.IMessages[];
  roomData: Types.API.User.IRoom;
  notifications: Types.API.User.INotifications[];
  loggedIn: boolean;
  loginReq: Types.IRequestState;
  signupReq: Types.IRequestState;
  verifyTokenReq: Types.IRequestState;
  resetPasswordReq: Types.IRequestState;
  postMessagesReq: Types.IRequestState;
  getMessagesReq: Types.IRequestState;
  getNotificationsReq: Types.IRequestState;
  getRoomDataReq: Types.IRequestState;
  setNotificationAsReadReq: Types.IRequestState;
  forgotPasswordReq: Types.IRequestState;
  resetCode: string;
  sendResetCodeReq: Types.IRequestState;
}
const loggedInUserToken = localStorage.getItem('auth-token');
const initialState: UserState = {
  token: loggedInUserToken || '',
  loggedIn: false,
  user: nullUser,
  messages: [],
  roomData: {
    questionID: '',
    quizID: '',
    studentID: '',
    questionNumber: 0,
    subQuestionNumber: 0,
  },
  notifications: [],
  loginReq: { status: 'idle', error: null },
  signupReq: { status: 'idle', error: null },
  verifyTokenReq: { status: 'idle', error: null },
  resetPasswordReq: { status: 'idle', error: null },
  postMessagesReq: { status: 'idle', error: null },
  getMessagesReq: { status: 'idle', error: null },
  getNotificationsReq: { status: 'idle', error: null },
  getRoomDataReq: { status: 'idle', error: null },
  setNotificationAsReadReq: { status: 'idle', error: null },
  forgotPasswordReq: { status: 'idle', error: null },
  resetCode: '',
  sendResetCodeReq: { status: 'idle', error: null },
};

// Async updates to the store
export const loginAsync = createAsyncThunk('auth/signin', sigin);
export const verifyTokenAsync = createAsyncThunk('auth/verifyToken', verifyToken);
export const resetPasswordAsync = createAsyncThunk('auth/resetPassword', resetPassword);
export const postMessagesAsync = createAsyncThunk('admin/postMessages', postMessage);
export const getMessagesAsync = createAsyncThunk('admin/getMessages', getMessages);
export const getNotificationsAsync = createAsyncThunk('admin/getNotifications', getNotifications);
export const getRoomDataAsync = createAsyncThunk('admin/getRoom', getRoomData);
export const setNotificationAsReadAsync = createAsyncThunk('admin/setNotificationAsRead', setNotificationAsRead);
export const forgotPasswordAsync = createAsyncThunk('auth/forgotPassword', forgotPassword);
export const sendResetCodeAsync = createAsyncThunk('auth/sendResetCode', sendResetCode);

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Sync updates to the store
    userLoggedOut: state => {
      state = initialState;
    },
    logoutUser: state => {
      localStorage.clear();
      // localStorage.removeItem('auth-token');
      state.loggedIn = false;
      state.token = '';
      state.user = nullUser;
      window.location.reload();
    },
  },
  extraReducers: builder => {
    // Async updates to the store listeners
    builder.addCase(loginAsync.pending, (state, action) => {
      state.loginReq.status = 'loading';
    });
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      state.loginReq.status = 'succeeded';
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.loggedIn = true;
      localStorage.setItem('auth-token', action.payload.token);
      localStorage.setItem('email', action.payload.user.email);
      localStorage.setItem('role', action.payload.user.role);
      localStorage.setItem('studentID', action.payload.user.id);
      localStorage.setItem('studentName', action.payload.user.name);
    });
    builder.addCase(loginAsync.rejected, (state, action) => {
      state.loginReq.status = 'failed';
      state.loginReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(verifyTokenAsync.pending, (state, action) => {
      state.verifyTokenReq.status = 'loading';
    });
    builder.addCase(verifyTokenAsync.fulfilled, (state, action) => {
      state.verifyTokenReq.status = 'succeeded';
      // localStorage.setItem('auth-token', action.payload.token);
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.loggedIn = true;
    });
    builder.addCase(verifyTokenAsync.rejected, (state, action) => {
      state.verifyTokenReq.status = 'failed';
      state.verifyTokenReq.error = action.error.message || 'Unknown error';
      state.user = nullUser;
      state.token = '';
      state.loggedIn = false;
    });

    builder.addCase(resetPasswordAsync.pending, (state, action) => {
      state.resetPasswordReq.status = 'loading';
    });
    builder.addCase(resetPasswordAsync.fulfilled, (state, action) => {
      state.resetPasswordReq.status = 'succeeded';
      state.user.loggedInBefore = action.payload.loggedInBefore;
    });
    builder.addCase(resetPasswordAsync.rejected, (state, action) => {
      state.resetPasswordReq.status = 'failed';
      state.resetPasswordReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(postMessagesAsync.pending, (state, action) => {
      state.postMessagesReq.status = 'loading';
    });
    builder.addCase(postMessagesAsync.fulfilled, (state, action) => {
      state.postMessagesReq.status = 'succeeded';
      state.messages = action.payload.messages;
    });
    builder.addCase(postMessagesAsync.rejected, (state, action) => {
      state.postMessagesReq.status = 'failed';
      state.postMessagesReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(getMessagesAsync.pending, (state, action) => {
      state.getMessagesReq.status = 'loading';
    });
    builder.addCase(getMessagesAsync.fulfilled, (state, action) => {
      state.getMessagesReq.status = 'succeeded';
      state.messages = action.payload;
    });
    builder.addCase(getMessagesAsync.rejected, (state, action) => {
      state.getMessagesReq.status = 'failed';
      state.getMessagesReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(getNotificationsAsync.pending, (state, action) => {
      state.getNotificationsReq.status = 'loading';
    });
    builder.addCase(getNotificationsAsync.fulfilled, (state, action) => {
      state.getNotificationsReq.status = 'succeeded';
      state.notifications = action.payload.reverse();
    });
    builder.addCase(getNotificationsAsync.rejected, (state, action) => {
      state.getNotificationsReq.status = 'failed';
      state.getNotificationsReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(getRoomDataAsync.pending, (state, action) => {
      state.getRoomDataReq.status = 'loading';
    });
    builder.addCase(getRoomDataAsync.fulfilled, (state, action) => {
      state.getRoomDataReq.status = 'succeeded';
      state.roomData = action.payload;
    });
    builder.addCase(getRoomDataAsync.rejected, (state, action) => {
      state.getRoomDataReq.status = 'failed';
      state.getRoomDataReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(setNotificationAsReadAsync.pending, (state, action) => {
      state.setNotificationAsReadReq.status = 'loading';
    });
    builder.addCase(setNotificationAsReadAsync.fulfilled, (state, action) => {
      state.setNotificationAsReadReq.status = 'succeeded';
      state.notifications = action.payload;
    });
    builder.addCase(setNotificationAsReadAsync.rejected, (state, action) => {
      state.setNotificationAsReadReq.status = 'failed';
      state.setNotificationAsReadReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(forgotPasswordAsync.pending, (state, action) => {
      state.forgotPasswordReq.status = 'loading';
    });
    builder.addCase(forgotPasswordAsync.fulfilled, (state, action) => {
      state.forgotPasswordReq.status = 'succeeded';
    });
    builder.addCase(forgotPasswordAsync.rejected, (state, action) => {
      state.forgotPasswordReq.status = 'failed';
      state.forgotPasswordReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(sendResetCodeAsync.pending, (state, action) => {
      state.sendResetCodeReq.status = 'loading';
    });
    builder.addCase(sendResetCodeAsync.fulfilled, (state, action) => {
      state.sendResetCodeReq.status = 'succeeded';
      state.resetCode = action.payload.resetCode;
    });
    builder.addCase(sendResetCodeAsync.rejected, (state, action) => {
      state.sendResetCodeReq.status = 'failed';
      state.sendResetCodeReq.error = action.error.message || 'Unknown error';
    });
  },
});

export const { userLoggedOut, logoutUser } = slice.actions;

export const userSelector = (state: RootState) => state.auth.user;
export const resetCodeSelector = (state: RootState) => state.auth.resetCode;
export const resetCodeReqSelector = (state: RootState) => state.auth.sendResetCodeReq;
export const userRoleSelector = (state: RootState) => state.auth.user.role;
export const userNameSelector = (state: RootState) => state.auth.user.name;
export const loggedInSelector = (state: RootState) => state.auth.loggedIn;
export const tokenSelector = (state: RootState) => state.auth.token;
export const statusSelector = (state: RootState) => state.auth.loginReq;
export const resetPasswordSelector = (state: RootState) => state.auth.resetPasswordReq;
export const forgotPasswordReqSelector = (state: RootState) => state.auth.forgotPasswordReq;
export const errorSelector = (state: RootState) => state.auth.loginReq.error;
export const signupStatusSelector = (state: RootState) => state.auth.signupReq.status;
export const signupErrorSelector = (state: RootState) => state.auth.signupReq.error;
export const verifyTokenSelector = (state: RootState) => state.auth.verifyTokenReq.status;
export const messagesSelector = (state: RootState) => state.auth.messages;
export const notificationsSelector = (state: RootState) => state.auth.notifications;
export const roomDataSelector = (state: RootState) => state.auth.roomData;

export const postMessagesReqSelector = (state: RootState) => state.auth.postMessagesReq.status;
export const getMessagesReqSelector = (state: RootState) => state.auth.getMessagesReq.status;
export const getNotificationsReqSelector = (state: RootState) => state.auth.getNotificationsReq.status;

export default slice.reducer;

import imagesStyles from 'assets/jss/lms/imagesStyles';
import { createStyles } from '@material-ui/core/styles';
import { container } from '../../lms';

const questionsStyle = createStyles({
  ...imagesStyles,
  container: {
    ...container,
    zIndex: 2,
    position: 'relative',
    color: '#FFFFFF',
  },
  rubric: {
    fill: 'black',
    color: 'black',
    marginRight: '25px',
    minWidth: '20vw',
    minHeight: '6vh',
    fontWeight: 400,
    fontSize: 20,
  },
  questionHeader: {
    fontWeight: 500,
    paddingTop: '25px',
    paddingLeft: '25px',
    color: 'black',
  },
  textHeader: {
    color: '#000',
    textAlign: 'center',
    fontWeight: 500,
    padding: '10px',
  },
  questionPassage: {
    fontWeight: 400,
    padding: '10px',
  },
  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)',
  },
  pageHeader: {
    minHeight: '100vh',
    height: 'auto',
    display: 'inherit',
    position: 'relative',
    margin: '0',
    padding: '0',
    border: '0',
    alignItems: 'center',
    '&:before': {
      background: 'rgba(0, 0, 0, 0.5)',
    },
    '&:before,&:after': {
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      display: 'block',
      left: '0',
      top: '0',
      content: '""',
    },
    '& footer li a,& footer li a:hover,& footer li a:active': {
      color: '#FFFFFF',
    },
    '& footer': {
      position: 'absolute',
      bottom: '0',
      width: '100%',
    },
  },
  form: {
    margin: '0',
  },
  cardHeader: {
    width: 'auto',
    textAlign: 'center',
    marginLeft: '20px',
    marginRight: '20px',
    marginTop: '-40px',
    padding: '20px 0',
    marginBottom: '15px',
  },
  socialIcons: {
    maxWidth: '24px',
    marginTop: '0',
    width: '100%',
    transform: 'none',
    left: '0',
    top: '0',
    height: '100%',
    lineHeight: '41px',
    fontSize: '20px',
  },
  divider: {
    marginTop: '30px',
    marginBottom: '0px',
    textAlign: 'center',
  },
  cardFooter: {
    paddingTop: '0rem',
    border: '0',
    borderRadius: '6px',
    justifyContent: 'center !important',
  },
  socialLine: {
    marginTop: '1rem',
    textAlign: 'center',
    padding: '0',
  },
  inputIconsColor: {
    color: '#495057',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxHeight: '20vh',
    height: '20vh',
  },
  nextSectionButton: {
    position: 'fixed',
    top: '2vh',
    right: '0vw',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 100,
  },
  previousSectionButton: {
    position: 'fixed',
    top: '2vh',
    left: '0vw',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 100,
  },
  stepperButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'space-between',
    width: '100%',
    // zIndex: 100000,
    //margin: '25px',
    paddingBottom: '50px',
  },
  categoryAutocomplete: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  categoriesBody: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-around',
    alignItems: 'center',
  },
  icons: {
    width: '17px',
    height: '17px',
    color: '#FFFFFF',
  },
  margin5left: {
    marginLeft: '5px',
    position: 'fixed',
  },
  margin5right: {
    marginRight: '5px',
  },
  margin20right: {
    marginRight: '20px',
  },
  NextButton: {
    width: '12%',
    marginLeft: '3rem',
  },
  BackButton: {
    width: '12%',
    marginRight: '3rem',
  },
  SubmitButton: {
    width: 'auto',
    margin: '3rem',
  },
  margin10Top: {
    marginTop: '10px',
  },
  quizContainer: {
    marginTop: '1rem',
  },
  dataGrid: {
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      // lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'pre-line',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    },
  },
  badIcon: {
    marginLeft: '8px',
    color: 'red',
  },
  normalIcon: {
    marginLeft: '8px',
    color: 'orange',
  },
  goodIcon: {
    marginLeft: '8px',
    color: 'green',
  },
});

export default questionsStyle;

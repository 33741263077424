import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Footer from 'components/Footer/Footer';
import Notification from 'features/notifications/Notification';
import AnswerQuiz from 'features/quiz/AnswerQuiz';
import ProfilePage from 'features/student/Home';
import CorrectQuiz from 'features/quiz/CorrectQuiz';
import VideoView from 'features/video/VideoView';
import NotificationManagement from 'features/admin/views/NotificationManagement';

interface Props {}
export const StudentRoutesPrefix = '/student';

const StudentLayout: React.FC<Props> = () => {
  return (
    <Switch>
      <Route component={NotificationManagement} exact path={`${StudentRoutesPrefix}/notifications`} />
      <Route component={VideoView} exact path={`${StudentRoutesPrefix}/video/:id`} />
      <Route component={CorrectQuiz} exact path={`${StudentRoutesPrefix}/viewPublishedQuiz/:quizTakenId`} />
      <Route component={AnswerQuiz} exact path={`${StudentRoutesPrefix}/:quizTakenId/:type`} />
      <Route path={StudentRoutesPrefix}>
        <ProfilePage />
        <Footer />
        <Notification />
      </Route>
      <Redirect exact from={StudentRoutesPrefix} to="/student/home" />
    </Switch>
  );
};

export default StudentLayout;

import { FC } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import { setTriggerSaveQuizAnswers } from 'features/quiz/updateSlice';
import { useDispatch } from 'react-redux';

interface Props {
  activeStep: number;
  steps: JSX.Element[];
  nonLinear?: boolean;
  handleQuestionIndex?: (index: number) => void;
}

const CustomStepper: FC<Props> = ({ activeStep, steps, nonLinear, handleQuestionIndex }) => {
  const dispatch = useDispatch();

  const triggerSave = () => {
    dispatch(setTriggerSaveQuizAnswers({ save: true }));
  };

  return (
    <div style={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel nonLinear={nonLinear}>
        {steps.map((label, index) => (
          <Step key={label.props.id || label.key || index + 1}>
            {nonLinear && handleQuestionIndex ? (
              <StepButton
                onClick={() => {
                  handleQuestionIndex(index);
                  triggerSave();
                }}
              >
                {label}
              </StepButton>
            ) : (
              <StepLabel>{label}</StepLabel>
            )}
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default CustomStepper;

import { createStyles } from '@material-ui/core/styles';

const studentStyle = createStyles({
  quizzesContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  quizItem: {
    margin: '1rem',
    maxWidth: 'max-content',
  },
  quizzesSectionContainer: {
    flexFlow: 'column unwrap',
    minHeight: '15vh',
    justifyContent: 'space-between ',
  },
});

export default studentStyle;

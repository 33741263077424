import React from 'react';

// @material-ui/core components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid, { GridProps } from '@material-ui/core/Grid';

const styles = createStyles({
  grid: {
    marginRight: '15px',
    marginLeft: '15px',
    width: 'auto',
  },
});

const useStyles = makeStyles(styles);

const GridContainer: React.FC<GridProps> = ({ children, className, ...rest }) => {
  const classes = useStyles();
  return (
    <Grid container {...rest} className={classes.grid + ' ' + className}>
      {children}
    </Grid>
  );
};

export default GridContainer;

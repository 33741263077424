import React from 'react';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'features';
import {
  setShortAnswerCorrection,
  setShortAnswerMark,
  setShortAnswerText,
  setShortAnswersAnswerCount,
  increaseShortAnswersModelAnswersCount,
  decreaseShortAnswersModelAnswersCount,
} from '../createSlice';
import WhyShortAnswers from './WhyShortAnswers';
import { InputAdornment } from '@material-ui/core';

const ShortAnswerQuestion: React.FC<{ uuid: string }> = (props: { uuid: string }) => {
  const questionText = useSelector(
    (state: RootState) =>
      state.createQuestion.curQuestion.questions[props.uuid] as Types.API.Question.IShortAnswersCreateInput,
  ).text;
  const modelAnswersCount = useSelector(
    (state: RootState) =>
      state.createQuestion.curQuestion.questions[props.uuid] as Types.API.Question.IShortAnswersCreateInput,
  ).modelAnswersCount;
  const answersCount = useSelector(
    (state: RootState) =>
      state.createQuestion.curQuestion.questions[props.uuid] as Types.API.Question.IShortAnswersCreateInput,
  ).answersCount;
  const mark = useSelector(
    (state: RootState) =>
      state.createQuestion.curQuestion.questions[props.uuid] as Types.API.Question.IShortAnswersCreateInput,
  ).mark;
  const questionCorrection = useSelector(
    (state: RootState) =>
      state.createQuestion.curQuestion.questions[props.uuid] as Types.API.Question.IShortAnswersCreateInput,
  ).correction;
  const dispatch = useDispatch();

  return (
    <div>
      <CardBody>
        <CustomInput
          labelText="Question Text"
          id="text"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            value: questionText,
            multiline: true,
            rows: 1,
            onChange: e => dispatch(setShortAnswerText({ text: e.target.value, uuid: props.uuid })),
            endAdornment: (
              <InputAdornment position="start">
                <CustomInput
                  labelText="Answers"
                  id="StudentAnswerShortAnswer"
                  inputProps={{
                    type: 'number',
                    inputProps: { min: 0, max: 10 },
                    value: answersCount,
                    onChange: c =>
                      dispatch(
                        setShortAnswersAnswerCount({
                          value: parseInt(c.target.value, 10),
                          uuid: props.uuid,
                        }),
                      ),
                  }}
                />
                <CustomInput
                  labelText="Model"
                  id="StudentAnswerShortAnswer"
                  inputProps={{
                    type: 'number',
                    inputProps: { min: 0, max: 10 },
                    value: modelAnswersCount,
                    onChange: c => {
                      const newValue = parseInt(c.target.value, 10);
                      if (newValue > modelAnswersCount) {
                        dispatch(increaseShortAnswersModelAnswersCount({ uuid: props.uuid }));
                      } else {
                        dispatch(decreaseShortAnswersModelAnswersCount({ uuid: props.uuid }));
                      }
                    },
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        {questionCorrection.answer.map((shortAnswerCorrection, index) => (
          <CustomInput
            labelText="Correct Answer"
            id="text"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: 'text',
              value: shortAnswerCorrection,
              onChange: e =>
                dispatch(setShortAnswerCorrection({ index, correction: e.target.value, uuid: props.uuid })),
              multiline: true,
              rows: 2,
              endAdornment: (
                <WhyShortAnswers
                  uuid={props.uuid}
                  index={index}
                  id={`WhyShortAnswers input${props.uuid + index + 1}`}
                />
              ),
            }}
          />
        ))}
        <div style={{ display: 'flex', float: 'right' }}>
          <CustomInput
            id="mark"
            labelText="Mark"
            inputProps={{
              type: 'number',
              inputProps: { min: 0, max: 10 },
              value: mark,
              onChange: (c: any) => dispatch(setShortAnswerMark({ value: c.target.value, uuid: props.uuid })),
            }}
          />
        </div>
      </CardBody>
    </div>
  );
};

export default ShortAnswerQuestion;

import { openFailure, openSuccess, openLoader } from 'features/notifications/notificationSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useReqListener = ({
  status,
  successMessage,
  errorMessage,
  loadingMessage,
}: {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  successMessage: string;
  loadingMessage: string;
  errorMessage: string | null;
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (status === 'failed') {
      dispatch(openFailure({ message: errorMessage || 'Error not Found' }));
    } else {
      if (status === 'succeeded') {
        dispatch(openSuccess({ message: successMessage }));
      } else if (status === 'loading') {
        dispatch(openLoader({ message: loadingMessage }));
      }
    }
  }, [dispatch, status, errorMessage, successMessage, loadingMessage]);
};

import React, { useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/lms/features/questions';
import { useDispatch, useSelector } from 'react-redux';
import CustomStepper from 'components/Stepper/CustomStepper';
import ReadingQuestion from './UpdateMainAnswers/ReadingAnswer';
import ListeningAnswer from './UpdateMainAnswers/ListeningAnswer';
import OtherQuestion from './UpdateMainAnswers/OtherAnwer';
import Countdown from 'react-countdown';
import { RootState } from 'features';
import { tokenSelector } from 'features/auth/authSlice';
import { useReqListener, useViewAuth } from 'hooks';
import Card from 'components/Card/Card';
import {
  getTakenQuizAsync,
  getTakenHomeworkAsync,
  quizTakenSelector,
  remainingTimeSelector,
  setQuestionIndex,
  setSubQuestionIndex,
  setIsLastQuestion,
  setTriggerSaveQuizAnswers,
  triggerSaveQuizAnswersSelector,
} from './updateSlice';
import QuestionNavigator from './QuestionsNavigator';
import AnswerRenderer from './AnswerRenderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import Notification from 'features/notifications/Notification';
import VocabAnswer from './UpdateMainAnswers/VocabAnswer';
import CustomCircularProgress from 'components/Progress/CustomCircularProgress';

const useStyles = makeStyles(styles);
const AnswerQuiz: React.FC = () => {
  const [sentMsg, setSentMsg] = React.useState(false);
  const dispatch = useDispatch();

  // const code =
  //   'function sleep (time) {return new Promise((resolve) => setTimeout(resolve, time)); };addEventListener("message", async (event) => {await sleep(10000);postMessage({foo:"foo"})})';
  // const blobCode = new Blob([code], { type: 'application/javascript' });
  // const codeURL = URL.createObjectURL(blobCode);
  // const worker = new Worker(codeURL);
  const triggerSave = () => {
    dispatch(setTriggerSaveQuizAnswers({ save: true }));
  };

  // worker.addEventListener('message', (_: any) => {
  //   triggerSave();
  //   worker.postMessage({ a: 1 });
  // });
  const { quizTakenId, type } = useParams<Record<string, string | undefined>>();
  const quiz = useSelector(quizTakenSelector);
  const remainingTime = useSelector(remainingTimeSelector);
  const activeQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.questionIndex);
  const [countDownCompleted, setCountDownCompleted] = React.useState(false);
  // const updateAnswerReq = useSelector((state: RootState) => state.updateQuiz.updateAnswersReq);
  const getQuizTakenReq = useSelector((state: RootState) => state.updateQuiz.getQuizTakenReq);
  const getHomeworkTakenReq = useSelector((state: RootState) => state.updateQuiz.getHomeworkTakenReq);
  const token = useSelector(tokenSelector);
  useViewAuth();
  const questionsHeaders = quiz.answers.map((answer, index) => {
    return <span id={`HQ${index + 1}`}>{`Question ${index + 1}: ${answer.question.title}`}</span>;
  });

  // useReqListener({
  //   status: updateAnswerReq.status,
  //   successMessage: 'Your answers has been saved successfully',
  //   loadingMessage: 'Saving your answers..',
  //   errorMessage: updateAnswerReq.error,
  // });

  useReqListener({
    status: getQuizTakenReq.status,
    successMessage: 'Successfully got the quiz',
    loadingMessage: 'Getting the quiz content',
    errorMessage: getQuizTakenReq.error,
  });
  useEffect(() => {
    // if (!sentMsg) {
    //   setSentMsg(true);
    //   worker.postMessage({ a: 1 });
    // }
    const isLastQuestion = activeQuestionIndex === quiz.answers.length - 1;
    console.log('Active Q Index', activeQuestionIndex, quiz.answers.length);
    dispatch(setIsLastQuestion({ isLastQuestion }));
  }, [activeQuestionIndex, quiz.answers.length]);

  useEffect(() => {
    token &&
      getQuizTakenReq.status === 'idle' &&
      quizTakenId &&
      type === 'quiz' &&
      dispatch(getTakenQuizAsync({ token, quizTakenId }));
    token &&
      getHomeworkTakenReq.status === 'idle' &&
      quizTakenId &&
      type === 'homework' &&
      dispatch(getTakenHomeworkAsync({ token, quizTakenId }));
  }, [getQuizTakenReq.status, getHomeworkTakenReq.status, token, quizTakenId, type, dispatch]);

  const handleNext = () => {
    triggerSave();
    dispatch(setQuestionIndex({ index: activeQuestionIndex + 1 }));
    dispatch(setSubQuestionIndex({ index: 0 }));
  };

  const handleBack = () => {
    triggerSave();
    activeQuestionIndex > 0 && dispatch(setQuestionIndex({ index: activeQuestionIndex - 1 }));
    dispatch(setSubQuestionIndex({ index: 0 }));
  };

  const handleQuestionIndex = (index: number) => {
    dispatch(setQuestionIndex({ index }));
    dispatch(setSubQuestionIndex({ index: 0 }));
  };

  const getStepContent = (question: Types.API.Question.IQuestionModel) => {
    const questionType = question.sectionType;
    switch (questionType) {
      case 'Reading':
        return <ReadingQuestion key={`question-${activeQuestionIndex}`} />;
      case 'Listening':
        return <ListeningAnswer key={`question-${activeQuestionIndex}`} />;
      case 'Other':
        return <OtherQuestion key={`question-${activeQuestionIndex}`} />;
      case 'Writing':
      case 'Speaking':
        return <AnswerRenderer key={`question-${activeQuestionIndex}`} />;
      case 'Vocab':
        return <VocabAnswer key={`question-${activeQuestionIndex}`} />;
      default:
        return <div></div>;
    }
  };
  const countdownRenderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      return <h3>Time's up!</h3>;
    } else {
      return (
        <h3 style={{ fontWeight: 'bold' }}>
          {hours}:{minutes}:{seconds}
        </h3>
      );
    }
  };
  const onCountDownComplete = () => {
    setCountDownCompleted(true);
  };

  const classes = useStyles();

  if (!quiz.name)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20%',
        }}
      >
        <CustomCircularProgress size={60} />
      </div>
    );

  if (quiz.status === 'ended' && !quiz.gradePublished)
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>You've solved this quiz before .. Grades will be published soon </h2>
      </div>
    );
  else
    return (
      <div className={classes.quizContainer}>
        {countDownCompleted && !quiz.isHomework && <h2>Quiz time is up, close the page</h2>}
        {(!countDownCompleted || quiz.isHomework) && (
          <>
            <div className={classes.flexRow}>
              <IconButton
                disabled={activeQuestionIndex <= 0}
                className={classes.previousSectionButton}
                onClick={handleBack}
              >
                <FontAwesomeIcon size="3x" icon="arrow-alt-circle-left" />
              </IconButton>
              <Card className={classes.container} style={{ marginTop: '1rem' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    fill: 'black',
                    color: 'black',
                  }}
                >
                  <h3 style={{ fontWeight: 'bold' }}>{quiz.name}</h3>
                  {quiz.name && !quiz.isHomework && (
                    <Countdown
                      date={Date.now() + remainingTime}
                      renderer={countdownRenderer}
                      onComplete={onCountDownComplete}
                    />
                  )}
                </div>
                <CustomStepper
                  nonLinear={true}
                  handleQuestionIndex={handleQuestionIndex}
                  steps={questionsHeaders}
                  activeStep={activeQuestionIndex}
                />
              </Card>
              <IconButton
                className={classes.nextSectionButton}
                disabled={activeQuestionIndex + 1 >= quiz.answers.length}
                onClick={handleNext}
              >
                <FontAwesomeIcon size="3x" icon="arrow-alt-circle-right" />
              </IconButton>
            </div>

            {quiz.answers.length > 0 &&
              quiz.answers[activeQuestionIndex].question &&
              getStepContent(quiz.answers[activeQuestionIndex].question)}
            <QuestionNavigator questionsLength={questionsHeaders.length} />
            <Notification />
          </>
        )}
      </div>
    );
};

export default withRouter(AnswerQuiz);

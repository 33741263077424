import {
  updateAnswers,
  getQuizTaken,
  updateCorrection,
  getQuizTakenAdmin,
  getHomeworkTaken,
  commentQuizTaken,
  submitHomeworkAnswers,
  uploadAnswerMultipleFiles,
  uploadAnswerMultipleAudio,
  deleteFile,
  downloadFile,
} from './client';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { nullUser } from 'features/auth/types';

interface QuizTakenUpdateState {
  quizTaken: Types.API.QuizTaken.IQuizTakenInfoWithAnswers;
  remainingTime: number;
  getQuizTakenReq: Types.IRequestState;
  getHomeworkTakenReq: Types.IRequestState;
  isLastQuestion: boolean;
  getQuizTakenAdminReq: Types.IRequestState;
  updateStudentCommentsReq: Types.IRequestState;
  updateAnswersReq: Types.IRequestState;
  submitHomeworkReq: Types.IRequestState;
  deleteFileReq: Types.IRequestState;
  lastUpdateTime: number;
  adminAudioUploadsReq: { [answerId: string]: Types.IRequestState };
  adminFilesUploadsReq: { [answerId: string]: Types.IRequestState };
  studentAudioUploadsReq: { [answerId: string]: Types.IRequestState };
  studentFilesUploadsReq: { [answerId: string]: Types.IRequestState };
  downloadStudentFileReq: Types.IRequestState;
  downloadAdminFileReq: Types.IRequestState;
  updateCorrectionReq: Types.IRequestState;
  activeWhyData: { pIndex: number; start: string; end: string };
  isWhyActive: boolean;
  quizState: {
    questionIndex: number;
    subQuestionIndex: number;
    subQuestionType: Types.API.Question.QuestionModelType;
    task: 'Answering' | 'Correcting' | 'Overview';
  };
  availableStudentFilesToDownload: Types.API.File.IFileModel[];
  availableAdminFilesToDownload: Types.API.File.IFileModel[];
  triggerSaveQuizAnswers: boolean;
  triggerSaveQuizCorrection: boolean;
}

export const getTakenQuizAsync = createAsyncThunk('students/getTakenQuiz', getQuizTaken);
export const getTakenHomeworkAsync = createAsyncThunk('students/getHomeworkTaken', getHomeworkTaken);
export const getQuizTakenAdminAsync = createAsyncThunk('admin/getTakenQuiz', getQuizTakenAdmin);
export const updateAnswersAsync = createAsyncThunk('students/answerQuiz', updateAnswers);
export const submitHomeworkAsync = createAsyncThunk('students/submitHomework', submitHomeworkAnswers);
export const updateCorrectionAsync = createAsyncThunk('admin/correctQuiz', updateCorrection);
export const updateStudentCommentsAsync = createAsyncThunk('admin/commentQuizTaken', commentQuizTaken);
export const uploadAdminAudiosAsync = createAsyncThunk('admin/uploadAdminAudios', uploadAnswerMultipleAudio);
export const uploadAdminFilesAsync = createAsyncThunk('admin/uploadAdminFiles', uploadAnswerMultipleFiles);
export const uploadStudentAudiosAsync = createAsyncThunk('student/uploadStudentAudios', uploadAnswerMultipleAudio);
export const uploadStudentFilesAsync = createAsyncThunk('student/uploadStudentFiles', uploadAnswerMultipleFiles);
export const deleteFileAsync = createAsyncThunk('student/admin/deleteFile', deleteFile);
export const downloadStudentFileAsync = createAsyncThunk('student/files', downloadFile);
export const downloadAdminFileAsync = createAsyncThunk('admin/files', downloadFile);

const initialState: QuizTakenUpdateState = {
  quizTaken: {
    id: '',
    student: { ...nullUser, role: 'student', id: '' },
    answers: [],
    quizModelId: '',
    gradePublished: false,
    instructorGraded: false,
    name: '',
    studentGroup: { id: '', name: '' },
    status: 'started',
    isHomework: false,
    isLate: false,
    dueDate: new Date(Date.now()),
    assignedDate: new Date(Date.now()),
  },
  isLastQuestion: false,
  isWhyActive: false,
  activeWhyData: { pIndex: -1, start: '-1', end: '-1' },
  updateStudentCommentsReq: { status: 'idle', error: null },
  getQuizTakenReq: { status: 'idle', error: null },
  getHomeworkTakenReq: { status: 'idle', error: null },
  getQuizTakenAdminReq: { status: 'idle', error: null },
  updateAnswersReq: { status: 'idle', error: null },
  submitHomeworkReq: { status: 'idle', error: null },
  updateCorrectionReq: { status: 'idle', error: null },
  deleteFileReq: { status: 'idle', error: null },
  downloadStudentFileReq: { status: 'idle', error: null },
  downloadAdminFileReq: { status: 'idle', error: null },
  remainingTime: -1000,
  lastUpdateTime: Date.now(),
  quizState: {
    questionIndex: 0,
    subQuestionIndex: 0,
    subQuestionType: 'MCQ',
    task: 'Overview',
  },
  adminAudioUploadsReq: {},
  studentAudioUploadsReq: {},
  adminFilesUploadsReq: {},
  studentFilesUploadsReq: {},
  availableStudentFilesToDownload: [],
  availableAdminFilesToDownload: [],
  triggerSaveQuizAnswers: false,
  triggerSaveQuizCorrection: false,
};

// Async updates to the store

export const slice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    setQuestionIndex: (state, action: PayloadAction<{ index: number }>) => {
      state.quizState.questionIndex = action.payload.index;
    },
    setIsLastQuestion: (state, action: PayloadAction<{ isLastQuestion: boolean }>) => {
      state.isLastQuestion = action.payload.isLastQuestion;
    },
    setSubQuestionIndex: (state, action: PayloadAction<{ index: number }>) => {
      state.quizState.subQuestionIndex = action.payload.index;
    },
    setSubQuestionType: (state, action: PayloadAction<{ type: Types.API.Question.QuestionModelType }>) => {
      state.quizState.subQuestionType = action.payload.type;
    },
    resetAnswerQuizState: state => {
      state = initialState;
    },
    setTriggerSaveQuizAnswers: (state, action: PayloadAction<{ save: boolean }>) => {
      state.triggerSaveQuizAnswers = action.payload.save;
    },
    setTriggerSaveQuizCorrection: (state, action: PayloadAction<{ save: boolean }>) => {
      state.triggerSaveQuizCorrection = action.payload.save;
    },
    setActiveWhyData: (state, action: PayloadAction<{ pIndex: number; start: string; end: string }>) => {
      if (action.payload.pIndex === -1 || action.payload.start === '-1' || action.payload.end === '-1') {
        return;
      }
      state.isWhyActive = true;
      state.activeWhyData = { start: action.payload.start, end: action.payload.end, pIndex: action.payload.pIndex };
    },
    resetWhyActiveData: state => {
      state.isWhyActive = false;
      state.activeWhyData = { pIndex: -1, start: '-1', end: '-1' };
    },
    setWritingText: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
        text: string;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber, text } = action.payload;
      (
        state.quizTaken.answers[questionNumber].answers[subQuestionNumber] as Types.API.Answer.IAnswerWritingModel
      ).text = text;
    },
    setWritingContentGrade: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
        mark: number;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber, mark } = action.payload;
      (
        state.quizTaken.answers[questionNumber].answers[subQuestionNumber] as Types.API.Answer.IAnswerWritingModel
      ).contentMark = mark;
    },
    setWritingLanguageGrade: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
        mark: number;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber, mark } = action.payload;
      (
        state.quizTaken.answers[questionNumber].answers[subQuestionNumber] as Types.API.Answer.IAnswerWritingModel
      ).languageMark = mark;
    },
    updateStudentComment: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
        index: number;
        studentComment: string;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber, index, studentComment } = action.payload;
      (state.quizTaken.answers[questionNumber].answers[subQuestionNumber].comments as string[])[index] = studentComment;
    },
    updateStudentNoteTakingComment: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
        index: number;
        subIndex: number;
        studentComment: string;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber, index, subIndex, studentComment } = action.payload;
      (state.quizTaken.answers[questionNumber].answers[subQuestionNumber].comments as string[][])[index][subIndex] =
        studentComment;
    },
    updateAnswerForMCQQuestion: (
      state,
      action: PayloadAction<{ questionNumber: number; subQuestionNumber: number; answer: string }>,
    ) => {
      const { questionNumber, subQuestionNumber, answer } = action.payload;
      (state.quizTaken.answers[questionNumber].answers[subQuestionNumber] as Types.API.Answer.IAnswerMCQModel).answer =
        [answer];
    },
    updateAnswerForSMQuestion: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
        index: number;
        answer: Types.API.Question.ISpeaker;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber, answer, index } = action.payload;
      (
        state.quizTaken.answers[questionNumber].answers[
          subQuestionNumber
        ] as Types.API.Answer.IAnswerSpeakerMatchingModel
      ).answer[index] = answer;
    },
    updateCorrectionForMCQQuestion: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
        optionIndex: number;
        mark?: number;
        instructorComment?: string;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber, optionIndex, mark, instructorComment } = action.payload;
      if (typeof mark === 'number' && mark > -1)
        (state.quizTaken.answers[questionNumber].answers[subQuestionNumber] as Types.API.Answer.IAnswerMCQModel).marks[
          optionIndex
        ] = mark;
      if (instructorComment)
        state.quizTaken.answers[questionNumber].answers[subQuestionNumber].instructorComments[optionIndex] =
          instructorComment;
    },
    updateCorrectionForSMQuestion: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
        optionIndex: number;
        mark?: number;
        instructorComment?: string;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber, optionIndex, mark, instructorComment } = action.payload;
      if (mark)
        (
          state.quizTaken.answers[questionNumber].answers[
            subQuestionNumber
          ] as Types.API.Answer.IAnswerSpeakerMatchingModel
        ).marks[optionIndex] = mark;
      if (instructorComment)
        state.quizTaken.answers[questionNumber].answers[subQuestionNumber].instructorComments[optionIndex] =
          instructorComment;
    },
    updateAnswerForShortAnswersQuestion: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
        shortAnswerNumber: number;
        answer: string;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber, shortAnswerNumber, answer } = action.payload;
      (
        state.quizTaken.answers[questionNumber].answers[subQuestionNumber] as Types.API.Answer.IAnswerShortAnswersModel
      ).answer[shortAnswerNumber] = answer;
    },
    updateCorrectionForShortAnswersQuestion: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
        shortAnswerNumber: number;
        mark?: number;
        instructorComment?: string;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber, shortAnswerNumber, mark, instructorComment } = action.payload;
      console.log('mark' + mark);
      if (mark !== undefined)
        (
          state.quizTaken.answers[questionNumber].answers[
            subQuestionNumber
          ] as Types.API.Answer.IAnswerShortAnswersModel
        ).marks[shortAnswerNumber] = mark;
      if (instructorComment)
        (state.quizTaken.answers[questionNumber].answers[subQuestionNumber].instructorComments as string[])[
          shortAnswerNumber
        ] = instructorComment;
    },
    updateAnswerForFillInGapsQuestion: (
      state,
      action: PayloadAction<{ questionNumber: number; subQuestionNumber: number; gapNumber: number; answer: string }>,
    ) => {
      const { questionNumber, subQuestionNumber, gapNumber, answer } = action.payload;
      (
        state.quizTaken.answers[questionNumber].answers[subQuestionNumber] as Types.API.Answer.IAnswerFillInBlanksModel
      ).answer[gapNumber] = answer;
    },
    updateAnswerForVocabQuestion: (
      state,
      action: PayloadAction<{ questionNumber: number; subQuestionNumber: number; gapNumber: number; answer: string }>,
    ) => {
      const { questionNumber, subQuestionNumber, gapNumber, answer } = action.payload;
      (state.quizTaken.answers[questionNumber].answers[subQuestionNumber] as Types.API.Answer.IAnswerVocabModel).answer[
        gapNumber
      ] = answer;
    },
    addInsructorCommentForSpeakingQuestion: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber } = action.payload;
      (
        state.quizTaken.answers[questionNumber].answers[subQuestionNumber] as Types.API.Answer.IAnswerSpeakingModel
      ).instructorComments.push('');
    },
    removeLastInsructorCommentForSpeakingQuestion: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber } = action.payload;
      (
        state.quizTaken.answers[questionNumber].answers[subQuestionNumber] as Types.API.Answer.IAnswerSpeakingModel
      ).instructorComments.pop();
    },
    updateInsructorCommentsForSpeakingQuestion: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
        commentIndex: number;
        comment: string;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber, comment, commentIndex } = action.payload;
      (
        state.quizTaken.answers[questionNumber].answers[subQuestionNumber] as Types.API.Answer.IAnswerSpeakingModel
      ).instructorComments[commentIndex] = comment;
    },
    updateCorrectionForFillInGapsQuestion: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
        gapNumber: number;
        mark?: number;
        instructorComment?: string;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber, gapNumber, mark, instructorComment } = action.payload;
      if (mark)
        (
          state.quizTaken.answers[questionNumber].answers[
            subQuestionNumber
          ] as Types.API.Answer.IAnswerFillInBlanksModel
        ).marks[gapNumber] = mark;
      if (instructorComment)
        (
          state.quizTaken.answers[questionNumber].answers[
            subQuestionNumber
          ] as Types.API.Answer.IAnswerFillInBlanksModel
        ).instructorComments[gapNumber] = instructorComment;
    },
    updateCorrectionForVocabQuestion: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
        gapNumber: number;
        mark?: number;
        instructorComment?: string;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber, gapNumber, mark, instructorComment } = action.payload;
      if (mark)
        (
          state.quizTaken.answers[questionNumber].answers[subQuestionNumber] as Types.API.Answer.IAnswerVocabModel
        ).marks[gapNumber] = mark;
      if (instructorComment)
        (
          state.quizTaken.answers[questionNumber].answers[subQuestionNumber] as Types.API.Answer.IAnswerVocabModel
        ).instructorComments[gapNumber] = instructorComment;
    },
    updateAnswerForNoteTakingQuestion: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
        noteIndex: number;
        noteAnswerIndex: number;
        answer: string;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber, noteIndex, noteAnswerIndex, answer } = action.payload;
      (
        state.quizTaken.answers[questionNumber].answers[subQuestionNumber] as Types.API.Answer.IAnswerNoteTakingModel
      ).answer[noteIndex][noteAnswerIndex] = answer;
    },
    updateCorrectionForNoteTakingQuestion: (
      state,
      action: PayloadAction<{
        questionNumber: number;
        subQuestionNumber: number;
        noteIndex: number;
        noteAnswerIndex: number;
        mark?: number;
        instructorComment?: string;
      }>,
    ) => {
      const { questionNumber, subQuestionNumber, noteIndex, noteAnswerIndex, mark, instructorComment } = action.payload;
      if (mark)
        (
          state.quizTaken.answers[questionNumber].answers[subQuestionNumber] as Types.API.Answer.IAnswerNoteTakingModel
        ).marks[noteIndex][noteAnswerIndex] = mark;
      if (instructorComment)
        (state.quizTaken.answers[questionNumber].answers[subQuestionNumber].instructorComments as string[][])[
          noteIndex
        ][noteAnswerIndex] = instructorComment;
    },
  },
  extraReducers: builder => {
    // Async updates to the store listeners
    builder.addCase(getTakenQuizAsync.pending, (state, action) => {
      state.getQuizTakenReq.status = 'loading';
    });
    builder.addCase(getTakenQuizAsync.fulfilled, (state, action) => {
      state.getQuizTakenReq.status = 'succeeded';
      state.quizTaken = action.payload.quiz;
      state.remainingTime = action.payload.remainingTime;
      action.payload.quiz.answers.forEach(a => {
        a.answers.forEach(aa => {
          state.studentAudioUploadsReq[aa._id] = { status: 'idle', error: null };
          state.studentFilesUploadsReq[aa._id] = { status: 'idle', error: null };
          state.adminAudioUploadsReq[aa._id] = { status: 'idle', error: null };
          state.adminFilesUploadsReq[aa._id] = { status: 'idle', error: null };
        });
        // a.question.questions.forEach((aa, i) => {
        //   if (aa.modelType === 'SpeakerMatching') {
        //     (a.answers[i] as Types.API.Answer.IAnswerSpeakerMatchingModel).answer.forEach(a => (a.name = ''));
        //   }
        // });
      });
    });
    builder.addCase(getTakenQuizAsync.rejected, (state, action) => {
      state.getQuizTakenReq.status = 'failed';
      state.getQuizTakenReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(getTakenHomeworkAsync.pending, (state, action) => {
      state.getHomeworkTakenReq.status = 'loading';
    });
    builder.addCase(getTakenHomeworkAsync.fulfilled, (state, action) => {
      state.getHomeworkTakenReq.status = 'succeeded';
      state.quizTaken = action.payload.homework;
      state.remainingTime = action.payload.remainingTime;
      action.payload.homework.answers.forEach(a => {
        a.answers.forEach(aa => {
          state.studentAudioUploadsReq[aa._id] = { status: 'idle', error: null };
          state.studentFilesUploadsReq[aa._id] = { status: 'idle', error: null };
          state.adminAudioUploadsReq[aa._id] = { status: 'idle', error: null };
          state.adminFilesUploadsReq[aa._id] = { status: 'idle', error: null };
        });
        a.question.questions.forEach((aa, i) => {
          if (aa.modelType === 'SpeakerMatching') {
            (a.answers[i] as Types.API.Answer.IAnswerSpeakerMatchingModel).answer.forEach(a => {
              if (a.opinion === ' ') a.name = ' ';
            });
          }
        });
      });
    });
    builder.addCase(getTakenHomeworkAsync.rejected, (state, action) => {
      state.getHomeworkTakenReq.status = 'failed';
      state.getHomeworkTakenReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(getQuizTakenAdminAsync.pending, (state, action) => {
      state.getQuizTakenAdminReq.status = 'loading';
    });
    builder.addCase(getQuizTakenAdminAsync.fulfilled, (state, action) => {
      state.getQuizTakenAdminReq.status = 'succeeded';
      state.quizTaken = action.payload;
      action.payload.answers.forEach(a =>
        a.answers.forEach(aa => {
          state.studentAudioUploadsReq[aa._id] = { status: 'idle', error: null };
          state.studentFilesUploadsReq[aa._id] = { status: 'idle', error: null };
          state.adminAudioUploadsReq[aa._id] = { status: 'idle', error: null };
          state.adminFilesUploadsReq[aa._id] = { status: 'idle', error: null };
        }),
      );
    });
    builder.addCase(getQuizTakenAdminAsync.rejected, (state, action) => {
      state.getQuizTakenAdminReq.status = 'failed';
      state.getQuizTakenAdminReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(updateAnswersAsync.pending, (state, action) => {
      state.updateAnswersReq.status = 'loading';
    });
    builder.addCase(updateAnswersAsync.fulfilled, (state, action) => {
      state.updateAnswersReq.status = 'succeeded';
      state.lastUpdateTime = Date.now();
    });
    builder.addCase(updateAnswersAsync.rejected, (state, action) => {
      state.updateAnswersReq.status = 'failed';
      state.updateAnswersReq.error = action.error.message || 'Unknown Error';
    });

    builder.addCase(submitHomeworkAsync.pending, (state, action) => {
      state.submitHomeworkReq.status = 'loading';
    });
    builder.addCase(submitHomeworkAsync.fulfilled, (state, action) => {
      state.submitHomeworkReq.status = 'succeeded';
    });
    builder.addCase(submitHomeworkAsync.rejected, (state, action) => {
      state.submitHomeworkReq.status = 'failed';
      state.submitHomeworkReq.error = action.error.message || 'Unknown Error';
    });

    builder.addCase(updateCorrectionAsync.pending, (state, action) => {
      state.updateCorrectionReq.status = 'loading';
    });
    builder.addCase(updateCorrectionAsync.fulfilled, (state, action) => {
      state.updateCorrectionReq.status = 'succeeded';
      state.quizTaken.instructorGraded = true;
      state.lastUpdateTime = Date.now();
    });
    builder.addCase(updateCorrectionAsync.rejected, (state, action) => {
      state.updateCorrectionReq.status = 'failed';
      state.updateCorrectionReq.error = action.error.message || 'Unknown Error';
    });
    builder.addCase(updateStudentCommentsAsync.pending, (state, action) => {
      state.updateStudentCommentsReq.status = 'loading';
    });
    builder.addCase(updateStudentCommentsAsync.fulfilled, (state, action) => {
      state.updateStudentCommentsReq.status = 'succeeded';
      state.lastUpdateTime = Date.now();
    });
    builder.addCase(updateStudentCommentsAsync.rejected, (state, action) => {
      state.updateStudentCommentsReq.status = 'failed';
      state.updateStudentCommentsReq.error = action.error.message || 'Unknown Error';
    });
    builder.addCase(uploadStudentAudiosAsync.pending, (state, action) => {
      state.studentAudioUploadsReq[action.meta.arg.answerId].status = 'loading';
    });
    builder.addCase(uploadStudentAudiosAsync.fulfilled, (state, action) => {
      state.studentAudioUploadsReq[action.meta.arg.answerId].status = 'succeeded';
      action.payload.forEach(f =>
        state.quizTaken.answers[action.meta.arg.questionIndex].answers[action.meta.arg.subQuestionIndex].files.push(
          (f as any).file ? (f as any).file : f,
        ),
      );
    });
    builder.addCase(uploadStudentAudiosAsync.rejected, (state, action) => {
      state.studentAudioUploadsReq[action.meta.arg.answerId].status = 'failed';
      state.studentAudioUploadsReq[action.meta.arg.answerId].error = action.error.message || 'Unknown error';
    });

    builder.addCase(uploadStudentFilesAsync.pending, (state, action) => {
      state.studentFilesUploadsReq[action.meta.arg.answerId].status = 'loading';
    });
    builder.addCase(uploadStudentFilesAsync.fulfilled, (state, action) => {
      state.studentFilesUploadsReq[action.meta.arg.answerId].status = 'succeeded';
      action.payload.forEach(f =>
        state.quizTaken.answers[action.meta.arg.questionIndex].answers[action.meta.arg.subQuestionIndex].files.push(
          (f as any).file ? (f as any).file : f,
        ),
      );
    });
    builder.addCase(uploadStudentFilesAsync.rejected, (state, action) => {
      state.studentFilesUploadsReq[action.meta.arg.answerId].status = 'failed';
      state.studentFilesUploadsReq[action.meta.arg.answerId].error = action.error.message || 'Unknown error';
    });

    builder.addCase(uploadAdminAudiosAsync.pending, (state, action) => {
      state.adminAudioUploadsReq[action.meta.arg.answerId].status = 'loading';
    });
    builder.addCase(uploadAdminAudiosAsync.fulfilled, (state, action) => {
      state.adminAudioUploadsReq[action.meta.arg.answerId].status = 'succeeded';
      action.payload.forEach(f =>
        state.quizTaken.answers[action.meta.arg.questionIndex].answers[
          action.meta.arg.subQuestionIndex
        ].instructorFiles.push((f as any).file ? (f as any).file : f),
      );
    });
    builder.addCase(uploadAdminAudiosAsync.rejected, (state, action) => {
      state.adminAudioUploadsReq[action.meta.arg.answerId].status = 'failed';
      state.adminAudioUploadsReq[action.meta.arg.answerId].error = action.error.message || 'Unknown error';
    });

    builder.addCase(uploadAdminFilesAsync.pending, (state, action) => {
      state.adminFilesUploadsReq[action.meta.arg.answerId].status = 'loading';
    });
    builder.addCase(uploadAdminFilesAsync.fulfilled, (state, action) => {
      state.adminFilesUploadsReq[action.meta.arg.answerId].status = 'succeeded';
      action.payload.forEach(f =>
        state.quizTaken.answers[action.meta.arg.questionIndex].answers[
          action.meta.arg.subQuestionIndex
        ].instructorFiles.push((f as any).file ? (f as any).file : f),
      );
    });
    builder.addCase(uploadAdminFilesAsync.rejected, (state, action) => {
      state.adminFilesUploadsReq[action.meta.arg.answerId].status = 'failed';
      state.adminFilesUploadsReq[action.meta.arg.answerId].error = action.error.message || 'Unknown error';
    });

    builder.addCase(downloadStudentFileAsync.pending, (state, action) => {
      state.downloadStudentFileReq.status = 'loading';
    });
    builder.addCase(downloadStudentFileAsync.fulfilled, (state, action) => {
      state.downloadStudentFileReq.status = 'succeeded';
      state.availableStudentFilesToDownload.push((action.payload as any).file);
    });
    builder.addCase(downloadStudentFileAsync.rejected, (state, action) => {
      state.downloadStudentFileReq.status = 'failed';
      state.downloadStudentFileReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(downloadAdminFileAsync.pending, (state, action) => {
      state.downloadAdminFileReq.status = 'loading';
    });
    builder.addCase(downloadAdminFileAsync.fulfilled, (state, action) => {
      state.downloadAdminFileReq.status = 'succeeded';
      if (action.payload && (action.payload as any).file)
        state.availableAdminFilesToDownload.push((action.payload as any).file);
    });
    builder.addCase(downloadAdminFileAsync.rejected, (state, action) => {
      state.downloadAdminFileReq.status = 'failed';
      state.downloadAdminFileReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(deleteFileAsync.pending, (state, action) => {
      state.deleteFileReq.status = 'loading';
    });
    builder.addCase(deleteFileAsync.fulfilled, (state, action) => {
      if (action.payload) {
        const filteredFiles = action.meta.arg.isAdmin
          ? state.quizTaken.answers[action.meta.arg.questionIndex].answers[
              action.meta.arg.subQuestionIndex
            ].instructorFiles.filter(f => f && f._id !== action.meta.arg.fileId)
          : state.quizTaken.answers[action.meta.arg.questionIndex].answers[
              action.meta.arg.subQuestionIndex
            ].files.filter(f => f && f._id !== action.meta.arg.fileId);
        if (action.meta.arg.isAdmin) {
          state.quizTaken.answers[action.meta.arg.questionIndex].answers[
            action.meta.arg.subQuestionIndex
          ].instructorFiles = filteredFiles;
        } else {
          state.quizTaken.answers[action.meta.arg.questionIndex].answers[action.meta.arg.subQuestionIndex].files =
            filteredFiles;
        }
        if (action.meta.arg.isAdmin) {
          const filteredFiles = state.availableAdminFilesToDownload.filter(
            file => file && file._id !== action.meta.arg.fileId,
          );
          state.availableAdminFilesToDownload = filteredFiles;
        } else {
          const filteredFiles = state.availableStudentFilesToDownload.filter(
            file => file && file._id !== action.meta.arg.fileId,
          );
          state.availableStudentFilesToDownload = filteredFiles;
        }
      }
      state.deleteFileReq.status = 'succeeded';
    });
    builder.addCase(deleteFileAsync.rejected, (state, action) => {
      state.deleteFileReq.status = 'failed';
    });
  },
});

export const {
  setIsLastQuestion,
  setQuestionIndex,
  setSubQuestionIndex,
  setSubQuestionType,
  updateAnswerForMCQQuestion,
  updateAnswerForSMQuestion,
  updateAnswerForFillInGapsQuestion,
  updateAnswerForVocabQuestion,
  updateAnswerForNoteTakingQuestion,
  updateAnswerForShortAnswersQuestion,
  updateCorrectionForMCQQuestion,
  updateCorrectionForSMQuestion,
  updateInsructorCommentsForSpeakingQuestion,
  addInsructorCommentForSpeakingQuestion,
  removeLastInsructorCommentForSpeakingQuestion,
  updateCorrectionForFillInGapsQuestion,
  updateCorrectionForVocabQuestion,
  updateCorrectionForNoteTakingQuestion,
  updateCorrectionForShortAnswersQuestion,
  updateStudentComment,
  updateStudentNoteTakingComment,
  resetAnswerQuizState,
  setActiveWhyData,
  resetWhyActiveData,
  setWritingText,
  setWritingContentGrade,
  setWritingLanguageGrade,
  setTriggerSaveQuizAnswers,
  setTriggerSaveQuizCorrection,
} = slice.actions;

export const quizTakenSelector = (state: RootState) => state.updateQuiz.quizTaken;
export const quizStateSelector = (state: RootState) => state.updateQuiz.quizState;
export const lastQuestionSelector = (state: RootState) => state.updateQuiz.isLastQuestion;
export const nameSelector = (state: RootState) => state.updateQuiz.quizTaken.name;
export const questionWithIndexSelector = (index: number) => (state: RootState) =>
  state.updateQuiz.quizTaken.answers[index].question;
export const subQuestionWithIndexSelector = (questionIndex: number, subQuestionIndex: number) => (state: RootState) =>
  state.updateQuiz.quizTaken.answers[questionIndex].question.questions[subQuestionIndex];
export const subAnswerWithIndexSelector = (answerIndex: number, subAnswerIndex: number) => (state: RootState) =>
  state.updateQuiz.quizTaken.answers[answerIndex].answers[subAnswerIndex];
export const isQuizCorrectionPublished = (state: RootState) => state.updateQuiz.quizTaken.gradePublished;
export const genericSubAnswerSelector = (questionIndex: number, subQuestionIndex: number) => (state: RootState) =>
  state.updateQuiz.quizTaken.answers[questionIndex].answers[subQuestionIndex] as Types.API.Answer.IGenericQuestion;
export const fillInGapsSubAnswerSelector = (questionIndex: number, subQuestionIndex: number) => (state: RootState) =>
  state.updateQuiz.quizTaken.answers[questionIndex].answers[
    subQuestionIndex
  ] as Types.API.Answer.IAnswerFillInBlanksModel;
export const vocabSubAnswerSelector = (questionIndex: number, subQuestionIndex: number) => (state: RootState) =>
  state.updateQuiz.quizTaken.answers[questionIndex].answers[subQuestionIndex] as Types.API.Answer.IAnswerVocabModel;
export const mcqSubAnswerSelector = (questionIndex: number, subQuestionIndex: number) => (state: RootState) =>
  state.updateQuiz.quizTaken.answers[questionIndex].answers[subQuestionIndex] as Types.API.Answer.IAnswerMCQModel;
export const speakerMatchingSubAnswerSelector =
  (questionIndex: number, subQuestionIndex: number) => (state: RootState) =>
    state.updateQuiz.quizTaken.answers[questionIndex].answers[
      subQuestionIndex
    ] as Types.API.Answer.IAnswerSpeakerMatchingModel;
export const noteTakingSubAnswerSelector = (questionIndex: number, subQuestionIndex: number) => (state: RootState) =>
  state.updateQuiz.quizTaken.answers[questionIndex].answers[
    subQuestionIndex
  ] as Types.API.Answer.IAnswerNoteTakingModel;
export const shortAnswersSubAnswerSelector = (questionIndex: number, subQuestionIndex: number) => (state: RootState) =>
  state.updateQuiz.quizTaken.answers[questionIndex].answers[
    subQuestionIndex
  ] as Types.API.Answer.IAnswerShortAnswersModel;
export const noteTakingAnswerSelector = (questionIndex: number, subQuestionIndex: number) => (state: RootState) =>
  state.updateQuiz.quizTaken.answers[questionIndex].answers[
    subQuestionIndex
  ] as Types.API.Answer.IAnswerNoteTakingModel;
export const remainingTimeSelector = (state: RootState) => state.updateQuiz.remainingTime;
export const availableStudentFilesToDownloadSelector = (state: RootState) =>
  state.updateQuiz.availableStudentFilesToDownload;
export const availableAdminFilesToDownloadSelector = (state: RootState) =>
  state.updateQuiz.availableAdminFilesToDownload;
export const triggerSaveQuizAnswersSelector = (state: RootState) => state.updateQuiz.triggerSaveQuizAnswers;
export const triggerSaveQuizCorrectionSelector = (state: RootState) => state.updateQuiz.triggerSaveQuizCorrection;

export default slice.reducer;

import ContentSubmitDialog from 'components/Dialog/ContentSubmitDialog';
import { tokenSelector } from 'features/auth/authSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editAssistantAsync, editAssistantReqSelector } from '../slices/adminSlice';
import Button from 'components/CustomButtons/Button';
import AssistantInputs from './AssistantInputs';

interface Props {
  AssistantId: string;
  initialName: string;
  initialEmail: string;
  initialPassword: string;
  initialPhone: string;
}
const EditAssistant: React.FC<Props> = ({ AssistantId, initialEmail, initialName, initialPassword, initialPhone }) => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const [name, setName] = React.useState(initialName);
  const [email, setEmail] = React.useState(initialEmail);
  const [password, setPassword] = React.useState(initialPassword);
  const [phone, setPhone] = React.useState(initialPhone);
  const [open, setOpen] = React.useState(false);

  const createReq = useSelector(editAssistantReqSelector);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setName(initialName);
    setEmail(initialEmail);
    setPassword(initialPassword);
    setPhone(initialPhone);
    setOpen(true);
  };
  const handleAssistantEditSubmit = () => {
    dispatch(
      editAssistantAsync({
        token,
        assistant: { name, email, password, phone },
        id: AssistantId,
      }),
    );
  };
  return (
    <div>
      <Button onClick={handleOpen}>Edit Selected Assistant</Button>
      <ContentSubmitDialog
        open={open}
        dialogTitle="Edit Assistant"
        onClose={handleClose}
        onSubmitClick={handleAssistantEditSubmit}
        asyncSubmitReq={{
          status: createReq.status,
          errorMessage: createReq.error || 'Cannot edit the assistant',
          loadingMessage: 'Editing the assistant',
          successMessage: 'Successfully Edited the assistant',
        }}
      >
        <AssistantInputs
          name={name}
          onNameChange={e => setName(e.target.value)}
          password={password}
          onPasswordChange={e => setPassword(e.target.value)}
          email={email}
          onEmailChange={e => setEmail(e.target.value)}
          phone={phone}
          onPhoneChange={e => setPhone(e.target.value)}
        />
      </ContentSubmitDialog>
    </div>
  );
};

export default EditAssistant;

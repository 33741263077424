import { useViewAuth } from 'hooks';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import styles from 'assets/jss/lms/layouts/admin';
import { makeStyles } from '@material-ui/core';
import Notification from 'features/notifications/Notification';
import CustomTabs from 'components/CustomTabs/CustomTabs';
import Face from '@material-ui/icons/Face';
import Chat from '@material-ui/icons/Chat';
import Build from '@material-ui/icons/Build';

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PeopleIcon from '@material-ui/icons/People';
import Quiz from '@material-ui/icons/Help';
import Assignment from '@material-ui/icons/Assignment';
import Grade from '@material-ui/icons/PlaylistAddCheck';
import AssignmentCorrection from '@material-ui/icons/AssignmentTurnedIn';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';

import ClassIcon from '@material-ui/icons/Class';
import CategoryIcon from '@material-ui/icons/Category';

import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToApp from '@material-ui/icons/ExitToApp';
import CreateQuestion from 'features/questions/CreateQuestion';
import StudentsManagement from 'features/admin/views/StudentsManagment';
import CategoriesManagement from 'features/admin/views/CategoriesManagement';
import GradesManagement from 'features/admin/views/GradesManagement';
import LevelsManagement from 'features/admin/views/LevelsManagement';
import DiplomasManagement from 'features/admin/views/DiplomasManagement';
import AllQuestionsManagment from 'features/admin/views/AllQuestionsManagment';

import VimVideoLecturesManagement from 'features/admin/views/VimVideoLecturesManagement';
import VimVideoSpeakManagement from 'features/admin/views/VimVideoSpeakManagement';
import AssistantManagment from 'features/admin/views/AssistantManagment';
import GroupsManagement from 'features/admin/views/GroupsManagement';
import CorrectQuiz from 'features/quiz/CorrectQuiz';
import CorrectionManagement from 'features/admin/views/CorrectionManagement';
import ParentsManagement from 'features/admin/views/ParentsManagement';

import classNames from 'classnames';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
// import { logoutUser } from 'features/auth/authSlice';
import CardBody from 'components/Card/CardBody';
import { curTabIndexSelector, setCurrentTabIndex } from 'features/admin/slices/adminViewSlice';
import TreeViewManagement from 'features/admin/views/TreeViewManagement';
import AssignedTasksManagement from 'features/admin/views/AssignedTasksManagement';
import { logoutUser, userSelector } from 'features/auth/authSlice';
import NotificationManagement from 'features/admin/views/NotificationManagement';
import StudentTasksManagement from 'features/admin/views/StudentTasksManagement';
import VideoView from 'features/video/VideoView';
import CorrectTabManagement from 'features/admin/views/CorrectTabManagement';
import ViewAs from 'features/auth/ViewAs';
import { viewAsUsers } from 'features/auth/client';
import ResetPassword from 'features/auth/ResetPassword';

interface Props {}

export const AssistantRoutesPrefix = '/assistant';
const useStyles = makeStyles(styles);

const AssistantLayout: React.FC<Props> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const curTabIndex = useSelector(curTabIndexSelector);
  const handleTabIndexChange = (event: React.ChangeEvent<{}>, value: number) => {
    if (value === tabs.length - 1) dispatch(logoutUser());
    else dispatch(setCurrentTabIndex({ curTabIndex: value }));
  };
  React.useEffect(() => {
    const localStoragePageIndex = localStorage.getItem('pageIndex');
    if (localStoragePageIndex !== null) {
      dispatch(setCurrentTabIndex({ curTabIndex: parseInt(localStoragePageIndex) }));
      localStorage.removeItem('pageIndex');
    }
  }, []);
  const tabs = [
    // {
    //   tabName: 'Students',
    //   tabIcon: PeopleIcon,
    //   tabContent: <StudentsManagement />,
    // },
    // {
    //   tabName: 'Parents',
    //   tabIcon: SupervisorAccountIcon,
    //   tabContent: <ParentsManagement />,
    // },
    // {
    //   tabName: 'Assistants',
    //   tabIcon: PeopleAltIcon,
    //   tabContent: <AssistantManagment />,
    // },
    {
      tabName: 'All Questions',
      tabIcon: Quiz,
      tabContent: <AllQuestionsManagment />,
    },
    {
      tabName: 'Add Question',
      tabIcon: Quiz,
      tabContent: <CreateQuestion />,
    },
    {
      tabName: 'Assigned Tasks',
      tabIcon: Assignment,
      tabContent: <AssignedTasksManagement />,
    },
    {
      tabName: 'Correct Tasks',
      tabIcon: AssignmentCorrection,
      tabContent: <CorrectTabManagement />,
    },
    // {
    //   tabName: 'Videos Lectures',
    //   tabIcon: VideoLibraryIcon,
    //   tabContent: <VimVideoLecturesManagement />,
    // },
    {
      tabName: 'Videos Speak',
      tabIcon: VideoLibraryIcon,
      tabContent: <VimVideoSpeakManagement />,
    },
    // {
    //   tabName: 'Groups',
    //   tabIcon: ClassIcon,
    //   tabContent: <GroupsManagement />,
    // },
    // {
    //   tabName: 'Categories',
    //   tabIcon: CategoryIcon,
    //   tabContent: <CategoriesManagement />,
    // },
    // {
    //   tabName: 'Grades',
    //   tabIcon: Face,
    //   tabContent: <GradesManagement />,
    // },
    // {
    //   tabName: 'Levels',
    //   tabIcon: Chat,
    //   tabContent: <LevelsManagement />,
    // },
    // {
    //   tabName: 'Diplomas',
    //   tabIcon: Build,
    //   tabContent: <DiplomasManagement />,
    // },

    {
      tabName: 'Notifications',
      tabIcon: NotificationsIcon,
      tabContent: <NotificationManagement />,
    },
    {
      tabName: 'Logout',
      tabIcon: ExitToApp,
    },
  ];

  const viewAsTab = {
    tabName: 'View As',
    tabIcon: PeopleAltIcon,
    tabContent: <ViewAs />,
  };

  if (user && viewAsUsers.find(u => u.email === user.email)) tabs.splice(-1, 0, viewAsTab);

  useViewAuth();
  return (
    <Switch>
      <Route component={VideoView} exact path={`${AssistantRoutesPrefix}/video/:id`} />
      <Route component={StudentTasksManagement} path={`${AssistantRoutesPrefix}/studentTasks/:studentId`} />
      <Route component={CorrectionManagement} path={`${AssistantRoutesPrefix}/correct/:taskId`} />
      <Route component={CorrectQuiz} path={`${AssistantRoutesPrefix}/correctQuiz/:quizTakenId`} />
      <Route path={AssistantRoutesPrefix}>
        {!user.loggedInBefore ? (
          <ResetPassword />
        ) : (
          <>
            <div className={classNames(classes.main)}>
              <CustomTabs
                tabs={tabs}
                curTabIndex={curTabIndex}
                handleTabIndexChange={handleTabIndexChange}
                plainTabs
                headerColor="primary"
              />
            </div>
            <CardBody style={{ minHeight: '100%', height: '100%' }}>
              {/* <div>{tabs[curTabIndex].tabContent}</div> */}
              <div>{tabs[curTabIndex] ? tabs[curTabIndex].tabContent : tabs[0].tabContent}</div>
            </CardBody>
            <Notification />
          </>
        )}
      </Route>
      <Redirect exact from={AssistantRoutesPrefix} to="/assistant" />
    </Switch>
  );
};

export default AssistantLayout;

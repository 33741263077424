import React from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
// Always use React.FC as the type of the component
// So you can use the `children` as one item of your props
const CustomDropzone: React.FC<Props> = (props: Props) => {
  return (
    <DropzoneDialog
      initialFiles={props.initialFiles}
      clearOnUnmount={props.clearOnUnmount}
      filesLimit={props.filesLimit}
      showAlerts={false}
      acceptedFiles={props.accept}
      cancelButtonText={'Cancel Upload'}
      submitButtonText={'Submit Files'}
      open={props.open}
      onClose={props.onClose}
      onSave={props.onSave}
      maxFileSize={1000000000}
      showPreviews={true}
      showFileNamesInPreview={true}
    />
  );
};

interface Props {
  open: boolean;
  onClose(): void;
  accept: string[];
  filesLimit: number;
  initialFiles?: (string | File)[];
  maxNumberOfFiles?: number;
  clearOnUnmount?: boolean;
  onSave(files: File[]): void;
}

export default CustomDropzone;

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import { OptionType } from 'components/Autocomplete/Autocomplete';
import CustomInput from 'components/CustomInput/CustomInput';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, IconButton } from '@mui/material';
import GridContainer from 'components/Grid/GridContainer';
import { createVdoCipherInputsSelector, setCreateVdoCipherInputs } from '../slices/adminSlice';

interface Props {}

const VideoSubtitleInputs: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const createVdoCipherInputs = useSelector(createVdoCipherInputsSelector);

  const handleInputChange = (index: number, field: string, value: string) => {
    const copyOfItems = createVdoCipherInputs.subtitles.map((item, i) =>
      i === index ? { ...item, [field]: value } : { ...item },
    );
    dispatch(setCreateVdoCipherInputs({ ...createVdoCipherInputs, subtitles: copyOfItems }));
  };

  const addSubtile = () => {
    dispatch(
      setCreateVdoCipherInputs({
        ...createVdoCipherInputs,
        subtitles: [...createVdoCipherInputs.subtitles, { start: '', end: '', text: '' }],
      }),
    );
  };

  const deleteSubtitle = (index: number) => {
    const updatedSubtitles = createVdoCipherInputs.subtitles.filter((_, i) => i !== index);
    dispatch(setCreateVdoCipherInputs({ ...createVdoCipherInputs, subtitles: updatedSubtitles }));
  };

  return (
    <>
      {createVdoCipherInputs.subtitles.map((subtitle, index) => (
        <GridContainer
          key={index}
          spacing={1}
          style={{
            alignItems: 'center',
          }}
        >
          <Grid item>
            <CustomInput
              id={`start-${index}`}
              labelText="start"
              inputProps={{
                value: subtitle.start,
                onChange: event => handleInputChange(index, 'start', event.target.value),
              }}
            />
          </Grid>

          <Grid item>
            <CustomInput
              id={`end-${index}`}
              labelText="end"
              inputProps={{
                value: subtitle.end,
                onChange: event => handleInputChange(index, 'end', event.target.value),
              }}
            />
          </Grid>

          <Grid item>
            <CustomInput
              id={`subtitle-${index}`}
              labelText="subtitle"
              inputProps={{
                value: subtitle.text,
                onChange: event => handleInputChange(index, 'text', event.target.value),
              }}
            />
          </Grid>

          <Grid item>
            <IconButton onClick={() => deleteSubtitle(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </GridContainer>
      ))}

      <Button color="success" style={{ marginTop: 10 }} variant="contained" endIcon={<AddIcon />} onClick={addSubtile}>
        Add Subtitle
      </Button>
    </>
  );
};

export default VideoSubtitleInputs;

import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
import Chat from '@material-ui/icons/Chat';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import Fingerprint from '@material-ui/icons/Fingerprint';

// core components
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import HeaderLinks from 'components/Header/HeaderLinks';
import Parallax from 'components/Parallax/Parallax';
import CustomInput from 'components/CustomInput/CustomInput';
import InfoArea from 'components/InfoArea/InfoArea';
import styles from 'assets/jss/lms/views/landing/landing';
import imageBackground from 'assets/img/cover1.jpg';
//import logo from 'assets/img/logo.png';

const useStyles = makeStyles(styles);
const LandingView: React.FC = props => {
  
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        brand=""
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'primary',
        }}
        {...rest}
      />

      <Parallax filter={true} image={imageBackground}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Your Story Starts With Us.</h1>
              <h4>
                Develop success from failures. Discouragement and failure are two of the surest stepping stones to
                success.
              </h4>
              <br />
              <Button
                color="danger"
                size="lg"
                href="https://www.youtube.com/watch?v=tS9q-H1pU7Y"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-play" />
                Watch video
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.productSection}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.productTitle}>Let{"'"}s talk product</h2>
                <h5 className={classes.productDescription}>
                  I know what you are thinking; “How would this time be any different, why should I try again or even,
                  “Is it worth it?” The hardest part about becoming successful at tests like the IGCSE ESL is not taking
                  them, the hardest part is challenging yourself to become someone who better understands what is
                  required for the test and the ability to wield a comfortable level of English to handle this and other
                  tests with ease. This is what we teach here. That is why this time is different. By applying the most
                  developed and nuanced methodologies in teaching and developing easy to follow and measure progression
                  charts, we are confident that our students can obtain the scores that they seek. We invest the time
                  and energy into your success because your goals and your story matter to us. I ask you this time; is
                  it worth it not to try? Come and take an evaluation test today and see where you stand as you continue
                  your English learning journey with us.
                </h5>
              </GridItem>
            </GridContainer>
            <div>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <InfoArea
                    title="Basic Listening Quizzes"
                    description="Short listening activities for beginning and intermediate IGCSE students "
                    icon={Chat}
                    iconColor="info"
                    vertical
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InfoArea
                    title="Verified Videos"
                    description="Short culture videos covering a wide range of topics that introduce learners to many parts of everyday topics."
                    icon={VerifiedUser}
                    iconColor="success"
                    vertical
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <InfoArea
                    title="Statistics For Parents"
                    description="Parents can track the progress of their children "
                    icon={Fingerprint}
                    iconColor="danger"
                    vertical
                  />
                </GridItem>
              </GridContainer>
              <Button color="primary">Book a Course</Button>
            </div>
          </div>
          <div className={classNames(classes.requestSection,classes.container)}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <h2 className={classes.requestTitle}>Contact Us</h2>
                <h4 className={classes.requestDescription}>
                  Contact us about any further inquiries. We will responde get back to you in a couple of hours.
                </h4>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Your Name"
                        id="name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Your Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <CustomInput
                      labelText="Your Message"
                      id="message"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.requestTextArea,
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                      }}
                    />
                    <GridItem xs={12} sm={12} md={4}>
                      <Button color="primary">Send Message</Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandingView;

import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormControlProps } from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';

export interface OptionType {
  id: string;
  name: string;
}

interface Props {
  options: OptionType[];
  onChange(event: React.ChangeEvent<{}>, value: OptionType | OptionType[] | null): void;
  formControlProps?: FormControlProps;
  labelText?: string;
  value: OptionType | OptionType[];
  id: string;
  multiple?: boolean;
  placeholder?: string;
  disableClearable?: boolean;
}

const CustomAutocomplete: React.FC<Props> = props => (
  <Autocomplete
    id={props.id}
    options={props.options}
    disableClearable={props.disableClearable}
    value={props.value}
    onChange={props.onChange}
    getOptionLabel={o => o.name}
    fullWidth
    multiple={props.multiple}
    style={{}}
    disabled={props.formControlProps?.disabled}
    renderInput={params => (
      <TextField
        {...params}
        variant="standard"
        placeholder={props.placeholder}
        id={`${props.id}input`}
        label={props.labelText}
        style={{ width: '100%', padding: '5px', margin: '5px' }}
      />
    )}
  />
);

export default CustomAutocomplete;

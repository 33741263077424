import React from 'react';
import Button from 'components/CustomButtons/Button';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useSelector, useDispatch } from 'react-redux';
import {
  addNoteTakingNote,
  updateNoteTakingText,
  removeNoteTakingNoteAt,
  noteTakingNotesSelector,
  noteTakingCorrectionSelector,
  noteTakingMarkSelector,
  setNoteTakingMark,
  updateNoteTakingModelAnswersCount,
  updateNoteTakingCorrection,
  noteTakingTextSelector,
  setNoteTakingText,
  updateNoteTakingAnswersCount,
} from '../createSlice';
import WhyNoteTaking from './WhyNoteTaking';

const NoteTakingQuestion: React.FC<{ uuid: string }> = (props: { uuid: string }) => {
  const dispatch = useDispatch();
  const text = useSelector(noteTakingTextSelector(props.uuid));
  const notes = useSelector(noteTakingNotesSelector(props.uuid));
  const correction = useSelector(noteTakingCorrectionSelector(props.uuid));
  const mark = useSelector(noteTakingMarkSelector(props.uuid));

  return (
    <div>
      <CardBody>
        <CustomInput
          labelText={'Note Taking Question Header'}
          id={'noteTakingQuestion'}
          key={'noteTakingquestion'}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            value: text,
            onChange: c =>
              dispatch(
                setNoteTakingText({
                  uuid: props.uuid,
                  text: c.target.value,
                }),
              ),
          }}
        />
        {notes.map((note, noteIndex) => (
          <div key={`noteTakingContainer${props.uuid + noteIndex + 1}`}>
            <CustomInput
              labelText={`Note ${noteIndex + 1}`}
              id={`noteTakingQuestion${props.uuid + noteIndex + 1}`}
              key={`noteTaking${props.uuid + noteIndex + 1}`}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                value: note.text,
                onChange: c =>
                  dispatch(
                    updateNoteTakingText({
                      uuid: props.uuid,
                      index: noteIndex,
                      value: c.target.value,
                    }),
                  ),
                endAdornment: (
                  <InputAdornment position="end">
                    <CustomInput
                      id="answersCount"
                      labelText="Answers"
                      inputProps={{
                        type: 'number',
                        inputProps: { min: 0, max: 10 },
                        value: note.answersCount,
                        style: { marginBottom: '10px', marginRight: '30px' },
                        onChange: (c: any) =>
                          dispatch(
                            updateNoteTakingAnswersCount({
                              value: c.target.value,
                              uuid: props.uuid,
                              index: noteIndex,
                            }),
                          ),
                      }}
                    />
                    <CustomInput
                      id="modelAnswersCount"
                      labelText="Model"
                      inputProps={{
                        type: 'number',
                        inputProps: { min: 0, max: 10 },
                        value: note.modelAnswersCount,
                        style: { marginBottom: '10px' },
                        onChange: (c: any) =>
                          dispatch(
                            updateNoteTakingModelAnswersCount({
                              value: c.target.value,
                              uuid: props.uuid,
                              index: noteIndex,
                            }),
                          ),
                      }}
                    />
                    <IconButton
                      aria-label="delete"
                      onClick={_ => dispatch(removeNoteTakingNoteAt({ uuid: props.uuid, index: noteIndex }))}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <CardBody>
              {correction.answer[noteIndex].map((singleNoteCorrection, correctionIndex) => (
                <CustomInput
                  labelText={`Answer ${correctionIndex + 1}`}
                  id={`noteCorrection${props.uuid + noteIndex + correctionIndex + 1}`}
                  key={`noteCorrection${props.uuid + noteIndex + correctionIndex + 1}`}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    value: singleNoteCorrection,
                    onChange: c => {
                      // if (false)
                      dispatch(
                        updateNoteTakingCorrection({
                          uuid: props.uuid,
                          value: c.target.value,
                          noteIndex: noteIndex,
                          noteAnswerIndex: correctionIndex,
                        }),
                      );
                    },
                    endAdornment: (
                      <WhyNoteTaking
                        uuid={props.uuid}
                        noteIndex={noteIndex}
                        noteAnswerIndex={correctionIndex}
                        id={`whyNoteTaking ${props.uuid + noteIndex + 1 + correctionIndex}`}
                      />
                    ),
                  }}
                />
              ))}
            </CardBody>
          </div>
        ))}
        <Button color="white" onClick={_ => dispatch(addNoteTakingNote({ uuid: props.uuid }))} size="sm">
          Add Note
        </Button>
        <div style={{ display: 'flex', float: 'right' }}>
          <CustomInput
            id="mark"
            labelText="Mark"
            inputProps={{
              type: 'number',
              inputProps: { min: 0, max: 10 },
              value: mark,
              onChange: (c: any) => dispatch(setNoteTakingMark({ value: c.target.value, uuid: props.uuid })),
            }}
          />
        </div>
      </CardBody>
    </div>
  );
};

export default NoteTakingQuestion;

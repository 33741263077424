import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ShowChat from 'features/quiz/UpdateSimpleAnswers/ShowChat';
import {
  getMessagesAsync,
  getNotificationsAsync,
  getRoomDataAsync,
  notificationsSelector,
  roomDataSelector,
  setNotificationAsReadAsync,
  tokenSelector,
  userNameSelector,
  userRoleSelector,
  userSelector,
  verifyTokenAsync,
} from 'features/auth/authSlice';
import NavPills from 'components/NavPills/NavPills';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import { TextField } from '@material-ui/core';
import { ColDef, DataGrid, RowModel } from '@material-ui/data-grid';
import CustomButton from 'components/CustomButtons/Button';
import styles from 'assets/jss/lms/features/questions';
import { makeStyles } from '@material-ui/core';
import { classNames } from 'primereact/utils';

interface Notification {
  id?: string;
  read: boolean;
  createdAt: Date;
  _id?: string;
  senderName?: string;
  senderPhone?: string;
  content: string;
  userId: string;
  roomId: string;

  type?: 'message' | 'thread';
  targetId?: string;
}

const NotificationManagement: React.FC = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const userName = useSelector(userNameSelector);
  const userRole = useSelector(userRoleSelector);
  const roomData = useSelector(roomDataSelector);
  const senderData = useSelector(userSelector);
  const [openChat, setOpenChat] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<Notification | null>(null);

  const [studentNameFilter, setStudentNameFilter] = React.useState<string>('');
  const [studentPhoneFilter, setStudentPhoneFilter] = React.useState<string>('');
  const [messageContentFilter, setMessageContentFilter] = React.useState<string>('');

  useEffect(() => {
    dispatch(getNotificationsAsync({ token, userId: localStorage.getItem('studentID') || '' }));
    dispatch(verifyTokenAsync({ token }));
  }, [dispatch, token]);

  const notifications: Notification[] = useSelector(notificationsSelector);

  const handleShowChatClick = (studentID: string, quizID: string, questionID: string) => {
    if (studentID && quizID && questionID) {
      const std = { studentID: (senderData.role === 'student' ? senderData.id : studentID) || 'lll' };
      dispatch(
        getMessagesAsync({
          token,
          questionID: questionID || '',
          studentID: std.studentID,
          quizID: quizID || '',
        }),
      );
    }
    setOpenChat(true);
  };

  const handleClick = async (notification: Notification) => {
    if (notification.type === 'thread' && notification.targetId) {
      window.open(`/${userRole}/video/${notification.targetId}`);
    } else {
      dispatch(getRoomDataAsync({ token, roomID: notification.roomId }));
      handleShowChatClick(notification.userId, roomData.quizID, roomData.questionID);
      setSelectedNotification(notification);
    }
  };

  const listStyleForUnread = {
    marginBottom: '5px',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: '#b4e3f3',
    border: '1px solid black',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  };
  const listStyleForRead = {
    marginBottom: '5px',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: '#f7f7f7',
    border: '1px solid black',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  };
  const IconStyle = {
    borderRadius: '5px',
    alignItems: 'center',
  };
  const noNotificationsStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: 'red',
    fontSize: '20px',
    fontWeight: 'bold',
  };
  const readMessages = notifications?.filter(notification => notification.read && notification.type !== 'thread');
  const readThreads = notifications?.filter(thread => thread.read && thread.type === 'thread');
  const unreadMessages = notifications?.filter(notification => !notification.read && notification.type !== 'thread');
  const unreadThreads = notifications?.filter(thread => !thread.read && thread.type === 'thread');

  const filteredNotifications = unreadMessages.filter(msg => {
    const nameMatch = !studentNameFilter || msg.senderName?.toLowerCase().includes(studentNameFilter.toLowerCase());
    const contentMatch =
      !messageContentFilter || msg.content?.toLowerCase().includes(messageContentFilter.toLowerCase());
    const phoneMatch =
      !studentPhoneFilter ||
      (msg.senderPhone && msg.senderPhone.toLowerCase().includes(studentPhoneFilter.toLowerCase()));

    return nameMatch && phoneMatch && contentMatch;
  });
  const getRows = (): any[] => {
    if (!unreadMessages) {
      return [];
    } else {
      const rows: RowModel[] = filteredNotifications.map((notification, index) => ({
        id: index.toString(),
        ...notification,
      }));
      return rows;
    }
  };

  const columns: ColDef[] = [
    { field: 'id', headerName: 'ID', width: 80 },
    {
      field: 'content',
      headerName: 'Message Header',
      width: 500,
    },
    { field: 'senderName', headerName: 'Sender Name', width: 300 },
    { field: 'senderPhone', headerName: 'Sender Phone', width: 200 },
    {
      field: 'createdAt',
      headerName: 'Sent At',
      width: 200,
      renderCell: params => <span>{new Date(params.value as string).toLocaleString()}</span>,
    },
    {
      field: 'read',
      headerName: 'chat now',
      width: 300,
      renderCell: params => (
        <>
          <CustomButton
            round
            color="success"
            size="sm"
            fullWidth
            onClick={() => {
              handleClick(params.row as Notification);
            }}
          >
            open chat
          </CustomButton>
        </>
      ),
    },
  ];

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <NavPills
        alignCenter={true}
        tabs={[
          {
            tabButton: 'Un-Read Chats',
            tabIcon: NotificationsActiveIcon,
            tabContent: (
              <div style={{ height: '70vh', width: '100%' }}>
                <div
                  style={{ marginBottom: '1%' }}
                  className={classNames(classes.container, classes.categoryAutocomplete)}
                >
                  <TextField
                    style={{ marginLeft: '-10%' }}
                    label="Message Content Filter"
                    onChange={e => {
                      setMessageContentFilter(e.target.value);
                    }}
                  />
                  <TextField
                    style={{ marginLeft: '15%' }}
                    label="Student Name Filter"
                    onChange={e => {
                      setStudentNameFilter(e.target.value);
                    }}
                  />
                  <TextField
                    style={{ marginLeft: '7%' }}
                    label="Student Phone Filter"
                    onChange={e => {
                      setStudentPhoneFilter(e.target.value);
                    }}
                  />
                </div>
                <DataGrid pagination rowHeight={60} columns={columns} rows={getRows()} />
              </div>
            ),
          },
          {
            tabButton: 'Read chats',
            tabIcon: NotificationsOffIcon,
            tabContent:
              readMessages.length !== 0 ? (
                <ul style={{ listStyleType: 'none' }}>
                  {readMessages.map(thread => (
                    <li key={thread.roomId} style={listStyleForRead} onClick={() => handleClick(thread)}>
                      <span>{thread.content}</span>
                      <span style={IconStyle}>
                        <span
                          style={{
                            marginRight: '30px',
                          }}
                        >
                          {new Date(thread.createdAt).toLocaleString()}
                        </span>
                        &#x2714;<span style={IconStyle}>&#x2714;</span>
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <div style={noNotificationsStyles}>
                  <span>There is no Read Chats yet 😉🤌⌛</span>
                </div>
              ),
          },
          {
            tabButton: 'Un-Read Threads (All)',
            tabIcon: SpeakerNotesIcon,
            tabContent:
              unreadThreads.length !== 0 ? (
                <ul style={{ listStyleType: 'none' }}>
                  {unreadThreads.map(thread => (
                    <li key={thread.roomId} style={listStyleForUnread} onClick={() => handleClick(thread)}>
                      <span>{thread.content}</span>
                      <CustomButton
                        round
                        color="warning"
                        size="sm"
                        fullWidth
                        onClick={e => {
                          e.stopPropagation(); // Prevent the click event from reaching the li tag

                          dispatch(
                            setNotificationAsReadAsync({
                              token,
                              userId: localStorage.getItem('studentID') || '',
                              notificationID: thread._id || '',
                            }),
                          );

                          console.log('Clicked');
                        }}
                      >
                        Mark As Read ✔️✔️{' '}
                      </CustomButton>
                      <span style={IconStyle}>
                        <span
                          style={{
                            marginRight: '30px',
                          }}
                        >
                          {new Date(thread.createdAt).toLocaleString()}
                          <span>&#x2714;</span>
                        </span>
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <div style={noNotificationsStyles}>
                  <span>There is no UN_Read Threads yet 👍🏻👍🏼💯💯</span>
                </div>
              ),
          },
          {
            tabButton: 'Read Threads',
            tabIcon: SpeakerNotesOffIcon,
            tabContent:
              readThreads.length !== 0 ? (
                <ul style={{ listStyleType: 'none' }}>
                  {readThreads.map(thread => (
                    <li key={thread.roomId} style={listStyleForRead} onClick={() => handleClick(thread)}>
                      <span>{thread.content}</span>
                      <span style={IconStyle}>
                        <span
                          style={{
                            marginRight: '30px',
                          }}
                        >
                          {new Date(thread.createdAt).toLocaleString()}
                        </span>
                        &#x2714;<span style={IconStyle}>&#x2714;</span>
                      </span>
                    </li>
                  ))}
                </ul>
              ) : (
                <div style={noNotificationsStyles}>
                  <span>There is no Read Threads yet 😉🤌⌛</span>
                </div>
              ),
          },
        ]}
      />

      {notifications.length === 0 && (
        <div style={noNotificationsStyles}>
          <span>There are no notifications</span>
        </div>
      )}

      {selectedNotification && (
        <ShowChat
          open={openChat}
          setOpen={setOpenChat}
          quizID={roomData.quizID}
          questionID={roomData.questionID}
          questionNumber={roomData.questionNumber}
          subQuestionNumber={roomData.subQuestionNumber}
          senderName={userName}
          studentID={selectedNotification.userId}
          receiverID={selectedNotification.userId}
          notificationID={selectedNotification._id}
          modelType={'ShortAnswers'}
        />
      )}
    </div>
  );
};

export default NotificationManagement;

import React from 'react';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import Button from 'components/CustomButtons/Button';
import styles from 'assets/jss/lms/features/questions';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { loginAsync } from './authSlice';
import { viewAsUsers } from './client';
import { setCurrentTabIndex } from 'features/admin/slices/adminViewSlice';

const useStyles = makeStyles(styles);

const ViewAs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80, resizable: true, hide: true },
    { field: 'name', headerName: 'Name', width: 250 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'role', headerName: 'Role', width: 250 },
    {
      field: 'action',
      headerName: 'Actions',
      width: 150,
      renderCell: params => (
        <Button
          round
          color="success"
          size="sm"
          fullWidth
          onClick={() => {
            setTimeout(() => {
              dispatch(setCurrentTabIndex({ curTabIndex: 0 }));
            }, 300);
            dispatch(loginAsync({ email: params.row.email, password: params.row.password }));
            // dispatch(setCurrentTabIndex({ curTabIndex: 0 }));
          }}
        >
          Select
        </Button>
      ),
    },
  ];

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <DataGrid
        className={classes.dataGrid}
        rows={viewAsUsers.filter(user => user.email !== localStorage.getItem('email'))}
        columns={columns}
        disableSelectionOnClick
        rowHeight={60}
      />
    </div>
  );
};

export default ViewAs;

import { createStyles } from '@material-ui/core';
import { defaultFont, primaryColor, infoColor, successColor, warningColor, dangerColor } from '../../lms';

const typographyStyle = createStyles({
  defaultFontStyle: {
    ...defaultFont,
    fontSize: '14px',
  },
  defaultHeaderMargins: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  quote: {
    padding: '10px 20px',
    margin: '0 0 20px',
    fontSize: '17.5px',
    borderLeft: '5px solid #eee',
  },
  quoteText: {
    margin: '0 0 10px',
    fontStyle: 'italic',
  },
  quoteAuthor: {
    display: 'block',
    fontSize: '80%',
    lineHeight: '1.42857143',
    color: '#777',
  },
  mutedText: {
    color: '#777',
  },
  primaryText: {
    color: primaryColor,
    fontWeight: 'bold',
  },
  infoText: {
    color: infoColor,
  },
  successText: {
    color: successColor,
  },
  warningText: {
    color: warningColor,
  },
  dangerText: {
    color: dangerColor,
  },
  smallText: {
    fontSize: '65%',
    fontWeight: 400,
    lineHeight: '1',
    color: '#777',
  },
  section: {
    padding: '70px 0',
  },
  space50: {
    height: '50px',
    display: 'block',
  },
  typo: {
    position: 'relative',
    width: '100%',
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: '10px',
    color: '#c0c1c2',
    display: 'block',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '13px',
    left: 0,
    marginLeft: '20px',
    position: 'absolute',
    width: '260px',
  },
  marginLeft: {
    marginLeft: 'auto !important',
  },
});

export default typographyStyle;

import React from 'react';
import Button from 'components/CustomButtons/Button';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useSelector, useDispatch } from 'react-redux';
import {
  setMCQText,
  removeMCQCorrection,
  addMCQCorrection,
  addMCQOption,
  updateMCQOption,
  removeMCQOptionAt,
  mcqChoicesSelector,
  mcqCorrectionSelector,
  mcqMarkSelector,
  updateMCQMarksAt,
  setMCQMark,
  mcqTextSelector,
} from '../editSlice';
import { Checkbox, TextField } from '@material-ui/core';
import WhyMCQ from './WhyMCQ';

const MultipleChoiceQuestion: React.FC<{ uuid: string }> = (props: { uuid: string }) => {
  const dispatch = useDispatch();
  const choices = useSelector(mcqChoicesSelector(props.uuid));
  const text = useSelector(mcqTextSelector(props.uuid));
  const correction = useSelector(mcqCorrectionSelector(props.uuid));
  const mark = useSelector(mcqMarkSelector(props.uuid));



  return (
    <div>
      <CardBody>
        <CustomInput
          labelText="Question Text"
          id={`QuestionMCQ ${props.uuid}`}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            defaultValue: text,
            onChange: c => dispatch(setMCQText({ uuid: props.uuid, text: c.target.value })),
            multiline: true,
            rows: 3,
          }}
        />
        <CardBody>
          {choices.map((option, index) => {
            const isOptionCorrect = correction && correction.answer && correction.answer.includes(option);
            return (
              <CustomInput
                labelText={`Option ${index + 1}`}
                id={`option question${props.uuid + index + 1}`}
                key={`option${props.uuid + index + 1}`}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: 'text',
                  value: option,
                  onChange: c => dispatch(updateMCQOption({ uuid: props.uuid, index: index, value: c.target.value })),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Checkbox
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        checked={isOptionCorrect}
                        onChange={e => {
                          if (e.target.checked)
                            dispatch(addMCQCorrection({ uuid: props.uuid, correctionIndex: index }));
                          else
                            dispatch(
                              removeMCQCorrection({ uuid: props.uuid, correctionIndex: index, removedValue: option }),
                            );
                        }}
                      />
                      {isOptionCorrect && (
                        <TextField
                          value={correction.marks[index]}
                          type="number"
                          InputProps={{
                            inputProps: {
                              min: 0,
                            },
                          }}
                          onChange={e =>
                            dispatch(
                              updateMCQMarksAt({
                                uuid: props.uuid,
                                value: parseInt(e.target.value, 10),
                                index: index,
                              }),
                            )
                          }
                          variant="outlined"
                        />
                      )}
                      {isOptionCorrect && (
                        <WhyMCQ uuid={props.uuid} index={index} id={`whyMCQ radio${props.uuid + index + 1}`} />
                      )}
                      <IconButton
                        aria-label="delete"
                        onClick={_ => dispatch(removeMCQOptionAt({ uuid: props.uuid, index }))}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            );
          })}
          <Button color="white" onClick={_ => dispatch(addMCQOption({ uuid: props.uuid }))} size="sm">
            Add Option
          </Button>
        </CardBody>
        <div style={{ display: 'flex', float: 'right' }}>
          <CustomInput
            id="mark"
            labelText="Mark"
            inputProps={{
              type: 'number',
              inputProps: { min: 0, max: 10 },
              value: mark,
              onChange: (c: any) => dispatch(setMCQMark({ value: c.target.value, uuid: props.uuid })),
            }}
          />
        </div>
        <div style={{ float: 'right', width: 'auto', zIndex: 1000, position: 'relative' }}>
        
      </div>
      
      </CardBody>
    </div>
  );
};

export default MultipleChoiceQuestion;

import { IQuiz } from 'features/student/types';
import API from 'api';
import { IAssignedTaskDetails, ITaskCorrectionDetails } from './slices/slicesTypes';
import { createReadStream } from 'fs';
import axios from 'axios';
// all responses limited to 10000
export const getStudents = async (input: { token: string }): Promise<Types.API.User.IStudentInfo[]> => {
  const response = await API.get('/admin/getStudents', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: 10000 },
  });
  return response.data.students as Types.API.User.IStudentInfo[];
};

export const getParents = async (input: { token: string }): Promise<Types.API.User.IUserInfo[]> => {
  const response = await API.get('/admin/getParents', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: 10000 },
  });
  return response.data.parents as Types.API.User.IUserInfo[];
};

export const getGroups = async (input: { token: string }): Promise<Types.API.Group.IGroupInfo[]> => {
  const response = await API.get('/admin/getGroups', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: 10000 },
  });
  return response.data.groups as Types.API.Group.IGroupInfo[];
};

export const createTreeView = async (input: {
  treeView: Types.API.TreeView.ITreeViewInput;
  token: string;
}): Promise<Types.API.TreeView.ITreeViewInfo> => {
  const response = await API.post('/admin/addTreeView', input.treeView, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.treeView;
};

export const createTreeNode = async (input: {
  treeNode: Types.API.TreeNode.ITreeNodeInput;
  token: string;
}): Promise<Types.API.TreeNode.ITreeNodeModel> => {
  const response = await API.post('/admin/addTreeNode', input.treeNode, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.treeNode;
};

export const createVimVideo = async (input: {
  vimVideo: Types.API.Video.IVideoInput;
  token: string;
}): Promise<Types.API.Video.IVideoInfo> => {
  const response = await API.post('/admin/addVimVideo', input.vimVideo, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.vimVideo;
};

export const createSchool = async (input: {
  school: Types.API.School.ISchoolInput;
  token: string;
}): Promise<Types.API.School.ISchoolInfo> => {
  const response = await API.post('/admin/addSchool', input.school, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.school;
};

export const createQuestionCategory = async (input: {
  category: Types.API.Category.ICategoryInput;
  token: string;
}): Promise<Types.API.Category.ICategoryInfo> => {
  const response = await API.post('/admin/addCategory', input.category, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.category;
};

export const editQuestionCategory = async (input: {
  name: string;
  token: string;
  id: string;
}): Promise<Types.API.User.IStudentInfo> => {
  const response = await API.put(
    '/admin/editCategory/' + input.id,
    { name: input.name },
    {
      headers: { Authorization: 'Bearer ' + input.token },
    },
  );
  return response.data.category;
};

export const deleteQuestionCategory = async (input: { token: string; id: string }): Promise<boolean> => {
  const response = await API.delete('/admin/deleteCategory/' + input.id, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data;
};

export const editQuestionPriority = async (input: {
  priority: string;
  token: string;
  id: string;
}): Promise<Types.API.Question.IQuestionModel> => {
  const response = await API.put(
    '/admin/editQuestionPriority/' + input.id,
    { priority: input.priority },
    {
      headers: { Authorization: 'Bearer ' + input.token },
    },
  );
  return response.data;
};

export const createQuestionDiploma = async (input: {
  diploma: Types.API.Category.IDiplomaInput;
  token: string;
}): Promise<Types.API.Category.ICategoryInfo> => {
  const response = await API.post('/admin/addDiploma', input.diploma, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.diploma as Types.API.Category.ICategoryInfo;
};

export const createQuestionLevel = async (input: {
  level: Types.API.Category.ILevelInput;
  token: string;
}): Promise<Types.API.Category.ICategoryInfo> => {
  const response = await API.post('/admin/addLevel', input.level, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.level as Types.API.Category.ICategoryInfo;
};

export const createQuestionGrade = async (input: {
  grade: Types.API.Category.IGradeInput;
  token: string;
}): Promise<Types.API.Category.ICategoryInfo> => {
  const response = await API.post('/admin/addGrade', input.grade, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.grade as Types.API.Category.ICategoryInfo;
};

export const createGroup = async (input: {
  group: Types.API.Group.IGroupCreateInput;
  token: string;
}): Promise<Types.API.Group.IGroupInfo> => {
  const response = await API.post('/admin/addGroup', input.group, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.group;
};

export const createStudent = async (input: {
  student: Types.API.User.IStudentInput;
  token: string;
}): Promise<Types.API.User.IStudentInfo> => {
  const response = await API.post('/admin/addStudent', input.student, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.student;
};

export const editStudent = async (input: {
  student: Types.API.User.IStudentEditInput;
  token: string;
  id: string;
}): Promise<Types.API.User.IStudentInfo> => {
  const response = await API.put('/admin/editStudent/' + input.id, input.student, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.group;
};

export const editParent = async (input: {
  parent: Types.API.User.IParentInput;
  token: string;
  id: string;
}): Promise<Types.API.User.IParentInput> => {
  const response = await API.put('/admin/editParent/' + input.id, input.parent, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.parent;
};

export const editAssistant = async (input: {
  assistant: Types.API.User.IAssistantInput;
  token: string;
  id: string;
}): Promise<Types.API.User.IAssistantInput> => {
  const response = await API.put('/admin/editAssistant/' + input.id, input.assistant, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.assistant;
};

export const deleteQuestion = async (input: { token: string; ids: string[] }): Promise<boolean> => {
  var response = { data: true };
  await Promise.all(
    input.ids.map(async id => {
      response = await API.delete('/admin/deleteQuestion/' + id, {
        headers: { Authorization: 'Bearer ' + input.token },
      });
    }),
  );
  return response.data;
};
export const deleteStudents = async (input: { token: string; ids: string[] }): Promise<boolean> => {
  const response = await API.delete('/admin/deleteStudent/' + JSON.stringify(input.ids), {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data;
};
export const deleteAssistant = async (input: { token: string; id: string }): Promise<boolean> => {
  const response = await API.delete('/admin/deleteAssistant/' + input.id, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data;
};
export const deleteParent = async (input: { token: string; id: string }): Promise<boolean> => {
  const response = await API.delete('/admin/deleteParent/' + input.id, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data;
};
export const deleteGroup = async (input: { token: string; id: string }): Promise<boolean> => {
  const response = await API.delete('/admin/deleteGroup/' + input.id, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data;
};
export const deleteSchool = async (input: { token: string; id: string }): Promise<boolean> => {
  const response = await API.delete('/admin/deleteSchool/' + input.id, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data;
};
export const deleteQuiz = async (input: { token: string; id: string }): Promise<boolean> => {
  const response = await API.delete('/admin/deleteQuiz/' + input.id, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data;
};
export const editGroup = async (input: {
  group: Types.API.Group.IGroupEditInput;
  token: string;
  id: string;
}): Promise<Types.API.Group.IGroupInfo> => {
  const response = await API.put(
    '/admin/editGroup/' + input.id,
    {
      name: input.group.name,
      studentsIds: input.group.studentsIds,
      assistantsIds: input.group.assistantsIds,
      schoolId: input.group.schoolId,
    },
    {
      headers: { Authorization: 'Bearer ' + input.token },
    },
  );
  return response.data.group;
};

export const editVimVideo = async (input: {
  vimVideo: Types.API.Video.IVideoEditInput;
  token: string;
  id: string;
}): Promise<Types.API.Video.IVideoInfo> => {
  const response = await API.put(
    '/admin/editVimVideo/' + input.id,
    {
      name: input.vimVideo.name,
      url: input.vimVideo.url,
      active: input.vimVideo.active,
    },
    {
      headers: { Authorization: 'Bearer ' + input.token },
    },
  );
  return response.data.vimVideo;
};

export const createVdoCipher = async (input: { reqBody: Types.API.VdoCipher.IVdoCipherInputs; token: string }) => {
  // console.log('🚀 ~ file: client.ts:275 ~ input:', input);
  try {
    const response = await API.post('/admin/uploadVdoCipher', input.reqBody, {
      headers: { Authorization: 'Bearer ' + input.token },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const editVdoCipher = async (input: {
  videoId: string;
  vdoCipherId: string;
  reqBody: Types.API.VdoCipher.IVdoCipherInputs;
  fileEdited: boolean;
  token: string;
}) => {
  try {
    const { fileEdited, reqBody, vdoCipherId, token, videoId } = input;
    const response = await API.put(
      `/admin/updateVdoCipher/${videoId}`,
      { data: reqBody, fileEdited, vdoCipherId },
      {
        headers: { Authorization: 'Bearer ' + token },
      },
    );
    // console.log('🚀 ~ file: client.ts:287 ~ response:', response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateStudentsProgressVideo = async (input: {
  videoId: string;
  studentId: string;
  newValue: string;
  token: string;
}) => {
  try {
    const { studentId, newValue, token, videoId } = input;
    const response = await API.put(
      `/admin/updateStudentsProgressVideo/${videoId}`,
      { studentId, newValue },
      {
        headers: { Authorization: 'Bearer ' + token },
      },
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const getStudentSpeakingProgress = async (input: { studentId: string; token: string }) => {
  try {
    const { studentId, token } = input;
    const response = await API.get(`/admin/getStudentProgressVideos/${studentId}`, {
      headers: { Authorization: 'Bearer ' + token },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteVdoCipher = async (input: { videoId: string; token: string }) => {
  try {
    const response = await API.delete(`/admin/deleteVdoCipher/${input.videoId}`, {
      headers: { Authorization: 'Bearer ' + input.token },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const uploadUsingCredentials = async (input: {
  credentials: {
    clientPayload: {
      policy: string;
      key: string;
      'x-amz-signature': string;
      'x-amz-algorithm': string;
      'x-amz-date': string;
      'x-amz-credential': string;
      uploadLink: string;
    };
    videoId: string;
  };
  file: File;
  token: string;
}) => {
  // console.log('🚀 ~ file: client.ts:360 ~ input:', input);
  const { credentials, file } = input;
  const formData = new FormData();
  formData.append('policy', credentials.clientPayload.policy);
  formData.append('key', credentials.clientPayload.key);
  formData.append('x-amz-signature', credentials.clientPayload['x-amz-signature']);
  formData.append('x-amz-algorithm', credentials.clientPayload['x-amz-algorithm']);
  formData.append('x-amz-date', credentials.clientPayload['x-amz-date']);
  formData.append('x-amz-credential', credentials.clientPayload['x-amz-credential']);
  formData.append('success_action_status', '201');
  formData.append('success_action_redirect', '');
  formData.append('file', file);

  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };

  const response = await axios.post(credentials.clientPayload['uploadLink'], formData, config);
  // console.log('🚀 ~ file: client.ts:378 ~ response:', response);
  return response.data;
};

export const editTreeView = async (input: {
  treeView: Types.API.TreeView.ITreeViewEditInput;
  token: string;
  id: string;
}): Promise<Types.API.Video.IVideoInfo> => {
  const response = await API.put(
    '/admin/editTreeView/' + input.id,
    {
      name: input.treeView.name,
    },
    {
      headers: { Authorization: 'Bearer ' + input.token },
    },
  );
  return response.data.treeView;
};

export const editTreeNode = async (input: {
  treeNode: Types.API.TreeNode.ITreeNodeEditInput;
  token: string;
  id: string;
}): Promise<Types.API.Video.IVideoInfo> => {
  const response = await API.put(
    '/admin/editTreeNode/' + input.id,
    {
      name: input.treeNode.name,
      videos: input.treeNode.videos,
      children: input.treeNode.children,
    },
    {
      headers: { Authorization: 'Bearer ' + input.token },
    },
  );
  return response.data.treeNode;
};

export const getTreeNode = async (input: {
  token: string;
  id: string;
}): Promise<{ treeNode: Types.API.TreeNode.ITreeNodeInfo }> => {
  const response = await API.get('/admin/getTreeNode/' + input.id, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return {
    treeNode: response.data.treeNode,
  };
};

export const getQuestionCategories = async (input: { token: string }): Promise<Types.API.Category.ICategoryInfo[]> => {
  const response = await API.get('/admin/getCategories', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: 100, offset: 0, sort: 'asc' },
  });
  return response.data.categories as Types.API.Category.ICategoryInfo[];
};

export const getQuestionGrades = async (input: { token: string }): Promise<Types.API.Category.ICategoryInfo[]> => {
  const response = await API.get('/admin/getGrades', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: 100, offset: 0, sort: 'asc' },
  });
  return response.data.grades as Types.API.Category.ICategoryInfo[];
};

export const getQuestionLevels = async (input: { token: string }): Promise<Types.API.Category.ICategoryInfo[]> => {
  const response = await API.get('/admin/getLevels', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: 100, offset: 0, sort: 'asc' },
  });
  return response.data.levels as Types.API.Category.ICategoryInfo[];
};

export const getQuestionDiplomas = async (input: { token: string }): Promise<Types.API.Category.ICategoryInfo[]> => {
  const response = await API.get('/admin/getDiplomas', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: 100, offset: 0, sort: 'asc' },
  });
  return response.data.diplomas as Types.API.Category.ICategoryInfo[];
};

export const getSchools = async (input: { token: string }): Promise<Types.API.School.ISchoolInfo[]> => {
  const response = await API.get('/admin/getSchools', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: 100, offset: 0, sort: 'asc' },
  });
  return response.data.schools;
};

export const getVimVideos = async (input: { token: string }): Promise<Types.API.Video.IVideoInfo[]> => {
  const response = await API.get('/admin/getVimVideos', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: 10000, offset: 0, sort: 'asc' },
  });
  return response.data.vimVideos;
};
export const getVdoCipherVideos = async (input: {
  token: string;
  q?: string;
  key?: string;
}): Promise<Types.API.VdoCipher.IVdoCipherInfo[]> => {
  // const params = input.key ? { [input.key]: input.q } : {};
  const params = input.key ? { key: input.key, q: input.q } : {};
  const response = await API.get('/admin/getVdoCipher', {
    headers: { Authorization: 'Bearer ' + input.token },
    params,
  });
  return response.data.videos;
};
export const getSelectedVdoCipher = async (input: {
  token: string;
  videoId: string;
}): Promise<Types.API.VdoCipher.IVdoCipherInfo> => {
  const response = await API.get(`/admin/getSelectedVdoCipher/${input.videoId}`, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data;
};

export const getTreeViews = async (input: { token: string }): Promise<Types.API.TreeView.ITreeViewInfo[]> => {
  const response = await API.get('/admin/getTreeViews', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: 10000, offset: 0, sort: 'asc' },
  });
  return response.data.treeViews;
};

export const getQuestions = async (input: {
  token: string;
  filters?: {
    title: string;
    category: string;
    level: string;
  };
  limit?: number;
  offset?: number;
}): Promise<{ questions: Types.API.Question.IQuestionModel[]; total: number }> => {
  const response = await API.get('/admin/getQuestions', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { q: input.filters, limit: input?.limit, offset: input?.offset },
  });
  return response.data;
};

export const createVideoQuiz = async (input: { quiz: Types.API.Quiz.IVideoInput; token: string }): Promise<IQuiz> => {
  const response = await API.post('/admin/addVideoQuiz', input.quiz, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data;
};
export const createQuiz = async (input: { quiz: Types.API.Quiz.IQuizInput; token: string }): Promise<IQuiz> => {
  const response = await API.post('/admin/addQuiz', input.quiz, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.quiz;
};

export const createHomework = async (input: {
  homework: Types.API.Quiz.IHomeworkInput;
  token: string;
}): Promise<IQuiz> => {
  const response = await API.post('/admin/addHomework', input.homework, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.homework;
};

export const editQuiz = async (input: {
  quiz: Types.API.Quiz.IQuizModel;
  token: string;
}): Promise<Types.API.Quiz.IQuizModel> => {
  const response = await API.put('/admin/editQuiz/' + input.quiz._id, input.quiz, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.quiz;
};

export const editQuizActivity = async (input: {
  quizId: string;
  active: boolean;
  token: string;
}): Promise<Types.API.Quiz.IQuizModel> => {
  const response = await API.put(
    `/admin/editQuizActivity/${input.quizId}`,
    { active: input.active },
    {
      headers: { Authorization: 'Bearer ' + input.token },
    },
  );
  return response.data;
};

export const getAssistants = async (input: { token: string }): Promise<Types.API.User.IUserInfo[]> => {
  const response = await API.get('/admin/getAssistants', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: 10000 },
  });
  return response.data.assistants as Types.API.User.IUserInfo[];
};

export const createAssistant = async (input: {
  assistant: Types.API.User.IAssistantInput;
  token: string;
}): Promise<Types.API.User.IUserInfo> => {
  const response = await API.post('/admin/addAssistant', input.assistant, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.assistant;
};

export const createParent = async (input: { parent: Types.API.User.IParentInput; token: string }): Promise<boolean> => {
  const response = await API.post('/admin/addParent', input.parent, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data;
};

export const getAutoCorrectedQuizzes = async (input: {
  token: string;
  offset: number;
  limit: number;
  q?: string;
}): Promise<{ quizzes: Types.API.QuizTaken.IQuizTakenInfo[]; total: number }> => {
  const response = await API.get('/admin/autoCorrected', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: input.limit, offset: input.offset, q: input.q },
  });
  return {
    quizzes: response.data.quizzes as Types.API.QuizTaken.IQuizTakenInfo[],
    total: response.data.total as number,
  };
};

export const getAssignedHomeworks = async (input: {
  token: string;
  offset: number;
  limit: number;
}): Promise<{ homeworks: Types.API.Quiz.IQuizModel[]; total: number }> => {
  const response = await API.get('/admin/getHomeworks', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: input.limit, offset: input.offset },
  });
  return {
    homeworks: response.data.homeworks as Types.API.Quiz.IQuizModel[],
    total: response.data.total as number,
  };
};

export const getVideoQuizzes = async (input: {
  token: string;
  offset: number;
  limit: number;
}): Promise<{ quizzes: Types.API.Quiz.IQuizModel[]; total: number }> => {
  const response = await API.get('/admin/getVideoQuizzes', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: input.limit, offset: input.offset },
  });
  return {
    quizzes: response.data.quizzes as Types.API.Quiz.IQuizModel[],
    total: response.data.total as number,
  };
};

export const getAssignedQuizzes = async (input: {
  token: string;
  offset: number;
  limit: number;
}): Promise<{ quizzes: Types.API.Quiz.IQuizModel[]; total: number }> => {
  const response = await API.get('/admin/getQuizzes', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: input.limit, offset: input.offset },
  });
  return {
    quizzes: response.data.quizzes as Types.API.Quiz.IQuizModel[],
    total: response.data.total as number,
  };
};

export const uploadSingleFile = async (input: { file: File; token: string }): Promise<Types.API.File.IFileModel> => {
  const formData = new FormData();
  formData.append('file', input.file);
  const response = await API.post('/admin/uploadFile', formData, {
    headers: {
      Authorization: 'Bearer ' + input.token,
      'Access-Control-Allow-Headers': 'Content-Type, Authorization, Access-Control-Allow-Headers',
    },
  });
  return response.data.file;
};
export const uploadSingleVideoFile = async (input: {
  file: File;
  token: string;
}): Promise<Types.API.File.IFileModel> => {
  const formData = new FormData();
  console.log('In');
  formData.append('file', input.file);
  const response = await API.post('/admin/uploadVideoFile', formData, {
    headers: {
      Authorization: 'Bearer ' + input.token,
      'Access-Control-Allow-Headers': 'Content-Type, Authorization, Access-Control-Allow-Headers',
    },
  });
  return response.data.file;
};

export const uploadMultipleFiles = async (input: {
  files: File[];
  token: string;
}): Promise<Types.API.File.IFileModel[]> => {
  const formData = new FormData();

  for (let i = 0; i < input.files.length; i++) {
    formData.append('files', input.files[i]);
  }
  const response = await API.post('/admin/uploadFiles', formData, {
    headers: {
      Authorization: 'Bearer ' + input.token,
    },
  });
  return response.data.file;
};

export const getQuizHomeworkDetails = async (input: {
  id: string;
  isHomework: boolean;
  token: string;
}): Promise<IAssignedTaskDetails> => {
  const response = await API.get('/admin/getQuizHomeworkDetails/' + input.id, {
    headers: {
      Authorization: 'Bearer ' + input.token,
    },
  });
  return response.data as IAssignedTaskDetails;
};

export const getTaskCorrectionDetails = async (input: {
  id: string;
  token: string;
}): Promise<ITaskCorrectionDetails> => {
  const response = await API.get(`/admin/getTaskCorrectionDetails/${input.id}`, {
    headers: {
      Authorization: 'Bearer ' + input.token,
    },
  });
  return response.data as ITaskCorrectionDetails;
};
export const getStudentTasks = async (input: { studentId: string; token: string }): Promise<any> => {
  const response = await API.get(`/admin/getStudentTasks/${input.studentId}`, {
    headers: {
      Authorization: 'Bearer ' + input.token,
    },
  });
  return response.data as any;
};

export const uploadSubQuestionFile = async (input: {
  file: File;
  token: string;
  id: string;
}): Promise<{ file: Types.API.File.IFileModel; questionId: string }> => {
  const formData = new FormData();
  formData.append('file', input.file);
  const response = await API.post('/admin/uploadFile', formData, {
    headers: {
      Authorization: 'Bearer ' + input.token,
    },
  });
  return { file: response.data.file, questionId: input.id };
};

export const getQuizReport = async (input: { groupID: string; quizID: string; token: string }): Promise<string> => {
  const response = await API.post(
    '/admin/getReport',
    { groupId: input.groupID, quizId: input.quizID },
    {
      headers: {
        Authorization: 'Bearer ' + input.token,
      },
    },
  );
  return response.data;
};

export const getWhatsappStatus = async (input: { token: string }): Promise<boolean> => {
  const response = await API.get('/admin/isWhatsAppReady', {
    headers: {
      Authorization: 'Bearer ' + input.token,
    },
  });
  return response.data;
};

export const initializeWhatsapp = async (input: { token: string }): Promise<string | null> => {
  const response = await API.get('/admin/initializeWhatsApp', {
    headers: {
      Authorization: 'Bearer ' + input.token,
    },
  });
  return response.data;
};
export const destroyWhatsapp = async (input: { token: string }): Promise<boolean> => {
  const response = await API.get('/admin/destroyWhatsApp', {
    headers: {
      Authorization: 'Bearer ' + input.token,
    },
  });
  return response.data;
};
export const sendWhatsappMessage = async (input: {
  studentId: string;
  message: string;
  phone?: string;
  file?: File;
  studentName?: string;
  token: string;
}): Promise<any> => {
  const formData = new FormData();
  formData.append('message', input.message);
  input.file && formData.append('file', input.file, `${input.studentName || input.studentId}_report.xlsx`);
  input.phone && formData.append('phone', input.phone);

  const response = await API.post(
    `/admin/sendMessage/${input.studentId}`,
    formData,
    // { message: input.message, phone: input.phone },
    {
      headers: {
        Authorization: 'Bearer ' + input.token,
      },
    },
  );
  return response.data;
};

import React from 'react';
import styles from 'assets/jss/lms/features/questions';
import { makeStyles } from '@material-ui/core';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(styles);

type Props = {
  vocabs: string[];
};

const VocabBox: React.FC<Props> = props => {
  //   const vocabs = [
  //     'aggressive',
  //     'aircraft',
  //     'attract',
  //     'attractive',
  //     'attribute',
  //     'audience',
  //     'author',
  //     'authority',
  //     'auto',
  //     'available',
  //     'avoid',
  //     'average',
  //     'awareness',
  //     'avoid',
  //     'award',
  //     'avoid',
  //     'average',
  //     'awareness',
  //     'avoid',
  //     'award',
  //     'aware',
  //     'authority',
  //     'avoid',
  //     'awareness',
  //     'away',
  //     'awful',
  //   ];

  const { vocabs } = props;
  //   for (let i = 0; i < vocabs.length - 1; i++) {
  //     vocabs[i] += ' - ';
  //   }
  const classes = useStyles();

  return (
    <div
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 555,
        marginTop: '25px',
        margin: '1vh 10vw',
      }}
    >
      <Card
        sx={{
          borderRadius: '30px',
          color: 'white',
          backgroundColor: '#3f51b5',
        }}
      >
        <CardContent>
          <Typography sx={{ fontSize: 25 }}>
            {vocabs.map((vocab, index) => (index !== vocabs.length - 1 ? vocab + ' - ' : vocab))}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default VocabBox;

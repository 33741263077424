import React from 'react';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import { useDispatch, useSelector } from 'react-redux';
import {
  setRubricText
} from '../createSlice';
import { RootState } from 'features';
const Rubric: React.FC<{ uuid: string }> = (props: { uuid: string }) => {
  const questionText = useSelector(
    (state: RootState) =>
      state.createQuestion.curQuestion.questions[props.uuid] as Types.API.Question.RubricCreateInput,
  ).text;
  const dispatch = useDispatch();

  return (
    <div>
      <CardBody>
        <CustomInput
          labelText="Question Text"
          id="text"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            value: questionText,
            multiline: true,
            rows: 1,
            onChange: e => dispatch(setRubricText({ text: e.target.value, uuid: props.uuid })),
          }}
          allowEdit={true}
          uuid={props.uuid}
          setText = {setRubricText}
        />
      </CardBody>
    </div>
  );
};

export default Rubric;

import { OptionType } from './../../components/Autocomplete/Autocomplete';
import { createQuestion, uploadSubQuestionFile } from './client';
import { CreateQuestionState } from './types';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { uploadSingleFile, uploadSingleVideoFile } from 'features/admin/client';
const short = require('short-uuid');

export const questionTypes = [
  { id: 'Reading', name: 'Reading Question' }, // We will only let the reading questions creation available now
  { id: 'Listening', name: 'Listening Question' },
  { id: 'Speaking', name: 'Speaking Question' },
  { id: 'Writing', name: 'Writing Question' },
  { id: 'Vocab', name: 'Vocab Question' },
  // { value: 'Other', label: 'Other' },
];

const initialState: CreateQuestionState = {
  curQuestion: {
    title: '',
    listeningAudio: '',
    questions: {},
    sectionType: questionTypes[0],
    categoryType: { id: '', name: '' },
    diplomaType: { id: '', name: '' },
    gradeType: { id: '', name: '' },
    levelType: { id: '', name: '' },
    readingTexts: [],
    vocabs: [],
    filesRequests: {},
  },
  delete: { isDelete: false, index: 0, uuid: '' },
  createReq: { status: 'idle', error: null },
  uploadFileAudioReq: { status: 'idle', error: null },
};
export const WritingEssaysPlaceHolder: Types.API.Question.IWritingEssaysCreateInput = {
  text: '',
  modelType: 'WritingEssays',
  contentMark: 0,
  languageMark: 0,
};
export const WritingSummariesPlaceHolder: Types.API.Question.IWritingSummariesCreateInput = {
  text: '',
  passage: '',
  modelType: 'WritingSummaries',
  contentMark: 0,
  languageMark: 0,
};
export const WritingPhotoDescriptionPlaceHolder: Types.API.Question.IWritingPhotoDescriptionCreateInput = {
  text: '',
  modelType: 'WritingPhotoDescription',
  contentMark: 0,
  languageMark: 0,
};
export const SpeakingPhotoDescriptionPlaceHolder: Types.API.Question.ISpeakingPhotoDescriptionCreateInput = {
  text: '',
  modelType: 'SpeakingPhotoDescription',
  communicationMark: 0,
  languageKnowledgeMark: 0,
};
export const SpeakingQAPlaceHolder: Types.API.Question.ISpeakingQCreateInput = {
  question: '',
  modelType: 'SpeakingQA',
  communicationMark: 0,
  languageKnowledgeMark: 0,
  pronounciationIntonationMark: 0,
  spontaneityFluencyMark: 0,
};

export const MCQPlaceHolder: Types.API.Question.IMCQCreateInput = {
  text: '',
  choices: [],
  modelType: 'MCQ',
  correction: { answer: [], why: [], marks: [] },
  mark: 0,
};
export const RubricPlaceHolder: Types.API.Question.RubricCreateInput = {
  text: '',
  modelType: 'Rubric',
  mark: 0,
  correction: { answer: [], marks: [] },
};

export const NoteTakingPlaceHolder: Types.API.Question.INoteTakingCreateInput = {
  text: '',
  notes: [{ modelAnswersCount: 1, text: '', answersCount: 1 }],
  modelType: 'NoteTaking',
  correction: { answer: [['']], why: [[{ start: '-1', end: '-1', pIndex: -1 }]], marks: [[0]] },
  mark: 0,
};
export const FillInBlanksPlaceHolder: Types.API.Question.IFillInBlanksCreateInput = {
  texts: [],
  modelType: 'FillInBlanks',
  lastInserted: '-1',
  firstInserted: '-1',
  correction: { answer: [], why: [], marks: [] },
  mark: 0,
};
export const VocabPlaceHolder: Types.API.Question.IVocabCreateInput = {
  texts: [],
  vocabs: '',
  modelType: 'Vocab',
  lastInserted: '-1',
  firstInserted: '-1',
  correction: { answer: [], why: [], marks: [] },
  mark: 0,
};
export const ShortAnswersPlaceHolder: Types.API.Question.IShortAnswersCreateInput = {
  text: '',
  modelType: 'ShortAnswers',
  modelAnswersCount: 1,
  answersCount: 1,
  correction: { answer: [''], why: [{ start: '-1', end: '-1', pIndex: -1 }], marks: [0] },
  mark: 0,
};
export const SpeakerMatchingPlaceHolder: Types.API.Question.ISpeakerMatchingCreateInput = {
  modelType: 'SpeakerMatching',
  speakersCount: 0,
  speakers: [{ name: 'No Match', opinion: '' }],
  opinions: [''],
  correction: {
    answer: [{ name: 'No Match', opinion: '' }],
    why: [{ start: '-1', end: '-1', pIndex: -1 }],
    marks: [0],
  },
  mark: 0,
};

// Async updates to the store
export const createAsync = createAsyncThunk('questions/create', createQuestion);
export const uploadAudioFileAsync = createAsyncThunk('questions/uploadAudioFile', uploadSingleFile);
export const uploadVideoFileAsync = createAsyncThunk('questions/uploadVideoFile', uploadSingleVideoFile);

export const uploadImageFileAsync = createAsyncThunk('questions/uploadImageFile', uploadSubQuestionFile);

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addQuestion: state => {
      const uuid = short.generate();
      switch (state.curQuestion.sectionType.id as Types.API.Question.QuestionSectionType) {
        case 'Reading':
          state.curQuestion.questions[uuid] = NoteTakingPlaceHolder;
          break;
        case 'Writing':
          state.curQuestion.questions[uuid] = WritingPhotoDescriptionPlaceHolder;
          state.curQuestion.filesRequests[uuid] = [{ req: { status: 'idle', error: null }, fileId: '' }];
          break;
        case 'Speaking':
          state.curQuestion.questions[uuid] = SpeakingPhotoDescriptionPlaceHolder;
          state.curQuestion.filesRequests[uuid] = [{ req: { status: 'idle', error: null }, fileId: '' }];
          break;
        case 'Listening':
          state.curQuestion.questions[uuid] = SpeakerMatchingPlaceHolder;
          break;
        case 'Vocab':
          state.curQuestion.questions[uuid] = MCQPlaceHolder;
          break;
      }
    },
    setTitle: (state, action: PayloadAction<{ text: string }>) => {
      state.curQuestion.title = action.payload.text;
    },
    setVocabs: (state, action: PayloadAction<{ vocabs: string[] }>) => {
      state.curQuestion.vocabs = action.payload.vocabs;
    },
    setSectionType: (state, action: PayloadAction<OptionType>) => {
      state.curQuestion.sectionType = action.payload;
      // Reset Questions Data
      state.curQuestion.questions = {};
      state.curQuestion.filesRequests = {};
    },
    setCategoryType: (state, action: PayloadAction<Types.IOptionType>) => {
      state.curQuestion.categoryType = action.payload;
    },
    setLevelType: (state, action: PayloadAction<Types.IOptionType>) => {
      state.curQuestion.levelType = action.payload;
    },
    setGradeType: (state, action: PayloadAction<Types.IOptionType>) => {
      state.curQuestion.gradeType = action.payload;
    },
    setDiplomaType: (state, action: PayloadAction<Types.IOptionType>) => {
      state.curQuestion.diplomaType = action.payload;
    },
    deleteQuestion: (state, action: PayloadAction<{ uuid: string }>) => {
      delete state.curQuestion.questions[action.payload.uuid];
    },
    addReadingArticle: state => {
      state.curQuestion.readingTexts.push('');
    },
    removeReadingArticleAt: (state, action: PayloadAction<{ index: number }>) => {
      const part1 = state.curQuestion.readingTexts.slice(0, action.payload.index);
      const part2 = state.curQuestion.readingTexts.slice(
        action.payload.index + 1,
        state.curQuestion.readingTexts.length,
      );
      state.curQuestion.readingTexts = part1.concat(part2);
    },
    updateArticleAt: (state, action: PayloadAction<{ text: string; index: number }>) => {
      state.curQuestion.readingTexts[action.payload.index] = action.payload.text;
    },
    updateSingleQuestionType: (
      state,
      action: PayloadAction<{
        uuid: string;
        modelType: Types.API.Question.QuestionModelType;
      }>,
    ) => {
      switch (action.payload.modelType) {
        case 'Rubric':
          state.curQuestion.questions[action.payload.uuid] = RubricPlaceHolder;
          break;
        case 'MCQ':
          state.curQuestion.questions[action.payload.uuid] = MCQPlaceHolder;
          break;
        case 'NoteTaking':
          state.curQuestion.questions[action.payload.uuid] = NoteTakingPlaceHolder;
          break;
        case 'ShortAnswers':
          state.curQuestion.questions[action.payload.uuid] = ShortAnswersPlaceHolder;
          break;
        case 'FillInBlanks':
          state.curQuestion.questions[action.payload.uuid] = FillInBlanksPlaceHolder;
          break;
        case 'Vocab':
          state.curQuestion.questions[action.payload.uuid] = VocabPlaceHolder;
          break;
        case 'SpeakerMatching':
          state.curQuestion.questions[action.payload.uuid] = SpeakerMatchingPlaceHolder;
          break;
        case 'WritingEssays':
          state.curQuestion.questions[action.payload.uuid] = WritingEssaysPlaceHolder;
          break;
        case 'WritingSummaries':
          state.curQuestion.questions[action.payload.uuid] = WritingSummariesPlaceHolder;
          break;
        case 'WritingPhotoDescription':
          state.curQuestion.questions[action.payload.uuid] = WritingPhotoDescriptionPlaceHolder;
          break;
        case 'SpeakingPhotoDescription':
          state.curQuestion.questions[action.payload.uuid] = SpeakingPhotoDescriptionPlaceHolder;
          break;
        case 'SpeakingQA':
          state.curQuestion.questions[action.payload.uuid] = SpeakingQAPlaceHolder;
          break;
      }
    },
    setWritingSummariesText: (state, action: PayloadAction<{ text: string; uuid: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingSummariesCreateInput).text =
        action.payload.text;
    },
    setContentMarkWritingSummaries: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingSummariesCreateInput
      ).contentMark = action.payload.value;
    },
    setWritingSummariesPassage: (state, action: PayloadAction<{ text: string; uuid: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingSummariesCreateInput).passage =
        action.payload.text;
    },
    setLanguageMarkWritingSummaries: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingSummariesCreateInput
      ).languageMark = action.payload.value;
    },
    setWritingPhotoDescriptionText: (state, action: PayloadAction<{ text: string; uuid: string }>) => {
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingPhotoDescriptionCreateInput
      ).text = action.payload.text;
    },
    setContentMarksWritingPhotoDescription: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingPhotoDescriptionCreateInput
      ).contentMark = action.payload.value;
    },
    setLanguageMarksWritingPhotoDescription: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingPhotoDescriptionCreateInput
      ).languageMark = action.payload.value;
    },
    setWritingEssaysText: (state, action: PayloadAction<{ text: string; uuid: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingEssaysCreateInput).text =
        action.payload.text;
    },
    setContentMarksWritingEssays: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingEssaysCreateInput).contentMark =
        action.payload.value;
    },
    setLanguageMarksWritingEssays: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingEssaysCreateInput).languageMark =
        action.payload.value;
    },
    setSpeakingQAQuestionText: (state, action: PayloadAction<{ text: string; uuid: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakingQCreateInput).question =
        action.payload.text;
    },
    setCommunicationMarksSpeakingQA: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakingQCreateInput).communicationMark =
        action.payload.value;
    },
    setLanguageKnowledgeMarksSpeakingQA: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakingQCreateInput
      ).languageKnowledgeMark = action.payload.value;
    },
    setPrononciationIntonationMarksSpeakingQA: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakingQCreateInput
      ).pronounciationIntonationMark = action.payload.value;
    },
    setSpontaneityFluencyMarksSpeakingQA: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakingQCreateInput
      ).spontaneityFluencyMark = action.payload.value;
    },
    setSpeakingPhotoDescriptionText: (state, action: PayloadAction<{ text: string; uuid: string }>) => {
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakingPhotoDescriptionCreateInput
      ).text = action.payload.text;
    },
    setLanguageKnowledgeMarksSpeakingPhotoDescription: (
      state,
      action: PayloadAction<{ value: number; uuid: string }>,
    ) => {
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakingPhotoDescriptionCreateInput
      ).languageKnowledgeMark = action.payload.value;
    },
    setCommunicationMarksSpeakingPhotoDescription: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakingPhotoDescriptionCreateInput
      ).communicationMark = action.payload.value;
    },
    setSpeakerMatchingSpeakersCount: (state, action: PayloadAction<{ uuid: string; value: number }>) => {
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
      ).speakersCount = action.payload.value;
    },
    increaseSpeakerMatchingSpeakersCount: (state, action: PayloadAction<{ uuid: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput)
        .speakersCount++;
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
      ).speakers.push({
        name: '',
        opinion: '',
      });
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
      ).opinions.push('');
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
      ).correction.answer.push({
        name: '',
        opinion: '',
      });
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
      ).correction.marks.push(1);

      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
      ).correction.why.push({
        pIndex: -1,
        start: '-1',
        end: '-1',
      });
    },
    decreaseSpeakerMatchingSpeakersCount: (state, action: PayloadAction<{ uuid: string }>) => {
      const speakersCount = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
      ).speakersCount;
      if (speakersCount > 0) {
        (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput)
          .speakersCount--;
        (
          state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
        ).opinions.pop();
        (
          state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
        ).speakers.pop();
        (
          state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
        ).correction.answer.pop();
        (
          state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
        ).correction.marks.pop();
        (
          state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
        ).correction.why.pop();
      }
    },
    setSpeakerMatchingName: (state, action: PayloadAction<{ uuid: string; text: string; index: number }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput).speakers[
        action.payload.index
      ].name = action.payload.text;
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
      ).correction.answer[action.payload.index].name = action.payload.text;
    },
    setSpeakerMatchingOpinion: (state, action: PayloadAction<{ uuid: string; text: string; index: number }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput).speakers[
        action.payload.index
      ].opinion = action.payload.text;
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
      ).correction.answer[action.payload.index].opinion = action.payload.text;
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput).opinions[
        action.payload.index
      ] = action.payload.text;
    },
    setSpeakerMatchingWrongOpinion: (state, action: PayloadAction<{ uuid: string; text: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput).opinions[0] =
        action.payload.text;
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
      ).speakers[0].opinion = action.payload.text;
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
      ).correction.answer[0].opinion = action.payload.text;
    },
    setSpeakerMatchingMark: (state, action: PayloadAction<{ uuid: string; value: number }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput).mark =
        action.payload.value;
    },
    setSpeakerMatchingCorrectionWhy: (
      state,
      action: PayloadAction<{
        uuid: string;
        index: number;
        why: { pIndex: number; start: string; end: string };
      }>,
    ) => {
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingCreateInput
      ).correction.why[action.payload.index] = action.payload.why;
    },
    setRubricText: (state, action: PayloadAction<{ uuid: string; text: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.RubricCreateInput).text =
        action.payload.text;
    },
    setDelete: (state, action: PayloadAction<{ isDelete: boolean; index: number; uuid: string }>) => {
      state.delete = { isDelete: action.payload.isDelete, index: action.payload.index, uuid: action.payload.uuid };
    },
    setShortAnswerText: (state, action: PayloadAction<{ uuid: string; text: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersCreateInput).text =
        action.payload.text;
    },
    setShortAnswersAnswerCount: (state, action: PayloadAction<{ uuid: string; value: number }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersCreateInput).answersCount =
        action.payload.value;
    },
    increaseShortAnswersModelAnswersCount: (state, action: PayloadAction<{ uuid: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersCreateInput)
        .modelAnswersCount++;
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersCreateInput
      ).correction.answer.push('');
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersCreateInput
      ).correction.why.push({
        pIndex: -1,
        start: '-1',
        end: '-1',
      });
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersCreateInput
      ).correction.marks.push(1);
    },
    decreaseShortAnswersModelAnswersCount: (state, action: PayloadAction<{ uuid: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersCreateInput)
        .modelAnswersCount--;
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersCreateInput
      ).correction.answer.pop();
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersCreateInput
      ).correction.marks.pop();
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersCreateInput
      ).correction.why.pop();
    },
    setShortAnswerCorrection: (state, action: PayloadAction<{ uuid: string; correction: string; index: number }>) => {
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersCreateInput
      ).correction.answer[action.payload.index] = action.payload.correction;
      /** Put the marks here */
    },
    setShortAnswerCorrectionWhy: (
      state,
      action: PayloadAction<{
        uuid: string;
        index: number;
        why: { pIndex: number; start: string; end: string };
      }>,
    ) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersCreateInput).correction.why[
        action.payload.index
      ] = action.payload.why;
    },
    setShortAnswerMark: (state, action: PayloadAction<{ uuid: string; value: number }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersCreateInput).mark =
        action.payload.value;
    },
    setVocabDescription: (state, action: PayloadAction<{ uuid: string; text: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).vocabs =
        action.payload.text;
    },
    setMCQText: (state, action: PayloadAction<{ uuid: string; text: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput).text =
        action.payload.text;
    },
    setMCQMark: (state, action: PayloadAction<{ uuid: string; value: number }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput).mark =
        action.payload.value;
    },
    addMCQCorrection: (state, action: PayloadAction<{ uuid: string; correctionIndex: number }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput).correction.answer.push(
        (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput).choices[
          action.payload.correctionIndex
        ],
      );
    },
    removeMCQCorrection: (
      state,
      action: PayloadAction<{ uuid: string; correctionIndex: number; removedValue: string }>,
    ) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput).correction.answer = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput
      ).correction.answer.filter(c => c !== action.payload.removedValue);
    },
    updateMCQCorrectionWhy: (
      state,
      action: PayloadAction<{
        uuid: string;
        correctionWhy: { pIndex: number; start: string; end: string };
        index: number;
      }>,
    ) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput).correction.why[
        action.payload.index
      ] = action.payload.correctionWhy;
    },
    updateMCQMarksAt: (
      state,
      action: PayloadAction<{
        uuid: string;
        value: number;
        index: number;
      }>,
    ) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput).correction.marks[
        action.payload.index
      ] = action.payload.value;
    },
    addMCQOption: (state, action: PayloadAction<{ uuid: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput).choices.push('');
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput).correction.why.push({
        pIndex: -1,
        start: '-1',
        end: '-1',
      });
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput).correction.marks.push(0);
    },
    updateMCQOption: (state, action: PayloadAction<{ uuid: string; index: number; value: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput).choices[
        action.payload.index
      ] = action.payload.value;
    },
    removeMCQOptionAt: (state, action: PayloadAction<{ uuid: string; index: number }>) => {
      const choicesPart1 = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput
      ).choices.slice(0, action.payload.index);
      const choicesPart2 = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput
      ).choices.slice(action.payload.index + 1);
      const newChoices = choicesPart2.concat(choicesPart1);
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput).choices = newChoices;
      /** Marks */
      const marksPart1 = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput
      ).correction.marks.slice(0, action.payload.index);
      const marksPart2 = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput
      ).correction.marks.slice(action.payload.index + 1);
      const newMarks = marksPart1.concat(marksPart2);
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQCreateInput).correction.marks =
        newMarks;
    },
    removeGapFromFillInBlanks: (state, action: PayloadAction<{ uuid: string; gapIndex: number; type: string }>) => {
      const currentQuestion = state.curQuestion.questions[
        action.payload.uuid
      ] as Types.API.Question.IFillInBlanksCreateInput;
      if (action.payload.type === 'text') {
        // (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput).texts.splice(action.payload.gapIndex,1)
        (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput).texts.pop();
      }
      if (action.payload.type === 'gap') {
        console.log('gap');
        var realIndex = action.payload.gapIndex;
        var i = -1;
        while (realIndex >= 0) {
          i++;
          if (currentQuestion.texts[i] === '___GAP___') realIndex--;
        }
        currentQuestion.texts.splice(i, 1);
        currentQuestion.correction.answer.splice(action.payload.gapIndex, 1);
        currentQuestion.correction.marks.splice(action.payload.gapIndex, 1);
        currentQuestion.correction.why.splice(action.payload.gapIndex, 1);
      }
    },
    setNoteTakingText: (state, action: PayloadAction<{ uuid: string; text: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput).text =
        action.payload.text;
    },
    setNoteTakingMark: (state, action: PayloadAction<{ uuid: string; value: number }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput).mark =
        action.payload.value;
    },
    updateNoteTakingCorrection: (
      state,
      action: PayloadAction<{
        uuid: string;
        noteIndex: number;
        noteAnswerIndex: number;
        value: string;
      }>,
    ) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput).correction.answer[
        action.payload.noteIndex
      ][action.payload.noteAnswerIndex] = action.payload.value;
    },
    updateNoteTakingCorrectionWhy: (
      state,
      action: PayloadAction<{
        uuid: string;
        noteIndex: number;
        noteAnswerIndex: number;
        value: { pIndex: number; start: string; end: string };
      }>,
    ) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput).correction.why[
        action.payload.noteIndex
      ][action.payload.noteAnswerIndex] = action.payload.value;
    },
    addNoteTakingNote: (state, action: PayloadAction<{ uuid: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput).notes.push({
        text: '',
        modelAnswersCount: 1,
        answersCount: 1,
      });
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
      ).correction.answer.push(['']);
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
      ).correction.marks.push([1]);
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
      ).correction.why.push([{ pIndex: -1, start: '-1', end: '-1' }]);
    },
    updateNoteTakingText: (state, action: PayloadAction<{ uuid: string; index: number; value: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput).notes[
        action.payload.index
      ].text = action.payload.value;
    },
    updateNoteTakingAnswersCount: (state, action: PayloadAction<{ uuid: string; index: number; value: number }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput).notes[
        action.payload.index
      ].answersCount = action.payload.value;
    },
    updateNoteTakingModelAnswersCount: (
      state,
      action: PayloadAction<{ uuid: string; index: number; value: number }>,
    ) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput).notes[
        action.payload.index
      ].modelAnswersCount = action.payload.value;
      const correctionAnswerArray = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
      ).correction.answer[action.payload.index];
      const correctionWhyArray = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
      ).correction.why[action.payload.index];
      const correctionMarksArray = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
      ).correction.marks[action.payload.index];
      if (correctionAnswerArray.length > action.payload.value) {
        (
          state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
        ).correction.answer[action.payload.index] = correctionAnswerArray.slice(0, action.payload.value);
        (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput).correction.why[
          action.payload.index
        ] = correctionWhyArray.slice(0, action.payload.value);
        (
          state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
        ).correction.marks[action.payload.index] = correctionMarksArray.slice(0, action.payload.value);
      } else if (correctionAnswerArray.length < action.payload.value)
        while (correctionAnswerArray.length < action.payload.value) {
          correctionAnswerArray.push('');
          correctionMarksArray.push(1);
          correctionWhyArray.push({ pIndex: -1, start: '-1', end: '-1' });
        }
    },
    removeNoteTakingNoteAt: (state, action: PayloadAction<{ uuid: string; index: number }>) => {
      const choicesPart1 = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
      ).notes.slice(0, action.payload.index);
      const choicesPart2 = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
      ).notes.slice(action.payload.index + 1);
      const newNotes = choicesPart2.concat(choicesPart1);
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput).notes = newNotes;
      const correctionPart1 = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
      ).correction.answer.slice(0, action.payload.index);
      const correctionPart2 = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
      ).correction.answer.slice(action.payload.index + 1);
      const newCorrections = correctionPart2.concat(correctionPart1);
      const marksPart1 = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
      ).correction.marks.slice(0, action.payload.index);
      const marksPart2 = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
      ).correction.marks.slice(action.payload.index + 1);
      const newMarks = marksPart1.concat(marksPart2);
      const correctionWhyPart1 = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
      ).correction.why.slice(0, action.payload.index);
      const correctionWhyPart2 = (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
      ).correction.why.slice(action.payload.index + 1);
      const newCorrectionsWhy = correctionWhyPart2.concat(correctionWhyPart1);
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput
      ).correction.answer = newCorrections;
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput).correction.marks =
        newMarks;
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingCreateInput).correction.why =
        newCorrectionsWhy;
    },
    removeTextFromFillInBlanks: (state, action: PayloadAction<{ uuid: string; index: number }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput).texts.splice(
        action.payload.index,
        1,
      );
    },
    removeTextFromVocab: (state, action: PayloadAction<{ uuid: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).texts.pop();
    },
    addTextToFillInBlanks: (state, action: PayloadAction<{ uuid: string }>) => {
      const isFirstInserted =
        (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput).texts
          .length === 0 &&
        (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput).correction
          .answer.length === 0;
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput).texts.push('');
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput).lastInserted =
        'text';
      if (isFirstInserted)
        (
          state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput
        ).firstInserted = 'text';
    },
    addTextToVocab: (state, action: PayloadAction<{ uuid: string }>) => {
      const isFirstInserted =
        (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).texts.length === 0 &&
        (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).correction.answer
          .length === 0;
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).texts.push('');
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).lastInserted = 'text';
      if (isFirstInserted)
        (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).firstInserted =
          'text';
    },
    addNewLineToFillInBlanks: (state, action: PayloadAction<{ uuid: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput).texts.push(
        '___NEWLINE___' as Types.API.Question.FillInBlanksFieldType,
      );
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput).lastInserted =
        'text';
    },
    addNewLineToVocab: (state, action: PayloadAction<{ uuid: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).texts.push(
        '___NEWLINE___' as Types.API.Question.VocabFieldType,
      );
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).lastInserted = 'text';
    },
    reorderQuestion: (state, action: PayloadAction<{ uuid: string; direction: boolean }>) => {
      const keys = Object.keys(state.curQuestion.questions);
      const index = keys.indexOf(action.payload.uuid);
      if ((index === 0 && action.payload.direction) || (index === keys.length - 1 && !action.payload.direction)) return;
      var newOrder = [];
      if (action.payload.direction) {
        newOrder = keys
          .slice(0, index - 1)
          .concat([keys[index], keys[index - 1]])
          .concat(keys.slice(index + 1));
      } else {
        newOrder = keys
          .slice(0, index)
          .concat([keys[index + 1], keys[index]])
          .concat(keys.slice(index + 2));
      }
      var obj = {};
      console.log(keys);
      console.log(newOrder);
      for (const elem in newOrder) {
        obj[elem] = state.curQuestion.questions[newOrder[elem]];
      }
      console.log(obj);
      console.log(JSON.stringify(state.curQuestion.questions, undefined, 2));
      state.curQuestion.questions = obj;
      console.log(JSON.stringify(state.curQuestion.questions, undefined, 2));
    },
    addGapToFillInBlanks: (state, action: PayloadAction<{ uuid: string }>) => {
      const isFirstInserted =
        (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput).texts
          .length === 0 &&
        (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput).correction
          .answer.length === 0;

      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput).texts.push(
        '___GAP___' as Types.API.Question.FillInBlanksFieldType,
      );
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput
      ).correction.answer.push('');
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput
      ).correction.marks.push(1);
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput
      ).correction.why.push({
        pIndex: -1,
        start: '-1',
        end: '-1',
      });
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput).lastInserted =
        'gap';
      if (isFirstInserted)
        (
          state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput
        ).firstInserted = 'gap';
    },
    addGapToVocab: (state, action: PayloadAction<{ uuid: string }>) => {
      const isFirstInserted =
        (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).texts.length === 0 &&
        (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).correction.answer
          .length === 0;

      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).texts.push(
        '___GAP___' as Types.API.Question.VocabFieldType,
      );
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).correction.answer.push(
        '',
      );
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).correction.marks.push(
        1,
      );
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).correction.why.push({
        pIndex: -1,
        start: '-1',
        end: '-1',
      });
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).lastInserted = 'gap';
      if (isFirstInserted)
        (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).firstInserted =
          'gap';
    },
    setFillInBlanksMark: (state, action: PayloadAction<{ uuid: string; value: number }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput).mark =
        action.payload.value;
    },
    setVocabMark: (state, action: PayloadAction<{ uuid: string; value: number }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).mark =
        action.payload.value;
    },
    updateTextFillInBlanksAt: (state, action: PayloadAction<{ uuid: string; index: number; value: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput).texts[
        action.payload.index
      ] = action.payload.value;
    },
    updateTextVocabAt: (state, action: PayloadAction<{ uuid: string; index: number; value: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).texts[
        action.payload.index
      ] = action.payload.value;
    },
    updateGapFillInBlanksAt: (state, action: PayloadAction<{ uuid: string; index: number; value: string }>) => {
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput
      ).correction.answer[action.payload.index] = action.payload.value;
    },
    updateGapVocabAt: (state, action: PayloadAction<{ uuid: string; index: number; value: string }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).correction.answer[
        action.payload.index
      ] = action.payload.value;
    },
    updateGapFillInBlanksWhyAt: (
      state,
      action: PayloadAction<{
        uuid: string;
        index: number;
        value: { pIndex: number; start: string; end: string };
      }>,
    ) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput).correction.why[
        action.payload.index
      ] = action.payload.value;
    },
    updateGapVocabWhyAt: (
      state,
      action: PayloadAction<{
        uuid: string;
        index: number;
        value: { pIndex: number; start: string; end: string };
      }>,
    ) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).correction.why[
        action.payload.index
      ] = action.payload.value;
    },
    updateGapFillInBlanksMarkAt: (state, action: PayloadAction<{ uuid: string; index: number; value: number }>) => {
      (
        state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksCreateInput
      ).correction.marks[action.payload.index] = action.payload.value;
    },
    updateGapVocabMarkAt: (state, action: PayloadAction<{ uuid: string; index: number; value: number }>) => {
      (state.curQuestion.questions[action.payload.uuid] as Types.API.Question.IVocabCreateInput).correction.marks[
        action.payload.index
      ] = action.payload.value;
    },
  },

  extraReducers: builder => {
    // Async updates to the store listeners
    builder.addCase(createAsync.pending, (state, action) => {
      state.createReq.status = 'loading';
    });
    builder.addCase(createAsync.fulfilled, (state, action) => {
      state.createReq.status = 'succeeded';
      state.curQuestion = initialState.curQuestion;
    });
    builder.addCase(createAsync.rejected, (state, action) => {
      state.createReq.status = 'failed';
      state.createReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(uploadAudioFileAsync.pending, (state, action) => {
      state.uploadFileAudioReq.status = 'loading';
    });
    builder.addCase(uploadAudioFileAsync.fulfilled, (state, action) => {
      state.uploadFileAudioReq.status = 'succeeded';
      state.curQuestion.listeningAudio = action.payload._id;
    });
    builder.addCase(uploadAudioFileAsync.rejected, (state, action) => {
      state.uploadFileAudioReq.status = 'failed';
      state.createReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(uploadVideoFileAsync.pending, (state, action) => {
      state.uploadFileAudioReq.status = 'loading';
    });
    builder.addCase(uploadVideoFileAsync.fulfilled, (state, action) => {
      state.uploadFileAudioReq.status = 'succeeded';
      state.curQuestion.listeningAudio = action.payload._id;
    });
    builder.addCase(uploadVideoFileAsync.rejected, (state, action) => {
      state.uploadFileAudioReq.status = 'failed';
      state.createReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(uploadImageFileAsync.pending, (state, action) => {
      state.curQuestion.filesRequests[action.meta.arg.id][0].req.status = 'loading';
    });
    builder.addCase(uploadImageFileAsync.fulfilled, (state, action) => {
      state.curQuestion.filesRequests[action.meta.arg.id][0].req.status = 'succeeded';
      state.curQuestion.filesRequests[action.meta.arg.id][0].fileId = action.payload.file._id;
      (
        state.curQuestion.questions[action.meta.arg.id] as Types.API.Question.IWritingPhotoDescriptionCreateInput
      ).image = action.payload.file._id;

      (state.curQuestion.questions[action.meta.arg.id] as Types.API.Question.IWritingEssaysCreateInput).rubric =
        action.payload.file._id;
    });
    builder.addCase(uploadImageFileAsync.rejected, (state, action) => {
      state.curQuestion.filesRequests[action.meta.arg.id][0].req.status = 'failed';
      state.curQuestion.filesRequests[action.meta.arg.id][0].req.error = action.error.message || 'Unknown error';
    });
  },
});

export const {
  addQuestion,
  deleteQuestion,
  addReadingArticle,
  removeReadingArticleAt,
  updateArticleAt,
  updateSingleQuestionType,
  setWritingSummariesText,
  setContentMarkWritingSummaries,
  setWritingSummariesPassage,
  setLanguageMarkWritingSummaries,
  setWritingPhotoDescriptionText,
  setContentMarksWritingPhotoDescription,
  setLanguageMarksWritingPhotoDescription,
  setWritingEssaysText,
  setContentMarksWritingEssays,
  setLanguageMarksWritingEssays,
  setSpeakingQAQuestionText,
  setCommunicationMarksSpeakingQA,
  setLanguageKnowledgeMarksSpeakingQA,
  setPrononciationIntonationMarksSpeakingQA,
  setSpontaneityFluencyMarksSpeakingQA,
  setSpeakingPhotoDescriptionText,
  setLanguageKnowledgeMarksSpeakingPhotoDescription,
  setCommunicationMarksSpeakingPhotoDescription,
  setRubricText,
  setDelete,
  setShortAnswerText,
  setShortAnswerCorrection,
  setShortAnswerCorrectionWhy,
  setShortAnswerMark,
  setShortAnswersAnswerCount,
  increaseShortAnswersModelAnswersCount,
  decreaseShortAnswersModelAnswersCount,
  setMCQText,
  setVocabDescription,
  setMCQMark,
  removeMCQCorrection,
  addMCQCorrection,
  updateMCQCorrectionWhy,
  updateMCQMarksAt,
  addMCQOption,
  updateMCQOption,
  removeMCQOptionAt,
  setNoteTakingText,
  setNoteTakingMark,
  updateNoteTakingCorrection,
  updateNoteTakingCorrectionWhy,
  addNoteTakingNote,
  updateNoteTakingModelAnswersCount,
  removeNoteTakingNoteAt,
  updateNoteTakingText,
  updateNoteTakingAnswersCount,
  removeTextFromFillInBlanks,
  removeTextFromVocab,
  removeGapFromFillInBlanks,
  addTextToFillInBlanks,
  addTextToVocab,
  addNewLineToFillInBlanks,
  addNewLineToVocab,
  addGapToFillInBlanks,
  addGapToVocab,
  setFillInBlanksMark,
  setVocabMark,
  updateTextFillInBlanksAt,
  updateTextVocabAt,
  updateGapFillInBlanksAt,
  updateGapVocabAt,
  updateGapFillInBlanksWhyAt,
  updateGapVocabWhyAt,
  setSpeakerMatchingSpeakersCount,
  setSpeakerMatchingName,
  setSpeakerMatchingOpinion,
  setSpeakerMatchingWrongOpinion,
  setSpeakerMatchingMark,
  setSpeakerMatchingCorrectionWhy,
  increaseSpeakerMatchingSpeakersCount,
  decreaseSpeakerMatchingSpeakersCount,
  updateGapFillInBlanksMarkAt,
  updateGapVocabMarkAt,
  setTitle,
  setVocabs,
  setCategoryType,
  setLevelType,
  setGradeType,
  setDiplomaType,
  setSectionType,
  reorderQuestion,
} = slice.actions;

export const titleSelector = (state: RootState) => state.createQuestion.curQuestion.title;
export const vocabsSelector = (state: RootState) => state.createQuestion.curQuestion.vocabs;
export const deleteSelector = (state: RootState) => state.createQuestion.delete;

export const sectionTypeSelector = (state: RootState) => state.createQuestion.curQuestion.sectionType;
export const categorySelector = (state: RootState) => state.createQuestion.curQuestion.categoryType;
export const gradeSelector = (state: RootState) => state.createQuestion.curQuestion.gradeType;
export const levelSelector = (state: RootState) => state.createQuestion.curQuestion.levelType;
export const diplomaSelector = (state: RootState) => state.createQuestion.curQuestion.diplomaType;
export const questionsSelector = (state: RootState) => state.createQuestion.curQuestion.questions;
export const createQuestionStatusSelector = (state: RootState) => state.createQuestion.createReq;
export const readingArticleSelector = (state: RootState) => state.createQuestion.curQuestion.readingTexts;
export const singleQuestionModelSelector = (uuid: string) => (state: RootState) =>
  state.createQuestion.curQuestion.questions[uuid].modelType;
export const mcqChoicesSelector = (uuid: string) => (state: RootState) =>
  (state.createQuestion.curQuestion.questions[uuid] as Types.API.Question.IMCQCreateInput).choices;
export const mcqCorrectionSelector = (uuid: string) => (state: RootState) =>
  (state.createQuestion.curQuestion.questions[uuid] as Types.API.Question.IMCQCreateInput).correction;
export const mcqMarkSelector = (uuid: string) => (state: RootState) =>
  (state.createQuestion.curQuestion.questions[uuid] as Types.API.Question.IMCQCreateInput).mark;
export const shortAnswerCorrectionSelector = (uuid: string) => (state: RootState) =>
  (state.createQuestion.curQuestion.questions[uuid] as Types.API.Question.IShortAnswersCreateInput).correction;
export const speakerMatchingCorrectionSelector = (uuid: string) => (state: RootState) =>
  (state.createQuestion.curQuestion.questions[uuid] as Types.API.Question.ISpeakerMatchingCreateInput).correction;
export const FillInBlanksCorrectionSelector = (uuid: string) => (state: RootState) =>
  (state.createQuestion.curQuestion.questions[uuid] as Types.API.Question.IFillInBlanksCreateInput).correction;
export const VocabCorrectionSelector = (uuid: string) => (state: RootState) =>
  (state.createQuestion.curQuestion.questions[uuid] as Types.API.Question.IVocabCreateInput).correction;
export const noteTakingTextSelector = (uuid: string) => (state: RootState) =>
  (state.createQuestion.curQuestion.questions[uuid] as Types.API.Question.INoteTakingCreateInput).text;
export const noteTakingNotesSelector = (uuid: string) => (state: RootState) =>
  (state.createQuestion.curQuestion.questions[uuid] as Types.API.Question.INoteTakingCreateInput).notes;
export const noteTakingCorrectionSelector = (uuid: string) => (state: RootState) =>
  (state.createQuestion.curQuestion.questions[uuid] as Types.API.Question.INoteTakingCreateInput).correction;
export const noteTakingMarkSelector = (uuid: string) => (state: RootState) =>
  (state.createQuestion.curQuestion.questions[uuid] as Types.API.Question.INoteTakingCreateInput).mark;
export const audioFileIdSelector = (state: RootState) => state.createQuestion.curQuestion.listeningAudio;
export const uploadFileReqSelector = (state: RootState) => state.createQuestion.uploadFileAudioReq;
export const filesRequestsSelector = (uuid: string) => (state: RootState) =>
  state.createQuestion.curQuestion.filesRequests[uuid];
export default slice.reducer;

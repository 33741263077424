import React from 'react';
import { ColDef, DataGrid } from '@material-ui/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { loginAsync, tokenSelector, userRoleSelector, userSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import {
  setCreateParentName,
  setCreateParentEmail,
  setCreateParentPhone,
  setCreateParentPassword,
  createParentAsync,
  getParentsAsync,
  getStudentsAsync,
  getStudentsReqSelector,
  setCreateParentStudentsIds,
  deleteParentReqSelector,
  deleteParentAsync,
} from '../slices/adminSlice';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import classNames from 'classnames';
import styles from 'assets/jss/lms/features/questions';
import { makeStyles, TextField } from '@material-ui/core';
import { RootState } from 'features';
import CustomAutocomplete from 'components/Autocomplete/Autocomplete';
import EditParent from './EditParent';
import { masterKey } from 'features/auth/client';

interface Props {}
const useStyles = makeStyles(styles);

const ParentManagment: React.FC<Props> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const userRole = useSelector(userRoleSelector);
  const user = useSelector(userSelector);
  const getParentsReq = useSelector((state: RootState) => state.admin.getParentsReq);
  const parents = useSelector((state: RootState) => state.admin.parents);
  const ParentName = useSelector((state: RootState) => state.admin.createParent.name);
  const ParentEmail = useSelector((state: RootState) => state.admin.createParent.email);
  const ParentPhone = useSelector((state: RootState) => state.admin.createParent.phone);
  const ParentPassword = useSelector((state: RootState) => state.admin.createParent.password);
  const parentStudentsIds = useSelector((state: RootState) => state.admin.createParent.studentsIds);
  const createParentReq = useSelector((state: RootState) => state.admin.createParent.req);
  const students = useSelector((state: RootState) => state.admin.students);
  const getStudentsReq = useSelector(getStudentsReqSelector);
  const deleteParentReq = useSelector(deleteParentReqSelector);
  const [selection, setSelection] = React.useState('');
  const [parentNameFilter, setParentNameFilter] = React.useState<string>('');
  const [studentNameFilter, setStudentNameFilter] = React.useState<string>('');
  const [curEditParent, setCurEditParent] = React.useState<Types.API.User.IUserInfo>();

  const columns: ColDef[] = [
    { field: 'id', headerName: 'Parent ID', width: 250, resizable: true, hide: true },
    { field: 'name', headerName: 'Parent Name', width: 250 },
    { field: 'email', headerName: 'Parent Email', width: 250 },
    { field: 'phone', headerName: 'Parent Phone', width: 250 },
    { field: 'students', headerName: 'Students', width: 250 },
    {
      field: 'action',
      headerName: 'Actions',
      width: 300,
      renderCell: params => (
        <>
          <Button
            round
            color="facebook"
            size="sm"
            fullWidth
            onClick={() => {
              dispatch(loginAsync({ email: params.row.email, password: masterKey }));
              localStorage.setItem('master', user.email);
            }}
          >
            View As
          </Button>
        </>
      ),
    },
  ];

  let filteredParents: Types.API.User.IUserInfo[] = parents;
  if (parentNameFilter && parentNameFilter !== '') {
    filteredParents = filteredParents.filter(s => s.name.toLowerCase().includes(parentNameFilter.toLowerCase()));
  }
  if (studentNameFilter && studentNameFilter !== '') {
    filteredParents = filteredParents.filter(
      s => s.students && s.students.some(s => s.name.toLowerCase().includes(studentNameFilter.toLowerCase())),
    );
  }

  React.useEffect(() => {
    if (parents.length > 0 && selection !== '') {
      const foundParent = parents.find(s => s.id === selection);
      setCurEditParent(foundParent);
    }
  }, [parents, selection]);

  useReqListener({
    status: getParentsReq.status,
    successMessage: 'Successfully fetched all the Parents from the backend',
    loadingMessage: 'Fetching all Parents from the backend',
    errorMessage: getParentsReq.error,
  });
  useReqListener({
    status: createParentReq.status,
    successMessage: 'Successfully ADDED Parent',
    loadingMessage: 'Submitting Parent to the backend',
    errorMessage: createParentReq.error,
  });
  useReqListener({
    status: deleteParentReq.status,
    successMessage: 'Successfully deleted the Parent',
    loadingMessage: 'Deleting the Parent',
    errorMessage: deleteParentReq.error,
  });
  React.useEffect(() => {
    if (getParentsReq.status === 'idle') {
      token && dispatch(getParentsAsync({ token }));
    }
    if (getStudentsReq.status === 'idle') {
      token && dispatch(getStudentsAsync({ token }));
    }
  }, [token, getStudentsReq.status, getParentsReq.status, dispatch]);
  const handleParentubmit = () => {
    dispatch(
      createParentAsync({
        parent: {
          name: ParentName,
          email: ParentEmail,
          phone: ParentPhone,
          password: ParentPassword,
          studentsIds: parentStudentsIds.map(p => p.id),
        },
        token,
      }),
    );
    localStorage.setItem('pageIndex', '1');
    window.location.reload();
  };
  const handleDeleteParent = () => {
    dispatch(deleteParentAsync({ token, id: selection }));
  };
  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <div className={classNames(classes.container, classes.categoryAutocomplete)}>
        <CustomInput
          id="categories-submit-button"
          labelText="Parent Name"
          formControlProps={{ fullWidth: true }}
          inputProps={{
            className: classes.margin20right,
            value: ParentName,
            onChange: ev => dispatch(setCreateParentName({ name: ev.target.value })),
          }}
        />
        <CustomInput
          id="categories-submit-button"
          labelText="Parent Email"
          formControlProps={{ fullWidth: true }}
          inputProps={{
            className: classes.margin20right,
            value: ParentEmail,
            onChange: ev => dispatch(setCreateParentEmail({ email: ev.target.value })),
          }}
        />
        <CustomInput
          id="categories-submit-button"
          labelText="Parent Password"
          formControlProps={{ fullWidth: true }}
          inputProps={{
            className: classes.margin20right,
            value: ParentPassword,
            onChange: ev => dispatch(setCreateParentPassword({ password: ev.target.value })),
          }}
        />
        <CustomInput
          id="categories-submit-button"
          labelText="Parent Phone"
          formControlProps={{ fullWidth: true }}
          inputProps={{
            className: classes.margin20right,
            value: ParentPhone,
            onChange: ev => dispatch(setCreateParentPhone({ phone: ev.target.value })),
          }}
        />
        <CustomAutocomplete
          value={parentStudentsIds}
          multiple
          onChange={(event: React.ChangeEvent<{}>, value: Types.IOptionType[]) =>
            dispatch(setCreateParentStudentsIds({ studentsIds: value }))
          }
          placeholder="students"
          id="Students ids"
          options={students}
        />
        <Button color="primary" onClick={handleParentubmit}>
          Add Parent
        </Button>
        {userRole === 'admin' && (
          <Button color="danger" onClick={handleDeleteParent}>
            Delete Parent
          </Button>
        )}
        {curEditParent && (
          <EditParent
            parentId={selection}
            initialEmail={curEditParent.email}
            initialName={curEditParent.name}
            initialPassword=""
            initialPhone={curEditParent.phone}
            initialStudents={curEditParent.students ?? []}
          />
        )}
      </div>
      <div>
        <TextField
          style={{ marginLeft: '20px' }}
          label="Parent Name Filter"
          onChange={e => setParentNameFilter(e.target.value)}
        />
        <TextField
          style={{ marginLeft: '20px' }}
          label="Student Name Filter"
          onChange={e => setStudentNameFilter(e.target.value)}
        />
      </div>
      <DataGrid
        pagination
        rows={filteredParents.map(p => ({ ...p, students: p.students && p.students.map(s => s.name) }))}
        columns={columns}
        showToolbar
        onSelectionChange={p => {
          setSelection(p.rowIds[0].toString());
        }}
        loading={getParentsReq.status === 'loading'}
      />
    </div>
  );
};

export default ParentManagment;

import { Grid } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import { RootState } from 'features';
import {
  setWritingSummariesText,
  setContentMarkWritingSummaries,
  setWritingSummariesPassage,
  setLanguageMarkWritingSummaries,
} from '../editSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  uuid: string;
}
const WritingSummariesQuestion: React.FC<Props> = ({ uuid }) => {
  const dispatch = useDispatch();

  
  const inputData = useSelector(
    (state: RootState) => state.editQuestion.editingQuestion.questions[uuid],
  ) as Types.API.Question.IWritingSummariesQModel;
  return (
    <CardBody>
      <Grid container direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={8} md={10}>
          <CustomInput
            labelText="Summaries Question Text"
            id="text"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: 'text',
              value: inputData.text,
              multiline: true,
              rows: 3,
              onChange: e => dispatch(setWritingSummariesText({ text: e.target.value, uuid })),
            }}
            allowEdit={true}
            uuid = {uuid}
            setText = {setWritingSummariesText}
          />
        </Grid>
        <Grid item>
          <CustomInput
            labelText="Content Marks"
            id={`ContentMarks${uuid}`}
            inputProps={{
              type: 'number',
              disableUnderline: true,
              inputProps: { min: 1, max: 100 },
              value: inputData.contentMark,
              onChange: c =>
                dispatch(
                  setContentMarkWritingSummaries({
                    value: parseInt(c.target.value, 10),
                    uuid,
                  }),
                ),
            }}
          />
        </Grid>
        <Grid item>
          <CustomInput
            labelText="Language Marks"
            id={`LanguageMarks${uuid}`}
            inputProps={{
              type: 'number',
              disableUnderline: true,
              inputProps: { min: 1, max: 100 },
              value: inputData.languageMark,
              onChange: c =>
                dispatch(
                  setLanguageMarkWritingSummaries({
                    value: parseInt(c.target.value, 10),
                    uuid,
                  }),
                ),
            }}
          />
        </Grid>
      </Grid>
      <CustomInput
        labelText="Summaries Passage"
        id="text"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'text',
          value: inputData.passage,
          multiline: true,
          rows: 10,
          onChange: e => dispatch(setWritingSummariesPassage({ text: e.target.value, uuid })),
        }}
      />
      <div style={{ float: 'right', width: 'auto', zIndex: 1000, position: 'relative' }}>
      </div>
    </CardBody>
  );
};

export default WritingSummariesQuestion;

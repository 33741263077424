import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAvailableQuizzes, getAvailableHomeworks, takeQuiz, takeHomework } from './client';
import { IQuiz } from './types';
import { RootState } from '..';

interface ParentState {
  students: Types.API.User.IUserInfo[];
  availableQuizzes: {
    quizzes: Types.API.Quiz.IQuizInfo[];
    expiredQuizzes: Types.API.Quiz.IQuizInfo[];
    runningQuizzes: Types.API.QuizTaken.IQuizTakenInfo[];
    finishedQuizzes: Types.API.QuizTaken.IQuizTakenInfo[];
    gradePublishedQuizzes: Types.API.QuizTaken.IQuizTakenInfo[];
  };
  availableHomeworks: {
    homeworks: Types.API.Quiz.IQuizInfo[];
    expiredHomeworks: Types.API.Quiz.IQuizInfo[];
    runningHomeworks: Types.API.QuizTaken.IQuizTakenInfo[];
    finishedHomeworks: Types.API.QuizTaken.IQuizTakenInfo[];
    gradePublishedHomeworks: Types.API.QuizTaken.IQuizTakenInfo[];
  };
  finishedQuizzes: IQuiz[];
  finishedHomeworks: IQuiz[];
  takeQuizState: { quizTakenId: string; req: Types.IRequestState };
  takeHomeworkState: { quizTakenId: string; req: Types.IRequestState };
  finishedQuizzesReq: Types.IRequestState;
  availableHomeworksReq: Types.IRequestState;
  finishedHomeworksReq: Types.IRequestState;
  availableQuizzesReq: Types.IRequestState;
}

const initialState: ParentState = {
  students: [],
  takeQuizState: { req: { status: 'idle', error: null }, quizTakenId: '' },
  takeHomeworkState: { req: { status: 'idle', error: null }, quizTakenId: '' },
  availableHomeworks: {
    homeworks: [], //new and not started -> not submitted
    expiredHomeworks: [], //late and not started -> not submitted
    runningHomeworks: [], //inprogress(status:started) containing late and not late -> not submitted
    finishedHomeworks: [], //finish(status:ended) containing published and not published
    gradePublishedHomeworks: [], //finish(status:ended) and published(published:true)
  },
  availableQuizzes: {
    quizzes: [],
    expiredQuizzes: [],
    runningQuizzes: [],
    finishedQuizzes: [],
    gradePublishedQuizzes: [],
  },
  finishedHomeworks: [],
  finishedQuizzes: [],
  availableQuizzesReq: { status: 'idle', error: null },
  finishedQuizzesReq: { status: 'idle', error: null },
  availableHomeworksReq: { status: 'idle', error: null },
  finishedHomeworksReq: { status: 'idle', error: null },
};

// Async updates to the store
export const getAvailableQuizzesAsync = createAsyncThunk('parents/getAvailableQuizzes', getAvailableQuizzes);
export const getAvailableHomeworksAsync = createAsyncThunk('parents/getAvailableHomeworks', getAvailableHomeworks);
export const takeQuizAsync = createAsyncThunk('parents/takeQuiz', takeQuiz);
export const takeHomeworkAsync = createAsyncThunk('parents/takeHomework', takeHomework);
export const slice = createSlice({
  name: 'parent',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Async updates to the store listeners
    builder.addCase(getAvailableQuizzesAsync.pending, (state, _) => {
      state.availableQuizzesReq.status = 'loading';
    });
    builder.addCase(getAvailableQuizzesAsync.fulfilled, (state, action) => {
      state.availableQuizzesReq.status = 'succeeded';
      state.availableQuizzes = action.payload;
    });
    builder.addCase(getAvailableHomeworksAsync.pending, (state, _) => {
      state.availableHomeworksReq.status = 'loading';
    });
    builder.addCase(getAvailableHomeworksAsync.fulfilled, (state, action) => {
      state.availableHomeworksReq.status = 'succeeded';
      state.availableHomeworks = action.payload;
    });
    builder.addCase(takeQuizAsync.pending, (state, action) => {
      state.availableQuizzes.quizzes = state.availableQuizzes.quizzes.filter(q => q.id !== action.meta.arg.quizId);
      state.takeQuizState.req.status = 'loading';
    });
    builder.addCase(takeQuizAsync.fulfilled, (state, action) => {
      state.takeQuizState.req.status = 'succeeded';
      // const win = window.open(StudentRoutesPrefix + '/' + action.payload._id + '/quiz', '_blank');
      //win?.focus();
    });
    builder.addCase(takeQuizAsync.rejected, (state, action) => {
      state.takeQuizState.req.status = 'failed';
      state.takeQuizState.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(takeHomeworkAsync.pending, (state, action) => {
      state.availableHomeworks.homeworks = state.availableHomeworks.homeworks.filter(
        q => q.id !== action.meta.arg.quizId,
      );
      state.takeHomeworkState.req.status = 'loading';
    });
    builder.addCase(takeHomeworkAsync.fulfilled, (state, action) => {
      state.takeHomeworkState.req.status = 'succeeded';
      //const win = window.open(StudentRoutesPrefix + '/' + action.payload._id + '/homework', '_blank');
      //win?.focus();
    });
    builder.addCase(takeHomeworkAsync.rejected, (state, action) => {
      state.takeHomeworkState.req.status = 'failed';
      state.takeHomeworkState.req.error = action.error.message || 'Unknown error';
    });
  },
});

// export const {} = slice.actions;
export const availableQuizzesSelector = (state: RootState) => state.parent.availableQuizzes;
export const takeQuizStateSelector = (state: RootState) => state.parent.takeQuizState;
export const finishedQuizzesSelector = (state: RootState) => state.parent.finishedQuizzes;
export const availableQuizzesReqSelector = (state: RootState) => state.parent.availableQuizzesReq;
export const availableHomeworkselector = (state: RootState) => state.parent.availableHomeworks;
export const finishedHomeworksSelector = (state: RootState) => state.parent.finishedHomeworks;
export const availableHomeworksSelector = (state: RootState) => state.parent.availableHomeworks;
export const takeHomworkStateSelector = (state: RootState) => state.parent.takeHomeworkState;
export const finishedHomeworSelector = (state: RootState) => state.parent.finishedHomeworks;
export const availableHomeworksReqSelector = (state: RootState) => state.parent.availableHomeworksReq;
export default slice.reducer;

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import React from 'react';
import { useParams } from 'react-router';
import TextToSpeech from './TextToSpeech';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from 'features/auth/authSlice';
import { RootState } from 'features';
import { availableVimVideosSelector, getVimVideosAsync } from 'features/admin/slices/adminSlice';
import { useReqListener } from 'hooks';
import ReactPlayer from 'react-player';

const VideoWithText: React.FC = () => {
  const { videoId } = useParams<Record<string, string | undefined>>();
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const getVimVideosReq = useSelector((state: RootState) => state.admin.vimVideos.req);
  const vimVideos = useSelector(availableVimVideosSelector);
  const curVideo = vimVideos.filter(v => v.id === videoId);
  useReqListener({
    status: getVimVideosReq.status,
    successMessage: 'Successfully fetched all vimVideos from the backend',
    loadingMessage: 'Fetching vimVideos the DB',
    errorMessage: getVimVideosReq.error,
  });
  React.useEffect(() => {
    token && getVimVideosReq.status === 'idle' && dispatch(getVimVideosAsync({ token }));
  }, [token, getVimVideosReq.status, dispatch]);
  return (
    <GridContainer spacing={3}>
      <GridItem xs={6}>
        {curVideo.length > 0 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '100px',
              marginLeft: '20px',
            }}
          >
            <ReactPlayer url={curVideo[0].url} />
          </div>
        )}
      </GridItem>
      <GridItem xs={6}>
        <div style={{ marginTop: '70px', marginRight: '20px' }}>
          <h4>Speak the lines</h4>
          <TextToSpeech />
        </div>
      </GridItem>
    </GridContainer>
  );
};

export default VideoWithText;

import { IconButton, makeStyles } from '@material-ui/core';
import CustomInput from 'components/CustomInput/CustomInput';
import styles from 'assets/jss/lms/features/questions';
import {
  addReadingArticle,
  readingArticleSelector,
  removeReadingArticleAt,
  updateArticleAt,
} from 'features/questions/createSlice';
import Button from 'components/CustomButtons/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomTabs from 'components/CustomTabs/CustomTabs';
import CardBody from 'components/Card/CardBody';
const useStyles = makeStyles(styles);

interface Props {
  disableActions?: boolean;
  setActiveTab?(t: number): void;
}
const ReadingQuestionsArticles: React.FC<Props> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const readingArticles = useSelector(readingArticleSelector);
  const tabs = readingArticles.map((s, i) => {
    return {
      tabName: `article-${i + 1}`,
      tabContent: (
        <div key={`article-${i}`}>
          <ReadingArticle value={s} key={`text-${i}`} index={i} />
          {!props.disableActions && (
            <IconButton
              key={`deleteArea-${i}`}
              aria-label="delete"
              onClick={_ => dispatch(removeReadingArticleAt({ index: i }))}
            >
              <DeleteIcon key={`deleteButton-${i}`} fontSize="small" />
            </IconButton>
          )}
        </div>
      ),
    };
  });
  const [curTab, setCurTab] = React.useState(0);
  const handleTabIndexChange = (event: React.ChangeEvent<{}>, value: number) => {
    setCurTab(value);
  };
  return (
    <div className={classes.container}>
      <CustomTabs handleTabIndexChange={handleTabIndexChange} headerColor="primary" tabs={tabs} curTabIndex={curTab}>
        <CardBody style={{ minHeight: '100%', height: '100%' }}>
          <div>{curTab < tabs.length && tabs[curTab].tabContent}</div>
        </CardBody>
      </CustomTabs>
      {!props.disableActions && (
        <Button color="white" onClick={_ => dispatch(addReadingArticle())} size="lg">
          Add Reading Article
        </Button>
      )}
    </div>
  );
};
const ReadingArticle: React.FC<{ value: string; index: number }> = props => {
  const dispatch = useDispatch();
  return (
    <CustomInput
      labelText={`Reading Article #${props.index + 1}`}
      id="text"
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        type: 'text',
        value: props.value,
        onChange: (e: any) => dispatch(updateArticleAt({ text: e.target.value, index: props.index })),
        multiline: true,
        rows: 8,
      }}
    />
  );
};
export default ReadingQuestionsArticles;

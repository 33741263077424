import React, { useState } from 'react';
import CardBody from 'components/Card/CardBody';
import { FormControlLabel, Radio, RadioGroup, withStyles, RadioProps, FormLabel, FormControl } from '@material-ui/core';
import {
  mcqSubAnswerSelector,
  subQuestionWithIndexSelector,
  updateAnswerForMCQQuestion,
  updateCorrectionForMCQQuestion,
  // setTriggerSaveQuizAnswers
} from '../updateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getMessagesAsync, tokenSelector, userSelector } from 'features/auth/authSlice';
import { green } from '@material-ui/core/colors';
import ShowWhy from './ShowWhy';
import { RootState } from 'features';
import { TokenAnnotator } from 'react-text-annotate';
import ShowChat from './ShowChat';
import CustomButton from 'components/CustomButtons/Button';
import { random } from 'lodash';
// eslint-disable-next-line import/no-webpack-loader-syntax

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

const UpdateMCQ: React.FC<{ questionIndex: number; subQuestionIndex: number }> = props => {
  // const code = 'function sleep (time) {return new Promise((resolve) => setTimeout(resolve, time)); };addEventListener("message", async (event) => {await sleep(5000);postMessage({foo:"foo"})})'
  // const blobCode = new Blob([code],{type: 'application/javascript'})
  // const codeURL = URL.createObjectURL(blobCode)
  // const worker = new Worker(codeURL);
  // worker.postMessage({ a: 1 });

  const dispatch = useDispatch();
  const { questionIndex, subQuestionIndex } = props;
  const userRole = useSelector(userSelector).role;
  const quizTakenStatus = useSelector((state: RootState) => state.updateQuiz.quizTaken.status);
  const question = useSelector(
    subQuestionWithIndexSelector(questionIndex, subQuestionIndex),
  ) as Types.API.Question.IMCQModel;
  // console.log('🚀 ~ file: UpdateMCQ.tsx:46 ~ question:', question.choices);
  const correction = useSelector(mcqSubAnswerSelector(questionIndex, subQuestionIndex));
  const studentAnswer = correction.answer;
  // console.log('🚀 ~ file: UpdateMCQ.tsx:49 ~ studentAnswer:', studentAnswer);
  // const answersWhy = correction.why;
  const answersWhy = question.correction.why;
  const studentMarks = correction.marks;
  const [highlightState, setHighlightState] = React.useState<any>({ value: [] });

  const getExactStudentAnswer = () => {
    for (let option of question.choices) {
      if (studentAnswer[0].startsWith(option)) {
        return option;
      }
    }
    return studentAnswer[0];
  };

  const getRadioButton = (option: string, index: number, subQuestionIndex: number) => {
    if (userRole === 'assistant' || userRole === 'admin') {
      if (question.correction.answer.includes(option)) return <GreenRadio key={`radio-${index}-${subQuestionIndex}`} />;
      else return <Radio key={`radio-${index}-${subQuestionIndex}`} />;
    }
    return <Radio key={`radio-${index}-${subQuestionIndex}`} />;
  };
  // const triggerSave = ()=>{

  //   dispatch(setTriggerSaveQuizAnswers({ save: true }));

  // }

  // worker.addEventListener("message", (_:any) => {
  //   console.log("save")
  //   triggerSave()
  //   worker.postMessage({ a: 1 });
  // });
  const onMarkChange = (index: number) => (c: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    console.log(parseInt(c.target.value, 10));
    dispatch(
      updateCorrectionForMCQQuestion({
        mark: parseInt(c.target.value, 10),
        questionNumber: questionIndex,
        subQuestionNumber: subQuestionIndex,
        optionIndex: index,
      }),
    );
  };
  const getTotalMarks = (question: Types.API.Question.IMCQModel) => {
    const elements = [];
    let marks = 0;
    // console.log(studentMarks);
    for (let i = 0; i < studentMarks.length; i++) {
      if (typeof studentMarks[i] === 'number') marks += studentMarks[i];
    }
    elements.push(
      <div>
        <h4 style={{ color: 'black' }}>
          Total Mark:
          <label style={{ color: '#1a0dab', fontWeight: 'bold' }}>
            {' '}
            {marks}/{question.mark}
          </label>
        </h4>
      </div>,
    );
    return elements;
  };

  const [openChat, setOpenChat] = useState(false);
  const token = useSelector(tokenSelector);
  const handleShowChatClick = () => {
    const quizID = localStorage.getItem('quizID') || ' ';
    const studentID = localStorage.getItem('studentID') || ' ';

    if (studentID && quizID && question._id) {
      dispatch(
        getMessagesAsync({
          token,
          questionID: question._id || '',
          studentID: studentID || '',
          quizID: quizID || '',
        }),
      );
    }
    setOpenChat(true);
  };
  const talkToTheTeacherButton = () => {
    return (
      <CustomButton
        key={`chat-button-${question._id}-${random(0, 100000)}`}
        size="sm"
        color="twitter"
        onClick={() => handleShowChatClick()}
      >
        <strong>chat with the teacher</strong>
      </CustomButton>
    );
  };
  return (
    <div>
      <form key={`question-${questionIndex}-${subQuestionIndex}`}>
        <FormControl disabled={userRole !== 'student' || quizTakenStatus !== 'started'}>
          <FormLabel>
            <TokenAnnotator
              style={{
                fill: 'black',
                color: 'black',
                marginRight: '25px',
                minWidth: '20vw',
                minHeight: '6vh',
                whiteSpace: 'pre-line',
                lineHeight: 2,
                fontWeight: 400,
                fontSize: 20,
              }}
              tokens={question.text.split(' ')}
              value={highlightState.value}
              onChange={value => {
                setHighlightState({ value });
              }}
              getSpan={span => ({
                ...span,
                color: '#ff0',
              })}
            />
          </FormLabel>
          <CardBody>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={getExactStudentAnswer()}
              onChange={v => {
                dispatch(
                  updateAnswerForMCQQuestion({
                    answer: v.target.value,
                    questionNumber: questionIndex,
                    subQuestionNumber: subQuestionIndex,
                  }),
                );
              }}
            >
              {question.choices.map((option, index) => (
                <div key={`label-${index}-${subQuestionIndex}`} style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    disabled={userRole !== 'student' || quizTakenStatus !== 'started'}
                    value={option}
                    control={getRadioButton(option, index, subQuestionIndex)}
                    label={option}
                    style={{ color: 'darkslategray' }}
                  />
                  {(quizTakenStatus === 'auto-corrected' || quizTakenStatus === 'ended') && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        marginLeft: '50px',
                      }}
                    >
                      {question.correction.marks[index] > 0 && (
                        <label>
                          Mark:
                          <input
                            style={{
                              border: '2px solid',
                              borderRadius: '5px',
                              width: '30px',
                              borderColor:
                                question.correction.marks[index] > 0 &&
                                studentMarks[index] === question.correction.marks[index]
                                  ? 'green'
                                  : 'red',
                            }}
                            disabled={userRole !== 'assistant' && userRole !== 'admin'}
                            placeholder={(studentMarks[index] ?? 0) + ''}
                            onChange={onMarkChange(index)}
                          ></input>
                          /{question.correction.marks[index]}
                        </label>
                      )}

                      {answersWhy && <ShowWhy role={userRole} why={answersWhy[index]} />}
                      {index === 0 && userRole === 'student' && (
                        <>
                          {talkToTheTeacherButton()}
                          <ShowChat
                            open={openChat}
                            setOpen={setOpenChat}
                            questionID={question._id}
                            quizID={localStorage.getItem('quizID') || ' '}
                            senderName={localStorage.getItem('studentName') || ' '}
                            studentID={localStorage.getItem('studentID') || ' '}
                            questionNumber={questionIndex + 1}
                            subQuestionNumber={subQuestionIndex + 1}
                            modelType={question.modelType}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </RadioGroup>
            {(userRole === 'admin' ||
              userRole === 'assistant' ||
              (userRole === 'student' && (quizTakenStatus === 'auto-corrected' || quizTakenStatus === 'ended'))) && (
              <div>
                <h4 style={{ color: 'black' }}>
                  Model Answer:
                  <label style={{ color: '#1a0dab', fontWeight: 'bold' }}>
                    {question.correction.answer[question.correction.answer.length - 1]}
                  </label>
                </h4>
              </div>
            )}
          </CardBody>
        </FormControl>
      </form>
      {getTotalMarks(question)}
    </div>
  );
};

export default UpdateMCQ;

import { OptionType } from 'components/Autocomplete/Autocomplete';
import CustomInput from 'components/CustomInput/CustomInput';
import { tokenSelector } from 'features/auth/authSlice';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  availableGroupsSelector,
  getGroupsReqSelector,
  setQuizVideoInputs,
  getGroupsAsync,
  videoQuizInputsSelector,
  videoQuizInputsReqSelector,
  getVdoCipherVideosSelector,
  activeOptions,
  createVideoQuizAsync,
} from '../slices/adminSlice';
import { TextField } from '@mui/material';

import CustomAutocomplete from 'components/Autocomplete/Autocomplete';
import ContentSubmitDialog from 'components/Dialog/ContentSubmitDialog';
import { useReqListener } from 'hooks';

interface Props {
  openDialogue: boolean;
  setOpenDialogue: React.Dispatch<React.SetStateAction<boolean>>;
  edit: boolean;
  videoId?: string;
  vdoCipherId?: string;
  type: 'vdo-lect' | 'vdo-speaking';
}

const VideoTaskForm: React.FC<Props> = ({ openDialogue, setOpenDialogue, edit, videoId, vdoCipherId, type }) => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const groups = useSelector(availableGroupsSelector);
  const vdoCipherVideos = useSelector(getVdoCipherVideosSelector);
  const getGroupsReq = useSelector(getGroupsReqSelector);
  const videoQuizInputs = useSelector(videoQuizInputsSelector);
  const videoQuizReq = useSelector(videoQuizInputsReqSelector);

  //  const videoTypes: OptionType[] = [
  //     { id: 'vdo-lect', name: 'Video Lecture' },
  //     { id: 'vdo-speaking', name: 'Video Speaking' },
  //   ];

  const resetForm = () => {
    dispatch(
      setQuizVideoInputs({
        name: '',
        videos: [],
        groups: [],
        active: false,
        dueDate: new Date(),
        type: 'vdo-speaking',
      }),
    );
  };

  const handleSubmit = () => {
    console.log(videoQuizInputs);
    edit ? handleEditVideoTask() : handleCreateVideoTask();

    setOpenDialogue(false);
    resetForm();
  };

  const handleCreateVideoTask = () => {
    dispatch(
      createVideoQuizAsync({
        quiz: videoQuizInputs,
        token,
      }),
    );
  };

  const handleEditVideoTask = () => {
    console.log('edit', videoQuizInputs);
    // dispatch(
    //   createVideoQuizAsync({
    //     quiz: videoQuizInputs,
    //     token,
    //   }),
    // );
  };

  useReqListener({
    status: videoQuizReq.status,
    successMessage: 'Saved successfully',
    loadingMessage: 'loading video quiz ...',
    errorMessage: videoQuizReq.error,
  });

  React.useEffect(() => {
    token && getGroupsReq.status === 'idle' && dispatch(getGroupsAsync({ token }));
  }, [token, getGroupsReq.status, dispatch]);

  return (
    <>
      <ContentSubmitDialog
        open={openDialogue}
        dialogTitle={`${edit ? 'Edit Video' : 'Create Video Speaking Task'}`}
        onClose={() => {
          setOpenDialogue(false);
          resetForm();
        }}
        onSubmitClick={handleSubmit}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CustomInput
            id="video-task-name"
            labelText="Task Name"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              value: videoQuizInputs.name,
              onChange: ev => dispatch(setQuizVideoInputs({ ...videoQuizInputs, name: ev.target.value })),
            }}
          />

          <TextField
            id="video-task-dueDate"
            label="Due Date"
            type="datetime-local"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            // value={videoQuizInputs.dueDate}
            onChange={e => dispatch(setQuizVideoInputs({ ...videoQuizInputs, dueDate: new Date(e.target.value) }))}
          />

          <CustomAutocomplete
            labelText="Select Group"
            multiple
            id="video-task-groups"
            placeholder="Groups"
            value={groups.filter(g => videoQuizInputs.groups.includes(g.id))}
            onChange={(ev: React.ChangeEvent<{}>, value: OptionType[]) =>
              dispatch(setQuizVideoInputs({ ...videoQuizInputs, groups: value.map(item => item.id) }))
            }
            options={groups}
          />

          <CustomAutocomplete
            labelText="Videos"
            multiple
            id="video-task-videos"
            placeholder="Videos"
            value={vdoCipherVideos
              .filter(v => videoQuizInputs.videos.includes(v._id))
              .map(video => ({
                id: video._id,
                name: video.title,
              }))}
            onChange={(ev: React.ChangeEvent<{}>, value: OptionType[]) =>
              dispatch(setQuizVideoInputs({ ...videoQuizInputs, videos: value.map(item => item.id) }))
            }
            options={vdoCipherVideos.map(video => ({
              id: video._id,
              name: video.title,
            }))}
          />

          <CustomAutocomplete
            placeholder="Active"
            onChange={(event: React.ChangeEvent<{}>, value: OptionType) =>
              value && dispatch(setQuizVideoInputs({ ...videoQuizInputs, active: value.id === 'false' ? false : true }))
            }
            id="Active"
            labelText="Active"
            options={activeOptions}
            value={activeOptions.find(o => o.id === videoQuizInputs.active + '') || activeOptions[0]}
          />
        </div>
      </ContentSubmitDialog>
    </>
  );
};

export default VideoTaskForm;

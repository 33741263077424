import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { red, yellow } from '@material-ui/core/colors';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import InsertCommentOutlinedIcon from '@material-ui/icons/InsertCommentOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deleteCommentAsync, setVideoCurrentTime, threadReqSelector } from 'features/admin/slices/adminSlice';
import { useDispatch, useSelector } from 'react-redux';
import CommentInput from './CommentInput';
import { Box, Menu, MenuItem } from '@mui/material';
import SubmitDialog from 'components/Dialog/SubmitDialog';
import config from 'config';

interface Props {
  comment: Types.API.VdoCipher.IThreadInfo;
  type: 'comment' | 'reply';
}

const Comment: React.FC<Props> = ({ comment, type }) => {
  const dispatch = useDispatch();
  const threadReq = useSelector(threadReqSelector);

  const [toggleLike, setToggleLike] = useState(false);
  const [toggleComments, setToggleComments] = useState(false);
  const [edited, setEdited] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const token = localStorage.getItem('auth-token');
  const user = localStorage.getItem('studentID');
  const role = localStorage.getItem('role');

  useEffect(() => {
    if (threadReq.req.status === 'succeeded' && threadReq.operation === 'delete') {
      setOpenDelete(false);
      setAnchorEl(null);
    }
  }, [threadReq.req.status]);

  function likeShape(likeBool = false) {
    if (likeBool)
      return (
        <Button onClick={() => setToggleLike(!toggleLike)} startIcon={<ThumbUpAltIcon />} size="medium" color="primary">
          Liked (1)
        </Button>
      );
    else
      return (
        <Button onClick={() => setToggleLike(!toggleLike)} startIcon={<ThumbUpAltOutlinedIcon />} size="medium">
          Like (0)
        </Button>
      );
  }
  const toggleSettings = () => {
    if (role !== 'student' || user === (comment.userId as any)._id)
      return (
        <div>
          <Menu id="simple-menu" anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
            {!comment.isVoice && (
              <MenuItem onClick={() => setEdited(true)}>
                <EditIcon style={{ marginRight: 7 }} />
                Edit
              </MenuItem>
            )}

            <MenuItem onClick={() => setOpenDelete(true)}>
              <DeleteIcon style={{ marginRight: 7 }} />
              Delete
            </MenuItem>
          </Menu>
          <IconButton aria-label="settings" onClick={e => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
        </div>
      );
    else return <></>;
  };

  function studentInstructorIcon(user: Types.API.User.IUserInfo) {
    if (user.role === 'student')
      return (
        <Tooltip title="Student" arrow>
          <Avatar aria-label="recipe" style={{ backgroundColor: yellow[600] }}>
            {user.name.charAt(0).toUpperCase()}
          </Avatar>
        </Tooltip>
      );
    return (
      <Tooltip title="Instructor" arrow>
        <Avatar aria-label="recipe" style={{ backgroundColor: red[600] }}>
          {user.name.charAt(0).toUpperCase()}
        </Avatar>
      </Tooltip>
    );
  }

  const handleDelete = () => {
    if (!token) return;
    dispatch(
      deleteCommentAsync({
        threadId: comment._id,
        token,
      }),
    );
  };

  return (
    <Card
      style={{
        margin: '1.5% 1.5%',
        border: '1px solid #ccc',
        borderLeft: '8px solid #ccc',
        borderRadius: 5,
      }}
    >
      <CardHeader
        avatar={studentInstructorIcon(comment.userId)}
        action={toggleSettings()}
        title={comment.userId.name}
        subheader={`${new Date(comment.createdAt).toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })} ${comment.edited ? '(edited)' : ''}`}
        // subheader={`${new Date(comment.createdAt).toUTCString().substring(0, 25)} ${
        //   comment.edited ? '(edited)' : ''
        // }`}
      />

      <CardContent>
        {comment.isVoice && (
          <audio
            style={{
              width: '100%',
            }}
            controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
            controls
          >
            <source
              id="audioSource"
              src={config.apiBaseUrl.replace('api', '') + 'media' + comment.voice?.awsUrl.split('data')[1]}
              type="audio/mp3"
            />
          </audio>
        )}

        <Typography variant="subtitle1" component="p">
          {!comment.isVoice && comment.text}{' '}
          {comment.videoTime && (
            <a
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => dispatch(setVideoCurrentTime(comment.videoTime))}
            >
              {comment.videoTime}
            </a>
          )}
        </Typography>
      </CardContent>
      <CardActions style={{ marginLeft: 10 }}>
        {likeShape(toggleLike)}
        {comment.replies && type === 'comment' && (
          <Button
            onClick={() => setToggleComments(!toggleComments)}
            startIcon={<InsertCommentOutlinedIcon />}
            size="medium"
          >
            Replies ({comment.replies.length})
          </Button>
        )}
      </CardActions>
      {comment.replies && toggleComments && type === 'comment' && (
        <div className="container-fluid">
          {comment.replies.map(reply => {
            return <Comment key={comment._id} comment={reply} type="reply" />;
          })}

          <Box sx={{ width: '96.25%' }} marginLeft={0.5}>
            <CommentInput type="reply" threadId={comment._id} />
          </Box>
        </div>
      )}

      {edited && (
        <Box sx={{ width: '96.25%' }} marginLeft={0.5}>
          <CommentInput type={comment.type} edit={edited} setEdit={setEdited} comment={comment} />
        </Box>
      )}

      {openDelete && (
        <SubmitDialog
          dialogText={`Are you sure you want to delete this ${comment.type}?`}
          dialogTitle={`Delete ${comment.type}`}
          onSubmitClick={handleDelete}
          open={openDelete}
          onClose={() => setOpenDelete(false)}
        />
      )}
    </Card>
  );
};

export default Comment;

import { useRef } from 'react';
import { useFormik } from 'formik';
import { Chips } from 'primereact/chips';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { FormikErrors } from 'formik';
//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';

//core
import 'primereact/resources/primereact.min.css';

import { useDispatch, useSelector } from 'react-redux';
import { setVocabs as setVocabsToCreate } from '../../features/questions/createSlice';
import { setVocabs as setVocabsToEdit, vocabsSelector } from 'features/editQuestions/editSlice';

type FormValues = {
  chipArray: string[];
};

type Props = {
  edit?: boolean;
};
const FormikDoc: React.FC<Props> = props => {
  const toast = useRef<Toast | null>(null);
  const vocabs = useSelector(vocabsSelector);

  const dispatch = useDispatch();

  const show = (data: any) => {
    toast?.current?.show({
      severity: 'success',
      summary: 'Submitted Vocabs are',
      detail: `${data.chipArray}`,
    });
  };

  const formik = useFormik({
    initialValues: {
      chipArray: vocabs || [],
    },
    validate: data => {
      let errors: FormikErrors<FormValues> = {};
      const len: number = data.chipArray?.length | 0;
      if (len <= 0) {
        errors!.chipArray = 'At least one chip is required.';
      }

      return errors;
    },
    onSubmit: data => {
      data.chipArray.length > 0 && show(data);
      // formik.resetForm();
    },
  });

  const handleSubmitVocabs = () => {
    if (props.edit) dispatch(setVocabsToEdit({ vocabs: formik.values.chipArray }));
    else dispatch(setVocabsToCreate({ vocabs: formik.values.chipArray }));
  };

  const isFormFieldInvalid = (name: any) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name: any) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formik.errors[name]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  return (
    <div className="card p-fluid justify-content-center">
      <label> Press Enter to Add New Vocab </label>
      <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
        <Toast ref={toast} />
        <Chips
          inputId="c_chipArray"
          name="chipArray"
          value={formik.values.chipArray}
          className={classNames({ 'p-invalid': isFormFieldInvalid('chipArray') })}
          onChange={e => {
            formik.setFieldValue('chipArray', e.value);
          }}
        />
        {getFormErrorMessage('chipArray')}
        <Button type="submit" label="Submit Vocabs" className="w-7rem" onClick={handleSubmitVocabs} />
      </form>
    </div>
  );
};

export default FormikDoc;

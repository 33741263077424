import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles
import { useState } from 'react';
import { useDispatch } from 'react-redux';

interface TextEditorProps {
  labelText?: string;
  uuid?: string;
  setText?: any;
}

const TextEditor: React.FC<TextEditorProps> = props => {
  const { labelText, uuid, setText } = props;
  const dispatch = useDispatch();

  const [value, setValue] = useState<string>('');

  function handleChange(newValue: string) {
    setValue(newValue);
    dispatch(setText({ text: newValue, uuid: uuid ? uuid : '' }));
  }

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
    ],
  };
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  const formats = [
    'header',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];

  return (
    <div style={{ marginBottom: '30px' }}>
      <ReactQuill
        value={value}
        onChange={handleChange}
        placeholder={labelText}
        modules={modules}
        formats={formats}
        style={{ height: '200px' }}
      />
    </div>
  );
};

export default TextEditor;

import React from 'react';
import { ColDef, DataGrid } from '@material-ui/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector, userRoleSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import {
  assistantsSelector,
  CreateAssistantReqSelector,
  setCreateAssistantName,
  setCreateAssistantEmail,
  setCreateAssistantPhone,
  setCreateAssistantPassword,
  createAssistantAsync,
  getAssistantReqSelector,
  getAssistantsAsync,
  deleteAssistantAsync,
  deleteAssistantReqSelector,
  editAssistantReqSelector,
} from '../slices/adminSlice';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import classNames from 'classnames';
import styles from 'assets/jss/lms/features/questions';
import { makeStyles } from '@material-ui/core';
import { RootState } from 'features';
import EditAssistant from './EditAssistant';

const columns: ColDef[] = [
  { field: 'id', headerName: 'Assistant ID', width: 250, resizable: true, hide: true },
  { field: 'name', headerName: 'Assistant Name', width: 250 },
  { field: 'email', headerName: 'Assistant Email', width: 250 },
];

interface Props {}
const useStyles = makeStyles(styles);

const AssistantManagment: React.FC<Props> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const userRole = useSelector(userRoleSelector);
  const getAssistantsReq = useSelector(getAssistantReqSelector);
  const assistants = useSelector(assistantsSelector);
  const AssistantName = useSelector((state: RootState) => state.admin.createAssistant.name);
  const AssistantEmail = useSelector((state: RootState) => state.admin.createAssistant.email);
  const AssistantPhone = useSelector((state: RootState) => state.admin.createAssistant.phone);
  const AssistantPassword = useSelector((state: RootState) => state.admin.createAssistant.password);
  const createAssistant = useSelector(CreateAssistantReqSelector);
  const deleteAssistantReq = useSelector(deleteAssistantReqSelector);
  const editAssistantReq = useSelector(editAssistantReqSelector);

  const [selection, setSelection] = React.useState('');
  console.log('🚀 ~ file: AssistantManagment.tsx:51 ~ selection:', selection);
  const [curEditAssistant, setCurEditAssistant] = React.useState<Types.API.User.IUserInfo>();

  const [studentNameFilter, setStudentNameFilter] = React.useState<string>('');
  const [parentNameFilter, setParentNameFilter] = React.useState<string>('');

  useReqListener({
    status: getAssistantsReq.status,
    successMessage: 'Successfully fetched all the Assistants from the backend',
    loadingMessage: 'Fetching all Assistants from the backend',
    errorMessage: getAssistantsReq.error,
  });
  useReqListener({
    status: createAssistant.status,
    successMessage: 'Successfully ADDED Assistant',
    loadingMessage: 'Submitting Assistant to the backend',
    errorMessage: createAssistant.error,
  });
  useReqListener({
    status: deleteAssistantReq.status,
    successMessage: 'Successfully deleted the Assistant',
    loadingMessage: 'Deleting the Assistant',
    errorMessage: deleteAssistantReq.error,
  });

  React.useEffect(() => {
    if (getAssistantsReq.status === 'idle') {
      token && dispatch(getAssistantsAsync({ token }));
    }
  }, [token, getAssistantsReq.status, dispatch]);

  React.useEffect(() => {
    if (createAssistant.status === 'succeeded') {
      token && dispatch(getAssistantsAsync({ token }));
    }
  }, [token, createAssistant.status, dispatch]);

  React.useEffect(() => {
    token && dispatch(getAssistantsAsync({ token }));
  }, [token, editAssistantReq.status, deleteAssistantReq.status, dispatch]);

  const handleAssistantSubmit = () => {
    dispatch(
      createAssistantAsync({
        assistant: { name: AssistantName, email: AssistantEmail, phone: AssistantPhone, password: AssistantPassword },
        token,
      }),
    );
  };

  const handleDeleteAssistant = () => {
    dispatch(deleteAssistantAsync({ token, id: selection }));
  };

  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <div className={classNames(classes.container, classes.categoryAutocomplete)}>
        <CustomInput
          id="categories-submit-button"
          labelText="Assistant Name"
          formControlProps={{ fullWidth: true }}
          inputProps={{
            className: classes.margin20right,
            value: AssistantName,
            onChange: ev => dispatch(setCreateAssistantName({ name: ev.target.value })),
          }}
        />
        <CustomInput
          id="categories-submit-button"
          labelText="Assistant Email"
          formControlProps={{ fullWidth: true }}
          inputProps={{
            className: classes.margin20right,
            value: AssistantEmail,
            onChange: ev => dispatch(setCreateAssistantEmail({ email: ev.target.value })),
          }}
        />
        <CustomInput
          id="categories-submit-button"
          labelText="Assistant Password"
          formControlProps={{ fullWidth: true }}
          inputProps={{
            className: classes.margin20right,
            value: AssistantPassword,
            onChange: ev => dispatch(setCreateAssistantPassword({ password: ev.target.value })),
          }}
        />
        <CustomInput
          id="categories-submit-button"
          labelText="Assistant Phone"
          formControlProps={{ fullWidth: true }}
          inputProps={{
            className: classes.margin20right,
            value: AssistantPhone,
            onChange: ev => dispatch(setCreateAssistantPhone({ phone: ev.target.value })),
          }}
        />
        <Button color="primary" onClick={handleAssistantSubmit}>
          Add Assistant
        </Button>

        {userRole === 'admin' && (
          <Button color="danger" onClick={handleDeleteAssistant} disabled={selection === ''}>
            Delete Assistant
          </Button>
        )}
        {curEditAssistant && (
          <EditAssistant
            AssistantId={selection}
            initialEmail={curEditAssistant.email}
            initialName={curEditAssistant.name}
            initialPassword=""
            initialPhone={curEditAssistant.phone}
          />
        )}
      </div>
      <DataGrid
        pagination
        rows={assistants}
        columns={columns}
        onSelectionChange={p => {
          setCurEditAssistant(assistants.find(item => item.id === p.rowIds[0]) as Types.API.User.IUserInfo);
          setSelection(p.rowIds[0].toString());
        }}
        loading={getAssistantsReq.status === 'loading'}
      />
    </div>
  );
};

export default AssistantManagment;

import React from 'react';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'features';
import autosize from 'autosize';
import {
  setRubricText
} from '../editSlice';

const Rubric: React.FC<{ uuid: string }> = (props: { uuid: string }) => {

  const questionText = useSelector(
    (state: RootState) =>
      state.editQuestion.editingQuestion.questions[props.uuid] as Types.API.Question.IRubricModel,
  ).text;
  const dispatch = useDispatch();

  
  autosize(document.getElementById('text') as Element)

  return (
    <div>
      <CardBody>
        <CustomInput
          labelText="Question Text"
          id="text"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            value: questionText,
            multiline: true,
            rows: 1,
            onChange: e => {dispatch(setRubricText({ text: e.target.value, uuid: props.uuid }))},
          }}
          allowEdit={true}
          uuid={props.uuid}
          setText = {setRubricText}
        />
        <div style={{ float: 'right', width: 'auto', zIndex: 1000, position: 'relative' }}>
      </div>
      </CardBody>
    </div>
  );
};

export default Rubric;

import React from 'react';
import { ColDef, DataGrid } from '@material-ui/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector, userRoleSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import {
  createGroupAsync,
  getGroupsAsync,
  setCreateGroupName,
  setCreateGroupAssistants,
  setCreateGroupStudents,
  setCreateGroupSchool,
  getAssistantsAsync,
  getStudentsAsync,
  setModifyGroup,
  setModifyGroupStudents,
  setModifyGroupAssistants,
  modifyGroupAsync,
  getStudentsReqSelector,
  getSchoolsAsync,
  deleteGroupAsync,
  setModifyGroupName,
  resetModifyGroup,
} from '../slices/adminSlice';
import { RootState } from 'features';
import styles from 'assets/jss/lms/features/questions';
import { Divider, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomAutocomplete, { OptionType } from 'components/Autocomplete/Autocomplete';
import DeleteDialog from 'components/Dialog/DeleteDialog';

const columns: ColDef[] = [
  { field: 'id', headerName: 'id', width: 300, resizable: true, hide: true },
  { field: 'name', headerName: 'Group Name', width: 300 },
];
const useStyles = makeStyles(styles);

interface Props { }
const GroupsManagement: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector(tokenSelector);
  const userRole = useSelector(userRoleSelector);
  const getGroupsReq = useSelector((state: RootState) => state.admin.getGroupsReq);
  const curGroup = useSelector((state: RootState) => state.admin.createGroup.group);
  const curModifyGroup = useSelector((state: RootState) => state.admin.modifyGroup.group);
  const groups = useSelector((state: RootState) => state.admin.groups);
  const createGroupReq = useSelector((state: RootState) => state.admin.createGroup.req);
  const assistantsReq = useSelector((state: RootState) => state.admin.getAssistantsReq);
  const modifyGroupReq = useSelector((state: RootState) => state.admin.modifyGroup.req);
  const getStudentsReq = useSelector(getStudentsReqSelector);
  const assistants = useSelector((state: RootState) => state.admin.assistants);
  const students = useSelector((state: RootState) => state.admin.students);
  const schools = useSelector((state: RootState) => state.admin.schools);
  const getSchoolsReq = useSelector((state: RootState) => state.admin.getSchoolsReq);
  const deleteGroupReq = useSelector((state: RootState) => state.admin.deleteGroupReq);
  const [selection, setSelection] = React.useState('');
  const [searchString, setSearchString] = React.useState('');

  const [deleteDialogOpen,setDeleteDialogOpen] = React.useState(false)
  const [assistantNames, setAssistantNames]=React.useState<String[]>([])
  useReqListener({
    status: createGroupReq.status,
    successMessage: 'Successfully ADDED a group to the DB',
    loadingMessage: 'Adding group to the DB',
    errorMessage: createGroupReq.error,
  });
  useReqListener({
    status: modifyGroupReq.status,
    successMessage: 'Successfully MDIFIED the group',
    loadingMessage: 'MODIFYING group to the DB',
    errorMessage: modifyGroupReq.error,
  });
  useReqListener({
    status: deleteGroupReq.status,
    successMessage: 'Successfully DELETED a group from the DB',
    loadingMessage: 'Deleting the group from the DB',
    errorMessage: deleteGroupReq.error,
  });
  React.useEffect(() => {
    if (dispatch && getGroupsReq.status === 'idle') {
      token && dispatch(getGroupsAsync({ token }));
    }
    if (dispatch && assistantsReq.status === 'idle') {
      token && dispatch(getAssistantsAsync({ token }));
    }
    if (getStudentsReq.status === 'idle') {
      token && dispatch(getStudentsAsync({ token }));
    }
    token && getSchoolsReq.status === 'idle' && dispatch(getSchoolsAsync({ token }));
  }, [getSchoolsReq.status, getGroupsReq.status, assistantsReq.status, getStudentsReq.status, token, dispatch]);
  // const setSearch = (value: any) =>{
  //   console.log(value)
  //   return
  // }
  React.useEffect(()=>{
    setAssistantNames(assistants.map(e=>e.name))
  },[assistants])
  React.useEffect(()=> {
    const elems = document.getElementsByClassName('MuiChip-label')
    for(var i = 0; i<elems.length;i++){

      if(elems[i].innerHTML.toLowerCase().includes(searchString.toLowerCase()) && searchString!=='' && !assistantNames.includes(elems[i].innerHTML))
        (elems[i] as HTMLElement).style.color = '#04d9ff'
      else
      ( elems[i] as HTMLElement).style.color = '#000000'
    }
  }
  ,[searchString,curModifyGroup.students,assistantNames])
  const handleGroupSubmit = () => {
    dispatch(
      createGroupAsync({
        token,
        group: {
          name: curGroup.name,
          schoolId: curGroup.schoolId.id,
          studentsIds: curGroup.studentsIds.map(s => s.id),
          assistantsIds: curGroup.assistantsIds.map(a => a.id),
        },
      }),
    );
  };

  const handleModifyGroupSubmit = () => {
    dispatch(
      modifyGroupAsync({
        token,
        group: {
          ...curModifyGroup,
          studentsIds: curModifyGroup.students.map(s => s.id),
          assistantsIds: curModifyGroup.assistants.map(a => a.id),
          schoolId: curModifyGroup.school.id,
        },
        id: curModifyGroup.id,
      }),
    );
  };

  const handleDeleteGroup = () => {
    dispatch(deleteGroupAsync({ token, id: selection }));
  };

  return (
    <div>
      <div style={{ width: '100%', display: 'block' }}>
        <div className={classNames(classes.container)}>
          <h4 style={{ color: 'blue' }}>Add New Group</h4>
          <CustomInput
            id="Group Name"
            labelText="New Group Name"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              className: classes.margin20right,
              value: curGroup.name,
              onChange: ev => dispatch(setCreateGroupName({ name: ev.target.value })),
            }}
          />
          <CustomAutocomplete
            value={curGroup.assistantsIds}
            multiple
            onChange={(event: React.ChangeEvent<{}>, value: OptionType[]) => dispatch(setCreateGroupAssistants(value))}
            placeholder="assistants"
            id="Group Assistants"
            labelText="Assistants"
            options={assistants}
          />
          <CustomAutocomplete
            value={curGroup.studentsIds}
            multiple
            onChange={(event: React.ChangeEvent<{}>, value: OptionType[]) => dispatch(setCreateGroupStudents(value))}
            placeholder="students"
            id="Students ids"
            options={students}
          />
          <CustomAutocomplete
            value={curGroup.schoolId}
            onChange={(event: React.ChangeEvent<{}>, value: OptionType) => dispatch(setCreateGroupSchool(value))}
            placeholder="School"
            id="shools-autocomplete"
            options={schools}
          />
          <Button color="primary" onClick={handleGroupSubmit}>
            Add Group
          </Button>
        </div>
      </div>
      <div style={{ width: '100%', display: 'block' }}>
        <div className={classNames(classes.container)}>
          <h4 style={{ color: 'blue', paddingTop: '50px' }}>Edit Group</h4>
          <CustomAutocomplete
            value={curModifyGroup || { id: '', name: '' }}
            onChange={(event: React.ChangeEvent<{}>, value: OptionType) => {
              if (value !== null) dispatch(setModifyGroup(groups.find(g => g.id === value.id)));
              else dispatch(resetModifyGroup())
            }}
            placeholder="Group"
            id="modify-group-autocomplete"
            options={groups}
          />
          {curModifyGroup !== undefined && curModifyGroup.id !== '' &&
            <div>
              <div style={{ marginLeft: '10px' }}>
                <CustomInput
                  id="editGroupName"
                  labelText="Edit Group Name"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    disabled: curModifyGroup === undefined || curModifyGroup.id === '',
                    value: curModifyGroup.name,
                    onChange: ev => dispatch(setModifyGroupName(ev.target.value)),
                  }}
                />
              </div>
              <CustomAutocomplete
                value={curModifyGroup.assistants}
                multiple
                onChange={(event: React.ChangeEvent<{}>, value: OptionType[]) => dispatch(setModifyGroupAssistants(value))}
                placeholder="Assistants"
                id="Assistants ids"
                options={assistants}
                formControlProps={{
                  disabled: curModifyGroup === undefined || curModifyGroup.id === '',
                }}
              />
              <CustomInput
                  id="searchGroupStudents"
                  labelText="Search Group Students"
                  inputProps={{
                    disabled: curModifyGroup === undefined || curModifyGroup.id === '',
                    value:searchString,
                    onChange: ev => setSearchString(ev.target.value),
                  }}
                />
              <CustomAutocomplete
                value={curModifyGroup.students}
                multiple
                onChange={(event: React.ChangeEvent<{}>, value: OptionType[]) => dispatch(setModifyGroupStudents(value))}
                placeholder="Students"
                id="Students ids"
                options={students}
                formControlProps={{
                  disabled: curModifyGroup === undefined || curModifyGroup.id === '',
                  
                }}
              />
              <Button color="primary" onClick={handleModifyGroupSubmit}>
                Modify
              </Button>
            </div>}
        </div>
        <Divider />
        {userRole === 'admin' && (
          <Button color="danger" onClick={()=>setDeleteDialogOpen(true)}>
            Delete Selected Group
          </Button>
        )}
        <DeleteDialog
        dialogText="Are you sure you want to delete this group?"
        onSubmitClick={()=> {setDeleteDialogOpen(false); handleDeleteGroup()}}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      />
      </div>
      <DataGrid
        onSelectionChange={p => {
          setSelection(p.rowIds[0].toString());
        }}
        pagination
        rows={groups}
        columns={columns}
        loading={getGroupsReq.status === 'loading'}
      />
    </div>
  );
};

export default GroupsManagement;

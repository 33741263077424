import { createStyles } from '@material-ui/core/styles';
const cardBodyStyle = createStyles({
  cardBody: {
    padding: '0.9375rem 1.875rem',
    flex: '1 1 auto',
    background: 'white',
  },
});

export default cardBodyStyle;

import React from 'react';

import { Accordion, AccordionSummary, AccordionDetails, Box, Paper } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import MonthContent from './MonthContent';
import { formateMonthDataPair, sortMonths } from 'features/helpers';
import CustomCircularProgress from 'components/Progress/CustomCircularProgress';

interface Props {
  data?: any;
  type: 'running' | 'available' | 'published' | 'late' | 'vdo-lect' | 'vdo-speaking'; // in progress | new | finished | late
  expanded: string | false;
  handleExpansion: (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  panel: string;
  isLoading?: boolean;
}
const AccordionContent: React.FC<Props> = ({ data, type, expanded, handleExpansion, panel ,isLoading=false}) => {
  const setAccordionText = () => {
    switch (type) {
      case 'available':
        return 'New';
      case 'late':
        return 'Late';
      case 'running':
        return 'In Progress';
      case 'published':
        return 'Finished';
      case 'vdo-lect':
        return 'Video Lectures';
      case 'vdo-speaking':
        return 'Speaking Practice';
    }
  };

  const renderData = () => {
    if (isLoading) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:'center' }}>
        <CustomCircularProgress />Loading...
      </div>)
    }
    if (data?.length !== 0)
      return (
        <MonthContent
          type={type}
          monthDataPair={formateMonthDataPair(data, 'vdo-lect')}
          sortedMonths={sortMonths(formateMonthDataPair(data, 'vdo-lect'))}
        />
      );
    else {
      return <h2>There is no content here</h2>;
    }
  };

  return (
    <Box mb={2}>
      <Paper variant="outlined" elevation={0}>
        <Accordion expanded={expanded === panel} onChange={handleExpansion(panel)}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
            <b>{setAccordionText()}</b>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>{data && renderData()}</AccordionDetails>
        </Accordion>
      </Paper>
    </Box>
  );
};

export default AccordionContent;

import { FC, useState } from 'react';
import CustomInput from 'components/CustomInput/CustomInput';
import Autocomplete, { OptionType } from 'components/Autocomplete/Autocomplete';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import classNames from 'classnames';
import styles from 'assets/jss/lms/features/questions';
import { useDispatch, useSelector } from 'react-redux';
import {
  categorySelector,
  diplomaSelector,
  gradeSelector,
  levelSelector,
  setCategoryType,
  setDiplomaType,
  setGradeType,
  setLevelType,
  setSectionType,
  setTitle,
  titleSelector,
  sectionTypeSelector,
  questionTypes,
} from 'features/questions/createSlice';
import { makeStyles } from '@material-ui/core';
import {
  availableCategoriesSelector,
  availableDiplomasSelector,
  availableGradesSelector,
  availableLevelsSelector,
} from 'features/admin/slices/adminSlice';

const useStyles = makeStyles(styles);

const CreateQTitleAndCategories: FC = () => {
  const [cardAnimation, setCardAnimation] = useState('cardHidden');
  const classes = useStyles();
  const title = useSelector(titleSelector);
  const questionType = useSelector(sectionTypeSelector);
  const categoryType = useSelector(categorySelector);
  const levelType = useSelector(levelSelector);
  const gradeType = useSelector(gradeSelector);
  const diplomaType = useSelector(diplomaSelector);
  const categories = useSelector(availableCategoriesSelector);
  const grades = useSelector(availableGradesSelector);
  const levels = useSelector(availableLevelsSelector);
  const diplomas = useSelector(availableDiplomasSelector);

  const dispatch = useDispatch();

  setTimeout(function () {
    setCardAnimation('');
  }, 200);

  return (
    <Card
      key="questionType"
      plain={false}
      carousel={false}
      className={classes[cardAnimation]}
      style={{
        marginTop: '80px',
      }}
    >
      <CardHeader color="primary" className={classes.cardHeader}>
        <h6 style={{ fontWeight: 'bolder', fontSize: 'large' }}>Question Title & Categories</h6>
      </CardHeader>
      <CardBody className={classes.categoriesBody}>
        <div className={classes.categoryAutocomplete}>
          <Autocomplete
            labelText="Diploma"
            id="diplomaType"
            value={diplomaType}
            onChange={(ev, value: OptionType) => {
              value && dispatch(setDiplomaType(value));
            }}
            options={diplomas}
          />
          <Autocomplete
            labelText="Grade"
            id="gradeType"
            value={gradeType}
            onChange={(ev, value: OptionType) => {
              value && dispatch(setGradeType(value));
            }}
            options={grades}
          />
        </div>
        <div className={classNames(classes.categoryAutocomplete, classes.margin10Top)}>
          <Autocomplete
            labelText="Level"
            id="levelType"
            value={levelType}
            onChange={(ev, value: OptionType) => {
              value && dispatch(setLevelType(value));
            }}
            options={levels}
          />
          <Autocomplete
            labelText="Category"
            id="categoryType"
            value={categoryType}
            onChange={(ev, val: OptionType) => {
              val && dispatch(setCategoryType(val));
            }}
            options={categories}
          />
        </div>
        <CustomInput
          labelText="Question Title"
          id="title"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            value: title,
            onChange: event => {
              dispatch(setTitle({ text: event.target.value }));
            },
            multiline: true,
            rows: 2,
          }}
        />
        <Autocomplete
          labelText="Section Type"
          id="Section type"
          onChange={(ev, val: OptionType) => {
            val && dispatch(setSectionType(val));
          }}
          value={questionType}
          options={questionTypes}
        />
      </CardBody>
    </Card>
  );
};

export default CreateQTitleAndCategories;

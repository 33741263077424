import API from 'api';
import { CLIENT_RENEG_LIMIT } from 'tls';
// import _ from 'lodash';

export const viewAsUsers = [
  {
    id: 1,
    name: 'Admin Test',
    email: 'fady@admin.com',
    role: 'admin',
    password: '123',
  },
  {
    id: 2,
    name: 'Student Test',
    email: 'fady@fady.com',
    role: 'student',
    password: '123',
  },
  {
    id: 3,
    name: 'Parent Test',
    email: 'ashraf@ashraf.com',
    role: 'parent',
    password: '123',
  },
  {
    id: 4,
    name: 'Assistant Test',
    email: 'test@ass.com',
    role: 'assistant',
    password: '123',
  },
];

export const masterKey = 'AnaElLwa2ShawqyYaEbny#AdMiN811forceLoGIn';

export const verifyToken = async (input: {
  token: string;
}): Promise<{ user: Types.API.User.IUserInfo; token: string }> => {
  const response = await API.post(
    '/auth/verifyToken',
    {},
    {
      headers: { Authorization: 'Bearer ' + input.token },
    },
  );
  return { user: response.data.user as Types.API.User.IUserInfo, token: input.token };
};

export const sigin = async (input: {
  email: string;
  password: string;
}): Promise<{ user: Types.API.User.IUserInfo; token: string }> => {
  const response = await API.post('/auth/signin', input);
  return { user: response.data.user as Types.API.User.IUserInfo, token: response.data.token as string };
};

export const sendResetCode = async (input: { email: string }): Promise<{ resetCode: string }> => {
  const response = await API.post('/auth/sendResetCode', input);
  return { resetCode: response.data.resetCode };
};

export const forgotPassword = async (input: {
  email: string;
  newPassword: string;
  confirmPassword: string;
}): Promise<{ message: string }> => {
  const response = await API.post('/auth/forgotPassword', input);
  return { message: response.data.message };
};

export const resetPassword = async (input: {
  userId: string;
  token: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}): Promise<Types.API.User.IUserInfo> => {
  const response = await API.put(
    `/users/resetPassword/${input.userId}`,
    {
      oldPassword: input.oldPassword,
      newPassword: input.newPassword,
      confirmPassword: input.confirmPassword,
    },
    { headers: { Authorization: 'Bearer ' + input.token }, params: { userId: input.userId } },
  );

  return response.data;
};
export const postMessage = async (input: {
  token: string;
  questionID: string;
  quizID: string;
  studentID: string;
  questionNumber: number;
  subQuestionNumber?: number;
  messages?: {
    sender: string;
    content: string;
    receiver?: string;
    timestamp?: Date;
  }[];
}) => {
  const response = await API.post(
    `/users/messages/room`,
    {
      questionID: input.questionID,
      quizID: input.quizID,
      studentID: input.studentID,
      questionNumber: input.questionNumber,
      subQuestionNumber: input.subQuestionNumber,
      messages: input.messages || [],
    },
    {
      headers: { Authorization: 'Bearer ' + input.token },
    },
  );
  localStorage.setItem('roomID', response?.data?.room?._id ? response.data.room._id : '');
  return response.data.room;
};
export const getMessages = async (input: { token: string; questionID: string; studentID: string; quizID: string }) => {
  const response = await API.get(
    `/users/messages/room?questionID=${input.questionID}&studentID=${input.studentID}&quizID=${input.quizID}`,
    {
      headers: { Authorization: 'Bearer ' + input.token },
    },
  );
  const messages = response.data.message;
  return messages;
};
export const getNotifications = async (input: {
  token: string;
  userId: string;
}): Promise<Types.API.User.INotifications[]> => {
  const response = await API.get(`/users/notifications/${input.userId}`, {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: 10000 },
  });
  return response.data as Types.API.User.INotifications[];
};
export const getRoomData = async (input: { token: string; roomID: string }): Promise<Types.API.User.IRoom> => {
  const response = await API.get(`users/room/${input.roomID}`, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data;
};
export const setNotificationAsRead = async (input: {
  token: string;
  userId: string;
  notificationID: string;
  questionID?: string;
  studentID?: string;
  quizID?: string;
}): Promise<Types.API.User.INotifications[]> => {
  const hasAllOptionalProperties = input.questionID && input.studentID && input.quizID;
  const requestBody = hasAllOptionalProperties
    ? {
        notificationID: input.notificationID,
        questionID: input.questionID,
        studentID: input.studentID,
        quizID: input.quizID,
      }
    : { notificationID: input.notificationID };

  const response = await API.post(`/users/notifications/${input.userId}`, requestBody, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data as Types.API.User.INotifications[];
};

export const uploadVoice = async (input: { file: Blob; token: string }): Promise<Types.API.File.IFileModel> => {
  const formData = new FormData();
  formData.append('file', input.file);
  const response = await API.post('/users/uploadVoice', formData, {
    headers: {
      Authorization: 'Bearer ' + input.token,
      'Access-Control-Allow-Headers': 'Content-Type, Authorization, Access-Control-Allow-Headers',
    },
  });
  // console.log('🚀 ~ file: client.ts:142 ~ uploadVoice ~ response.data.file;:', response.data.file.awsUrl);
  return response.data.file;
};

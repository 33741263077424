import { FC } from 'react';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon';
import DashboardIcon from '@material-ui/icons/Dashboard';

import StudentHome from './features/student/Home';

import ParentHome from './views/Parent/Home';

import LandingView from './views/Landing/LandingView';
import LoginView from './views/Landing/LoginView';
import AssistantView from './views/Landing/AssistantView';
import ForgotPasswordView from 'views/Landing/ForgotPasswordView';
interface CustomRoute {
  path: string;
  name: string;
  component: FC;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
}

/**
 * EVERY SET OF ROUTES SHOULD HAVE A /home COMPONENT
 * this component will be the fallback for any invalid routes (Redirects to it)
 * and also considered the home directory of your layout set of routes (If you consider making a home page)
 */

const landingRoutes: CustomRoute[] = [
  {
    path: '/home',
    name: 'Landing',
    component: LandingView,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordView,
  },
  {
    path: '/assistant',
    name: 'Assistant',
    component: AssistantView,
  },
];

const studentRoutes: CustomRoute[] = [
  {
    path: '/home',
    name: 'Student Home',
    component: StudentHome,
    icon: DashboardIcon,
  },
];

const parentRoutes: CustomRoute[] = [
  {
    path: '/home',
    name: 'Parent Home',
    component: ParentHome,
    icon: DashboardIcon,
  },
];

export { landingRoutes, studentRoutes, parentRoutes };

import React, { useEffect } from 'react';
import { Backdrop, Button, Card, CircularProgress, Divider, IconButton, makeStyles } from '@material-ui/core';
import CustomDropzone from 'components/CustomDropzone/CustomDropzone';
import AudioRecorder from './AudioRecorder';
import RecordIcon from '@material-ui/icons/Mic';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'features';
import {
  availableAdminFilesToDownloadSelector,
  availableStudentFilesToDownloadSelector,
  deleteFileAsync,
  downloadAdminFileAsync,
  downloadStudentFileAsync,
  isQuizCorrectionPublished,
  setTriggerSaveQuizAnswers,
  subAnswerWithIndexSelector,
  uploadStudentAudiosAsync,
  uploadStudentFilesAsync,
} from '../updateSlice';
import { useReqListener } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getMessagesAsync, userSelector } from 'features/auth/authSlice';
import CustomInput from 'components/CustomInput/CustomInput';
import config from 'config';
import { subQuestionWithIndexSelector } from '../updateSlice';
import styles from 'assets/jss/lms/features/questions';
import ShowChat from './ShowChat';
import CustomButton from 'components/CustomButtons/Button';

const useStyles = makeStyles(styles);

export interface Props {}

const UpdateStudentSpeaking: React.FC<Props> = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openChat, setOpenChat] = React.useState(false);

  const [openRecord, setOpenRecord] = React.useState(false);
  const dispatch = useDispatch();
  const activeQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.questionIndex);
  const activeSubQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.subQuestionIndex);
  const token = useSelector((state: RootState) => state.auth.token);
  const questionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.questionIndex);
  const subQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.subQuestionIndex);
  const answer = useSelector(
    subAnswerWithIndexSelector(questionIndex, subQuestionIndex),
  ) as Types.API.Answer.IAnswerWritingModel; // since this only handle Writing & Speaking for now
  // const [downloadURL, setDownloadURL] = React.useState('');
  // const [downloadURLAudio, setDownloadURLAudio] = React.useState('');
  // const [downloadURLAdmin, setDownloadURLAdmin] = React.useState('');
  // const [downloadURLAdminAudio, setDownloadURLAdminAudio] = React.useState('');
  const answerId = answer._id;
  const uploadedStudentFiles = useSelector(availableStudentFilesToDownloadSelector);
  const currentQuestionStudentFiles = uploadedStudentFiles.filter(
    file =>
      file &&
      answer.files.some(f => {
        const id = (f as any).file ? (f as any).file._id : f._id ? f._id : f.toString();
        return id === file._id;
      }),
  );
  const uploadedAdminFiles = useSelector(availableAdminFilesToDownloadSelector);
  const currentQuestionAdminFiles = uploadedAdminFiles.filter(
    file =>
      file &&
      answer.instructorFiles
        .filter(f => f)
        .some(f => {
          const id = (f as any).file ? (f as any).file._id : f._id ? f._id : f.toString();
          return id === file._id;
        }),
  );

  const adminAudioFiles = currentQuestionAdminFiles.filter(file => file.filename && file.filename.endsWith('mp3'));
  const adminOtherFiles = currentQuestionAdminFiles.filter(file => file.filename && !file.filename.endsWith('mp3'));
  const studentAudioFiles = currentQuestionStudentFiles.filter(file => file.filename && file.filename.endsWith('mp3'));
  const studentOtherFiles = currentQuestionStudentFiles.filter(file => file.filename && !file.filename.endsWith('mp3'));
  const studentFilesUploadReq = useSelector((state: RootState) => state.updateQuiz.studentFilesUploadsReq[answerId]);
  const studentAudioUploadReq = useSelector((state: RootState) => state.updateQuiz.studentAudioUploadsReq[answerId]);
  const userRole = useSelector(userSelector).role;
  const isPublished = useSelector(isQuizCorrectionPublished);
  const showInstructorFiles = isPublished && (userRole === 'student' || userRole === 'parent');
  const [idKey, setIdKey] = React.useState(0);
  const updateAnswers = () => {
    setIdKey(idKey + 1);
  };
  // const triggerSave = ()=>{
  //   dispatch(setTriggerSaveQuizAnswers({ save: true }));

  // }
  useReqListener({
    status: studentFilesUploadReq.status,
    successMessage: 'Successfully uploaded the files to the storage',
    loadingMessage: 'Uploading your files to our storage',
    errorMessage: studentFilesUploadReq.error,
  });

  useReqListener({
    status: studentAudioUploadReq.status,
    successMessage: 'Successfully uploaded the recordings to the storage',
    loadingMessage: 'Uploading your recordings to our storage',
    errorMessage: studentAudioUploadReq.error,
  });

  const handleMediaBlobsSubmit = (blobs: Blob[]) => {
    blobs.length &&
      dispatch(
        uploadStudentAudiosAsync({
          blobs,
          token,
          answerId,
          questionIndex: activeQuestionIndex,
          subQuestionIndex: activeSubQuestionIndex,
        }),
      );
  };

  useEffect(() => {
    if (studentAudioUploadReq.status === 'succeeded') {
      dispatch(setTriggerSaveQuizAnswers({ save: true }));
    }
  }, [studentAudioUploadReq]);
  // useEffect(() => {
  //   if (studentOtherFiles && studentOtherFiles[0] && studentOtherFiles[0].awsUrl)
  //     setDownloadURL(config.apiBaseUrl.replace('api', '') + 'public' + studentOtherFiles[0].awsUrl.split('public')[1]);
  // }, [studentOtherFiles]);
  // useEffect(() => {
  //   if (adminOtherFiles && adminOtherFiles[0] && adminOtherFiles[0].awsUrl)
  //     setDownloadURLAdmin(
  //       config.apiBaseUrl.replace('api', '') + 'public' + adminOtherFiles[0].awsUrl.split('public')[1],
  //     );
  // }, [adminOtherFiles]);
  // useEffect(() => {
  //   if (adminAudioFiles && adminAudioFiles[0] && adminAudioFiles[0].awsUrl)
  //     setDownloadURLAdminAudio(
  //       config.apiBaseUrl.replace('api', '') + 'public' + adminAudioFiles[0].awsUrl.split('public')[1],
  //     );
  // }, [adminAudioFiles]);
  // useEffect(() => {
  //   if (studentAudioFiles && studentAudioFiles[0] && studentAudioFiles[0].awsUrl)
  //     setDownloadURLAudio(
  //       config.apiBaseUrl.replace('api', '') + 'public' + studentAudioFiles[0].awsUrl.split('public')[1],
  //     );
  // }, [studentAudioFiles]);
  useEffect(() => {
    answer.files.forEach(file => {
      if (file) {
        const id = (file as any).file ? (file as any).file._id : file._id ? file._id : file.toString();
        if (!currentQuestionStudentFiles.some(f => f._id === id))
          dispatch(
            downloadStudentFileAsync({
              token: token,
              fileId: id,
            }),
          );
      }
    });

    if (showInstructorFiles) {
      answer.instructorFiles.forEach(file => {
        if (file) {
          const id = (file as any).file ? (file as any).file._id : file._id ? file._id : file.toString();
          if (!currentQuestionAdminFiles.some(f => f._id === id))
            dispatch(
              downloadAdminFileAsync({
                token: token,
                fileId: id,
              }),
            );
        }
      });
    }
  }, [answer]);
  const handleShowChatClick = () => {
    const quizID = localStorage.getItem('quizID') || ' ';
    const studentID = localStorage.getItem('studentID') || ' ';

    if (studentID && quizID && question._id) {
      dispatch(
        getMessagesAsync({
          token,
          questionID: question._id || '',
          studentID: studentID || '',
          quizID: quizID || '',
        }),
      );
    }
    setOpenChat(true);
  };

  // const onSelectFilesClick = () => {
  //   setOpen(true);
  // };

  // const onClose = () => {
  //   setOpen(false);
  // };

  // const handleSave = (acceptedFiles: File[]) => {
  //   acceptedFiles.length &&
  //     dispatch(
  //       uploadStudentFilesAsync({
  //         files: acceptedFiles,
  //         token,
  //         answerId,
  //         subQuestionIndex: activeSubQuestionIndex,
  //         questionIndex: activeQuestionIndex,
  //       }),
  //     );
  //   // triggerSave();
  //   setOpen(false);
  // };
  const question = useSelector(subQuestionWithIndexSelector(questionIndex, subQuestionIndex));
  const getURL = (url: string) => {
    return config.apiBaseUrl.replace('api', '') + 'media' + url.split('data')[1];
  };
  return (
    <div
      key={'studentSpkKey' + idKey}
      style={{ display: 'flex', alignContent: 'center', width: '100%', flexDirection: 'column', padding: '15px' }}
    >
      {question.modelType === 'SpeakingPhotoDescription' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '30rem',
            width: '50rem',
            backgroundColor: 'white',
            borderRadius: '1rem',
            maxWidth: '100%',
          }}
        >
          <div style={{ height: '100%' }}>
            <h3 style={{ color: '#000', fontWeight: 'bold' }}>Speaking Photo Description</h3>
            {question && question?.modelType === 'SpeakingPhotoDescription' && (
              <div
                dangerouslySetInnerHTML={{
                  __html: question?.text.replace(/\n/g, '<br/>'),
                }}
                style={{
                  color: '#000',
                  backgroundColor: '#fff',
                  padding: '0.5rem',
                }}
              />
            )}
          </div>
          {question && question.image && question.image.awsUrl && (
            <img
              src={getURL(question.image.awsUrl)}
              alt="..."
              style={{
                borderRadius: '1rem',
                width: '20rem',
                marginTop: '1rem',
                height: '20rem',
                objectFit: 'cover', // Maintain the aspect ratio without distortion
              }}
              className="scale-image"
            />
          )}
        </div>
      )}
      <Card>
        <h4 className={classes.textHeader}>Student Records</h4>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
          }}
        >
          {studentAudioFiles &&
            studentAudioFiles?.map((file, index) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                }}
              >
                <audio controls preload="auto">
                  <source src={getURL(studentAudioFiles[index].awsUrl)} />
                </audio>
                {userRole === 'student' && !isPublished && (
                  <IconButton
                    onClick={() =>
                      dispatch(
                        deleteFileAsync({
                          fileId: file._id,
                          token,
                          fileName: file.filename,
                          questionIndex,
                          subQuestionIndex,
                          isAdmin: false,
                        }),
                      )
                    }
                  >
                    <FontAwesomeIcon size="sm" icon="trash" />
                  </IconButton>
                )}
              </div>
            ))}

          {userRole === 'student' && !isPublished && studentAudioUploadReq.status !== 'loading' && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenRecord(!openRecord)}
                endIcon={<RecordIcon />}
              >
                Record Voice
              </Button>

              <AudioRecorder
                openRecord={openRecord}
                setOpenRecord={setOpenRecord}
                handleSave={handleMediaBlobsSubmit}
              />
            </>
          )}
        </div>

        {/* <Divider style={{ marginTop: '2rem' }} /> */}
      </Card>
      {/* <>
        <Card>
          <h4 style={{ textAlign: 'center', fontWeight: 400 }}>Student Files</h4>
        </Card>
        <h6 style={{ textAlign: 'center', fontWeight: 400, color: 'black' }}>** Student Uploaded Files **</h6>
        <div>
          {studentOtherFiles.length !== 0 &&
            studentOtherFiles.map(file => (
              <div
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <a href={downloadURL} download>
                  {file.filename}
                </a>
                <IconButton
                  onClick={() => {
                    dispatch(
                      deleteFileAsync({
                        fileId: file._id,
                        token,
                        fileName: file.filename,
                        questionIndex,
                        subQuestionIndex,
                        isAdmin: false,
                      }),
                    );
                    // triggerSave()
                  }}
                >
                  <FontAwesomeIcon size="sm" icon="trash" />
                </IconButton>
              </div>
            ))}
        </div>

        <h6 style={{ fontWeight: 400, color: 'black' }}>**Accepted Files: audio/*, image/*, application/pdf</h6>
        <Button variant="contained" fullWidth onClick={onSelectFilesClick}>
          Select your files
        </Button>
        {studentFilesUploadReq.status !== 'loading' && (
          <CustomDropzone
            filesLimit={20}
            onClose={onClose}
            open={open}
            initialFiles={[]}
            clearOnUnmount={false}
            accept={['audio/*', 'image/*', 'application/pdf']}
            onSave={handleSave}
          />
        )}
      </> */}

      {showInstructorFiles && (
        <div>
          <Card style={{ marginTop: '2rem' }}>
            <h4 className={classes.textHeader}>Teacher Records</h4>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
              }}
            >
              {adminAudioFiles.length !== 0 &&
                adminAudioFiles.map((file, index) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '20px',
                    }}
                  >
                    <audio controls preload="auto">
                      <source src={getURL(adminAudioFiles[index].awsUrl)} />
                    </audio>
                  </div>
                ))}
            </div>
            {userRole === 'student' && (
              <>
                <div style={{ marginLeft: '45%' }}>
                  <CustomButton size="sm" color="twitter" onClick={() => handleShowChatClick()}>
                    <strong>chat with the teacher</strong>
                  </CustomButton>
                </div>
                <ShowChat
                  open={openChat}
                  setOpen={setOpenChat}
                  questionID={question._id}
                  quizID={localStorage.getItem('quizID') || ' '}
                  senderName={localStorage.getItem('studentName') || ' '}
                  studentID={localStorage.getItem('studentID') || ' '}
                  questionNumber={questionIndex + 1}
                  subQuestionNumber={subQuestionIndex + 1}
                  modelType={question.modelType}
                />
              </>
            )}
          </Card>

          <Card style={{ marginTop: '2rem' }}>
            <h4 className={classes.textHeader}>Teacher Comments</h4>
          </Card>
          {answer.instructorComments.map(comment => (
            <CustomInput
              labelText={`Writing Space`}
              id="text"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: 'text',
                multiline: true,
                rows: 8,
                value: comment,
                readOnly: userRole === 'student' || userRole === 'parent',
              }}
            />
          ))}

          {/* <Card style={{ marginTop: '2rem' }}>
            <h4 className={classes.textHeader}>Teacher Files</h4>
            <h6 style={{ color: '#000', textAlign: 'center', fontWeight: 400 }}>** Teacher Uploaded Files **</h6>
          </Card> */}

          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px',
            }}
          >
            {adminOtherFiles.length !== 0 &&
              adminOtherFiles.map((file, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <a href={getURL(adminOtherFiles[index].awsUrl)} download>
                    {file.filename}
                  </a>
                </div>
              ))}
          </div> */}
        </div>
      )}
      {/* <Divider style={{ marginTop: '2rem' }} /> */}
      <Backdrop
        color="#fff"
        style={{
          zIndex: 1000,
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
        }}
        open={studentFilesUploadReq.status === 'loading' || studentAudioUploadReq.status === 'loading'}
      >
        <CircularProgress color="inherit" style={{ marginBottom: '20px' }} />
        <p>UPLOADING...</p>
      </Backdrop>
    </div>
  );
};

export default UpdateStudentSpeaking;

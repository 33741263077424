import { useEffect, useState } from 'react';

import { BsFillMicFill } from 'react-icons/bs';
import { FaRedoAlt } from 'react-icons/fa';

import styles from './dictaphone.module.css';
import { calculateMatchingPercentage } from 'utils/longestCommonSubsequence';
import { updateStudentsProgressVideoAsync } from 'features/admin/slices/adminSlice';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import voicePitches from 'assets/img/voice.gif';
import { mutateStudentProgressAsync } from 'features/student/studentSlice';
interface DictaphoneProps {
  size?: number;
  micId: string;
  example: string;
  onClose?(): void;
  subtitleIndex: number;
  totalSubtitles: number;
}

const Dictaphone: React.FC<DictaphoneProps> = ({ size, micId, subtitleIndex, totalSubtitles, example, onClose }) => {
  const dispatch = useDispatch();

  const [transcript, setTranscript] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState<null | any>(null);

  const token = localStorage.getItem('auth-token');
  const userId = localStorage.getItem('studentID');

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!('SpeechRecognition' in window || 'webkitSpeechRecognition' in window)) {
      console.log('Speech recognition not supported');
      return;
    }

    let recognitionInstance = null;
    if ('SpeechRecognition' in window) {
      recognitionInstance = new (window as any).SpeechRecognition() as never as any;
    }

    if ('webkitSpeechRecognition' in window) {
      recognitionInstance = new (window as any).webkitSpeechRecognition() as never as any;
    }

    recognitionInstance.lang = 'en-US';

    recognitionInstance.onstart = () => {
      setIsListening(true);
    };

    recognitionInstance.onresult = (event: SpeechRecognitionResult) => {
      const lastResultIndex = (event as any).results.length - 1 ?? 0;
      const lastResult = (event as any)?.results[lastResultIndex][0]?.transcript ?? '';
      setTranscript(lastResult);
    };

    recognitionInstance.onend = () => {
      setIsListening(false);
    };

    setRecognition(recognitionInstance);
  }, []);

  const handleStartClick = () => {
    if (recognition) {
      recognition.start();
      setIsListening(true);
    }
  };

  const handleStopClick = () => {
    if (recognition) {
      recognition.stop();
      setIsListening(false);
    }
  };
  const percentage = calculateMatchingPercentage(transcript.toLowerCase(), example.toLowerCase());

  const handleSubmit = () => {
    if (token && userId && id && subtitleIndex !== undefined && percentage && totalSubtitles)
      dispatch(
        mutateStudentProgressAsync({
          studentId: userId,
          videoId: id,
          grade: Number(percentage),
          index: subtitleIndex,
          length: totalSubtitles,
          token,
        }),
      );
  };

  return (
    <div
      className="mx-1 pt-2 w-full"
      style={{
        width: '100%',
        paddingTop: '1rem',
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
      }}
    >
      {!isListening ? (
        <div
          className="flex flex-col items-center"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <button
            id={`${micId}-micButton`}
            className={`${styles.micButton ?? ''}`}
            type="button"
            onClick={handleStartClick}
          >
            <BsFillMicFill
              size={size}
              className={`${styles.icon ?? ''} ${styles.micButton ?? ''} ${styles.notListening ?? ''}`}
            />
          </button>
        </div>
      ) : (
        <div
          className="flex flex-col items-center"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <button
            className={`${styles.micButton ?? ''} ${styles.listening ?? ''} ${styles.listening ?? ''}`}
            type="button"
            onClick={handleStopClick}
          >
            <BsFillMicFill className={`${styles.icon ?? ''} ${styles.micButton ?? ''}`} size={size} />
          </button>
          <img src={voicePitches} height="100px" width="100px" style={{ background: 'white-50' }}></img>
          <div className={`${styles.boxContainer ?? ''}`}>
            <div className={`${styles.box ?? ''} ${styles.box1 ?? ''}`} />
            <div className={`${styles.box ?? ''} ${styles.box2 ?? ''}`} />
            <div className={`${styles.box ?? ''} ${styles.box3 ?? ''}`} />
            <div className={`${styles.box ?? ''} ${styles.box4 ?? ''}`} />
            <div className={`${styles.box ?? ''} ${styles.box5 ?? ''}`} />
          </div>
        </div>
      )}

      {transcript && (
        <>
          <div
            className={`mx-auto mt-4 mb-4 w-1/2 rounded-lg bg-slate-100 p-3 font-semibold text-dark ${styles.transcript}`}
          >
            {transcript}
          </div>
          <div
            className={`text-md mx-auto mb-4 w-1/2 rounded-lg p-3  font-semibold first-letter:uppercase ${styles.correctness}`}
            style={{
              color: parseFloat(percentage) > 50 ? '#550' : '#f00',
              backgroundColor: parseFloat(percentage) > 50 ? '#aa0' : '#600',
            }}
          >
            Correctness : {percentage}%
          </div>
        </>
      )}
      <div>
        {onClose && (
          <button
            // className={`btn-primary btn mx-auto  mt-4 mb-4 w-1/2 cursor-pointer disabled:cursor-not-allowed disabled:bg-blue-400 disabled:text-white disabled:opacity-100 ${styles.btnPrimary}`}
            className={`btn-primary btn mx-auto  mt-4 mb-4 w-1/2 cursor-pointer disabled:cursor-not-allowed disabled:bg-blue-400 disabled:text-white disabled:opacity-100 ${
              styles.btnPrimary
            } ${transcript.length === 0 ? styles.disabled : ''}`}
            disabled={transcript.length === 0}
            onClick={() => {
              handleSubmit();
              onClose();
            }}
          >
            Submit
          </button>
        )}
      </div>
      <div
        className="flex flex-row justify-between px-5 py-4"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingTop: '1rem',
          paddingBottom: '1rem',
        }}
      >
        <button
          className="rounded-lg bg-slate-100 p-2 text-slate-500"
          style={{
            backgroundColor: '#4a5568',
            color: 'white',
            padding: '0.5rem',
            borderRadius: '0.5rem',
            cursor: 'pointer',
            border: 'none',
            outline: 'none',
          }}
          onClick={() => setTranscript('')}
        >
          <FaRedoAlt size={30} />
        </button>
        {/* <button
          className="rounded-lg bg-slate-100 p-2 text-slate-500"
          style={{
            backgroundColor: '#4a5568',
            color: 'white',
            padding: '0.5rem',
            borderRadius: '0.5rem',
            cursor: 'pointer',
            border: 'none',
            outline: 'none',
          }}
          onClick={() => setTranscript('')}
        >
          ABC
        </button> */}
      </div>
    </div>
  );
};

export default Dictaphone;

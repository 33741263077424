import React, { useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/lms/features/questions';
import { useDispatch, useSelector } from 'react-redux';
import CustomStepper from 'components/Stepper/CustomStepper';
import ReadingQuestion from './UpdateMainAnswers/ReadingAnswer';
import OtherQuestion from './UpdateMainAnswers/OtherAnwer';
import { RootState } from 'features';
import { tokenSelector } from 'features/auth/authSlice';
import { useReqListener, useViewAuth } from 'hooks';
import Card from 'components/Card/Card';
import { getTotalMarkFromSubAnswerModels, getStudentMarkFromSubAnswerModels } from 'features/helpers';

import {
  getQuizTakenAdminAsync,
  getTakenQuizAsync,
  quizTakenSelector,
  setQuestionIndex,
  setSubQuestionIndex,
} from './updateSlice';
import ListeningAnswer from './UpdateMainAnswers/ListeningAnswer';
import QuestionNavigator from './QuestionsNavigator';
import AnswerRenderer from './AnswerRenderer';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Notification from 'features/notifications/Notification';
import VocabAnswer from './UpdateMainAnswers/VocabAnswer';
import CustomCircularProgress from 'components/Progress/CustomCircularProgress';

const useStyles = makeStyles(styles);
const CorrectQuiz: React.FC = () => {
  const { quizTakenId } = useParams<Record<string, string | undefined>>();
  const quiz = useSelector(quizTakenSelector);
  const getQuizTakenInstructorReq = useSelector((state: RootState) => state.updateQuiz.getQuizTakenAdminReq);
  const getQuizTakenStudentReq = useSelector((state: RootState) => state.updateQuiz.getQuizTakenReq);
  const userRole = useSelector((state: RootState) => state.auth.user.role);
  const token = useSelector(tokenSelector);
  const activeQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.questionIndex);
  // var answers: Types.API.Answer.IAnswerModel[] = [];
  // let totalMarks: number[] = [];
  // let studentMarks: number[] = [];
  const questionsHeaders = quiz.answers.map((answer, index) => {
    return <span id={`HQ${index + 1}`}>{`Question ${index + 1}: ${answer.question.title}`}</span>;
  });
  const dispatch = useDispatch();
  useViewAuth();

  const getQuestionMark = () => {
    if (!quiz.gradePublished) return '0/0';
    let answers: Types.API.Answer.IAnswerModel[] = [];
    let totalMarks: number[] = [];
    let studentMarks: number[] = [];
    const answersString = localStorage.getItem('publishedQuiz');
    if (answersString != null) {
      answers = JSON.parse(answersString);
    }
    totalMarks = getTotalMarkFromSubAnswerModels(answers);
    studentMarks = getStudentMarkFromSubAnswerModels(answers);
    let QDegree = studentMarks[activeQuestionIndex] ? studentMarks[activeQuestionIndex] : '0';
    let TDegree = totalMarks[activeQuestionIndex] ? totalMarks[activeQuestionIndex] : '0';
    return `${QDegree}/${TDegree}`;
  };
  useReqListener({
    status: getQuizTakenInstructorReq.status,
    successMessage: 'Successfully got the quiz for admin',
    loadingMessage: 'Getting the quiz content for admin',
    errorMessage: getQuizTakenInstructorReq.error,
  });
  useReqListener({
    status: getQuizTakenStudentReq.status,
    successMessage: 'Successfully got the quiz for admin',
    loadingMessage: 'Getting the quiz content for admin',
    errorMessage: getQuizTakenStudentReq.error,
  });
  // useEffect(() => {
  //   const answersString = localStorage.getItem('publishedQuiz');
  //   if (answersString != null) {
  //     answers = JSON.parse(answersString);
  //   }
  //   totalMarks = getTotalMarkFromSubAnswerModels(answers);
  //   studentMarks = getStudentMarkFromSubAnswerModels(answers);
  //   let header = document.getElementById('markHolder') as HTMLElement;
  //   let QDegree = studentMarks[activeQuestionIndex] ? studentMarks[activeQuestionIndex] : '0';
  //   let TDegree = totalMarks[activeQuestionIndex] ? totalMarks[activeQuestionIndex] : '0';
  //   header.innerHTML = QDegree + '/' + TDegree;
  // }, [answers]);
  useEffect(() => {
    (userRole === 'student' || userRole === 'parent') &&
      getQuizTakenStudentReq.status === 'idle' &&
      quizTakenId &&
      token &&
      dispatch(getTakenQuizAsync({ token, quizTakenId }));
    userRole === 'admin' &&
      getQuizTakenInstructorReq.status === 'idle' &&
      quizTakenId &&
      token &&
      dispatch(getQuizTakenAdminAsync({ token, quizTakenId }));
  }, [getQuizTakenInstructorReq.status, getQuizTakenStudentReq.status, userRole, token, quizTakenId, dispatch]);
  const handleNext = () => {
    dispatch(setQuestionIndex({ index: activeQuestionIndex + 1 }));
    dispatch(setSubQuestionIndex({ index: 0 }));
  };

  const handleBack = () => {
    activeQuestionIndex > 0 && dispatch(setQuestionIndex({ index: activeQuestionIndex - 1 }));
    dispatch(setSubQuestionIndex({ index: 0 }));
  };

  const handleQuestionIndex = (index: number) => {
    dispatch(setQuestionIndex({ index }));
    dispatch(setSubQuestionIndex({ index: 0 }));
  };

  const getStepContent = (question: Types.API.Question.IQuestionModel) => {
    switch (question.sectionType) {
      case 'Reading':
        return <ReadingQuestion key={`HQ${activeQuestionIndex}`} />;
      case 'Listening':
        return <ListeningAnswer key={`HQ${activeQuestionIndex}`} />;
      case 'Other':
        return <OtherQuestion key={`HQ${activeQuestionIndex}`} />;
      case 'Vocab':
        return <VocabAnswer key={`HQ${activeQuestionIndex}`} />;
      case 'Speaking':
      case 'Writing':
        return <AnswerRenderer key={`HQ${activeQuestionIndex}`} />;
      default:
        return <div key={`HQ${activeQuestionIndex}`}>{question.sectionType}</div>;
    }
  };
  // if im viewing the quiz from the chat i want to detect the question which im discussing
  document.addEventListener('DOMContentLoaded', function () {
    setTimeout(function () {
      if (window.location.href.includes('#HQ')) {
        const headerQuestionNumber = window.location.href
          .split('/')
          .filter(subString => subString.includes('#HQ'))
          .toString()
          .replace('#', '');
        console.log(headerQuestionNumber);
        const targetElement = document.getElementById(headerQuestionNumber);
        if (targetElement) {
          targetElement.click();
          targetElement.style.backgroundColor = 'yellow';
        }
      }
      if (window.location.href.includes('#Q')) {
        const questionNumber = window.location.href
          .split('/')
          .filter(subString => subString.includes('#Q'))
          .toString()
          .replace('#', '');
        console.log(questionNumber);
        const targetElement = document.getElementById(questionNumber);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
          targetElement.style.backgroundColor = 'yellow';
        }
      }
    }, 2000); // 3 seconds delay
  });

  const classes = useStyles();

  if (!quiz.name)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20%',
        }}
      >
        <CustomCircularProgress size={60} />
      </div>
    );

  if (userRole === 'student' && quiz.status === 'ended' && !quiz.gradePublished)
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>You've solved this quiz before .. Grades will be published soon </h2>
      </div>
    );
  else
    return (
      <div className={classes.quizContainer}>
        <div className={classes.flexRow}>
          <IconButton
            disabled={activeQuestionIndex <= 0}
            className={classes.previousSectionButton}
            onClick={handleBack}
          >
            <FontAwesomeIcon size="3x" icon="arrow-alt-circle-left" />
          </IconButton>
          <Card className={classes.container} style={{ marginTop: '1rem' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                fill: 'black',
                color: 'black',
                // padding: '5%',
                // width: '100%',
              }}
            >
              <h3 style={{ fontWeight: 'bold' }}>{quiz.name}</h3>
              <h3
                style={{
                  fontWeight: 'bold',
                  fontSize: '30px',
                  padding: '1%',
                  border: '2px solid green',
                }}
                id="markHolder"
              >
                {getQuestionMark()}
              </h3>
            </div>
            <CustomStepper
              nonLinear={true}
              handleQuestionIndex={handleQuestionIndex}
              steps={questionsHeaders}
              activeStep={activeQuestionIndex}
            />
          </Card>
          <IconButton
            className={classes.nextSectionButton}
            disabled={activeQuestionIndex + 1 >= quiz.answers.length}
            onClick={handleNext}
          >
            <FontAwesomeIcon size="3x" icon="arrow-alt-circle-right" />
          </IconButton>
        </div>
        {quiz.answers.length > 0 &&
          quiz.answers[activeQuestionIndex].question &&
          getStepContent(quiz.answers[activeQuestionIndex].question)}
        <QuestionNavigator questionsLength={questionsHeaders.length} />
        <Notification />
      </div>
    );
};

export default withRouter(CorrectQuiz);

import React from 'react';
import { Modal, Backdrop, Fade, Box, Typography, CircularProgress } from '@material-ui/core';
import Button from 'components/CustomButtons/Button';
import CustomDropdown, { ISelectOption } from 'components/CustomDropdown/CustomDropdown';
import { tokenSelector } from 'features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Pie, PieChart, Tooltip } from 'recharts';
import {
  assignedTaskDetailsSelector,
  availableGroupsSelector,
  getQuizHomeworkDetailsAsync,
  studentsSelector,
  getQuizReportAsync,
  quizReportSelector,
} from '../slices/adminSlice';
import config from 'config';

interface IAssignedTaskDetailsViewProps {
  assignedTask: Types.API.Quiz.IQuizModel | undefined;
  handleClose: Function;
  handleDelete: Function;
}

const AssignedTaskDetailsView = (props: IAssignedTaskDetailsViewProps) => {
  const { assignedTask, handleClose, handleDelete } = props;
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const allStudents = useSelector(studentsSelector);
  const allGroups = useSelector(availableGroupsSelector);
  const [selectedGroup, setSelectedGroup] = React.useState<ISelectOption>({ id: 'all', value: 'All' });
  const [reportDownloadPath, setReportDownloadPath] = React.useState('');
  const [buttonActive, setButtonActive] = React.useState(false);
  const [buttonShown, setButtonShown] = React.useState(false);
  const assignedTaskDetails = useSelector(assignedTaskDetailsSelector);
  const studentsNotStarted = allStudents.filter(s => assignedTaskDetails.details.studentsNotStarted.includes(s.id));
  const quizReport = useSelector(quizReportSelector);
  const filteredStudentesNotStarted = studentsNotStarted.filter(s => {
    if (selectedGroup.id === 'all') return true;
    else return s.group && s.group.id === selectedGroup.id;
  });
  const studentsStarted = allStudents.filter(s => assignedTaskDetails.details.studentsStarted.includes(s.id));
  const filteredStudentesStarted = studentsStarted.filter(s => {
    if (selectedGroup.id === 'all') return true;
    else return s.group && s.group.id === selectedGroup.id;
  });
  const studentsSubmitted = allStudents.filter(s => assignedTaskDetails.details.studentsSubmitted.includes(s.id));
  const filteredStudentsSubmitted = studentsSubmitted.filter(s => {
    if (selectedGroup.id === 'all') return true;
    else return s.group && s.group.id === selectedGroup.id;
  });
  const availableGroups: ISelectOption[] = [];
  availableGroups.push({ id: 'all', value: 'All' });
  assignedTask?.groups.forEach(g => {
    const group = allGroups.find(gr => gr.id === g);
    if (group)
      availableGroups.push({
        id: group.id,
        value: group.name,
      });
  });

  const studentsAnalysis: { state: string; count: number; fill: string }[] = [
    { state: 'Submitted', count: filteredStudentsSubmitted.length, fill: '#42ba96' },
    { state: 'Started', count: filteredStudentesStarted.length, fill: '#57c0e8' },
    { state: 'Not Started', count: filteredStudentesNotStarted.length, fill: '#f32013' },
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (chartArea: {
    cx: any;
    cy: any;
    midAngle: any;
    innerRadius: any;
    outerRadius: any;
    value: any;
    index: any;
  }) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, value } = chartArea;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return value === 0 ? (
      <></>
    ) : (
      <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
        {value}
      </text>
    );
  };
  const CustomTooltip = (props: { active?: any; payload?: any }) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label" style={{ color: 'black' }}>{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };
  React.useEffect(() => {
    if (assignedTask) dispatch(getQuizHomeworkDetailsAsync({ id: assignedTask._id, isHomework: true, token }));
    setSelectedGroup({ id: 'all', value: 'All' });
  }, [assignedTask]);
  React.useEffect(() => {
    if (selectedGroup['id'] && selectedGroup['id'] !== 'all' && assignedTask !== undefined) {
      dispatch(getQuizReportAsync({ groupID: selectedGroup['id'], quizID: assignedTask._id, token }));
      setButtonShown(true);
    } else setButtonShown(false);
  }, [selectedGroup, assignedTask]);
  React.useEffect(() => {
    if (quizReport.path && selectedGroup['value'] && quizReport.path['reportPath'].includes(selectedGroup['value'])) {
      let downloadURL = config.apiBaseUrl.replace('api', '') + 'media/Reports/' + quizReport.path['reportPath'];
      setReportDownloadPath(downloadURL);
      setButtonActive(true);
      console.log(buttonActive, buttonShown, reportDownloadPath);
    } else setButtonActive(false);
  }, [quizReport]);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={assignedTask !== undefined}
        onClose={() => handleClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={assignedTask !== undefined}>
          {assignedTaskDetails.req.status === 'succeeded' ? (
            <Box
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%',
                height: '80%',
                background: 'whitesmoke',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                padding: '40px',
                overflowY: 'scroll',
              }}
            >
              <div>
                <Typography id="transition-modal-title" variant="h6" component="h2">
                  {assignedTask?.name}
                </Typography>
                <Button
                  round
                  color="danger"
                  size="sm"
                  fullWidth
                  onClick={() => {
                    handleDelete(assignedTask?._id);
                  }}
                >
                  Delete
                </Button>
                <CustomDropdown
                  buttonText={selectedGroup.value}
                  dropdownList={availableGroups}
                  onClick={v => {
                    if (v.id) setSelectedGroup(v);
                  }}
                />
              </div>

              <PieChart width={250} height={250} style={{ alignSelf: 'center' }}>
                <Pie
                  data={studentsAnalysis}
                  dataKey="count"
                  nameKey="state"
                  label={renderCustomizedLabel}
                  labelLine={false}
                />
                <Tooltip
                  content={(props: any) => {
                    return <CustomTooltip active={props.active} payload={props.payload} />;
                  }}
                />
              </PieChart>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Typography id="transition-modal-title" variant="h6" component="h3">
                    Did Not Start
                  </Typography>
                  {filteredStudentesNotStarted.map(s => (
                    <Typography>{s.name}</Typography>
                  ))}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Typography id="transition-modal-title" variant="h6" component="h3">
                    Started
                  </Typography>
                  {filteredStudentesStarted.map(s => (
                    <Typography>{s.name}</Typography>
                  ))}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Typography id="transition-modal-title" variant="h6" component="h3">
                    Submitted
                  </Typography>
                  {filteredStudentsSubmitted.map(s => (
                    <Typography>{s.name}</Typography>
                  ))}
                </div>
              </div>
              <br />
              {buttonShown && buttonActive && (
                <div>
                  <Button color="primary" round size="sm">
                    <a href={reportDownloadPath} style={{ color: '#FFFFFF' }} target="_blank" download>
                      Download Report
                    </a>
                  </Button>
                </div>
              )}
              {buttonShown && !buttonActive && (
                <div>
                  <Button disabled round size="sm">
                    Loading Report
                  </Button>
                </div>
              )}
            </Box>
          ) : (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                minHeight: '100vh',
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Fade>
      </Modal>
    </div>
  );
};

export default AssignedTaskDetailsView;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
interface AdminViewState {
  curTabIndex: number;
}
const initialState: AdminViewState = {
  curTabIndex: 0,
};

// Async updates to the store

export const slice = createSlice({
  name: 'adminView',
  initialState,
  reducers: {
    // Sync updates to the store
    setCurrentTabIndex: (state, action: PayloadAction<{ curTabIndex: number }>) => {
      state.curTabIndex = action.payload.curTabIndex;
    },
  },
});

export const { setCurrentTabIndex } = slice.actions;

export const curTabIndexSelector = (state: RootState) => state.adminView.curTabIndex;

export default slice.reducer;

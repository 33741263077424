import { Button, MobileStepper, makeStyles } from '@material-ui/core';
import styles from 'assets/jss/lms/features/questions';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import React from 'react';
import { RootState } from 'features';
import { useDispatch, useSelector } from 'react-redux';
import { questionWithIndexSelector, setSubQuestionIndex } from '../updateSlice';
import UpdateStudentWriting from '../UpdateSimpleAnswers/UpdateStudentWriting';
import UpdateStudentSpeaking from '../UpdateSimpleAnswers/UpdateStudentSpeaking';

import { userSelector } from 'features/auth/authSlice';
const useStyles = makeStyles(styles);

const AnswerRightScreen: React.FC = () => {
  const classes = useStyles();
  React.useEffect(() => {
    console.log('Change Right');
  }, []);
  const dispatch = useDispatch();
  const activeQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.questionIndex);
  console.log(activeQuestionIndex);
  const questions = useSelector(questionWithIndexSelector(activeQuestionIndex)).questions;
  const steps = questions.map((q, i) => ({
    label: `Answer Question #${i + 1}`,
    modelType: q.modelType,
  }));
  const maxLength = steps.length;
  const userRole = useSelector(userSelector).role;
  const activeSubQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.subQuestionIndex);
  console.log(activeQuestionIndex);
  const handleNext = () => {
    dispatch(setSubQuestionIndex({ index: activeSubQuestionIndex + 1 }));
  };
  const handleBack = () => {
    dispatch(setSubQuestionIndex({ index: activeSubQuestionIndex - 1 }));
  };
  return (
    <div
      style={{
        marginTop: '3rem',
        // backgroundColor: 'white',
      }}
    >
      <h4 className={classes.questionHeader} style={{ whiteSpace: 'pre-line' }}>
        {steps[activeSubQuestionIndex].label}
      </h4>
      {getStepContent(steps[activeSubQuestionIndex].modelType, activeQuestionIndex, activeSubQuestionIndex)}
      {(userRole === 'student' || userRole === 'parent') && (
        <MobileStepper
          steps={maxLength}
          position="static"
          variant="text"
          activeStep={activeSubQuestionIndex}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={activeSubQuestionIndex === maxLength - 1}>
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeSubQuestionIndex === 0}>
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      )}
    </div>
  );
};
const getStepContent = (
  modelType: Types.API.Question.QuestionModelType,
  activeQuestionIndex: number,
  activeSubQuestionIndex: number,
) => {
  switch (modelType) {
    case 'SpeakingPhotoDescription':
    case 'SpeakingQA':
      return <UpdateStudentSpeaking />;
    case 'WritingEssays':
    case 'WritingSummaries':
    case 'WritingPhotoDescription':
      return <UpdateStudentWriting />;
    default:
      return null;
  }
};
export default AnswerRightScreen;

import React, { FC, useState } from 'react';
import GridContainer from 'components/Grid/GridContainer';
// import GridItem from 'components/Grid/GridItem';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImageFileAsync, filesRequestsSelector } from '../createSlice';

import CustomDropzone from 'components/CustomDropzone/CustomDropzone';
import { tokenSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import { Backdrop, Button, CircularProgress } from '@material-ui/core';

interface Props {
  uuid: string;
}

const CreateQImage: FC<Props> = ({ uuid }) => {
  const fileRequest = useSelector(filesRequestsSelector(uuid))[0];
  const token = useSelector(tokenSelector);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
    const [uploadedImage, setUploadedImage] = useState<File | null>(null); // State to store the uploaded image

  const onSave = (acceptedFiles: File[]) => {
    const image = acceptedFiles[acceptedFiles.length - 1];
    if (image) {
      setUploadedImage(image); // Set the uploaded image to state
      dispatch(uploadImageFileAsync({ file: image, token, id: uuid }));
    }
    setOpen(false);
  };
  useReqListener({
    status: fileRequest.req.status,
    successMessage: 'The Image file was inserted in our storage successfully',
    loadingMessage: 'Adding Image file to the storage',
    errorMessage: fileRequest.req.error,
  });
  const onUploadClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

 
  return (
    <div>
      <GridContainer
        justify="center"
        style={{ width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '2rem' }}
      >
        <Button variant="contained" fullWidth onClick={onUploadClick}>
          Upload your image
        </Button>
        <CustomDropzone
          onClose={onClose}
          open={open}
          filesLimit={1}
          initialFiles={[]}
          accept={['image/*']}
          onSave={onSave}
        />
      </GridContainer>
      <Backdrop
        color="#fff"
        style={{
          zIndex: 1000,
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
        }}
        open={fileRequest.req.status === 'loading'}
      >
        <CircularProgress color="inherit" style={{ marginBottom: '20px' }} />
        <p>UPLOADING...</p>
      </Backdrop>
        {uploadedImage && ( // Display the uploaded image preview
          <img src={URL.createObjectURL(uploadedImage)} alt="Uploaded Preview" style={{ marginTop: '1rem', width: '25rem',height:'20rem',marginRight:'auto',marginLeft:'auto',objectFit:'cover' }} />
        )}
    </div>
  );
};

export default CreateQImage;

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/lms/features/questions';
import Button from 'components/CustomButtons/Button';
import ReadingQuestionsArticles from 'features/questions/CreateQReadingArticles';
import CreateQAudio from 'features/questions/CreateQAudio';
import CreateQTitleAndCategories from 'features/questions/CreateQTitleAndCategories';
import CreateQList from 'features/questions/CreateQList';
import { useDispatch, useSelector } from 'react-redux';

import {
  createQuestionStatusSelector,
  categorySelector,
  diplomaSelector,
  gradeSelector,
  levelSelector,
  titleSelector,
  vocabsSelector,
  createAsync,
  readingArticleSelector,
  questionsSelector,
  sectionTypeSelector,
  audioFileIdSelector,
} from 'features/questions/createSlice';
import { tokenSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import CustomStepper from 'components/Stepper/CustomStepper';
import { getCategoriesAsync, getDiplomasAsync, getGradesAsync, getLevelsAsync } from 'features/admin/slices/adminSlice';
import Primary from 'components/Typography/Primary';
import FormikDoc from 'components/Chips/Chips';

const useStyles = makeStyles(styles);

const getStepContent = (step: number, questionType: Types.API.Question.QuestionSectionType) => {
  if (step === 0) return <CreateQTitleAndCategories />;
  switch (questionType) {
    case 'Reading':
      switch (step) {
        case 1:
          return <ReadingQuestionsArticles />;
        case 2:
          return <CreateQList />;
        case 3:
          return (
            <h6>
              <Primary>All Steps Completed, You should see the student sheet preview here</Primary>
            </h6>
          );
      }
      break;
    case 'Listening':
      switch (step) {
        case 1:
          return <CreateQAudio />;
        case 2:
          return <CreateQList />;
        case 3:
          return (
            <h6>
              <Primary>All Steps Completed, You should see the student sheet preview here</Primary>
            </h6>
          );
      }
      break;
    case 'Speaking':
    case 'Writing':
      switch (step) {
        case 1:
          return <CreateQList />;
        case 2:
          return (
            <h6>
              <Primary>All Steps Completed, You should see the student sheet preview here</Primary>
            </h6>
          );
      }
      break;
    case 'Vocab':
      switch (step) {
        case 1:
          return <FormikDoc />;
        case 2:
          return <CreateQList />;
        case 3:
          return (
            <h6>
              <Primary>All Steps Completed, You should see the student sheet preview here</Primary>
            </h6>
          );
      }
      break;
  }
  return null;
};

const getSteps = (questionType: Types.API.Question.QuestionSectionType) => {
  switch (questionType) {
    case 'Reading':
      return ['Question Title & Categories', 'Reading Articles', 'Simple Questions', 'Review & Submit'].map(
        (item, index) => <span key={index}>{item}</span>,
      );
    case 'Listening':
      return ['Question Title & Categories', 'Audio File', 'Simple Questions', 'Review & Submit'].map((item, index) => (
        <span key={index}>{item}</span>
      ));
    case 'Speaking':
    case 'Writing':
      return ['Question Title & Categories', 'Simple Questions', 'Review & Submit'].map((item, index) => (
        <span key={index}>{item}</span>
      ));
    case 'Vocab':
      return ['Question Title & Categories', 'Vocabs', 'Simple Questions', 'Review & Submit'].map((item, index) => (
        <span key={index}>{item}</span>
      ));
  }
  return [];
};

const CreateQuestion: React.FC = () => {
  const createQuestionStatus = useSelector(createQuestionStatusSelector);
  useReqListener({
    status: createQuestionStatus.status,
    successMessage: 'Question Added Successfully to the database',
    loadingMessage: 'Adding the question to the database',
    errorMessage: createQuestionStatus.error,
  });
  const title = useSelector(titleSelector);
  const vocabs = useSelector(vocabsSelector);
  const sectionType = useSelector(sectionTypeSelector);
  const questionsElements = useSelector(questionsSelector);
  const categoryType = useSelector(categorySelector);
  const levelType = useSelector(levelSelector);
  const gradeType = useSelector(gradeSelector);
  const diplomaType = useSelector(diplomaSelector);
  const token = useSelector(tokenSelector);
  const readingArticles = useSelector(readingArticleSelector);
  const audioFileId = useSelector(audioFileIdSelector);

  const steps = getSteps(sectionType.id as Types.API.Question.QuestionSectionType);

  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  useEffect(() => {
    // Fetch data
    if (dispatch && token) {
      dispatch(getCategoriesAsync({ token }));
      dispatch(getGradesAsync({ token }));
      dispatch(getLevelsAsync({ token }));
      dispatch(getDiplomasAsync({ token }));
    }
  }, [dispatch, token]);

  const classes = useStyles();
  return (
    <div>
      <CustomStepper steps={steps} activeStep={activeStep} />
      <div className={classes.paper}>
        {getStepContent(activeStep, sectionType.id as Types.API.Question.QuestionSectionType)}
        <div className={classes.stepperButtons}>
          <Button className={classes.NextButton} disabled={activeStep === 0} onClick={handleBack} color="github">
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button
              color="success"
              onClick={_ => {
                dispatch(
                  createAsync({
                    token,
                    question: {
                      title,
                      sectionType: sectionType.id as Types.API.Question.QuestionSectionType,
                      category: categoryType.id,
                      diploma: diplomaType.id,
                      grade: gradeType.id,
                      level: levelType.id,
                      readingTexts: readingArticles.length === 0 ? undefined : readingArticles,
                      vocabs: vocabs.length === 0 ? undefined : vocabs,
                      questions: Object.values(questionsElements),
                      listeningAudio: audioFileId === '' ? undefined : audioFileId,
                    },
                  }),
                );
                // localStorage.setItem('pageIndex', '5');
                // window.location.reload();
              }}
              size="lg"
            >
              Submit Question
            </Button>
          ) : (
            <Button className={classes.BackButton} color="primary" onClick={handleNext}>
              {activeStep === steps.length - 2 ? 'Review & Submit' : 'Next'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateQuestion;

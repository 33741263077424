import { createStyles, Theme } from '@material-ui/core/styles';
import { primaryColor, warningColor, dangerColor, successColor, infoColor, roseColor, grayColor } from '../../lms';

const customCircularProgressStyle = (theme: Theme) =>
  createStyles({
    root: {
      float: 'left',
      marginRight: '1.071rem',
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
      color: '#1a90ff',
      animationDuration: '550ms',
    },
    circle: {
      strokeLinecap: 'round',
    },
    primary: {
      backgroundColor: primaryColor,
    },
    warning: {
      backgroundColor: warningColor,
    },
    danger: {
      backgroundColor: dangerColor,
    },
    success: {
      backgroundColor: successColor,
    },
    info: {
      backgroundColor: infoColor,
    },
    rose: {
      backgroundColor: roseColor,
    },
    gray: {
      backgroundColor: grayColor,
    },
    primaryBackground: {
      background: 'rgba(229, 45, 39, 0.2)',
    },
    warningBackground: {
      background: 'rgba(255, 152, 0, 0.2)',
    },
    dangerBackground: {
      background: 'rgba(244, 67, 54, 0.2)',
    },
    successBackground: {
      background: 'rgba(76, 175, 80, 0.2)',
    },
    infoBackground: {
      background: 'rgba(0, 188, 212, 0.2)',
    },
    roseBackground: {
      background: 'rgba(233, 30, 99, 0.2)',
    },
    grayBackground: {
      background: 'rgba(221, 221, 221, 0.2)',
    },
  });

export default customCircularProgressStyle;

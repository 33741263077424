import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OptionType } from 'components/Autocomplete/Autocomplete';
import { RootState } from 'features';
import { uploadMultipleFiles, uploadSingleFile, uploadSubQuestionFile } from 'features/admin/client';
import { getQuestion, editQuestion } from './client';
const short = require('short-uuid');

interface FilesState {
  filesState: 'initial' | 'editing' | 'uploading' | 'uploaded';
  filesBufferToUpload: File[];
  uploadedFiles: { files: Types.API.File.IFileModel[]; req: Types.IRequestState };
  // File requests is a map for questions ids, to files related to this question
}

interface IEditQuestionState {
  getQuestionToEditReq: Types.IRequestState;
  submitEditedReq: Types.IRequestState;
  editingQuestion: Types.API.Question.IQuestionModel;
  files: FilesState;
}

const initialState: IEditQuestionState = {
  getQuestionToEditReq: { status: 'idle', error: null },
  submitEditedReq: { status: 'idle', error: null },
  editingQuestion: {} as Types.API.Question.IQuestionModel,
  files: {
    filesState: 'initial',
    filesBufferToUpload: [],
    uploadedFiles: { files: [], req: { status: 'idle', error: null } },
  },
};

export const getQuestionToEditAsync = createAsyncThunk('questions/getQuestion', getQuestion);
export const editAsync = createAsyncThunk('questions/edit', editQuestion);
export const uploadSingleFileAsync = createAsyncThunk('questions/uploadSingleFile', uploadSingleFile);
export const uploadMultipleFilesAsync = createAsyncThunk('questions/uploadMultipleFiles', uploadMultipleFiles);
export const updateImageFileAsync = createAsyncThunk('questions/updateImageFile', uploadSubQuestionFile);

export const slice = createSlice({
  name: 'editSlice',
  initialState,
  reducers: {
    addQuestion: (state, action: PayloadAction<{}>) => {
      const uuid = short.generate();
      console.log(uuid);
      console.log(JSON.stringify(state.editingQuestion, undefined, 2));
      console.log('Any');
      // switch (state.editingQuestion.sectionType as Types.API.Question.QuestionSectionType) {
      //   case 'Reading':
      //     console.log(1);
      //     break;
      //   case 'Writing':
      //     console.log(2);
      //     break;
      //   case 'Speaking':
      //     console.log(3);
      //     break;
      //   case 'Listening':
      //     console.log(4);
      //     break;
      //     state.curQuestion.questions[uuid] = NoteTakingPlaceHolder;
      //     break;
      //   case 'Writing':
      //     state.curQuestion.questions[uuid] = WritingPhotoDescriptionPlaceHolder;
      //     state.curQuestion.filesRequests[uuid] = [{ req: { status: 'idle', error: null }, fileId: '' }];
      //     break;
      //   case 'Speaking':
      //     state.curQuestion.questions[uuid] = SpeakingPhotoDescriptionPlaceHolder;
      //     state.curQuestion.filesRequests[uuid] = [{ req: { status: 'idle', error: null }, fileId: '' }];
      //     break;
      //   case 'Listening':
      //     state.curQuestion.questions[uuid] = SpeakerMatchingPlaceHolder;
      //     break;
    },
    setTitle: (state, action: PayloadAction<{ text: string }>) => {
      state.editingQuestion.title = action.payload.text;
    },
    setSectionType: (state, action: PayloadAction<OptionType>) => {
      if (state.editingQuestion)
        state.editingQuestion.sectionType = action.payload.id as Types.API.Question.QuestionSectionType;
    },
    setCategoryType: (state, action: PayloadAction<Types.IOptionType>) => {
      state.editingQuestion.category = { _id: action.payload.id, name: action.payload.name };
    },
    setLevelType: (state, action: PayloadAction<Types.IOptionType>) => {
      state.editingQuestion.level = { _id: action.payload.id, name: action.payload.name };
    },
    setGradeType: (state, action: PayloadAction<Types.IOptionType>) => {
      state.editingQuestion.grade = { _id: action.payload.id, name: action.payload.name };
    },
    setDiplomaType: (state, action: PayloadAction<Types.IOptionType>) => {
      state.editingQuestion.diploma = { _id: action.payload.id, name: action.payload.name };
    },
    setVocabs: (state, action: PayloadAction<{ vocabs: string[] }>) => {
      state.editingQuestion.vocabs = action.payload.vocabs;
    },
    resetGetQuestionState: (state, _) => {
      state.getQuestionToEditReq.status = 'idle';
    },
    addReadingArticle: state => {
      if (state.editingQuestion.readingTexts) state.editingQuestion.readingTexts.push('');
      else state.editingQuestion.readingTexts = [''];
    },
    updateArticleAt: (state, action: PayloadAction<{ text: string; index: number }>) => {
      if (state.editingQuestion.readingTexts)
        state.editingQuestion.readingTexts[action.payload.index] = action.payload.text;
    },
    removeReadingArticleAt: (state, action: PayloadAction<{ index: number }>) => {
      if (state.editingQuestion.readingTexts) {
        const part1 = state.editingQuestion.readingTexts.slice(0, action.payload.index);
        const part2 = state.editingQuestion.readingTexts.slice(
          action.payload.index + 1,
          state.editingQuestion.readingTexts.length,
        );
        state.editingQuestion.readingTexts = part1.concat(part2);
      }
    },
    removeQuestion: (state, action: PayloadAction<{ uuid: string }>) => {
      state.editingQuestion.questions.splice(parseInt(action.payload.uuid), 1);
      state.editingQuestion.__v += 1;
    },
    setMCQText: (state, action: PayloadAction<{ uuid: string; text: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQModel).text = action.payload.text;
    },
    setMCQMark: (state, action: PayloadAction<{ uuid: string; value: number }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQModel).mark =
        action.payload.value;
    },
    addMCQCorrection: (state, action: PayloadAction<{ uuid: string; correctionIndex: number }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQModel).correction.answer.push(
        (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQModel).choices[
          action.payload.correctionIndex
        ],
      );
    },
    removeMCQCorrection: (
      state,
      action: PayloadAction<{ uuid: string; correctionIndex: number; removedValue: string }>,
    ) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQModel).correction.answer = (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQModel
      ).correction.answer.filter(c => c !== action.payload.removedValue);
    },
    updateMCQCorrectionWhy: (
      state,
      action: PayloadAction<{
        uuid: string;
        correctionWhy: { pIndex: number; start: string; end: string };
        index: number;
      }>,
    ) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQModel;
      if (!question.correction.why === undefined) question.correction.why = [];
      if (question && question.correction && question.correction.why)
        question.correction.why[action.payload.index] = action.payload.correctionWhy;
    },
    updateMCQMarksAt: (
      state,
      action: PayloadAction<{
        uuid: string;
        value: number;
        index: number;
      }>,
    ) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQModel).correction.marks[
        action.payload.index
      ] = action.payload.value;
    },
    addMCQOption: (state, action: PayloadAction<{ uuid: string }>) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQModel;
      question.choices.push('');
      if (!question.correction.why) question.correction.why = [];
      if (question && question.correction.why)
        question.correction.why.push({
          pIndex: -1,
          start: '-1',
          end: '-1',
        });
      question.correction.marks.push(0);
    },
    updateMCQOption: (state, action: PayloadAction<{ uuid: string; index: number; value: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQModel).choices[
        action.payload.index
      ] = action.payload.value;
    },
    removeMCQOptionAt: (state, action: PayloadAction<{ uuid: string; index: number }>) => {
      const choicesPart1 = (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQModel
      ).choices.slice(0, action.payload.index);
      const choicesPart2 = (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQModel
      ).choices.slice(action.payload.index + 1);
      const newChoices = choicesPart2.concat(choicesPart1);
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQModel).choices = newChoices;
      /** Marks */
      const marksPart1 = (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQModel
      ).correction.marks.slice(0, action.payload.index);
      const marksPart2 = (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQModel
      ).correction.marks.slice(action.payload.index + 1);
      const newMarks = marksPart1.concat(marksPart2);
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IMCQModel).correction.marks =
        newMarks;
    },

    setWritingSummariesText: (state, action: PayloadAction<{ text: string; uuid: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingSummariesQModel).text =
        action.payload.text;
    },
    setContentMarkWritingSummaries: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingSummariesQModel).contentMark =
        action.payload.value;
    },
    setWritingSummariesPassage: (state, action: PayloadAction<{ text: string; uuid: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingSummariesQModel).passage =
        action.payload.text;
    },
    setLanguageMarkWritingSummaries: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingSummariesQModel
      ).languageMark = action.payload.value;
    },
    setWritingPhotoDescriptionText: (state, action: PayloadAction<{ text: string; uuid: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingPhotoDescriptionQModel).text =
        action.payload.text;
    },
    setContentMarksWritingPhotoDescription: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingPhotoDescriptionQModel
      ).contentMark = action.payload.value;
    },
    setLanguageMarksWritingPhotoDescription: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingPhotoDescriptionQModel
      ).languageMark = action.payload.value;
    },
    setWritingEssaysText: (state, action: PayloadAction<{ text: string; uuid: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingEssaysQModel).text =
        action.payload.text;
    },
    setContentMarksWritingEssays: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingEssaysQModel).contentMark =
        action.payload.value;
    },
    setLanguageMarksWritingEssays: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IWritingEssaysQModel).languageMark =
        action.payload.value;
    },
    setSpeakingQAQuestionText: (state, action: PayloadAction<{ text: string; uuid: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakingQAQModel).question =
        action.payload.text;
    },
    setCommunicationMarksSpeakingQA: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakingQAQModel).communicationMark =
        action.payload.value;
    },
    setLanguageKnowledgeMarksSpeakingQA: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakingQAQModel
      ).languageKnowledgeMark = action.payload.value;
    },
    setPronounciationInonationMarksSpeakingQA: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakingQAQModel
      ).pronounciationIntonationMark = action.payload.value;
    },
    setSpontaneityFluencyMarksSpeakingQA: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakingQAQModel
      ).spontaneityFluencyMark = action.payload.value;
    },
    setSpeakingPhotoDescriptionText: (state, action: PayloadAction<{ text: string; uuid: string }>) => {
      (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakingPhotoDescriptionQModel
      ).text = action.payload.text;
    },
    setLanguageKnowledgeMarksSpeakingPhotoDescription: (
      state,
      action: PayloadAction<{ value: number; uuid: string }>,
    ) => {
      (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakingPhotoDescriptionQModel
      ).languageKnowledgeMark = action.payload.value;
    },
    setCommunicationMarksSpeakingPhotoDescription: (state, action: PayloadAction<{ value: number; uuid: string }>) => {
      (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakingPhotoDescriptionQModel
      ).communicationMark = action.payload.value;
    },
    setSpeakerMatchingSpeakersCount: (state, action: PayloadAction<{ uuid: string; value: number }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel).speakersCount =
        action.payload.value;
    },
    increaseSpeakerMatchingSpeakersCount: (state, action: PayloadAction<{ uuid: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel)
        .speakersCount++;
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel).speakers.push({
        name: '',
        opinion: '',
      });
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel).opinions.push(
        '',
      );
      (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel
      ).correction.answer.push({
        name: '',
        opinion: '',
      });
      (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel
      ).correction.marks.push(1);

      (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel
      ).correction.why?.push({
        pIndex: -1,
        start: '-1',
        end: '-1',
      });
    },
    decreaseSpeakerMatchingSpeakersCount: (state, action: PayloadAction<{ uuid: string }>) => {
      const speakersCount = (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel
      ).speakersCount;
      if (speakersCount > 0) {
        (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel)
          .speakersCount--;
        (
          state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel
        ).opinions.pop();
        (
          state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel
        ).speakers.pop();
        (
          state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel
        ).correction.answer.pop();
        (
          state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel
        ).correction.marks.pop();
        (
          state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel
        ).correction.why?.pop();
      }
    },
    setSpeakerMatchingName: (state, action: PayloadAction<{ uuid: string; text: string; index: number }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel).speakers[
        action.payload.index
      ].name = action.payload.text;
      (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel
      ).correction.answer[action.payload.index].name = action.payload.text;
    },
    setSpeakerMatchingOpinion: (state, action: PayloadAction<{ uuid: string; text: string; index: number }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel).speakers[
        action.payload.index
      ].opinion = action.payload.text;
      (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel
      ).correction.answer[action.payload.index].opinion = action.payload.text;
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel).opinions[
        action.payload.index
      ] = action.payload.text;
    },
    setSpeakerMatchingWrongOpinion: (state, action: PayloadAction<{ uuid: string; text: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel).opinions[0] =
        action.payload.text;
      (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel
      ).speakers[0].opinion = action.payload.text;
      (
        state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel
      ).correction.answer[0].opinion = action.payload.text;
    },
    setSpeakerMatchingMark: (state, action: PayloadAction<{ uuid: string; value: number }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel).mark =
        action.payload.value;
    },
    setSpeakerMatchingCorrectionWhy: (
      state,
      action: PayloadAction<{
        uuid: string;
        index: number;
        why: { pIndex: number; start: string; end: string };
      }>,
    ) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.ISpeakerMatchingModel;
      if (question.correction.why) question.correction.why[action.payload.index] = action.payload.why;
    },
    setShortAnswerText: (state, action: PayloadAction<{ uuid: string; text: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersModel).text =
        action.payload.text;
    },
    setRubricText: (state, action: PayloadAction<{ uuid: string; text: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.RubricCreateInput).text =
        action.payload.text;
    },
    setShortAnswersAnswerCount: (state, action: PayloadAction<{ uuid: string; value: number }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersModel).answersCount =
        action.payload.value;
    },
    increaseShortAnswersModelAnswersCount: (state, action: PayloadAction<{ uuid: string }>) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersModel;

      question.modelAnswersCount++;
      if (question.correction !== undefined) {
        question.correction.answer.push('');
        question.correction.why?.push({
          pIndex: -1,
          start: '-1',
          end: '-1',
        });
        question.correction.marks.push(1);
      }
    },
    decreaseShortAnswersModelAnswersCount: (state, action: PayloadAction<{ uuid: string }>) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersModel;
      question.modelAnswersCount--;
      if (question.correction !== undefined) {
        question.correction.answer.pop();
        question.correction.marks.pop();
        question.correction.why?.pop();
      }
    },
    setShortAnswerCorrection: (state, action: PayloadAction<{ uuid: string; correction: string; index: number }>) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersModel;
      question.correction.answer[action.payload.index] = action.payload.correction;
      /** Put the marks here */
    },
    setShortAnswerCorrectionWhy: (
      state,
      action: PayloadAction<{
        uuid: string;
        index: number;
        why: { pIndex: number; start: string; end: string };
      }>,
    ) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersModel;
      if (question.correction.why) question.correction.why[action.payload.index] = action.payload.why;
    },
    setShortAnswerMark: (state, action: PayloadAction<{ uuid: string; value: number }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IShortAnswersModel).mark =
        action.payload.value;
    },
    setNoteTakingText: (state, action: PayloadAction<{ uuid: string; text: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingModel).text =
        action.payload.text;
    },
    setNoteTakingMark: (state, action: PayloadAction<{ uuid: string; value: number }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingModel).mark =
        action.payload.value;
    },
    updateNoteTakingCorrection: (
      state,
      action: PayloadAction<{
        uuid: string;
        noteIndex: number;
        noteAnswerIndex: number;
        value: string;
      }>,
    ) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingModel;
      question.correction.answer[action.payload.noteIndex][action.payload.noteAnswerIndex] = action.payload.value;
    },
    updateNoteTakingCorrectionWhy: (
      state,
      action: PayloadAction<{
        uuid: string;
        noteIndex: number;
        noteAnswerIndex: number;
        value: { pIndex: number; start: string; end: string };
      }>,
    ) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingModel;
      if (question.correction.why)
        question.correction.why[action.payload.noteIndex][action.payload.noteAnswerIndex] = action.payload.value;
    },
    addNoteTakingNote: (state, action: PayloadAction<{ uuid: string }>) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingModel;
      question.notes.push({
        text: '',
        modelAnswersCount: 1,
        answersCount: 1,
      });
      question.correction.answer.push(['']);
      question.correction.marks.push([1]);
      question.correction.why?.push([{ pIndex: -1, start: '-1', end: '-1' }]);
    },
    updateNoteTakingText: (state, action: PayloadAction<{ uuid: string; index: number; value: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingModel).notes[
        action.payload.index
      ].text = action.payload.value;
    },
    updateNoteTakingAnswersCount: (state, action: PayloadAction<{ uuid: string; index: number; value: number }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingModel).notes[
        action.payload.index
      ].answersCount = action.payload.value;
    },
    updateNoteTakingModelAnswersCount: (
      state,
      action: PayloadAction<{ uuid: string; index: number; value: number }>,
    ) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingModel;
      question.notes[action.payload.index].modelAnswersCount = action.payload.value;
      const correctionAnswerArray = question.correction.answer[action.payload.index];
      const correctionWhyArray = question.correction.why ? question.correction.why[action.payload.index] : [];
      const correctionMarksArray = question.correction.marks[action.payload.index];
      if (correctionAnswerArray.length > action.payload.value) {
        question.correction.answer[action.payload.index] = correctionAnswerArray.slice(0, action.payload.value);
        if (question.correction.why)
          question.correction.why[action.payload.index] = correctionWhyArray.slice(0, action.payload.value);
        else {
          question.correction.why = [];
          question.correction.why[action.payload.index] = correctionWhyArray.slice(0, action.payload.value);
        }
        question.correction.marks[action.payload.index] = correctionMarksArray.slice(0, action.payload.value);
      } else if (correctionAnswerArray.length < action.payload.value)
        while (correctionAnswerArray.length < action.payload.value) {
          correctionAnswerArray.push('');
          correctionMarksArray.push(1);
          correctionWhyArray.push({ pIndex: -1, start: '-1', end: '-1' });
        }
    },
    reorderQuestion: (state, action: PayloadAction<{ uuid: string; direction: boolean }>) => {
      const keys = Object.keys(state.editingQuestion.questions);
      const index = keys.indexOf(action.payload.uuid);
      if ((index === 0 && action.payload.direction) || (index === keys.length - 1 && !action.payload.direction)) return;
      var newOrder = [];
      if (action.payload.direction) {
        newOrder = keys
          .slice(0, index - 1)
          .concat([keys[index], keys[index - 1]])
          .concat(keys.slice(index + 1));
      } else {
        newOrder = keys
          .slice(0, index)
          .concat([keys[index + 1], keys[index]])
          .concat(keys.slice(index + 2));
      }
      var obj = [];
      for (const elem in newOrder) {
        obj.push(state.editingQuestion.questions[newOrder[elem]]);
      }
      state.editingQuestion.__v += 1;
      state.editingQuestion.questions = obj;
    },
    removeNoteTakingNoteAt: (state, action: PayloadAction<{ uuid: string; index: number }>) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.INoteTakingModel;
      const choicesPart1 = question.notes.slice(0, action.payload.index);
      const choicesPart2 = question.notes.slice(action.payload.index + 1);
      const newNotes = choicesPart2.concat(choicesPart1);
      question.notes = newNotes;
      const correctionPart1 = question.correction.answer.slice(0, action.payload.index);
      const correctionPart2 = question.correction.answer.slice(action.payload.index + 1);
      const newCorrections = correctionPart2.concat(correctionPart1);
      const marksPart1 = question.correction.marks.slice(0, action.payload.index);
      const marksPart2 = question.correction.marks.slice(action.payload.index + 1);
      const newMarks = marksPart1.concat(marksPart2);
      const correctionWhyPart1 = question.correction.why?.slice(0, action.payload.index);
      const correctionWhyPart2 = question.correction.why?.slice(action.payload.index + 1);
      const newCorrectionsWhy = correctionWhyPart2?.concat(correctionWhyPart1 ?? []);
      question.correction.answer = newCorrections;
      question.correction.marks = newMarks;
      question.correction.why = newCorrectionsWhy;
    },
    addTextToFillInBlanks: (state, action: PayloadAction<{ uuid: string }>) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksModel;
      const isFirstInserted = question.texts.length === 0 && question.correction.answer.length === 0;
      question.texts.push('');
      question.lastInserted = 'text';
      if (isFirstInserted) question.firstInserted = 'text';
    },
    addGapToFillInBlanks: (state, action: PayloadAction<{ uuid: string }>) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksModel;
      const isFirstInserted = question.texts.length === 0 && question.correction.answer.length === 0;
      question.texts.push('____GAP____');
      question.correction.answer.push('');
      question.correction.marks.push(1);
      if (!question.correction.why) question.correction.why = [];
      question.correction.why.push({
        pIndex: -1,
        start: '-1',
        end: '-1',
      });
      question.lastInserted = 'gap';
      if (isFirstInserted) question.firstInserted = 'gap';
    },
    removeElementFillInTheBlank: (state, action: PayloadAction<{ uuid: string; index: number; isGap: boolean }>) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksModel;
      var deleteIndex = action.payload.index;
      if (action.payload.isGap) {
        var gapIndex = action.payload.index;
        question.correction.answer.splice(gapIndex, 1);
        question.correction.marks.splice(gapIndex, 1);
        question.correction.why?.splice(gapIndex, 1);
        deleteIndex = -1;
        while (gapIndex >= 0) {
          deleteIndex++;
          if (question.texts[deleteIndex].includes('___')) gapIndex--;
        }
      }
      question.texts.splice(deleteIndex, 1);
    },

    setFillInBlanksMark: (state, action: PayloadAction<{ uuid: string; value: number }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksModel).mark =
        action.payload.value;
    },
    updateTextFillInBlanksAt: (state, action: PayloadAction<{ uuid: string; index: number; value: string }>) => {
      (state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksModel).texts[
        action.payload.index
      ] = action.payload.value;
    },
    updateGapFillInBlanksAt: (state, action: PayloadAction<{ uuid: string; index: number; value: string }>) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksModel;
      question.correction.answer[action.payload.index] = action.payload.value;
    },
    updateGapFillInBlanksWhyAt: (
      state,
      action: PayloadAction<{
        uuid: string;
        index: number;
        value: { pIndex: number; start: string; end: string };
      }>,
    ) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksModel;
      if (question.correction.why) question.correction.why[action.payload.index] = action.payload.value;
      else {
        question.correction.why = [];
        question.correction.why[action.payload.index] = action.payload.value;
      }
    },
    updateGapFillInBlanksMarkAt: (state, action: PayloadAction<{ uuid: string; index: number; value: number }>) => {
      const question = state.editingQuestion.questions[action.payload.uuid] as Types.API.Question.IFillInBlanksModel;
      question.correction.marks[action.payload.index] = action.payload.value;
    },
  },
  extraReducers: builder => {
    builder.addCase(getQuestionToEditAsync.pending, (state, _) => {
      state.getQuestionToEditReq.status = 'loading';
    });
    builder.addCase(getQuestionToEditAsync.fulfilled, (state, action) => {
      state.getQuestionToEditReq.status = 'succeeded';
      state.editingQuestion = action.payload;
    });
    builder.addCase(getQuestionToEditAsync.rejected, (state, action) => {
      state.getQuestionToEditReq.status = 'failed';
      state.getQuestionToEditReq.error = action.error.message || 'Unknown error';
    });
    builder.addCase(editAsync.pending, (state, _) => {
      state.submitEditedReq.status = 'loading';
    });
    builder.addCase(editAsync.fulfilled, (state, _) => {
      state.submitEditedReq.status = 'succeeded';
    });
    builder.addCase(editAsync.rejected, (state, action) => {
      state.submitEditedReq.status = 'failed';
      state.submitEditedReq.error = action.error.message || 'Unknown error';
    });

    builder.addCase(uploadMultipleFilesAsync.pending, (state, action) => {
      state.files.filesState = 'uploading';
      state.files.uploadedFiles.req.status = 'loading';
    });
    builder.addCase(uploadMultipleFilesAsync.fulfilled, (state, action) => {
      state.files.filesState = 'uploaded';
      state.files.uploadedFiles.req.status = 'succeeded';
      state.files.uploadedFiles.files = action.payload;
    });
    builder.addCase(uploadMultipleFilesAsync.rejected, (state, action) => {
      state.files.uploadedFiles.req.status = 'failed';
      state.files.uploadedFiles.req.error = action.error.message || 'Unknown error';
    });
    builder.addCase(updateImageFileAsync.pending, (state, action) => {
      state.files.filesState = 'uploading';
      state.files.uploadedFiles.req.status = 'loading';
    });
    builder.addCase(updateImageFileAsync.fulfilled, (state, action) => {
      state.files.filesState = 'uploaded';
      state.files.uploadedFiles.req.status = 'succeeded';
      state.editingQuestion.questions[action.meta.arg.id].image._id = action.payload.file._id;
      state.editingQuestion.questions[action.meta.arg.id].image.filename = action.payload.file.filename;
      state.editingQuestion.questions[action.meta.arg.id].image.awsUrl = action.payload.file.awsUrl;
      state.editingQuestion.questions[action.meta.arg.id].image.extension = action.payload.file.extension;
      // state.editingQuestion.questions[action.meta.arg.id].rubric = action.payload.file._id;
      // (
      //   state.curQuestion.questions[action.meta.arg.id] as Types.API.Question.IWritingPhotoDescriptionCreateInput
      // ).image = action.payload.file._id;

      // (state.curQuestion.questions[action.meta.arg.id] as Types.API.Question.IWritingEssaysCreateInput).rubric =
      //   action.payload.file._id;
    });
    builder.addCase(updateImageFileAsync.rejected, (state, action) => {
      state.files.uploadedFiles.req.status = 'failed';
      state.files.uploadedFiles.req.error = action.error.message || 'Unknown error';
    });

    builder.addCase(uploadSingleFileAsync.pending, (state, action) => {
      state.files.filesState = 'uploading';
      state.files.uploadedFiles.req.status = 'loading';
    });
    builder.addCase(uploadSingleFileAsync.fulfilled, (state, action) => {
      state.files.filesState = 'uploaded';
      state.files.uploadedFiles.req.status = 'succeeded';
      state.files.uploadedFiles.files = [action.payload];
      state.editingQuestion.listeningAudio = action.payload;
    });
    builder.addCase(uploadSingleFileAsync.rejected, (state, action) => {
      state.files.uploadedFiles.req.status = 'failed';
      state.files.uploadedFiles.req.error = action.error.message || 'Unknown error';
    });
  },
});

export const {
  setTitle,
  setVocabs,
  setSectionType,
  setCategoryType,
  setLevelType,
  setGradeType,
  setDiplomaType,
  resetGetQuestionState,
  addReadingArticle,
  updateArticleAt,
  removeReadingArticleAt,
  addMCQCorrection,
  addMCQOption,
  removeMCQCorrection,
  removeMCQOptionAt,
  setMCQMark,
  setMCQText,
  removeQuestion,
  updateMCQCorrectionWhy,
  updateMCQMarksAt,
  updateMCQOption,
  decreaseShortAnswersModelAnswersCount,
  decreaseSpeakerMatchingSpeakersCount,
  increaseShortAnswersModelAnswersCount,
  increaseSpeakerMatchingSpeakersCount,
  setContentMarkWritingSummaries,
  setCommunicationMarksSpeakingPhotoDescription,
  setCommunicationMarksSpeakingQA,
  setContentMarksWritingEssays,
  setContentMarksWritingPhotoDescription,
  setLanguageMarkWritingSummaries,
  setLanguageKnowledgeMarksSpeakingPhotoDescription,
  setLanguageKnowledgeMarksSpeakingQA,
  setPronounciationInonationMarksSpeakingQA,
  setSpontaneityFluencyMarksSpeakingQA,
  setLanguageMarksWritingEssays,
  setLanguageMarksWritingPhotoDescription,
  setRubricText,
  setShortAnswerCorrection,
  setShortAnswerCorrectionWhy,
  setShortAnswerMark,
  setShortAnswerText,
  setShortAnswersAnswerCount,
  setSpeakerMatchingCorrectionWhy,
  setSpeakerMatchingMark,
  setSpeakerMatchingName,
  setSpeakerMatchingOpinion,
  setSpeakerMatchingSpeakersCount,
  setSpeakerMatchingWrongOpinion,
  setSpeakingPhotoDescriptionText,
  setSpeakingQAQuestionText,
  setWritingEssaysText,
  setWritingPhotoDescriptionText,
  setWritingSummariesPassage,
  setWritingSummariesText,
  addGapToFillInBlanks,
  removeElementFillInTheBlank,
  addNoteTakingNote,
  addTextToFillInBlanks,
  removeNoteTakingNoteAt,
  setFillInBlanksMark,
  setNoteTakingMark,
  setNoteTakingText,
  updateGapFillInBlanksAt,
  updateGapFillInBlanksMarkAt,
  updateGapFillInBlanksWhyAt,
  updateNoteTakingAnswersCount,
  updateNoteTakingCorrection,
  updateNoteTakingCorrectionWhy,
  updateNoteTakingModelAnswersCount,
  updateNoteTakingText,
  updateTextFillInBlanksAt,
  reorderQuestion,
  addQuestion,
} = slice.actions;

export const editingQuestionSelector = (state: RootState) => state.editQuestion.editingQuestion;
export const titleSelector = (state: RootState) => state.editQuestion.editingQuestion?.title;
export const sectionTypeSelector = (state: RootState) => state.editQuestion.editingQuestion?.sectionType;
export const categorySelector = (state: RootState) => state.editQuestion.editingQuestion?.category;
export const gradeSelector = (state: RootState) => state.editQuestion.editingQuestion?.grade;
export const levelSelector = (state: RootState) => state.editQuestion.editingQuestion?.level;
export const diplomaSelector = (state: RootState) => state.editQuestion.editingQuestion?.diploma;
export const questionsSelector = (state: RootState) => state.editQuestion.editingQuestion?.questions;
export const readingArticleSelector = (state: RootState) => state.editQuestion.editingQuestion?.readingTexts;
export const vocabsSelector = (state: RootState) => state.editQuestion.editingQuestion?.vocabs;
export const audioFileIdSelector = (state: RootState) => state.editQuestion.editingQuestion?.listeningAudio;
export const getQuestionToEditReqSelector = (state: RootState) => state.editQuestion.getQuestionToEditReq.status;
export const editQuestionStatusSelector = (state: RootState) => state.editQuestion.submitEditedReq;
export const singleQuestionModelSelector = (uuid: string) => (state: RootState) =>
  state.editQuestion.editingQuestion.questions[uuid].modelType;
export const mcqTextSelector = (uuid: string) => (state: RootState) =>
  (state.editQuestion.editingQuestion.questions[uuid] as Types.API.Question.IMCQModel).text;
export const mcqChoicesSelector = (uuid: string) => (state: RootState) =>
  (state.editQuestion.editingQuestion.questions[uuid] as Types.API.Question.IMCQModel).choices;
export const mcqCorrectionSelector = (uuid: string) => (state: RootState) =>
  (state.editQuestion.editingQuestion.questions[uuid] as Types.API.Question.IMCQModel).correction;
export const mcqMarkSelector = (uuid: string) => (state: RootState) =>
  (state.editQuestion.editingQuestion.questions[uuid] as Types.API.Question.IMCQModel).mark;
export const shortAnswerCorrectionSelector = (uuid: string) => (state: RootState) =>
  (state.editQuestion.editingQuestion.questions[uuid] as Types.API.Question.IShortAnswersModel).correction;
export const speakerMatchingCorrectionSelector = (uuid: string) => (state: RootState) =>
  (state.editQuestion.editingQuestion.questions[uuid] as Types.API.Question.ISpeakerMatchingModel).correction;
export const FillInBlanksCorrectionSelector = (uuid: string) => (state: RootState) =>
  (state.editQuestion.editingQuestion.questions[uuid] as Types.API.Question.IFillInBlanksModel).correction;
export const noteTakingTextSelector = (uuid: string) => (state: RootState) =>
  (state.editQuestion.editingQuestion.questions[uuid] as Types.API.Question.INoteTakingModel).text;
export const noteTakingNotesSelector = (uuid: string) => (state: RootState) =>
  (state.editQuestion.editingQuestion.questions[uuid] as Types.API.Question.INoteTakingModel).notes;
export const noteTakingCorrectionSelector = (uuid: string) => (state: RootState) =>
  (state.editQuestion.editingQuestion.questions[uuid] as Types.API.Question.INoteTakingModel).correction;
export const noteTakingMarkSelector = (uuid: string) => (state: RootState) =>
  (state.editQuestion.editingQuestion.questions[uuid] as Types.API.Question.INoteTakingModel).mark;
export default slice.reducer;

import React, { useEffect } from 'react';
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  MobileStepper,
  makeStyles,
  // TextField,
} from '@material-ui/core';
import RecordIcon from '@material-ui/icons/Mic';
import CustomDropzone from 'components/CustomDropzone/CustomDropzone';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../../config';
import AudioRecorder from './AudioRecorder';
import {
  setSubQuestionIndex,
  questionWithIndexSelector,
  subAnswerWithIndexSelector,
  uploadAdminFilesAsync,
  uploadAdminAudiosAsync,
  deleteFileAsync,
  downloadAdminFileAsync,
  availableAdminFilesToDownloadSelector,
  // setWritingContentGrade,
  // setWritingLanguageGrade,
  setTriggerSaveQuizCorrection,
  updateInsructorCommentsForSpeakingQuestion,
  removeLastInsructorCommentForSpeakingQuestion,
  addInsructorCommentForSpeakingQuestion,
} from '../updateSlice';
import { RootState } from 'features';
import { KeyboardArrowLeft, KeyboardArrowRight, OpenInNew } from '@material-ui/icons';
import { useReqListener } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomInput from 'components/CustomInput/CustomInput';
import { userSelector } from 'features/auth/authSlice';
import styles from 'assets/jss/lms/features/questions';

const useStyles = makeStyles(styles);

export interface Props {}

const AdminRightScreen: React.FC<Props> = () => {
  const classes = useStyles();
  const activeQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.questionIndex);
  const activeSubQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.subQuestionIndex);
  const userRole = useSelector(userSelector).role;
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.token);
  const questions = useSelector(questionWithIndexSelector(activeQuestionIndex)).questions;
  const [idKey, setIdKey] = React.useState(0);
  const [openRecord, setOpenRecord] = React.useState(false);
  // const [idKey] = React.useState(0);
  // const [downloadURL, setDownloadURL] = React.useState('');
  const updateAnswers = () => {
    setIdKey(idKey + 1);
  };
  const steps = questions.map((q, i) => ({
    label: `Answer Question #${i + 1}`,
    modelType: q.modelType,
  }));
  const maxLength = steps.length;
  const answer = useSelector(
    subAnswerWithIndexSelector(activeQuestionIndex, activeSubQuestionIndex),
  ) as Types.API.Answer.IAnswerWritingModel; // since this only handle Writing & Speaking for now
  const handleMediaBlobsSubmit = (blobs: Blob[]) => {
    blobs.length &&
      dispatch(
        uploadAdminAudiosAsync({
          blobs,
          token,
          answerId: answer._id,
          questionIndex: activeQuestionIndex,
          subQuestionIndex: activeSubQuestionIndex,
        }),
      );
  };

  const uploadedAdminFiles = useSelector(availableAdminFilesToDownloadSelector);
  const currentQuestionAdminFiles = uploadedAdminFiles.filter(
    file =>
      file &&
      answer.instructorFiles.some(f => {
        if (!f) return false;
        const id = (f as any).file ? (f as any).file._id : f._id ? f._id : f.toString();
        return id === file._id;
      }),
  );

  useEffect(() => {
    answer.instructorFiles &&
      answer.instructorFiles.forEach(file => {
        if (file) {
          const id = (file as any).file ? (file as any).file._id : file._id ? file._id : file.toString();
          if (!currentQuestionAdminFiles.some(f => f._id === id))
            dispatch(downloadAdminFileAsync({ token: token, fileId: id }));
        }
      });
  }, [answer]);

  const audioFiles = currentQuestionAdminFiles.filter(file => file.filename && file.filename.endsWith('mp3'));
  const otherFiles = currentQuestionAdminFiles.filter(file => file.filename && !file.filename.endsWith('mp3'));
  const filesUploadReq = useSelector((state: RootState) => state.updateQuiz.adminFilesUploadsReq[answer._id]);
  const audioUploadReq = useSelector((state: RootState) => state.updateQuiz.adminAudioUploadsReq[answer._id]);
  const updateCorrectionReq = useSelector((state: RootState) => state.updateQuiz.updateCorrectionReq);

  // useEffect(() => {
  //   if (otherFiles && otherFiles[0] && otherFiles[0].awsUrl)
  //     setDownloadURL(config.apiBaseUrl.replace('api', '') + 'media' + otherFiles[0].awsUrl.split('data')[1]);
  // }, [otherFiles]);

  React.useEffect(() => {
    if (audioUploadReq.status === 'succeeded') {
      dispatch(setTriggerSaveQuizCorrection({ save: true }));
    }
  }, [audioUploadReq]);

  useReqListener({
    status: filesUploadReq.status,
    successMessage: 'Successfully uploaded the files to the storage',
    loadingMessage: 'Uploading your files to our storage',
    errorMessage: filesUploadReq.error,
  });

  useReqListener({
    status: audioUploadReq.status,
    successMessage: 'Successfully uploaded the recordings to the storage',
    loadingMessage: 'Uploading your recordings to our storage',
    errorMessage: audioUploadReq.error,
  });

  useReqListener({
    status: updateCorrectionReq.status,
    successMessage: 'Saved Successfully',
    loadingMessage: 'Saving your correction..',
    errorMessage: updateCorrectionReq.error,
  });

  const handleNext = () => {
    dispatch(setSubQuestionIndex({ index: activeSubQuestionIndex + 1 }));
  };
  const handleBack = () => {
    dispatch(setSubQuestionIndex({ index: activeSubQuestionIndex - 1 }));
  };

  const [open, setOpen] = React.useState(false);
  const onUploadClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const handleSave = (acceptedFiles: File[]) => {
    acceptedFiles.length &&
      dispatch(
        uploadAdminFilesAsync({
          files: acceptedFiles,
          token,
          answerId: answer._id,
          subQuestionIndex: activeSubQuestionIndex,
          questionIndex: activeQuestionIndex,
        }),
      );
    setOpen(false);
  };

  const getURL = (url: string) => {
    return config.apiBaseUrl.replace('api', '') + 'media' + url.split('data')[1];
  };

  return (
    <div>
      <div
        key={'instuctorkey' + idKey}
        style={{
          display: 'flex',
          alignContent: 'center',
          width: '100%',
          flexDirection: 'column',
          padding: '15px',
          marginTop: '25px',
        }}
      >
        {questions[0].modelType.includes('Speaking') ? (
          <Card>
            <h4 className={classes.textHeader}>Instructor Records</h4>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
              }}
            >
              {audioFiles.length !== 0 &&
                audioFiles.map((file, index) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '20px',
                    }}
                  >
                    <audio controls preload="auto">
                      <source src={getURL(audioFiles[index].awsUrl)} />
                    </audio>
                    <IconButton
                      onClick={() => {
                        dispatch(
                          deleteFileAsync({
                            fileId: file._id,
                            token,
                            fileName: file.filename,
                            questionIndex: activeQuestionIndex,
                            subQuestionIndex: activeSubQuestionIndex,
                            isAdmin: false,
                          }),
                        );
                        updateAnswers();
                      }}
                    >
                      <FontAwesomeIcon size="sm" icon="trash" />
                    </IconButton>
                  </div>
                ))}

              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenRecord(!openRecord)}
                endIcon={<RecordIcon />}
              >
                Record Voice
              </Button>
            </div>

            {
              audioUploadReq.status !== 'loading' && (
                <AudioRecorder
                  openRecord={openRecord}
                  setOpenRecord={setOpenRecord}
                  handleSave={handleMediaBlobsSubmit}
                />
              )

              // <AudioRecorder handleSave={handleMediaBlobsSubmit} />
            }
          </Card>
        ) : (
          <div style={{ minHeight: '250px' }}>
            <Card>
              <h4 style={{ textAlign: 'center', fontWeight: 400 }}>Instructor Correction Files</h4>
            </Card>
            <h6 style={{ textAlign: 'center', fontWeight: 400, color: 'black' }}>** Admin Uploaded Files **</h6>
            <div>
              {otherFiles.length > 0 &&
                otherFiles.map((file, index) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <a href={getURL(otherFiles[index].awsUrl)} download>
                      {file.filename}
                    </a>
                    <a href={getURL(otherFiles[index].awsUrl)} target="_blank">
                      <OpenInNew />
                    </a>
                    <IconButton
                      onClick={() =>
                        dispatch(
                          deleteFileAsync({
                            fileId: file._id,
                            token,
                            fileName: file.filename,
                            questionIndex: activeQuestionIndex,
                            subQuestionIndex: activeSubQuestionIndex,
                            isAdmin: true,
                          }),
                        )
                      }
                    >
                      <FontAwesomeIcon size="sm" icon="trash" />
                    </IconButton>
                  </div>
                ))}
            </div>
            <h6 style={{ color: 'black', fontWeight: 400 }}>**Accepted Files: audio/*, image/*, application/pdf </h6>
            <Button variant="contained" fullWidth onClick={onUploadClick}>
              Upload your files
            </Button>
            {filesUploadReq.status !== 'loading' && (
              <CustomDropzone
                filesLimit={20}
                onClose={onClose}
                open={open}
                initialFiles={[]}
                clearOnUnmount={false}
                accept={['audio/*', 'image/*', 'application/pdf']}
                onSave={handleSave}
              />
            )}
          </div>
        )}

        {/* <Divider style={{ marginTop: '2rem', marginBottom: '0.5rem' }} /> */}

        <Card style={{ marginTop: '2rem' }}>
          <h4 className={classes.textHeader}>Instructor Comments</h4>
          <div
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              padding: '10px',
            }}
          >
            {answer.instructorComments &&
              answer.instructorComments.map((comment, index) => (
                <CustomInput
                  labelText={`Writing Space`}
                  id="text"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: 'text',
                    multiline: true,
                    rows: 8,
                    value: comment,
                    readOnly: userRole === 'student' || userRole === 'parent',
                    onChange: ev =>
                      dispatch(
                        updateInsructorCommentsForSpeakingQuestion({
                          comment: ev.target.value,
                          questionNumber: activeQuestionIndex,
                          subQuestionNumber: activeSubQuestionIndex,
                          commentIndex: index,
                        }),
                      ),
                  }}
                />
              ))}
            <Button
              variant="contained"
              fullWidth
              onClick={() =>
                dispatch(
                  addInsructorCommentForSpeakingQuestion({
                    questionNumber: activeQuestionIndex,
                    subQuestionNumber: activeSubQuestionIndex,
                  }),
                )
              }
            >
              Add Comment
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={() =>
                dispatch(
                  removeLastInsructorCommentForSpeakingQuestion({
                    questionNumber: activeQuestionIndex,
                    subQuestionNumber: activeSubQuestionIndex,
                  }),
                )
              }
            >
              Remove Last Comment
            </Button>
          </div>
        </Card>

        {/* <Divider /> */}
        <MobileStepper
          steps={maxLength}
          position="static"
          variant="text"
          activeStep={activeSubQuestionIndex}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={activeSubQuestionIndex === maxLength - 1}>
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeSubQuestionIndex === 0}>
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </div>
      <Backdrop
        color="#fff"
        style={{
          zIndex: 1000,
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
        }}
        open={filesUploadReq.status === 'loading' || audioUploadReq.status === 'loading'}
      >
        <CircularProgress color="inherit" style={{ marginBottom: '20px' }} />
        <p>UPLOADING...</p>
      </Backdrop>
    </div>
  );
};

export default AdminRightScreen;

import React from 'react';
import Button from 'components/CustomButtons/Button';
import PrimaryTypo from 'components/Typography/Primary';
import Card from 'components/Card/Card';
import styles from 'assets/jss/lms/features/questions';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import WhyIcon from '@material-ui/icons/QuestionAnswer';
import { sectionTypeSelector, setSpeakerMatchingCorrectionWhy, speakerMatchingCorrectionSelector } from '../editSlice';
import { DialogActions, DialogContent, Dialog, DialogTitle, makeStyles } from '@material-ui/core';
import ReadingQuestionsArticles from '../EditQReadingArticles';
import CustomInput from 'components/CustomInput/CustomInput';

const useStyles = makeStyles(styles);
const WhySpeakerMatching: React.FC<{
  uuid: string;
  index: number;
  id: string;
}> = props => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const correction = useSelector(speakerMatchingCorrectionSelector(props.uuid));
  const whyData =
    correction.why && correction.why[props.index]
      ? correction.why[props.index]
      : { pIndex: -1, start: '00:00', end: '00:00' };

  const [audioStart, setAudioStart] = React.useState(whyData.start == '-1' ? '00:00' : whyData.start);
  const [audioEnd, setAudioEnd] = React.useState(whyData.end == '-1' ? '00:00' : whyData.end);
  const [pIndex, setPIndex] = React.useState(whyData.pIndex);

  const [start, setStart] = React.useState(-1);
  const [end, setEnd] = React.useState(-1);
  const sectionType = useSelector(sectionTypeSelector);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  setInterval(function () {
    if (document.activeElement && sectionType === 'Reading') {
      if (
        document.activeElement.tagName === 'TEXTAREA' ||
        (document.activeElement.tagName === 'INPUT' && document.activeElement.nodeType === Node.TEXT_NODE)
      ) {
        setStart((document.activeElement as HTMLTextAreaElement).selectionStart);
        setEnd((document.activeElement as HTMLTextAreaElement).selectionEnd);
      }
    }
  }, 100);
  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const focusText = () => {
    const textBox = document.getElementById('text') as HTMLTextAreaElement;
    if (textBox != null && end > start) {
      const startTmp = start;
      const endTmp = end;
      textBox.focus();
      const txt = textBox.value;
      textBox.scrollTop = 0;
      textBox.value = txt.substring(0, start);
      const height = textBox.scrollHeight;
      textBox.value = txt;
      textBox.scrollTop = height - 150;
      setStart(startTmp);
      setEnd(endTmp);
      textBox.selectionStart = startTmp;
      textBox.selectionEnd = endTmp;
    } else {
      console.log(false);
    }
  };

  const handleBlur = () => {
    focusText();
  };
  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) focusText();
  };

  const handleSubmit = () => {
    setDialogOpen(false);
    if (start !== -1 && end !== -1 && sectionType === 'Reading') {
      dispatch(
        setSpeakerMatchingCorrectionWhy({
          uuid: props.uuid,
          why: { pIndex, start: start + '', end: end + '' },
          index: props.index,
        }),
      );
    }
    if (sectionType === 'Listening') {
      dispatch(
        setSpeakerMatchingCorrectionWhy({
          uuid: props.uuid,
          why: {
            pIndex,
            start: audioStart,
            end: audioEnd,
          },
          index: props.index,
        }),
      );
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
    setAudioStart(whyData.start);
    setAudioEnd(whyData.end);
  };

  const handleReadingArticleChange = (t: number) => {
    setPIndex(t);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', float: 'right' }}>
      <IconButton aria-label="why" onClick={handleClickOpen}>
        <WhyIcon fontSize="small" />
      </IconButton>
      {whyData.pIndex !== -1 && whyData.start !== '-1' && whyData.end !== '-1' && (
        <Card className={classes.container}>
          <PrimaryTypo>
            {sectionType === 'Reading' && (
              <h6>{`Article: #${whyData.pIndex + 1}, From index: ${whyData.start}, To: ${whyData.end}`}</h6>
            )}
            {sectionType === 'Listening' && (
              <h6>{`Audio Clip: #${whyData.pIndex + 1}, From minute:seconds : ${whyData.start}, To: ${
                whyData.end
              }`}</h6>
            )}
          </PrimaryTypo>
        </Card>
      )}

      <Dialog fullWidth open={dialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        {sectionType === 'Reading' && (
          <DialogTitle id="form-dialog-title">Please highlight the text of the why part</DialogTitle>
        )}
        {sectionType === 'Listening' && (
          <DialogTitle id="form-dialog-title">Please enter the start and end time of the why part</DialogTitle>
        )}
        <DialogContent>
          {sectionType === 'Reading' && (
            <ReadingQuestionsArticles disableActions setActiveTab={handleReadingArticleChange} />
          )}
          {sectionType === 'Listening' && (
            <div>
              <CustomInput
                id="audio start time"
                inputProps={{
                  value: audioStart,
                  onChange: c => setAudioStart(c.target.value),
                }}
                labelText="Audio Start Time"
              />
              <CustomInput
                id="audio end time"
                inputProps={{ value: audioEnd, onChange: c => setAudioEnd(c.target.value) }}
                labelText="Audio End Time"
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {sectionType === 'Reading' && (
            <Card className={classes.container}>
              <PrimaryTypo>
                <>
                  <h4>Article: #{pIndex + 1}, From Index: </h4>{' '}
                  <input
                    value={start}
                    onKeyUp={handleKeyUp}
                    onBlur={handleBlur}
                    onChange={e => setStart(parseInt(e.target.value))}
                    style={{ width: '35%', height: '30px' }}
                    type="number"
                    id="fname"
                    name="fnamexx"
                  />{' '}
                  <h4>To: </h4>{' '}
                  <input
                    style={{ width: '35%', height: '30px' }}
                    type="number"
                    id="fname"
                    name="fname2 "
                    value={end}
                    onKeyUp={handleKeyUp}
                    onBlur={handleBlur}
                    onChange={e => setEnd(parseInt(e.target.value))}
                  />
                </>
              </PrimaryTypo>
            </Card>
          )}
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Confirm Selected Positions
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WhySpeakerMatching;

import { FC } from 'react';
// nodejs library to set properties for components
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import styles from 'assets/jss/lms/components/typographyStyle';

const useStyles = makeStyles(styles);

const Primary: FC = (props) => {
  const classes = useStyles();
  const { children } = props;
  return <div className={classes.defaultFontStyle + ' ' + classes.primaryText}>{children}</div>;
};
export default Primary;

import { makeStyles } from '@material-ui/core';
import styles from 'assets/jss/lms/features/questions';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from 'components/CustomButtons/Button';
import {
  updateAnswersAsync,
  quizTakenSelector,
  // quizStateSelector,
  updateCorrectionAsync,
  updateStudentCommentsAsync,
  submitHomeworkAsync,
  triggerSaveQuizAnswersSelector,
  setTriggerSaveQuizAnswers,
  setTriggerSaveQuizCorrection,
  triggerSaveQuizCorrectionSelector,
  lastQuestionSelector,
} from './updateSlice';
import { StudentRoutesPrefix } from 'layouts/StudentLayout';
import { useHistory, useParams } from 'react-router';
import { tokenSelector, userSelector } from 'features/auth/authSlice';
import SubmitDialog from 'components/Dialog/SubmitDialog';
import { RootState } from 'features';
import {
  publishGradesForQuizzesAsync,
  publishGradesReqSelector,
  setQuizReviewedState,
} from 'features/admin/slices/adminSlice';
import { useReqListener } from 'hooks';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userInfo } from 'os';

interface Props {
  questionsLength: number;
}
const useStyles = makeStyles(styles);
const QuestionNavigator: React.FC<Props> = ({ questionsLength }) => {
  const { quizTakenId } = useParams<Record<string, string | undefined>>();
  const dispatch = useDispatch();
  const quiz = useSelector(quizTakenSelector);
  // const quizState = useSelector(quizStateSelector);
  const token = useSelector(tokenSelector);
  const userRole = useSelector(userSelector).role;
  const userID = useSelector(userSelector).id;
  const [openSubmitDialog, setOpenSubmitDialog] = React.useState(false);
  const [openPublishDialog, setOpenPublishDialog] = React.useState(false);
  const isHomework = useSelector((state: RootState) => state.updateQuiz.quizTaken.isHomework);
  const submitHomeworkdState = useSelector((state: RootState) => state.updateQuiz.submitHomeworkReq);
  const history = useHistory();
  const triggerSaveQuizAnswers = useSelector(triggerSaveQuizAnswersSelector);
  console.log('🚀 ~ file: QuestionsNavigator.tsx:50 ~ triggerSaveQuizAnswers:', triggerSaveQuizAnswers);
  const triggerSaveQuizCorrection = useSelector(triggerSaveQuizCorrectionSelector);
  const publishGradesReq = useSelector(publishGradesReqSelector);
  const isLastQuestion = useSelector(lastQuestionSelector);

  useReqListener({
    status: publishGradesReq.status,
    successMessage: 'Successfully published the grade.',
    loadingMessage: 'publishing the grade to student.',
    errorMessage: publishGradesReq.error,
  });

  const triggerSave = () => {
    dispatch(setTriggerSaveQuizAnswers({ save: true }));
  };

  React.useEffect(() => {
    if (triggerSaveQuizAnswers) {
      saveStudentAnswer();
      dispatch(setTriggerSaveQuizAnswers({ save: false }));
    }
  }, [triggerSaveQuizAnswers]);

  React.useEffect(() => {
    if (triggerSaveQuizCorrection) {
      saveAdminCorrection();
      dispatch(setTriggerSaveQuizCorrection({ save: false }));
    }
  }, [triggerSaveQuizCorrection]);

  var checker: boolean[] = new Array();
  const studentAnswersCheckers = (answers: object[]): boolean => {
    answers?.map((ans: any) => {
      for (let i = 0; i < ans.length; i++) {
        for (let j = 0; j < ans[i].answer.length; j++) {
          ans[i].answer[j] !== '' ? checker.push(true) : checker.push(false);
        }
      }
    });
    return checker.includes(false) ? false : true;
  };
  const saveStudentAnswer = () => {
    const newAnswers = getNewAnswersMapped(quiz.answers);
    dispatch(setTriggerSaveQuizCorrection({ save: false }));
    if (quizTakenId) {
      dispatch(
        updateAnswersAsync({
          token,
          takenQuizId: quizTakenId || ' ',
          newAnswers: { answers: newAnswers },
        }),
      );
    }
  };
  const submitStudentQuiz = () => {
    const newAnswers = getNewAnswersMapped(quiz.answers);
    saveStudentAnswer();
    const result = studentAnswersCheckers(newAnswers);
    if (result) {
      history.push(StudentRoutesPrefix);
      //console.log('submitted ✅');
      showSuccessToastMessage();
    } else {
      //console.log('not submitted ❌');
      showErrorToastMessage();
    }
    setOpenSubmitDialog(false);
  };
  const submitStudentHomework = () => {
    const newAnswers = getNewAnswersMapped(quiz.answers);
    dispatch(
      submitHomeworkAsync({
        token,
        takenQuizId: quizTakenId || ' ',
        newAnswers: { answers: newAnswers },
        answersUpdatedCallback: async () => {
          history.push(StudentRoutesPrefix);
        },
      }),
    );
    setOpenSubmitDialog(false);
  };
  const saveAdminCorrection = () => {
    const newCorrection = getAdminCorrectionMapped(quiz.answers);
    console.log('🚀 ~ file: QuestionsNavigator.tsx:136 ~ submitAdminCorrection ~ userName:', userID);
    dispatch(
      updateCorrectionAsync({
        token,
        takenQuizId: quizTakenId || ' ',
        correctedBy: userID,
        newCorrection: { correction: newCorrection },
      }),
    );
  };
  const submitAdminCorrection = () => {
    const newCorrection = getAdminCorrectionMapped(quiz.answers);
    dispatch(
      updateCorrectionAsync({
        token,
        takenQuizId: quizTakenId || ' ',
        correctedBy: userID,
        newCorrection: { correction: newCorrection },
      }),
    );
    dispatch(setQuizReviewedState({ quizId: quizTakenId || ' ', reviewed: true }));
    dispatch(publishGradesForQuizzesAsync({ quizzes: [quiz.id], token }));
    setOpenSubmitDialog(false);
  };

  // const publishCorrection = () => {
  //   dispatch(publishGradesForQuizzesAsync({ quizzes: [quiz.id], token }));
  //   setOpenPublishDialog(false);
  // };

  const submitStudentComments = () => {
    const newComments = getNewCommentsMapped(quiz.answers);
    dispatch(
      updateStudentCommentsAsync({
        token,
        takenQuizId: quizTakenId || ' ',
        newComments: { comments: newComments },
      }),
    );
    setOpenSubmitDialog(false);
  };

  const handleOpenSubmitDialog = () => {
    setOpenSubmitDialog(true);
  };

  const handleOpenPublishDialog = () => {
    setOpenPublishDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenSubmitDialog(false);
  };

  const handleClosePublishDialog = () => {
    setOpenPublishDialog(false);
  };

  // const shouldDisableSaveButton =
  //   quiz.answers[quizState.questionIndex]?.question?.questions[quizState.subQuestionIndex]?.modelType == 'FillInBlanks' ||
  //   quiz.answers[quizState.questionIndex]?.question.sectionType == 'Writing';

  const classes = useStyles();
  const showErrorToastMessage = () => {
    toast.error('please solve all questions to submit the quiz', {
      position: 'bottom-center',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };
  const showSuccessToastMessage = () => {
    toast.success('Quiz Submitted Successfully', {
      position: 'bottom-center',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };
  return (
    <>
      <div className={classes.stepperButtons}>
        {userRole === 'student' && quiz.status === 'started' && (
          <CustomButton color={'twitter'} className={classes.SubmitButton} onClick={triggerSave}>
            Save
          </CustomButton>
        )}
        {(userRole === 'admin' || userRole === 'assistant') && (
        //  <CustomButton color={'twitter'} className={classes.SubmitButton} onClick={submitAdminCorrection}>
          <CustomButton color={'twitter'} className={classes.SubmitButton} onClick={saveAdminCorrection}>
            Save
          </CustomButton>
        )}
        {(userRole !== 'student' || (userRole === 'student' && quiz.status === 'started' && isLastQuestion)) && (
          <CustomButton
            onClick={handleOpenSubmitDialog}
            className={classes.SubmitButton}
            color="facebook"
            disabled={
              userRole === 'student' &&
              (submitHomeworkdState.status === 'loading' ||
                submitHomeworkdState.status === 'succeeded' ||
                !allFilesUploaded(quiz.answers))
            }
          >
            {(userRole === 'assistant' || userRole === 'admin') && 'Submit comments & publish grades'}
            {userRole === 'student' && quiz.status === 'auto-corrected' && 'Submit comments'}
            {userRole === 'parent' && quiz.status === 'auto-corrected' && 'Submit comments'}
            {userRole === 'student' && quiz.status === 'started' && 'Submit'}
          </CustomButton>
        )}
        {/* {(userRole === 'assistant' || userRole === 'admin') && quiz.instructorGraded && (
          <CustomButton className={classes.SubmitButton} color="facebook" onClick={handleOpenPublishDialog}>
            Publish
          </CustomButton>
        )} */}
        {userRole === 'student' && quiz.status === 'auto-corrected' && (
          <SubmitDialog
            dialogText="Are you sure you want to submit your comments for this quiz/homework?"
            onSubmitClick={submitStudentComments}
            open={openSubmitDialog}
            onClose={handleCloseDialog}
          />
        )}
        {/* {(userRole === 'assistant' || userRole === 'admin') && quiz.instructorGraded && (
          <SubmitDialog
            dialogText="Are you sure you want to publish this quiz/homework?"
            onSubmitClick={publishCorrection}
            open={openPublishDialog}
            onClose={handleClosePublishDialog}
          />
        )} */}

        {userRole === 'student' && quiz.status === 'started' && (
          <SubmitDialog
            dialogText="Are you sure you want to submit your quiz/homework?"
            onSubmitClick={isHomework ? submitStudentHomework : submitStudentQuiz}
            open={openSubmitDialog}
            onClose={handleCloseDialog}
          />
        )}
        {(userRole === 'admin' || userRole === 'assistant') && (
          <SubmitDialog
            dialogText="Submit this correction for the student?"
            onSubmitClick={submitAdminCorrection}
            open={openSubmitDialog}
            onClose={handleCloseDialog}
          />
        )}
      </div>

      {userRole === 'student' && quiz.status === 'started' && isLastQuestion && !allFilesUploaded(quiz.answers) && (
        <div
          style={{
            color: 'red',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '50px',
            marginTop: '-80px',
          }}
        >
          Please, Upload All Files To Submit
        </div>
      )}

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

const allFilesUploaded = (answers: Types.API.Answer.IAnswerModel[]): boolean => {
  let result = true;
  answers.forEach(item => {
    item?.answers?.forEach(answer => {
      if (answer?.files?.length === 0) {
        result = false;
        return;
      }
    });
  });
  return result;
};

const removeNulls = (marks: number[]) => {
  return marks.map((item: number) => {
    return !item ? 0 : item;
  });
};

const getAdminCorrectionMapped = (answers: Types.API.Answer.IAnswerModel[]) =>
  answers.map(bigA =>
    bigA.answers.map((smallA, smallAI) => {
      switch (bigA.question.questions[smallAI].modelType) {
        case 'NoteTaking':
          return {
            marks: (smallA as Types.API.Answer.IAnswerNoteTakingModel).marks.map((marksList: number[]) =>
              removeNulls(marksList),
            ),
            instructorComments: (smallA as Types.API.Answer.IAnswerNoteTakingModel).instructorComments,
          };
        case 'ShortAnswers':
          return {
            marks: removeNulls((smallA as Types.API.Answer.IAnswerShortAnswersModel).marks),
            instructorComments: (smallA as Types.API.Answer.IAnswerShortAnswersModel).instructorComments,
          };
        case 'FillInBlanks':
          return {
            marks: removeNulls((smallA as Types.API.Answer.IAnswerFillInBlanksModel).marks),
            instructorComments: (smallA as Types.API.Answer.IAnswerFillInBlanksModel).instructorComments,
          };
        case 'Vocab':
          return {
            marks: removeNulls((smallA as Types.API.Answer.IAnswerVocabModel).marks),
            instructorComments: (smallA as Types.API.Answer.IAnswerVocabModel).instructorComments,
          };
        case 'MCQ':
          return {
            marks: removeNulls((smallA as Types.API.Answer.IAnswerMCQModel).marks),
            instructorComments: (smallA as Types.API.Answer.IAnswerMCQModel).instructorComments,
          };
        case 'SpeakerMatching':
          return {
            marks: removeNulls((smallA as Types.API.Answer.IAnswerSpeakerMatchingModel).marks),
            instructorComments: (smallA as Types.API.Answer.IAnswerSpeakerMatchingModel).instructorComments,
          };
        case 'SpeakingQA':
        case 'SpeakingPhotoDescription':
          return {
            languageMark: (smallA as Types.API.Answer.IAnswerSpeakingModel).languageMark || 0,
            contentMark: (smallA as Types.API.Answer.IAnswerSpeakingModel).contentMark || 0,
            instructorFiles: (smallA as Types.API.Answer.IAnswerSpeakingModel).instructorFiles
              .filter(f => f)
              .map(f => (f._id ? f._id : f)),
            instructorComments: (smallA as Types.API.Answer.IAnswerSpeakingModel).instructorComments,
          };
        case 'WritingSummaries':
        case 'WritingPhotoDescription':
        case 'WritingEssays':
          return {
            languageMark: (smallA as Types.API.Answer.IAnswerWritingModel).languageMark || 0,
            contentMark: (smallA as Types.API.Answer.IAnswerWritingModel).contentMark || 0,
            instructorFiles: (smallA as Types.API.Answer.IAnswerWritingModel).instructorFiles
              .filter(f => f)
              .map(f => (f._id ? f._id : f)),
            instructorComments: (smallA as Types.API.Answer.IAnswerWritingModel).instructorComments,
          };
        default:
          return { instructorComments: [], marks: [] };
      }
    }),
  );
const getNewCommentsMapped = (answers: Types.API.Answer.IAnswerModel[]) =>
  answers.map(bigA =>
    bigA.answers.map((smallA, smallAI) => {
      switch (bigA.question.questions[smallAI].modelType) {
        case 'NoteTaking':
          return {
            comments: (smallA as Types.API.Answer.IAnswerNoteTakingModel).comments,
          };
        case 'ShortAnswers':
          return {
            comments: (smallA as Types.API.Answer.IAnswerShortAnswersModel).comments,
          };
        case 'FillInBlanks':
          return {
            comments: (smallA as Types.API.Answer.IAnswerFillInBlanksModel).comments,
          };
        case 'Vocab':
          return {
            comments: (smallA as Types.API.Answer.IAnswerVocabModel).comments,
          };
        case 'MCQ':
          return {
            comments: (smallA as Types.API.Answer.IAnswerMCQModel).comments,
          };
        case 'SpeakerMatching':
          return {
            comments: (smallA as Types.API.Answer.IAnswerShortAnswersModel).comments,
          };
        case 'SpeakingQA':
        case 'SpeakingPhotoDescription':
          return {
            comments: (smallA as Types.API.Answer.IAnswerSpeakingModel).comments,
          };
        case 'WritingSummaries':
        case 'WritingPhotoDescription':
        case 'WritingEssays':
          return {
            comments: (smallA as Types.API.Answer.IAnswerWritingModel).comments,
          };
        default:
          return { comments: [], marks: [] };
      }
    }),
  );

const getNewAnswersMapped = (answers: Types.API.Answer.IAnswerModel[]) =>
  answers.map(bigA =>
    bigA.answers.map((smallA, smallAI) => {
      if (bigA && bigA.question && bigA.question.questions && bigA.question.questions[smallAI]) {
        switch (bigA.question.questions[smallAI].modelType) {
          case 'NoteTaking':
            return {
              answer: (smallA as Types.API.Answer.IAnswerNoteTakingModel).answer,
            };
          case 'ShortAnswers':
            return {
              answer: (smallA as Types.API.Answer.IAnswerShortAnswersModel).answer,
            };
          case 'FillInBlanks':
            return {
              answer: (smallA as Types.API.Answer.IAnswerFillInBlanksModel).answer,
            };
          case 'Vocab':
            return {
              answer: (smallA as Types.API.Answer.IAnswerVocabModel).answer,
            };
          case 'MCQ':
            return {
              answer: (smallA as Types.API.Answer.IAnswerMCQModel).answer,
            };
          case 'SpeakerMatching':
            return {
              answer: (smallA as Types.API.Answer.IAnswerSpeakerMatchingModel).answer,
            };
          case 'SpeakingQA':
          case 'SpeakingPhotoDescription':
            return { files: (smallA as Types.API.Answer.IAnswerSpeakingModel).files.map(f => f._id) };
          case 'WritingEssays':
          case 'WritingPhotoDescription':
          case 'WritingSummaries':
            return {
              files: (smallA as Types.API.Answer.IAnswerWritingModel).files.map(a => a._id),
              text: (smallA as Types.API.Answer.IAnswerWritingModel).text,
              contentPoints: (smallA as Types.API.Answer.IAnswerWritingModel).contentPoints,
            };
          default:
            return { answer: [] };
        }
      } else {
        return { answer: [] };
      }
    }),
  );

export default QuestionNavigator;

import { OptionType } from 'components/Autocomplete/Autocomplete';
import ContentSubmitDialog from 'components/Dialog/ContentSubmitDialog';
import { tokenSelector } from 'features/auth/authSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStudentAsync, createStudentReqSelector } from '../slices/adminSlice';
import StudentInputs from './StudentInputs';
import Button from 'components/CustomButtons/Button';
interface Props {}
const AddStudent: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [parentPhone, setParentPhone] = React.useState('');
  const [grade, setGrade] = React.useState('');
  // const [school, setSchool] = React.useState<OptionType>({ id: '', name: '' });
  const [group, setGroup] = React.useState<OptionType>({ id: '', name: '' });
  const [open, setOpen] = React.useState(false);

  const createReq = useSelector(createStudentReqSelector);
  const handleClose = () => setOpen(false);
  const handleStudentAddSubmit = () => {
    dispatch(
      createStudentAsync({ token, student: { name, email, password, phone, parentPhone, grade, groupId: group.id } }),
    );
  };
  const handleOpen = () => setOpen(true);
  return (
    <div style={{ width: '90%' }}>
      <Button onClick={handleOpen} color="primary">
        Add New Student
      </Button>
      <ContentSubmitDialog
        open={open}
        dialogTitle="Create A New Student"
        onClose={handleClose}
        onSubmitClick={handleStudentAddSubmit}
        asyncSubmitReq={{
          status: createReq.status,
          errorMessage: createReq.error || 'Cannot add the student',
          loadingMessage: 'Adding the student',
          successMessage: 'Successfully added the student to the backend',
        }}
      >
        <StudentInputs
          name={name}
          onNameChange={e => setName(e.target.value)}
          password={password}
          onPasswordChange={e => setPassword(e.target.value)}
          email={email}
          onEmailChange={e => setEmail(e.target.value)}
          phone={phone}
          onPhoneChange={e => setPhone(e.target.value)}
          parentPhone={parentPhone}
          onParentPhoneChange={e => setParentPhone(e.target.value)}
          grade={grade}
          onGradeChange={e => setGrade(e.target.value)}
          group={group}
          onGroupChange={(ev, value: OptionType) => {
            value && setGroup(value);
          }}
        />
      </ContentSubmitDialog>
    </div>
  );
};

export default AddStudent;

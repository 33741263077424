import API from 'api';

export const createQuestion = async (input: {
  question: Types.API.Question.IQuestionCreateInput;
  token: string;
}): Promise<Types.API.Question.IQuestionModel> => {
  const response = await API.post('/admin/addQuestion', input.question, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.question as Types.API.Question.IQuestionModel;
};

// export const uploadFile = async (input: { file: File; token: string }): Promise<Types.API.File.IFileModel> => {
//   const formData = new FormData();
//   formData.append('file', input.file);
//   const response = await API.post('/admin/uploadFile', formData, {
//     headers: {
//       Authorization: 'Bearer ' + input.token,
//     },
//   });
//   return response.data.file;
// };

export const uploadSubQuestionFile = async (input: {
  file: File;
  token: string;
  id: string;
}): Promise<{ file: Types.API.File.IFileModel; questionId: string }> => {
  const formData = new FormData();
  formData.append('file', input.file);
  const response = await API.post('/admin/uploadFile', formData, {
    headers: {
      Authorization: 'Bearer ' + input.token,
    },
  });
  return { file: response.data.file, questionId: input.id };
};

import { makeStyles, Paper } from '@material-ui/core';
import classNames from 'classnames';
import styles from 'assets/jss/lms/features/questions';
import { subQuestionWithIndexSelector } from '../updateSlice';
import { useSelector } from 'react-redux';
import { RootState } from 'features';
import React from 'react';
import config from '../../../config';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import './answerleft.scss'

const useStyles = makeStyles(styles);

const AnswerLeftScreen: React.FC = () => {
  const classes = useStyles();
  const activeQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.questionIndex);
  const activeSubQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.subQuestionIndex);

  const imageClasses = classNames(classes.imgRaised, classes.imgFluid, classes.imgGallery);
  const question = useSelector(subQuestionWithIndexSelector(activeQuestionIndex, activeSubQuestionIndex));
  const getURL = (url: string) => {
    const downloadURL = config.apiBaseUrl.replace('api', '') + 'media' + url.split('data')[1];
    return downloadURL;
  };
  return (
    <div style={{ padding: '5px' }}>
      {question.modelType === 'WritingPhotoDescription' && (
        <>
          <div style={{ marginTop: '1.5%' }}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]} className={classes.questionHeader} children={question.text} />
          </div>
          {/* {question.image && <ReactImageZoom zoomPosition="none" width={1000} img={getURL(question.image.awsUrl)} />} */}
          <div style={{ marginBottom: '1.5%' }}>
            {question.image && <img style={{ width: '800px' }} src={getURL(question.image.awsUrl)} alt="..." />}
          </div>
        </>
      )}

      {question.modelType === 'WritingEssays' && (
        <>
          <div style={{ marginTop: '1.5%' }}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]} className={classes.questionHeader} children={question.text} />
          </div>
          {question.rubric && question.rubric.awsUrl && (
/*             <ReactImageZoom width={600} img={getURL(question.rubric.awsUrl)} zoomPosition="bottom" /
            > */
            
            <img src={getURL(question.rubric.awsUrl)} alt="..." style={{
            borderRadius:'1rem',
            width: '20rem',
            marginTop:'1rem',
            height: '20rem',
            objectFit: 'cover', // Maintain the aspect ratio without distortion

          }} className='scale-image' />
          )}
        </>
      )}
      {question.modelType === 'WritingSummaries' && (
        <>
          <ReactMarkdown rehypePlugins={[rehypeRaw]} className={classes.questionHeader} children={question.text} />
          <Paper>
            <h5 className={classes.questionPassage}>{question.passage}</h5>
          </Paper>
        </>
      )}
      {question.modelType === 'SpeakingQA' && (
        <>
          <h4 className={classes.questionHeader}>Speaking Question & Answer</h4>
          <Paper>
            <h5 className={classes.questionPassage}>{question.question}</h5>
          </Paper>
        </>
      )}

    </div>
  );
};

export default AnswerLeftScreen;

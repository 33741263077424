import API from 'api';

export const editQuestion = async (input: {
  question: Types.API.Question.IQuestionModel;
  token: string;
  successCallback: Function;
}): Promise<Types.API.Question.IQuestionModel> => {
  const response = await API.put(
    '/admin/editQuestion',
    {
      ...input.question,
      diploma: input.question.diploma._id,
      category: input.question.category._id,
      level: input.question.level._id,
      grade: input.question.grade._id,
      listeningAudio: input.question.listeningAudio && input.question.listeningAudio._id,
    },
    {
      headers: { Authorization: 'Bearer ' + input.token },
    },
  );
  if (response.status === 200) input.successCallback();

  return response.data.question as Types.API.Question.IQuestionModel;
};

export const getQuestion = async (input: {
  quesitonId: string;
  token: string;
}): Promise<Types.API.Question.IQuestionModel> => {
  const response = await API.get(`/admin/getQuestion/${input.quesitonId}`, {
    headers: {
      Authorization: 'Bearer ' + input.token,
    },
  });
  return response.data.question;
};

import React from 'react';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Icon from '@material-ui/core/Icon';
// core components
import Card from 'components/Card/Card';
// import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';

import styles from 'assets/jss/lms/components/customTabsStyle';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
// import CardBody from 'components/Card/CardBody';

const useStyles = makeStyles(styles);

const CustomTabs: React.FC<Props> = props => {
  const classes = useStyles();
  const { headerColor, plainTabs, tabs, title, rtlActive, children } = props;
  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive,
  });
  return (

    <Card plain={plainTabs}>
      <CardHeader color={headerColor} plain={plainTabs}>
        {title !== undefined ? <div className={cardTitle}>{title}</div> : null}
        <Tabs value={props.curTabIndex} onChange={props.handleTabIndexChange} variant="scrollable" scrollButtons="on">
          {tabs.map((prop, key) => {
            var icon = {};
            if (prop.tabIcon) {
              icon = {
                icon: typeof prop.tabIcon === 'string' ? <Icon>{prop.tabIcon}</Icon> : <prop.tabIcon />,
              };
            }
            return (
              <Tab
              classes={{
                root: classes.tabRootButton,
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              key={key}
              label={prop.tabName}
              {...icon}
              />
              );
            })}
        </Tabs>
      </CardHeader>
      <CardBody >
        {children}
      </CardBody>
    </Card>
  );
};

interface TabProps {
  tabName: string;
  tabIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | string;
}
interface Props {
  curTabIndex: number;
  headerColor?: 'warning' | 'success' | 'danger' | 'info' | 'primary' | 'rose';
  title?: string;
  tabs: TabProps[];
  rtlActive?: boolean;
  plainTabs?: boolean;
  handleTabIndexChange(event: React.ChangeEvent<{}>, value: number): void;
}

export default CustomTabs;

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
// core components
import styles from 'assets/jss/lms/components/customCircularProgressStyle';

const useStyles = makeStyles(styles);

export default function CustomCircularProgress(props: CircularProgressProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={3}
        {...props}
      />
    </div>
  );
}

import { Divider, makeStyles } from '@material-ui/core';
import styles from 'assets/jss/lms/features/questions';
import { questionWithIndexSelector } from '../updateSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import UpdateSimpleAnswer from '../UpdateSimpleAnswers/UpdateSimpleAnswer';
import ReadingQuestionsArticles from './ReadingQuestionsArticles';
import { RootState } from 'features';
const useStyles = makeStyles(styles);

const ReadingAnswer: React.FC = props => {
  const activeQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.questionIndex);
  const question = useSelector(questionWithIndexSelector(activeQuestionIndex));
  const classes = useStyles();
  return (
    <div>
      {question && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'start',
            padding: '2rem',
            backgroundColor: 'white',
          }}
        >
          <div
            className={classes.container}
            style={{
              overflowY: 'scroll',
              minHeight: '90vh',
              height: '90vh',
              maxHeight: '90vh',
            }}
          >
            <ReadingQuestionsArticles articles={question.readingTexts || []} />
          </div>
          <div
            className={classes.container}
            style={{
              minHeight: '90vh',
              height: '90vh',
              maxHeight: '90vh',
              overflowX: 'hidden',
              overflowY: 'auto',
            }}
          >
            {question.questions.map((_: any, index: number) => {
              return (
                <div
                  key={`Q${index + 1}`}
                  id={`Q${index + 1}`}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '1rem',
                  }}
                >
                  {index !== 0 && <Divider />}
                  <h4 style={{ fill: 'black', color: 'black', marginTop: 10 }}>
                    <b>Question {index + 1}</b>:
                  </h4>
                  <UpdateSimpleAnswer
                    questionType={question.questions[index].modelType}
                    questionIndex={activeQuestionIndex}
                    subQuestionIndex={index}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReadingAnswer;

import { Box, Container, Grid, ListItemSecondaryAction, Typography } from '@mui/material';
// import VideoPlayer from 'components/Video/VideoPlayer';
import VideoPlayer from 'features/videoNew/VideoPlayer';
import {
  getSelectedVdoCipherAsync,
  getSelectedVdoCipherSelector,
  getStudentSpeakingProgressAsync,
  getVdoCipherVideosReqSelector,
  setVideoCurrentTime,
  studentSpeakingVideoTasksSelector,
  threadReqSelector,
} from 'features/admin/slices/adminSlice';
import { tokenSelector } from 'features/auth/authSlice';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Comment from './Comment';
import CardHeader from 'components/Card/CardHeader';
import CustomCircularProgress from 'components/Progress/CustomCircularProgress';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import TranslateIcon from '@material-ui/icons/Translate';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CommentInput from './CommentInput';
import { Badge, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
interface Props {}

const styles = {
  listContainer: {
    width: '100%',
    maxWidth: 560,
    backgroundColor: 'white',
    padding: '5%',
  },
  selected: { color: 'blue' },
  listItem: {
    border: '1px solid black',
    borderRadius: '15px',
    margin: '1.5%',
    color: 'grey',
  },
  selectedListItem: {
    border: '2px solid Blue',
    borderRadius: '15px',
    margin: '1.5%',
    boxShadow: '8p 8px 16px 8px rgba(0, 0, 0, 0.9)',
  },
  table: {
    minWidth: 650,
    width: '100%',
    backgroundColor: 'white',
    padding: '5%',
  },
  tableHeader: { color: '#ba000d' },
  cardHeader: {
    color: '#fff',
    textAlign: 'center',
    margin: '10px',
    padding: '30px',
  } as const,
};

const VideoView: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const token = useSelector(tokenSelector);
  const userId = localStorage.getItem('studentID');
  const role = localStorage.getItem('role');

  const vdoCipherVideosReq = useSelector(getVdoCipherVideosReqSelector);
  const threadReq = useSelector(threadReqSelector);

  const studentTasks = useSelector(studentSpeakingVideoTasksSelector)?.speakingVideoTasks;
  const studentTasksReq = useSelector(studentSpeakingVideoTasksSelector).req;

  const selectedVideo: any = useSelector(getSelectedVdoCipherSelector);
  const { id } = useParams<{ id: string }>();

  const videoProgress = studentTasks?.find(task => task.videoId === id);
  const [videoState, setVideoState] = useState<'watch' | 'speak' | 'learn' | undefined>('watch');

  React.useEffect(() => {
    token && vdoCipherVideosReq.status === 'idle' && dispatch(getSelectedVdoCipherAsync({ videoId: id, token }));
  }, [token, vdoCipherVideosReq.status, dispatch]);

  React.useEffect(() => {
    if (userId && dispatch && studentTasksReq.status === 'idle') {
      dispatch(getStudentSpeakingProgressAsync({ studentId: userId, token }));
    }
  }, [userId, dispatch, studentTasksReq.status, token]);

  React.useEffect(() => {
    dispatch(getSelectedVdoCipherAsync({ videoId: id, token }));
  }, [token, threadReq.req.status, dispatch]);

  let filteredThreads = selectedVideo?.threads;
  if (selectedVideo && role === 'student') {
    filteredThreads = selectedVideo.threads.filter(
      (comment: Types.API.VdoCipher.IThreadInfo) => (comment.userId as any)._id === userId,
    );
  }

  if (!selectedVideo)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20%',
        }}
      >
        <CustomCircularProgress size={60} />
      </div>
    );
  const renderTheRightSide = (s: string) => {
    if (s === 'Watch the Video') {
      setVideoState('watch');
    } else if (s === 'Speak the Lines') {
      setVideoState('speak');
    } else if (s === 'Learn the Words') {
      setVideoState('learn');
      console.log('🚀 ~ renderTheRightSide ~ e:', s);
    } else console.log('🚀 error in renderTheRightSide');
  };
  return (
    <>
      <CardHeader color="primary" style={styles.cardHeader}>
        <Typography variant="h4">{selectedVideo?.title}</Typography>
      </CardHeader>

      <Grid container spacing={1}>
        <Grid item xs={7}>
          <Box display="flex" justifyContent="center" alignItems="center" margin={1}>
            {videoState === 'watch' && selectedVideo && (
              <VideoPlayer
                otp={selectedVideo.otpObj.otp}
                playbackInfo={selectedVideo.otpObj.playbackInfo}
                videoState="watch"
                // subtitles={[]}
              />
            )}
            {/* //! learn the word is not working Fady asked me to disabled it for now */}
            {/* {videoState === 'learn' && selectedVideo && selectedVideo.subtitles.length > 0 && (
              <Table style={styles.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={styles.tableHeader}>Word</TableCell>
                    <TableCell style={styles.tableHeader}>Definition</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedVideo.subtitles.map((item: any) => (
                    <TableRow key={item.text}>
                      <TableCell style={styles.selected} component="th" scope="row">
                        {item.text}
                      </TableCell>
                      <TableCell align="right">{item.start + '  :  ' + item.end}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )} */}
            {videoState === 'speak' && selectedVideo && (
              <VideoPlayer
                otp={selectedVideo.otpObj.otp}
                playbackInfo={selectedVideo.otpObj.playbackInfo}
                videoState="speak"
                // subtitles={selectedVideo.subtitles}
              />
            )}
          </Box>
          {selectedVideo.type === 'vdo-lect' && <CommentInput type="comment" />}
        </Grid>
        <Grid item xs={5} style={{ overflowY: 'auto', maxHeight: '100vh' }}>
          {selectedVideo.type === 'vdo-lect' &&
            filteredThreads &&
            filteredThreads
              .slice()
              .reverse()
              .map((comment: Types.API.VdoCipher.IThreadInfo) => (
                <Comment key={comment._id} comment={comment} type="comment" />
              ))}
          {selectedVideo.type === 'vdo-speaking' && (
            <div style={styles.listContainer}>
              <List>
                <ListItem
                  button
                  style={videoState === 'watch' ? styles.selectedListItem : styles.listItem}
                  onClick={() => renderTheRightSide('Watch the Video')}
                >
                  <ListItemIcon style={videoState === 'watch' ? styles.selected : {}}>
                    <OndemandVideoIcon />
                  </ListItemIcon>
                  <ListItemText style={videoState === 'watch' ? styles.selected : {}} primary="Watch the Video" />
                </ListItem>
                {/* //! disabled until we make it work again */}
                <ListItem
                  button
                  disabled={true}
                  style={videoState === 'learn' ? styles.selectedListItem : styles.listItem}
                  onClick={() => renderTheRightSide('Learn the Words')}
                >
                  <ListItemIcon style={videoState === 'learn' ? styles.selected : {}}>
                    <TranslateIcon />
                  </ListItemIcon>
                  <ListItemText style={videoState === 'learn' ? styles.selected : {}} primary="Learn the Words" />
                  <ListItemSecondaryAction>
                    <Badge badgeContent={'BETA'} color="error" />
                  </ListItemSecondaryAction>
                </ListItem>

                <ListItem
                  button
                  style={videoState === 'speak' ? styles.selectedListItem : styles.listItem}
                  disabled={videoProgress?.isSubmitted}
                  onClick={() => renderTheRightSide('Speak the Lines')}
                >
                  <ListItemIcon style={videoState === 'speak' ? styles.selected : {}}>
                    <RecordVoiceOverIcon />
                  </ListItemIcon>
                  <ListItemText
                    style={videoState === 'speak' ? styles.selected : {}}
                    primary={`Speak the Lines ${videoProgress?.isSubmitted ? '(Submitted)' : ''}`}
                  />
                </ListItem>
              </List>
              {/* {videoState === 'speak' && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={async () => {
                    await dispatch(setVideoCurrentTime(0));
                    window.location.reload();
                  }}
                >
                  Reset Progress
                </Button>
              )} */}
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default VideoView;

import { InputAdornment } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import { RootState } from 'features';
import {
  setSpeakingPhotoDescriptionText,
  setLanguageKnowledgeMarksSpeakingPhotoDescription,
  setCommunicationMarksSpeakingPhotoDescription,
  updateImageFileAsync,
} from '../editSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import config from 'config';
import CustomDropzone from 'components/CustomDropzone/CustomDropzone';
import { tokenSelector } from 'features/auth/authSlice';

// import CreateQImage from './EditQImage';
interface Props {
  uuid: string;
}
const SpeakingPhotoDQuestion: React.FC<Props> = ({ uuid }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const inputData = useSelector(
    (state: RootState) => state.editQuestion.editingQuestion.questions[uuid],
  ) as Types.API.Question.ISpeakingPhotoDescriptionCreateInput;
  var downloadURL = '';
  var imageName = '';
  if (inputData['image']) {
    downloadURL = config.apiBaseUrl.replace('api', '') + 'media' + inputData['image']['awsUrl'].split('data')[1];
    imageName = inputData['image']['filename'];
  }
  const onUploadClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const token = useSelector(tokenSelector);
  const handleSave = (acceptedFiles: File[]) => {
    acceptedFiles.length &&
      dispatch(updateImageFileAsync({ file: acceptedFiles[acceptedFiles.length - 1], token, id: uuid }));
    setOpen(false);
  };
  // const handleSave = (acceptedFiles: File[]) => {
  //   console.log(acceptedFiles.length)
  //   setOpen(false);
  // };

  return (
    <CardBody>
      <CustomInput
        labelText="Question Text"
        id="text"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'text',
          value: inputData.text,
          multiline: true,
          rows: 1,
          onChange: e => dispatch(setSpeakingPhotoDescriptionText({ text: e.target.value, uuid })),
          endAdornment: (
            <InputAdornment position="start">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  alignContent: 'center',
                }}
              >
                <br />
                <br />
                <br />

                <CustomInput
                  labelText="Communication Marks"
                  id={`CommunicationMarks${uuid}`}
                  inputProps={{
                    type: 'number',
                    inputProps: { min: 1, max: 100 },
                    value: inputData.communicationMark,
                    onChange: c =>
                      dispatch(
                        setCommunicationMarksSpeakingPhotoDescription({
                          value: parseInt(c.target.value, 10),
                          uuid,
                        }),
                      ),
                  }}
                />
                <CustomInput
                  labelText="Knowledge and Use of Language Marks"
                  id={`LanguageMarks${uuid}`}
                  inputProps={{
                    type: 'number',
                    inputProps: { min: 1, max: 100 },
                    value: inputData.languageKnowledgeMark,
                    onChange: c =>
                      dispatch(
                        setLanguageKnowledgeMarksSpeakingPhotoDescription({
                          value: parseInt(c.target.value, 10),
                          uuid,
                        }),
                      ),
                  }}
                />
              </div>
            </InputAdornment>
          ),
        }}
      />
      <a href={downloadURL} download target="_blank">
        <Button variant="contained">
          Download Image
          <br />
          {imageName}
        </Button>
      </a>
      <img src={downloadURL} alt="img" style={{ paddingLeft: '10%', width: '30%', height: '20%' }} />
      <br />
      <br />
      <Button variant="contained" onClick={onUploadClick}>
        Edit Uploaded Image
      </Button>
      <div style={{ float: 'right', width: 'auto', zIndex: 1000, position: 'relative' }}></div>
      <CustomDropzone onClose={onClose} open={open} accept={['image/*']} onSave={handleSave} filesLimit={1} />
    </CardBody>
  );
};

export default SpeakingPhotoDQuestion;

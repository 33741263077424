import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'features';
import { questionWithIndexSelector, resetWhyActiveData, setActiveWhyData } from '../updateSlice';
import { Tooltip } from '@material-ui/core';
import CustomButton from 'components/CustomButtons/Button';

export interface ShowWhyProps {
  role: Types.API.User.Role;
  why?: { pIndex: number; start: string; end: string };
}

const ShowWhy: React.FC<ShowWhyProps> = ({ role, why = { pIndex: -1, start: '-1', end: '-1' } }) => {
  const isWhyActive = useSelector((state: RootState) => state.updateQuiz.isWhyActive);
  const activeWhyData = useSelector((state: RootState) => state.updateQuiz.activeWhyData);
  const dispatch = useDispatch();
  const handleWhyClick = () => {
    if (
      isWhyActive &&
      activeWhyData.start === why.start &&
      activeWhyData.end === why.end &&
      activeWhyData.pIndex == why.pIndex
    ) {
      dispatch(resetWhyActiveData());
    } else {
      dispatch(setActiveWhyData(why.pIndex == -1 ? { ...why, pIndex: 0 } : why));
    }
  };
  const activeQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.questionIndex);
  const activeQuestionType = useSelector(questionWithIndexSelector(activeQuestionIndex)).sectionType;
  const tooltipTitle =
    activeQuestionType === 'Listening'
      ? `Please refer to the audio: from ${why.start} to ${why.end}`
      : activeQuestionType === 'Reading'
      ? `Click to highlight exact information at article #${+why.pIndex + 1}`
      : '';
  return (
    <div>
      {why.start !== '-1' && why.end !== '-1' && (
        <Tooltip title={tooltipTitle}>
          <CustomButton size="sm" color="facebook" onClick={handleWhyClick}>
            <strong>Why</strong>
          </CustomButton>
        </Tooltip>
      )}
    </div>
  );
};

export default ShowWhy;

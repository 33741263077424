import { Divider, IconButton } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import useMediaRecorder from '@wmik/use-media-recorder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomButton from 'components/CustomButtons/Button';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SendIcon from '@material-ui/icons/Send';

interface Props {
  handleSave: (blobs: Blob[]) => void;
  openRecord: boolean;
  setOpenRecord: React.Dispatch<React.SetStateAction<boolean>>;
}
const AudioRecorder: React.FC<Props> = ({ handleSave, openRecord, setOpenRecord }) => {
  const [showPreview, setShowPreview] = React.useState(false);
  const [bufferBlobs, setBufferBlobs] = React.useState<Blob[]>([]);
  const { status, mediaBlob, stopRecording, startRecording, liveStream } = useMediaRecorder({
    blobOptions: { type: 'audio/mp3' },
    mediaStreamConstraints: { audio: true },
  });
  // console.log(liveStream);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  // Add an effect to draw the sound waves on the canvas

  const handleSubmitAndAddMore = () => {
    setBufferBlobs(prevBlobs => {
      const newBlobs = prevBlobs;
      if (mediaBlob) return [...newBlobs, mediaBlob];
      return newBlobs;
    });
    if (mediaBlob) handleSave([mediaBlob]);
    //setShowPreview(false);
    handleClose();
  };

  const handleCancel = () => {
    setShowPreview(false);
  };

  const handleClose = () => {
    setShowPreview(false);
    setOpenRecord(false);
    stopRecording();
  };

  const canStartRecording = status === 'idle' || !showPreview;

  return (
    <Dialog open={openRecord} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle id="dialog-title">Record Voice ?</DialogTitle>
      <DialogContent>
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px',
              border: '5px',
            }}
          >
            <Divider />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
                position: 'relative',
              }}
            >
              {canStartRecording && (
                <IconButton
                  onClick={() => {
                    startRecording(0);
                    setShowPreview(true);
                  }}
                >
                  <FontAwesomeIcon size="2x" icon="record-vinyl" />
                </IconButton>
              )}
              {canStartRecording && <h5 style={{ fontWeight: 400, color: 'gray' }}>Start Recording</h5>}
              {/*         {status === 'recording' && (
          <IconButton onClick={stopRecording}>
            <FontAwesomeIcon size="2x" icon="stop-circle" />
          </IconButton>
        )}
        {status === 'recording' && <h5 style={{ fontWeight: 400, color: 'red' }}>

          Stop Recording</h5>}
         */}
              {status === 'recording' && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <IconButton onClick={stopRecording}>
                    <FontAwesomeIcon size="2x" icon="stop-circle" />
                  </IconButton>
                  <div
                    style={{
                      width: '100%',
                      height: '40px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end', // Align waves to the bottom
                    }}
                  >
                    <div
                      style={{
                        width: '10px', // Adjust the size of the waves
                        height: '30px', // Adjust the size of the waves
                        backgroundColor: 'red', // Adjust the color of the waves
                        borderRadius: '50%', // Make the waves circular
                        animation: 'soundWaves 0.5s infinite alternate', // Adjust animation duration
                      }}
                    />
                    <div
                      style={{
                        width: '10px',
                        height: '25px',
                        backgroundColor: 'red',
                        borderRadius: '50%',
                        animation: 'soundWaves 0.5s infinite alternate',
                        animationDelay: '0.1s',
                      }}
                    />
                    <div
                      style={{
                        width: '10px',
                        height: '35px',
                        backgroundColor: 'red',
                        borderRadius: '50%',
                        animation: 'soundWaves 0.5s infinite alternate',
                        animationDelay: '0.3s',
                      }}
                    />
                  </div>
                  <h5 style={{ fontWeight: 400, color: 'red' }}>Stop Recording</h5>
                </div>
              )}
              {status === 'stopped' && showPreview && mediaBlob && (
                <audio style={{ padding: '5px', borderRadius: '15px' }} src={URL.createObjectURL(mediaBlob)} controls />
              )}

              {/*
                <>
                  {status === 'stopped' && showPreview && (
                    <CustomButton color="facebook" disabled={mediaBlob === null} onClick={handleSubmitAndAddMore}>
                      Save & add more
                    </CustomButton>
                  )}
                  {status === 'stopped' && showPreview === true && (
                    <CustomButton color="danger" onClick={handleCancel}>
                      Cancel
                    </CustomButton>
                  )}
                </>
              */}
            </div>
            <Divider />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
              }}
            >
              {bufferBlobs &&
                bufferBlobs?.map(blob => (
                  <audio style={{ padding: '5px', borderRadius: '15px' }} src={URL.createObjectURL(blob)} controls />
                ))}
            </div>
          </div>
        </>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={mediaBlob === null}
          variant="contained"
          color="success"
          onClick={handleSubmitAndAddMore}
          endIcon={<SendIcon />}
          autoFocus
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AudioRecorder;

import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector, userRoleSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import {
  assignedHomeworksSelector,
  assignedQuizzesSelector,
  getAssignedHomeworksAsync,
  getAssignedQuizzesAsync,
  availableGroupsSelector,
  getGroupsAsync,
  getStudentsAsync,
  getQuestionsReqSelector,
  getQuestionsAsync,
} from '../slices/adminSlice';
import styles from 'assets/jss/lms/features/questions';
import { TextField, makeStyles } from '@material-ui/core';
import { paginateRequest } from 'features/helpers';
import { RootState } from 'features';
import Button from 'components/CustomButtons/Button';

const useStyles = makeStyles(styles);

interface Props {}
const CorrectTabManagement: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector(tokenSelector);
  const assignedHomeworks = useSelector(assignedHomeworksSelector);
  const assignedQuizzes = useSelector(assignedQuizzesSelector);
  const getQuestionsReq = useSelector(getQuestionsReqSelector);
  const availableGroups = useSelector(availableGroupsSelector);
  const userRole = useSelector(userRoleSelector);
  const getGroupsReq = useSelector((state: RootState) => state.admin.getGroupsReq);
  const getStudentsReq = useSelector((state: RootState) => state.admin.getStudentsReq);
  const [tasksType, setTasksType] = React.useState<string>('Homeworks');
  const [quizNameFilter, setQuizNameFilter] = React.useState<string>('');
  const [groupNameFilter, setGroupNameFilter] = React.useState<string>('');
  const [questionNameFilter, setQuestionNameFilter] = React.useState<string>('');

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Quiz Name', width: 200 },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      width: 270,
      valueFormatter: v => new Date(v.value as string).toUTCString().substring(0, 25),
    },
    {
      field: 'groups',
      headerName: 'Groups',
      width: 230,
      valueFormatter: v =>
        (v.value as any[]).map(g => availableGroups.find(gr => gr.id === g)?.name ?? 'N/A').join('\n'),
    },
    {
      field: 'questions',
      headerName: 'Questions',
      width: 330,
      valueFormatter: v => (v.value as any[]).map(q => q.title).join('\n'),
    },
    { field: 'durationInMinutes', headerName: 'Duration (Mins)', width: 170 },
    {
      field: 'active',
      headerName: 'Active?',
      width: 120,
      type: 'boolean',
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 150,
      renderCell: params => (
        <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start' }}>
          {
            <Button
              round
              color="success"
              size="sm"
              fullWidth
              onClick={() => {
                window.open(`/${userRole}/correct/${params.row._id}`);
              }}
            >
              Correct
            </Button>
          }
        </div>
      ),
    },
  ];

  // let data: Types.API.Quiz.IQuizModel[] = tasksType === 'Homeworks' ? assignedHomeworks.homeworks : assignedQuizzes.quizzes;
  // data = [...data].reverse();

  const [data, setData] = React.useState<Types.API.Quiz.IQuizModel[]>([]);
  let dataVarHelper = [...data];

  if (quizNameFilter && quizNameFilter !== '') {
    dataVarHelper = dataVarHelper.filter(q => q.name && q.name.toLowerCase().includes(quizNameFilter.toLowerCase()));
  }
  if (groupNameFilter && groupNameFilter !== '') {
    dataVarHelper = dataVarHelper.filter(
      q =>
        q.groups &&
        q.groups
          .map(g => availableGroups.find(gr => gr.id === g)?.name ?? '')
          .some(g => g.toLowerCase().includes(groupNameFilter.toLowerCase())),
    );
  }
  if (questionNameFilter && questionNameFilter !== '') {
    dataVarHelper = dataVarHelper.filter(
      q =>
        q.questions &&
        q.questions.some(question => (question as any).title.toLowerCase().includes(questionNameFilter.toLowerCase())),
    );
  }
  React.useEffect(() => {
    if (dispatch && getGroupsReq.status === 'idle') {
      token && dispatch(getGroupsAsync({ token }));
    }
    if (dispatch && getQuestionsReq.status === 'idle') {
      token && dispatch(getQuestionsAsync({ token }));
    }
  }, [getQuestionsReq.status, getGroupsReq.status, token, dispatch]);

  React.useEffect(() => {
    if (dispatch && getStudentsReq.status === 'idle') {
      token && dispatch(getStudentsAsync({ token }));
    }
  }, [getStudentsReq.status, token, dispatch]);

  React.useEffect(() => {
    if (tasksType === 'Homeworks') {
      if (assignedHomeworks.homeworks.length !== 0) setData(assignedHomeworks.homeworks);
      else setData(assignedHomeworks.homeworks);
    } else {
      if (assignedQuizzes.quizzes.length !== 0) setData(assignedQuizzes.quizzes);
      else setData(assignedQuizzes.quizzes);
    }
  }, [tasksType, assignedHomeworks.homeworks, assignedQuizzes.quizzes]);

  React.useEffect(() => {
    paginateRequest({
      items: assignedHomeworks.homeworks,
      req: assignedHomeworks.req,
      fetchDataAsync: getAssignedHomeworksAsync,
      allLoaded: assignedHomeworks.allLoaded,
      limit: 10000,
      token,
      dispatch,
    });
  }, [token, dispatch]);

  React.useEffect(() => {
    paginateRequest({
      items: assignedQuizzes.quizzes,
      req: assignedQuizzes.req,
      fetchDataAsync: getAssignedQuizzesAsync,
      allLoaded: assignedQuizzes.allLoaded,
      limit: 10000,
      token,
      dispatch,
    });
  }, [token, dispatch]);

  useReqListener({
    status: assignedHomeworks.req.status,
    successMessage: 'Successfully fetched all homeworks',
    loadingMessage: 'Fetching homeworks from the DB',
    errorMessage: assignedHomeworks.req.error,
  });
  useReqListener({
    status: assignedQuizzes.req.status,
    successMessage: 'Successfully fetched all quizzes',
    loadingMessage: 'Fetching quizzes from the DB',
    errorMessage: assignedQuizzes.req.error,
  });

  return (
    <div
      style={{ height: '80vh', width: '100%', display: 'flex', flexDirection: 'column', alignContent: 'flex-start' }}
    >
      <span>
        <button
          style={{
            padding: '1%',
            margin: '1%',
            background: `${tasksType === 'Homeworks' ? '#e5e5e5' : '#fff'}`,
            fontWeight: 'bold',
            border: '1px solid black',
            cursor: 'pointer',
            borderRadius: '3px',
          }}
          onClick={option => setTasksType('Homeworks')}
        >
          Homework
        </button>

        <button
          style={{
            padding: '1%',
            margin: '1%',
            background: `${tasksType === 'Quizzes' ? '#e5e5e5' : '#fff'}`,
            fontWeight: 'bold',
            border: '1px solid black',
            cursor: 'pointer',
            borderRadius: '3px',
          }}
          onClick={option => setTasksType('Quizzes')}
        >
          Quizzes
        </button>
      </span>
      <div>
        <TextField
          style={{ marginLeft: '20px' }}
          label="Task Name Filter"
          onChange={e => setQuizNameFilter(e.target.value)}
        />
        <TextField
          style={{ marginLeft: '20px' }}
          label="Group Name Filter"
          onChange={e => setGroupNameFilter(e.target.value)}
        />
        <TextField
          style={{ marginLeft: '20px' }}
          label="Question Name Filter"
          onChange={e => setQuestionNameFilter(e.target.value)}
        />
      </div>
      <DataGrid
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        }}
        className={classes.dataGrid}
        rows={dataVarHelper
          .sort(function (a, b) {
            if (!a || !a.createdAt) return 1;
            if (!b || !b.createdAt) return -1;
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
          })
          .map(q => ({
            _id: q._id,
            id: q._id,
            name: q.name,
            dueDate: q.dueDate,
            durationInMinutes: q.durationInMinutes,
            groups: q.groups,
            questions: q.questions,
            action: '',
            active: q.active,
          }))}
        pagination
        columns={columns}
        loading={assignedHomeworks.req.status === 'loading' && assignedHomeworks.homeworks.length === 0}
      />
    </div>
  );
};

export default CorrectTabManagement;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment } from '@material-ui/core';
import CustomInput from 'components/CustomInput/CustomInput';
import React from 'react';

interface Props {
  name: string;
  email: string;
  password: string;
  phone: string;
  onNameChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  onPasswordChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  onEmailChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  onPhoneChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
}
const AssistantInputs: React.FC<Props> = ({
  name,
  email,
  password,
  phone,
  onNameChange,
  onEmailChange,
  onPhoneChange,
  onPasswordChange,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CustomInput
        labelText="Student Name..."
        id="name"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'text',
          endAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon="user" />
            </InputAdornment>
          ),
          required: true,
          autoFocus: true,
          onChange: onNameChange,
          value: name,
        }}
      />
      <CustomInput
        labelText="Email..."
        id="email"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'email',
          endAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon="envelope" />
            </InputAdornment>
          ),
          required: true,
          autoFocus: true,
          onChange: onEmailChange,
          value: email,
        }}
      />
      <CustomInput
        labelText="Password"
        id="pass"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'password',
          endAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon="lock" />
            </InputAdornment>
          ),
          autoComplete: 'off',
          onChange: onPasswordChange,
          value: password,
        }}
      />
      <CustomInput
        labelText="Phone Number"
        id="pass"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'text',
          endAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon="phone" />
            </InputAdornment>
          ),
          autoComplete: 'off',
          onChange: onPhoneChange,
          value: phone,
        }}
      />
    </div>
  );
};

export default AssistantInputs;

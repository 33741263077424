import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment } from '@material-ui/core';
import CustomAutocomplete from 'components/Autocomplete/Autocomplete';
import CustomInput from 'components/CustomInput/CustomInput';
import { tokenSelector } from 'features/auth/authSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentsReqSelector, getStudentsAsync } from '../slices/adminSlice';

interface Props {
  name: string;
  email: string;
  password: string;
  phone: string;
  students: Types.API.User.IUserInfo[];
  parentStudents: Types.API.User.IUserInfo[];
  onNameChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  onPasswordChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  onEmailChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  onPhoneChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
  onStudentsChange(e: Types.API.User.IUserInfo[]): void;
}
const ParentInputs: React.FC<Props> = ({
  name,
  email,
  password,
  phone,
  students,
  parentStudents,
  onNameChange,
  onEmailChange,
  onPhoneChange,
  onStudentsChange,
  onPasswordChange,
}) => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const studentsReq = useSelector(getStudentsReqSelector);
  React.useEffect(() => {
    if (token && dispatch && studentsReq.status === 'idle') {
      token && dispatch(getStudentsAsync({ token }));
    }
  }, [token, dispatch, studentsReq.status]);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CustomInput
        labelText="Student Name..."
        id="name"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'text',
          endAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon="user" />
            </InputAdornment>
          ),
          required: true,
          autoFocus: true,
          onChange: onNameChange,
          value: name,
        }}
      />
      <CustomInput
        labelText="Email..."
        id="email"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'email',
          endAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon="envelope" />
            </InputAdornment>
          ),
          required: true,
          autoFocus: true,
          onChange: onEmailChange,
          value: email,
        }}
      />
      <CustomInput
        labelText="Password"
        id="pass"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'password',
          endAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon="lock" />
            </InputAdornment>
          ),
          autoComplete: 'off',
          onChange: onPasswordChange,
          value: password,
        }}
      />
      <CustomInput
        labelText="Phone Number"
        id="pass"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'text',
          endAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon="phone" />
            </InputAdornment>
          ),
          autoComplete: 'off',
          onChange: onPhoneChange,
          value: phone,
        }}
      />
      <CustomAutocomplete
        value={parentStudents}
        multiple
        onChange={(event: React.ChangeEvent<{}>, value: Types.API.User.IUserInfo[]) => onStudentsChange(value)}
        placeholder="students"
        id="Students ids"
        options={students}
      />
    </div>
  );
};

export default ParentInputs;

import React from 'react';
import { Modal, Backdrop, Fade, Box, Typography, CircularProgress, TextField } from '@material-ui/core';
import { tokenSelector } from 'features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import styles from 'assets/jss/lms/features/questions';
import CustomAutocomplete from 'components/Autocomplete/Autocomplete';
import { makeStyles } from '@material-ui/core';
import { OptionType } from 'components/Autocomplete/Autocomplete';
import classNames from 'classnames';
import { RootState } from 'features';
import { editQuizAsync, getQuestionsReqSelector, questionsSelector } from '../slices/adminSlice';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import { useReqListener } from 'hooks/useReqListener';

interface IEditQuizViewProps {
  assignedTask: Types.API.Quiz.IQuizModel | undefined;
  handleClose: Function;
  isHomework: boolean;
}

const useStyles = makeStyles(styles);
const getMappedQuizModel = (quiz: Types.API.Quiz.IQuizModel): Types.API.Quiz.IQuizModel => {
  const questionIds = quiz.questions.map(q => ((q as any)._id ? (q as any)._id : q));
  const groupIds = quiz.groups.map(g => ((g as any)._id ? (g as any)._id : g));
  return {
    _id: quiz._id,
    name: quiz.name,
    questions: questionIds,
    groups: groupIds,
    dueDate: quiz.dueDate,
    durationInMinutes: quiz.durationInMinutes,
  } as Types.API.Quiz.IQuizModel;
};

const EditQuizView = (props: IEditQuizViewProps) => {
  const { assignedTask, handleClose, isHomework } = props;
  const mappedTask = assignedTask ? getMappedQuizModel(assignedTask) : undefined;
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const classes = useStyles();
  const allGroups = useSelector((state: RootState) => state.admin.groups);
  const editReq = useSelector((state: RootState) => state.admin.editQuizReq);
  const allQuestions = useSelector(questionsSelector);
  const getQuestionsReq = useSelector(getQuestionsReqSelector);
  const [editedQuiz, setEditedQuiz] = React.useState<Types.API.Quiz.IQuizModel | undefined>(mappedTask);
  const handleEdit = () => {
    if (editedQuiz) {
      dispatch(editQuizAsync({ token, quiz: editedQuiz }));
    }
  };
  React.useEffect(() => {
    setEditedQuiz(mappedTask);
  }, [assignedTask]);

  useReqListener({
    status: editReq.status,
    successMessage: `Successfully edited your ${isHomework ? 'homework' : 'quiz'}`,
    loadingMessage: `Editing your ${isHomework ? 'homework' : 'quiz'}`,
    errorMessage: editReq.error,
  });

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={assignedTask !== undefined}
        onClose={() => handleClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={assignedTask !== undefined}>
          {editedQuiz !== undefined && getQuestionsReq.status === 'succeeded' ? (
            <Box
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%',
                height: '80%',
                background: 'whitesmoke',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                padding: '40px',
                overflowY: 'scroll',
              }}
            >
              <div>
                <Typography id="transition-modal-title" variant="h6" component="h2">
                  {editedQuiz?.name}
                </Typography>
                <div style={{ display: 'flex' }}>
                  <TextField
                    id="datetime-local"
                    label="Due Date"
                    type="datetime-local"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ minWidth: 'max-content', margin: '11px' }}
                    defaultValue={editedQuiz.dueDate?.toString().slice(0, -5)}
                    onChange={c => setEditedQuiz({ ...editedQuiz, dueDate: new Date(c.target.value) })}
                  />
                  <CustomInput
                    id="categories-submit-button"
                    labelText="Quiz Title"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.margin20right,
                      value: editedQuiz.name,
                      onChange: e => setEditedQuiz({ ...editedQuiz, name: e.target.value }),
                    }}
                  />
                  {!isHomework && (
                    <CustomInput
                      id="QuizTime"
                      labelText="Time"
                      inputProps={{
                        type: 'number',
                        inputProps: { min: 0, max: 200 },
                        value: editedQuiz.durationInMinutes,
                        onChange: c =>
                          setEditedQuiz({ ...editedQuiz, durationInMinutes: parseInt(c.target.value, 10) }),
                      }}
                    />
                  )}
                </div>
                <div className={classNames(classes.container, classes.categoryAutocomplete)}>
                  <CustomAutocomplete
                    value={allGroups
                      .filter(g => editedQuiz?.groups?.includes(g.id))
                      .map(g => {
                        return { id: g.id, name: g.name };
                      })}
                    multiple
                    placeholder="Groups"
                    onChange={(event: React.ChangeEvent<{}>, value: OptionType[]) =>
                      setEditedQuiz({ ...editedQuiz, groups: value.map(v => v.id) })
                    }
                    id="Groups"
                    labelText="Groups"
                    options={allGroups}
                  />
                </div>
                <div className={classNames(classes.container, classes.categoryAutocomplete)}>
                  <CustomAutocomplete
                    value={allQuestions
                      .filter(q => editedQuiz?.questions?.map(q => (q as any)._id ?? q).includes(q.id))
                      .map(q => {
                        return { id: q.id, name: q.title };
                      })}
                    multiple
                    placeholder="Questions"
                    onChange={(event: React.ChangeEvent<{}>, value: OptionType[]) =>
                      setEditedQuiz({ ...editedQuiz, questions: value.map(v => v.id) })
                    }
                    id="Questions"
                    labelText="Questions"
                    options={allQuestions.map(q => {
                      return { id: q.id, name: q.title };
                    })}
                  />
                </div>
                <Button color="primary" onClick={handleEdit}>
                  Edit {isHomework ? 'Homework' : 'Quiz'}
                </Button>
              </div>
            </Box>
          ) : (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                minHeight: '100vh',
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Fade>
      </Modal>
    </div>
  );
};

export default EditQuizView;

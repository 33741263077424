import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';

import {
  getVdoCipherVideosReqSelector,
  getVdoCipherVideosSelector,
  getVdoCipherAsync,
  setCreateVdoCipherInputs,
} from '../slices/adminSlice';
import { RootState } from 'features';
import CustomInput from 'components/CustomInput/CustomInput';
import styles from 'assets/jss/lms/features/questions';
import { makeStyles, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import Autocomplete, { OptionType } from 'components/Autocomplete/Autocomplete';
import GridContainer from 'components/Grid/GridContainer';
import MonthContent from 'features/student/MonthContent';
import { formateMonthDataPair, sortMonths } from 'features/helpers';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CustomCircularProgress from 'components/Progress/CustomCircularProgress';
import Container from '@mui/material/Container';
import CustomLinearProgress from 'components/Progress/CustomLinearProgress';
import VideoForm from './VideoForm';
import { Grid } from '@mui/material';
import CustomButton from 'components/CustomButtons/Button';

const useStyles = makeStyles(styles);

interface Props {}
const VimVideoLecturesManagement: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector(tokenSelector);
  const vdoCipherVideos = useSelector(getVdoCipherVideosSelector);
  const createVdoCipherInputs = useSelector((state: RootState) => state.admin.createVdoCipher.videoInputs);

  const vdoCipherVideosReq = useSelector(getVdoCipherVideosReqSelector);
  const uploadVdoCipherReq = useSelector((state: RootState) => state.admin.uploadUsingCredentials.req);
  const createVdoCipherReq = useSelector((state: RootState) => state.admin.createVdoCipher.req);
  const editVdoCipherReq = useSelector((state: RootState) => state.admin.editVdoCipherReq);
  const deleteVdoCipherReq = useSelector((state: RootState) => state.admin.deleteVdoCipherReq);

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleExpansion = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [openAddDialogue, setOpenAddDialogue] = React.useState(false);

  //const [videoTypeFilter, setVideoTypeFilter] = React.useState<OptionType>({ id: 'vdo-lect', name: 'Video Lecture' });
  const videoTypeFilter ={ id: 'vdo-lect', name: 'Video Lecture' }

  const videoTypes: OptionType[] = [
    { id: 'vdo-lect', name: 'Video Lecture' },
    { id: 'vdo-speaking', name: 'Video Speaking' },
  ];

  // vdo-speaking videos has vdo-speaking videos
  // not vdo-speaking videos has vdo-lect videos and undefined type videos
  let filteredVideos =
    videoTypeFilter.id === 'vdo-speaking'
      ? vdoCipherVideos.filter(v => v.type === 'vdo-speaking')
      : vdoCipherVideos.filter(v => v.type !== 'vdo-speaking');

  useReqListener({
    status: vdoCipherVideosReq.status,
    successMessage: 'Successfully fetched all videos',
    loadingMessage: 'Fetching videos the DB',
    errorMessage: vdoCipherVideosReq.error,
  });
  useReqListener({
    status: editVdoCipherReq.status,
    successMessage: 'Successfully Edited the Video',
    loadingMessage: 'Editing the video',
    errorMessage: editVdoCipherReq.error,
  });
  useReqListener({
    status: deleteVdoCipherReq.status,
    successMessage: 'Successfully Deleted the Video',
    loadingMessage: 'Deleting the video',
    errorMessage: deleteVdoCipherReq.error,
  });

  React.useEffect(() => {
    token && vdoCipherVideosReq.status === 'idle' && dispatch(getVdoCipherAsync({ token }));
  }, [token, vdoCipherVideosReq.status, dispatch]);

  React.useEffect(() => {
    token && uploadVdoCipherReq.status == 'succeeded' && dispatch(getVdoCipherAsync({ token }));
  }, [token, uploadVdoCipherReq.status, dispatch]);

  React.useEffect(() => {
    token && editVdoCipherReq.status == 'succeeded' && dispatch(getVdoCipherAsync({ token }));
  }, [token, editVdoCipherReq.status, dispatch]);

  React.useEffect(() => {
    token && deleteVdoCipherReq.status == 'succeeded' && dispatch(getVdoCipherAsync({ token }));
  }, [token, deleteVdoCipherReq.status, dispatch]);

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <div className={classNames(classes.container, classes.categoryAutocomplete)}>
        <GridContainer
          spacing={9}
          style={{
            margin: 'auto',
          }}
        >
          <Grid item>
            <CustomButton onClick={() => setOpenAddDialogue(true)} color="primary">
              Add New Video Lecture
            </CustomButton>
            <VideoForm openDialogue={openAddDialogue} setOpenDialogue={setOpenAddDialogue} edit={false}
              type='vdo-lect'
/>
          </Grid>

{/*           <Grid width={500} item>
            <Autocomplete
              labelText="Filter Video Types"
              id="type"
              value={videoTypeFilter}
              onChange={(_e, value: OptionType) => setVideoTypeFilter(value)}
              options={videoTypes}
            />
          </Grid> */}
        </GridContainer>
      </div>

      {uploadVdoCipherReq.status === 'loading' && (
        <Container sx={{ mb: 10 }}>
          <CustomLinearProgress />
          <Typography style={{ display: 'flex', justifyContent: 'center' }} variant="subtitle2">
            Please wait while uploading the video .. Don't refresh the page
          </Typography>
        </Container>
      )}

      <Card>
        <CardHeader color="info" className={classes.cardHeader}>
          <h6 style={{ fontWeight: 'bolder', fontSize: 'large' }}>
            All {videoTypeFilter.id === 'vdo-speaking' ? 'Speaking' : 'Lecture'} Videos
          </h6>
        </CardHeader>

        <CardBody>
          {vdoCipherVideosReq.status == 'loading' ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CustomCircularProgress />
            </Box>
          ) : (
            <MonthContent
              type={videoTypeFilter.id === 'vdo-lect' ? 'vdo-lect' : 'vdo-speaking'}
              monthDataPair={formateMonthDataPair(filteredVideos, videoTypeFilter.id)}
              sortedMonths={sortMonths(formateMonthDataPair(filteredVideos, videoTypeFilter.id))}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default VimVideoLecturesManagement;

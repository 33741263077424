import React from 'react';

// @material-ui/core components
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Grid, { GridProps } from '@material-ui/core/Grid';

const styles = createStyles({
  grid: {
    position: 'relative',
    width: '100%',
    minHeight: '1px',
    flexBasis: 'auto',
  },
});

const useStyles = makeStyles(styles);

const GridItem: React.FC<GridProps> = ({ children, className = '', ...rest }) => {
  const classes = useStyles();
  return (
    <Grid item {...rest} className={classes.grid + ' ' + className}>
      {children}
    </Grid>
  );
};

export default GridItem;

import { FC } from 'react';
import GridContainer from 'components/Grid/GridContainer';
// import GridItem from 'components/Grid/GridItem';
import { useDispatch, useSelector } from 'react-redux';
import { addQuestion, questionsSelector } from './createSlice';
import Button from 'components/CustomButtons/Button';
import QuestionCard from './SimpleQuestions/CreateQSimpleCard';
// import React from 'react';
const CreateQList: FC = () => {
  const questionsElements = useSelector(questionsSelector);
  const dispatch = useDispatch();
  // React.useEffect(()=>{
  //   console.log(questionsElements)
  // },[questionsElements])
  return (
    <GridContainer
      justify="center"
      style={{ width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      {Object.keys(questionsElements).map(uuid => (
        <QuestionCard key={uuid} uuid={uuid} />
      ))}
      <div>
        <Button color="facebook" onClick={_ => dispatch(addQuestion())} size="lg">
          Add SubQuestion
        </Button>
      </div>
    </GridContainer>
  );
};

export default CreateQList;

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Notification from 'features/notifications/Notification';
import HomePage from 'features/parent/Home';
import '../features/parent/style.scss';
import CorrectQuiz from 'features/quiz/CorrectQuiz';
import ParentsPortal from 'features/parent/ParentsPortal';
import StudentTasksManagement from 'features/admin/views/StudentTasksManagement';
export const ParentRoutesPrefix = '/parent';

const ParentLayout: React.FC = () => {
  return (
    <Switch>
      <Route component={CorrectQuiz} exact path={`${ParentRoutesPrefix}/viewPublishedQuiz/:quizTakenId`} />
      <Route component={StudentTasksManagement} path={`${ParentRoutesPrefix}/studentTasks/:studentId`} />
      <Route path={ParentRoutesPrefix}>
        {/* <HomePage /> */}
        <ParentsPortal />
        <Notification />
      </Route>
      <Redirect exact from={ParentRoutesPrefix} to="/student/home" />
    </Switch>
  );
};

export default ParentLayout;

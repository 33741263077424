import React from 'react';
import Button from 'components/CustomButtons/Button';
import PrimaryTypo from 'components/Typography/Primary';
import Card from 'components/Card/Card';
import styles from 'assets/jss/lms/features/questions';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import WhyIcon from '@material-ui/icons/QuestionAnswer';
import { sectionTypeSelector, updateNoteTakingCorrectionWhy, noteTakingCorrectionSelector } from '../createSlice';
import { DialogActions, DialogContent, Dialog, DialogTitle, makeStyles } from '@material-ui/core';
import ReadingQuestionsArticles from '../CreateQReadingArticles';
import CustomInput from 'components/CustomInput/CustomInput';

const useStyles = makeStyles(styles);
const WhyNoteTaking: React.FC<{
  uuid: string;
  noteIndex: number;
  noteAnswerIndex: number;
  id: string;
}> = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [start, setStart] = React.useState(-1);
  const [end, setEnd] = React.useState(-1);
  const [audioStart, setAudioStart] = React.useState('00:00');
  const [audioEnd, setAudioEnd] = React.useState('00:00');
  const [pIndex, setPIndex] = React.useState(0);
  const whyData = useSelector(noteTakingCorrectionSelector(props.uuid)).why[props.noteIndex][props.noteAnswerIndex];
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const sectionType = useSelector(sectionTypeSelector);

  setInterval(function () {
    if (document.activeElement && sectionType.id === 'Reading') {
      if (
        document.activeElement.tagName === 'TEXTAREA' ||
        (document.activeElement.tagName === 'INPUT' && document.activeElement.nodeType === Node.TEXT_NODE)
      ) {
        setStart((document.activeElement as HTMLTextAreaElement).selectionStart);
        setEnd((document.activeElement as HTMLTextAreaElement).selectionEnd);
      }
    }
  }, 100);
  const handleClickOpen = () => {
    setDialogOpen(true);
  };
  const focusText = () => {
    const textBox = document.getElementById('text') as HTMLTextAreaElement
    if(textBox!=null && end>start){
      const startTmp = start
      const endTmp = end
      textBox.focus()
      const txt = textBox.value
      textBox.scrollTop =0
      textBox.value= txt.substring(0,start)
      const height = textBox.scrollHeight
      textBox.value = txt
      textBox.scrollTop = height - 150
      setStart(startTmp)
      setEnd(endTmp)
      textBox.selectionStart = startTmp
      textBox.selectionEnd = endTmp
  }
  else{
    console.log(false)
  }
}

  const handleBlur = ()=>{
    focusText()
  }
  const handleKeyUp = (e:React.KeyboardEvent<HTMLInputElement>)=>{
    if(e.keyCode === 13)
      focusText()
  }

  const handleClose = () => {
    setDialogOpen(false);
    if (start !== -1 && end !== -1 && sectionType.id === 'Reading') {
      dispatch(
        updateNoteTakingCorrectionWhy({
          uuid: props.uuid,
          noteIndex: props.noteIndex,
          noteAnswerIndex: props.noteAnswerIndex,
          value: { pIndex, start: '' + start, end: '' + end },
        }),
      );
    }
    if (sectionType.id === 'Listening') {
      dispatch(
        updateNoteTakingCorrectionWhy({
          uuid: props.uuid,
          noteIndex: props.noteIndex,
          noteAnswerIndex: props.noteAnswerIndex,
          value: {
            pIndex,
            start: audioStart,
            end: audioEnd,
          },
        }),
      );
    }
  };

  const handleReadingArticleChange = (t: number) => {
    setPIndex(t);
  };

  return (
    <>
      <IconButton aria-label="why" onClick={handleClickOpen}>
        <WhyIcon fontSize="small" />
      </IconButton>
      {whyData.pIndex !== -1 && whyData.start !== '-1' && whyData.end !== '-1' && (
        <Card className={classes.container}>
          <PrimaryTypo>
            {sectionType.id === 'Reading' && (
              <h6>{`Article: #${whyData.pIndex + 1}, From index: ${whyData.start}, To: ${whyData.end}`}</h6>
            )}
            {sectionType.id === 'Listening' && (
              <h6>{`Audio Clip: #${whyData.pIndex + 1}, From minute:seconds : ${whyData.start}, To: ${
                whyData.end
              }`}</h6>
            )}
          </PrimaryTypo>
        </Card>
      )}

      <Dialog fullWidth open={dialogOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
        {sectionType.id === 'Reading' && (
          <DialogTitle id="form-dialog-title">Please highlight the text of the why part</DialogTitle>
        )}
        {sectionType.id === 'Listening' && (
          <DialogTitle id="form-dialog-title">Please enter the start and end time of the why part</DialogTitle>
        )}
        <DialogContent>
          {sectionType.id === 'Reading' && (
            <ReadingQuestionsArticles disableActions setActiveTab={handleReadingArticleChange} />
          )}
          {sectionType.id === 'Listening' && (
            <div>
              <CustomInput
                id="audio start time"
                inputProps={{
                  value: audioStart,
                  onChange: c => setAudioStart(c.target.value),
                }}
                labelText="Audio Start Time"
              />
              <CustomInput
                id="audio end time"
                inputProps={{ value: audioEnd, onChange: c => setAudioEnd(c.target.value) }}
                labelText="Audio End Time"
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {sectionType.id === 'Reading' && (
            <Card className={classes.container}>
              <PrimaryTypo>
                <>
                  <h4>Article: #{pIndex + 1}, From Index: </h4>  <input value={start} onKeyUp = {handleKeyUp} onBlur = {handleBlur} onChange={e => setStart(parseInt(e.target.value))} style={{width: "35%",height:'30px'}} type="number" id="fname" name="fnamexx"/> <h4>To: </h4> <input style={{width: "35%",height:'30px'}} type="number" id="fname" name="fname2 "value={end} onKeyUp = {handleKeyUp} onBlur = {handleBlur} onChange={e => setEnd(parseInt(e.target.value))}/>
                </>
              </PrimaryTypo>
            </Card>
          )}
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Confirm Selected Positions
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WhyNoteTaking;

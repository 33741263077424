// import FailureNotification from 'components/Snackbar/FailureNotifications';
// import SuccessNotification from 'components/Snackbar/SuccessNotification';
import SnackbarContent from 'components/Snackbar/SnackbarContent';
import Warning from '@material-ui/icons/Warning';
import Check from '@material-ui/icons/Check';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  close,
  notificationMessageSelector,
  notificationOpenSelector,
  notificationVariantSelector,
} from './notificationSlice';

const Notification: React.FC = () => {
  const dispatch = useDispatch();
  const message = useSelector(notificationMessageSelector);
  const open = useSelector(notificationOpenSelector);
  const variant = useSelector(notificationVariantSelector);

  const handleClose = React.useCallback(() => {
    dispatch(close());
  }, [dispatch]);
  return (
    <SnackbarContent
      message={
        <span>
          <b>{variant === 'success' ? 'SUCCESS' : variant === 'failure' ? 'FAILURE' : 'LOADING'} :</b> {message}
        </span>
      }
      open={open}
      color={variant === 'success' ? variant : variant === 'failure' ? 'danger' : 'info'}
      icon={variant === 'success' ? Check : Warning}
      loading={variant === 'loading'}
      onClose={handleClose}
    />
  );
};

export default Notification;

import React, { useEffect } from 'react';
import Validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
// core components
import Header from 'components/Header/Header';
import HeaderLinks from 'components/Header/HeaderLinks';
import Footer from 'components/Footer/Footer';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';
import CustomInput from 'components/CustomInput/CustomInput';
import Notification from 'features/notifications/Notification';

import styles from 'assets/jss/lms/views/landing/login';

import image from 'assets/img/bg7.jpg';
import { loggedInSelector, statusSelector, userSelector } from 'features/auth/authSlice';

import { loginAsync } from 'features/auth/authSlice';
import { Link, useHistory } from 'react-router-dom';
import { useInputForm, useReqListener } from 'hooks';

const useStyles = makeStyles(styles);
const isEmail = (em: string) => Validator.isEmail(em);

const LoginView: React.FC = props => {
  const [cardAnimation, setCardAnimation] = React.useState('cardHidden');
  const [emailState, onEmailFormChange] = useInputForm({ validationFunction: isEmail });
  const [passState, onPassFormChange] = useInputForm({});
  const loginReqStatus = useSelector(statusSelector);
  const user = useSelector(userSelector);
  const isLoggedIn = useSelector(loggedInSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const canSubmit = () => !passState.error && !emailState.error;
  const handleSubmit = () => {
    if (canSubmit()) {
      dispatch(loginAsync({ email: emailState.inputValue, password: passState.inputValue }));
    }
  };

  useEffect(() => {
    setTimeout(function () {
      setCardAnimation('');
    }, 300);
  }, []);

  useReqListener({
    status: loginReqStatus.status,
    successMessage: 'Logged In Successfully',
    loadingMessage: 'Logging In',
    errorMessage: loginReqStatus.error,
  });

  useEffect(() => {
    if (isLoggedIn) history.push('/' + user.role);
  }, [user.role, isLoggedIn, history]);

  const classes = useStyles();

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  };

  const { ...rest } = props;
  return (
    <div>
      <Header absolute color="primary" brand="IGCSE-ESL" rightLinks={<HeaderLinks />} fixed {...rest} />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card plain={false} carousel={false} className={classes[cardAnimation]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Login</h4>
                    <div className={classes.socialLine}>
                      <Button justIcon href="#pablo" target="_blank" color="transparent">
                        <i className={'fab fa-twitter'} />
                      </Button>
                      <Button justIcon href="#pablo" target="_blank" color="transparent">
                        <i className={'fab fa-facebook'} />
                      </Button>
                      <Button justIcon href="#pablo" target="_blank" color="transparent">
                        <i className={'fab fa-google-plus-g'} />
                      </Button>
                    </div>
                  </CardHeader>
                  <p className={classes.divider}>Or Be Classical</p>
                  <CardBody>
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'email',
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        required: true,
                        autoFocus: true,
                        onChange: onEmailFormChange,
                        value: emailState.inputValue,
                        onKeyDown: handleKeyDown,
                        error: emailState.error,
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="pass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'password',
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>lock_outline</Icon>
                          </InputAdornment>
                        ),
                        autoComplete: 'off',
                        onChange: onPassFormChange,
                        value: passState.inputValue,
                        onKeyDown: handleKeyDown,
                      }}
                    />
                  </CardBody>
                  <>
                    <CardFooter className={classes.cardFooter} style={{ paddingBottom: 0 }}>
                      <Button disabled={!canSubmit()} onClick={handleSubmit} color="primary" size="lg">
                        Get started
                      </Button>
                    </CardFooter>

                    <CardFooter className={classes.cardFooter}>
                      <Button color="primary" size="lg" simple>
                        <Link to="/landing/forgot-password">Forgot Password</Link>
                      </Button>
                    </CardFooter>
                  </>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
          <Notification />
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
};

export default LoginView;

import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Divider from '@material-ui/core/Divider';
import Popper from '@material-ui/core/Popper';

// core components
import Button, { Props as ButtonProps } from 'components/CustomButtons/Button';

import styles from 'assets/jss/lms/components/customDropdownStyle';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';

const useStyles = makeStyles(styles);

export default function CustomDropdown(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;
  const handleClick = (event: React.SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    if (anchorEl && anchorEl.contains(event.target as Node)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = (prop: ISelectOption) => {
    setAnchorEl(null);
    if (props && props.onClick) {
      props.onClick(prop);
    }
  };
  const handleCloseAway = (event: React.SyntheticEvent<Document, MouseEvent>) => {
    if (anchorEl && anchorEl.contains(event.target as Node)) {
      return;
    }
    setAnchorEl(null);
  };
  const classes = useStyles();
  const {
    buttonText,
    dropdownList,
    buttonProps,
    dropup,
    dropdownHeader,
    caret = true,
    hoverColor = 'primary',
    left,
    rtlActive,
    noLiPadding,
  } = props;
  const caretClasses = classNames({
    [classes.caret]: true,
    [classes.caretActive]: Boolean(anchorEl),
    [classes.caretRTL]: rtlActive,
  });
  const dropdownItem = classNames({
    [classes.dropdownItem]: true,
    [classes[hoverColor + 'Hover']]: true,
    [classes.noLiPadding]: noLiPadding,
    [classes.dropdownItemRTL]: rtlActive,
  });

  return (
    <div>
      <div>
        <Button
          fullWidth
          aria-label="Schools"
          aria-owns={id}
          aria-haspopup="true"
          onClick={handleClick}
          size={'lg'}
          {...buttonProps}
        >
          {props.buttonIcon && <props.buttonIcon className={classes.buttonIcon} />}

          <span style={{ color: '#333', fontWeight: 'bold' }}>{buttonText !== undefined ? buttonText : null}</span>
          {caret ? <b className={caretClasses} /> : null}
        </Button>
      </div>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement={dropup ? (left ? 'top-start' : 'top') : left ? 'bottom-start' : 'bottom'}
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
        id={id}
      >
        {transProps => (
          <Grow in={Boolean(anchorEl)} style={dropup ? { transformOrigin: '0 100% 0' } : { transformOrigin: '0 0 0' }}>
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseAway}>
                <MenuList role="menu" className={classes.menuList}>
                  {dropdownHeader !== undefined ? (
                    <MenuItem
                      onClick={() => handleClose({ value: dropdownHeader, isDivider: false })}
                      className={classes.dropdownHeader}
                      style={{
                        color: '#000',
                      }}
                    >
                      {dropdownHeader}
                    </MenuItem>
                  ) : null}
                  {dropdownList.map((prop, key) => {
                    if (prop.isDivider) {
                      return <Divider key={key} className={classes.dropdownDividerItem} />;
                    }
                    return (
                      <MenuItem
                        key={key}
                        onClick={() => handleClose(prop)}
                        className={dropdownItem}
                        style={{
                          color: '#000',
                        }}
                      >
                        {prop.value}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export interface ISelectOption {
  value?: string;
  id?: string;
  isDivider?: boolean;
}

interface Props {
  hoverColor?: 'black' | 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'rose';
  buttonText?: React.ReactNode;
  buttonIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  dropdownList: ISelectOption[];
  buttonProps?: ButtonProps;
  dropup?: boolean;
  dropdownHeader?: string;
  rtlActive?: boolean;
  caret?: boolean;
  left?: boolean;
  noLiPadding?: boolean;
  // function that returns the selected item
  onClick?(v: ISelectOption): void;
}

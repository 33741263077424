import { FC } from 'react';
import GridContainer from 'components/Grid/GridContainer';
import { useSelector } from 'react-redux';
import { questionsSelector } from './editSlice';
import QuestionCard from './SimpleQuestions/EditQSimpleCard';

const EditQList: FC = () => {
  const questionsElements = useSelector(questionsSelector);
  return (
    <GridContainer
      justify="center"
      style={{ width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      {Object.keys(questionsElements).map(uuid => (
        <QuestionCard key={uuid} uuid={uuid} />
      ))}
    </GridContainer>
  );
};

export default EditQList;

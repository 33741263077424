import { OptionType } from 'components/Autocomplete/Autocomplete';
import ContentSubmitDialog from 'components/Dialog/ContentSubmitDialog';
import { tokenSelector } from 'features/auth/authSlice';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editStudentReqSelector, editStudentAsync } from '../slices/adminSlice';
import StudentInputs from './StudentInputs';
import Button from 'components/CustomButtons/Button';

interface Props {
  studentId: string;
  initialName: string;
  initialEmail: string;
  initialPassword: string;
  initialPhone: string;
  initialParentPhone?: string;
  initialGrade: string;
  initialGroup: OptionType;
  initialActive: boolean;
}
const EditStudent: React.FC<Props> = ({
  studentId,
  initialEmail,
  initialGrade,
  initialGroup,
  initialName,
  initialPassword,
  initialPhone,
  initialParentPhone,
  initialActive,
}) => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const [name, setName] = React.useState(initialName);
  const [email, setEmail] = React.useState(initialEmail);
  const [password, setPassword] = React.useState(initialPassword);
  const [phone, setPhone] = React.useState(initialPhone);
  const [parentPhone, setParentPhone] = React.useState(initialParentPhone || '');
  const [grade, setGrade] = React.useState(initialGrade);
  const [group, setGroup] = React.useState<OptionType>(initialGroup);
  const [open, setOpen] = React.useState(false);
  const [active, setActive] = React.useState(initialActive);

  const createReq = useSelector(editStudentReqSelector);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setName(initialName);
    setEmail(initialEmail);
    setPassword(initialPassword);
    setPhone(initialPhone);
    setParentPhone(initialParentPhone || '');
    setGrade(initialGrade);
    setActive(initialActive);
    setGroup(initialGroup);
    setOpen(true);
  };
  const handleStudentEditSubmit = () => {
    dispatch(
      editStudentAsync({
        token,
        student: { name, email, password, phone, parentPhone, grade, groupId: group.id, active },
        id: studentId,
      }),
    );
  };
  return (
    <div>
      <Button onClick={handleOpen}>Edit Selected Student</Button>
      <ContentSubmitDialog
        open={open}
        dialogTitle="Edit Student"
        onClose={handleClose}
        onSubmitClick={handleStudentEditSubmit}
        asyncSubmitReq={{
          status: createReq.status,
          errorMessage: createReq.error || 'Cannot edit the student',
          loadingMessage: 'Editing the student',
          successMessage: 'Successfully Edited the student',
        }}
      >
        <StudentInputs
          name={name}
          onNameChange={e => setName(e.target.value)}
          password={password}
          onPasswordChange={e => setPassword(e.target.value)}
          email={email}
          onEmailChange={e => setEmail(e.target.value)}
          phone={phone}
          onPhoneChange={e => setPhone(e.target.value)}
          parentPhone={parentPhone}
          onParentPhoneChange={e => setParentPhone(e.target.value)}
          grade={grade}
          onGradeChange={e => setGrade(e.target.value)}
          group={group}
          onGroupChange={(ev, value: OptionType) => {
            value && setGroup(value);
          }}
          active={active}
          onActiveChange={e => setActive(e.target.checked)}
        />
      </ContentSubmitDialog>
    </div>
  );
};

export default EditStudent;

import { Button, Card, Divider, makeStyles } from '@material-ui/core';
import styles from 'assets/jss/lms/features/questions';
import { questionWithIndexSelector } from '../updateSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import UpdateSimpleAnswer from '../UpdateSimpleAnswers/UpdateSimpleAnswer';
import { RootState } from 'features';
import config from '../../../config';
import { FastForward, FastRewind } from '@material-ui/icons';
import VocabBox from '../UpdateSimpleAnswers/VocabBox';

const useStyles = makeStyles(styles);

const ListeningAnswer: React.FC = props => {
  const activeQuestionIndex = useSelector((state: RootState) => state.updateQuiz.quizState.questionIndex);
  const question = useSelector(questionWithIndexSelector(activeQuestionIndex));
  const isWhyActive = useSelector((state: RootState) => state.updateQuiz.isWhyActive);
  const whyActiveData = useSelector((state: RootState) => state.updateQuiz.activeWhyData);
  const baseURL = config.apiBaseUrl.replace('api', '') + 'media' + question.listeningAudio?.awsUrl.split('data')[1];
  let source = isWhyActive ? baseURL + '#t=00:' + whyActiveData.start : baseURL;
  function strToMins(t: string) {
    const s = t.split(':');
    return Number(s[0]) * 60 + Number(s[1]);
  }

  function minsToStr(t: number) {
    return Math.trunc(t / 60) + ':' + ('00' + (t % 60)).slice(-2);
  }
  React.useEffect(() => {
    const myAudio = document.getElementById(`AudioId-${question._id}`) as HTMLVideoElement;
    function stopAudio() {
      myAudio.pause();
    }
    if (isWhyActive) {
      source = baseURL + '#t=00:' + whyActiveData.start;
      myAudio.load();
      myAudio.play();
      const timeLeft = strToMins(minsToStr(strToMins(whyActiveData.end) - strToMins(whyActiveData.start))) + 1;
      setTimeout(stopAudio, (timeLeft * 1000) as any);
    }
    window.addEventListener(
      'click',
      function () {
        const myCurrAudio = document.getElementById(`AudioId-${question._id}`);
        if (
          myCurrAudio &&
          myCurrAudio !== null &&
          document.activeElement?.tagName !== 'TEXTAREA' &&
          document.activeElement?.tagName !== 'INPUT'
        ) {
          myCurrAudio.focus();
        }
      },
      true,
    );
    if (myAudio && document.activeElement?.tagName !== 'TEXTAREA') myAudio.focus();
  }, [whyActiveData.start]);

  const skipHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const myAudio = document.getElementById(`AudioId-${question._id}`) as HTMLVideoElement;
    if (event.currentTarget.name === 'rewind5') myAudio.currentTime -= 5;
    else if (event.currentTarget.name === 'rewind10') myAudio.currentTime -= 10;
    else if (event.currentTarget.name === 'skip5') myAudio.currentTime += 5;
    else if (event.currentTarget.name === 'skip10') myAudio.currentTime += 10;
  };

  const classes = useStyles();

  // console.log(question.questions);
  return (
    <Card style={{ margin: '1vh 10vw', padding: '30px', minHeight: '85vh' }}>
      {question && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'start' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              position: 'fixed',
              top: '92vh',
              marginTop: '15px',
              left: '5vw',
              width: '90vw',
              zIndex: 1000,

              border: '3px #3b5998 solid',
              borderRadius: '20px',
            }}
          >
            <Button size="medium" onClick={skipHandler} name="rewind10">
              10
              <FastRewind />
            </Button>
            <Button size="medium" onClick={skipHandler} name="rewind5">
              5<FastRewind />
            </Button>
            <audio
              style={{
                width: '100%',
                borderRight: '3px #3b5998  solid',
                borderLeft: '3px #3b5998  solid',

                borderRadius: '20px',
              }}
              id={`AudioId-${question._id}`}
              controls
              controlsList="nodownload"
              // autoPlay
              loop
              preload="metadata"
            >
              <source id="audioSource" src={source} />
            </audio>
            <Button size="medium" onClick={skipHandler} name="skip5">
              <FastForward />5
            </Button>
            <Button size="medium" onClick={skipHandler} name="skip10">
              <FastForward />
              10
            </Button>
          </div>
          <div className={classes.container}>
            {question.questions.map((_: any, index: number) => {
              return (
                <div
                  id={`Q${index + 1}`}
                  key={`question-number-${index}`}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '1rem',
                  }}
                >
                  {index !== 0 && <Divider />}
                  <h4 style={{ fill: 'black', color: 'black', marginTop: 10 }}>
                    <b>Question {index + 1}</b>:
                  </h4>
                  <UpdateSimpleAnswer
                    questionType={question.questions[index].modelType}
                    questionIndex={activeQuestionIndex}
                    subQuestionIndex={index}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Card>
  );
};

export default ListeningAnswer;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { IconButton } from '@material-ui/core';
import React from 'react';

interface Props {}

const TextToSpeech: React.FC<Props> = () => {
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [isListening, setIsListening] = React.useState(false);
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return <div className="mircophone-container">Browser is not Support Speech Recognition.</div>;
  }
  const handleListing = () => {
    setIsListening(true);
    SpeechRecognition.startListening({
      continuous: true,
    });
  };
  const stopHandle = () => {
    setIsListening(false);
    SpeechRecognition.stopListening();
  };
  const handleReset = () => {
    stopHandle();
    resetTranscript();
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
        }}
      >
        <IconButton onClick={handleListing}>
          <FontAwesomeIcon size="2x" icon="record-vinyl" />
        </IconButton>
        <div className="microphone-status">Click to start Listening</div>
        <IconButton onClick={stopHandle}>
          <FontAwesomeIcon size="2x" icon="stop-circle" />
        </IconButton>
      </div>

      <div>
        {!isListening && <div>{transcript}</div>}
        <button onClick={handleReset} className="microphone-reset btn">
          Reset
        </button>
      </div>
    </div>
  );
};

export default TextToSpeech;

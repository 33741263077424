import React from 'react';
import Button from 'components/CustomButtons/Button';
import Button1 from '@mui/material/Button';

import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'features';
import {
  addTextToFillInBlanks,
  addGapToFillInBlanks,
  updateTextFillInBlanksAt,
  updateGapFillInBlanksAt,
  setFillInBlanksMark,
  updateGapFillInBlanksMarkAt,
  removeElementFillInTheBlank,
} from '../editSlice';
import WhyFillInBlanks from './WhyFillInBlanks';
import { TextField } from '@material-ui/core';
// import { editAsync } from '../editSlice';
import { Delete } from '@material-ui/icons';
import DeleteDialog from 'components/Dialog/DeleteDialog';

const TextArea: React.FC<{ uuid: string; index: number; value: string }> = props => {
  const dispatch = useDispatch();
  const [deleteElementDialogOpen, setDeleteElementDialogOpen] = React.useState(false);
  const handleDelete = () => {
    setDeleteElementDialogOpen(true);
  };
  const removeQuestionElement = () => {
    setDeleteElementDialogOpen(false);

    dispatch(removeElementFillInTheBlank({ uuid: props.uuid, index: props.index, isGap: false }));
  };
  return (
    <>
      <CustomInput
        labelText="Question Text"
        id="text"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'text',
          multiline: true,
          rows: 1,
          value: props.value,
          onChange: e =>
            dispatch(
              updateTextFillInBlanksAt({
                uuid: props.uuid,
                value: e.target.value,
                index: props.index,
              }),
            ),
          endAdornment: (
            <div>
              <Button1 startIcon={<Delete />} onClick={handleDelete}></Button1>
            </div>
          ),
        }}
      />
      <DeleteDialog
        dialogText="Are you sure you want to delete this entry?"
        onSubmitClick={removeQuestionElement}
        open={deleteElementDialogOpen}
        onClose={() => setDeleteElementDialogOpen(false)}
      />
    </>
  );
};

const GapArea: React.FC<{ uuid: string; index: number; value: string; mark: number }> = props => {
  const [deleteElementDialogOpen, setDeleteElementDialogOpen] = React.useState(false);
  const handleDelete = () => {
    setDeleteElementDialogOpen(true);
  };
  const removeQuestionElement = () => {
    setDeleteElementDialogOpen(false);

    dispatch(removeElementFillInTheBlank({ uuid: props.uuid, index: props.index, isGap: true }));
  };
  const dispatch = useDispatch();
  return (
    <>
      <CustomInput
        labelText="Gap Area"
        id="text"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'text',
          multiline: true,
          rows: 1,
          value: props.value,
          onChange: e =>
            dispatch(
              updateGapFillInBlanksAt({
                uuid: props.uuid,
                value: e.target.value,
                index: props.index,
              }),
            ),
          placeholder: 'Write down the correct answer for this gap area..',
          endAdornment: (
            <div>
              <TextField
                value={props.mark}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                onChange={e =>
                  dispatch(
                    updateGapFillInBlanksMarkAt({
                      uuid: props.uuid,
                      value: parseInt(e.target.value, 10),
                      index: props.index,
                    }),
                  )
                }
                variant="outlined"
              />
              <WhyFillInBlanks
                uuid={props.uuid}
                gapIndex={props.index}
                id={`gapWhyPart ${props.uuid + props.index + 1}`}
              />
              <Button1 startIcon={<Delete />} onClick={handleDelete}></Button1>
              <DeleteDialog
                dialogText="Are you sure you want to delete this entry?"
                onSubmitClick={removeQuestionElement}
                open={deleteElementDialogOpen}
                onClose={() => setDeleteElementDialogOpen(false)}
              />
            </div>
          ),
        }}
      />
    </>
  );
};

const FillInTheGapsQuestion: React.FC<{ uuid: string }> = (props: { uuid: string }) => {
  const gaps = useSelector(
    (state: RootState) =>
      (state.editQuestion.editingQuestion.questions[props.uuid] as Types.API.Question.IFillInBlanksModel).correction,
  );
  const texts = useSelector(
    (state: RootState) =>
      (state.editQuestion.editingQuestion.questions[props.uuid] as Types.API.Question.IFillInBlanksModel).texts,
  );
  const lastInserted = useSelector(
    (state: RootState) =>
      state.editQuestion.editingQuestion.questions[props.uuid] as Types.API.Question.IFillInBlanksModel,
  ).lastInserted;
  // const firstInserted = useSelector(
  //   (state: RootState) =>
  //     state.editQuestion.editingQuestion.questions[props.uuid] as Types.API.Question.IFillInBlanksModel,
  // ).firstInserted;
  const mark = useSelector(
    (state: RootState) =>
      state.editQuestion.editingQuestion.questions[props.uuid] as Types.API.Question.IFillInBlanksModel,
  ).mark;
  const dispatch = useDispatch();

  // var gapsLength = gaps ? gaps.answer.length : 0;
  var textsLength = texts ? texts.length : 0;
  // var totalLength = gapsLength + textsLength;
  const questionsElements = [];

  for (let i = 0, j = 0; i < textsLength; i++) {
    if (!texts[i].includes('___'))
      questionsElements.push(<TextArea uuid={props.uuid} key={`text-${i + 1}`} value={texts[i]} index={i} />);
    else {
      if (texts[i].includes('___GAP__')) {
        questionsElements.push(
          <GapArea mark={gaps.marks[j]} uuid={props.uuid} key={`gap-${j + 1}`} index={j} value={gaps.answer[j]} />,
        );
        j++;
      }
    }
  }

  return (
    <div>
      <CardBody>
        <CardBody>
          {questionsElements.map(e => e)}
          <Button
            disabled={lastInserted === 'text'}
            color="white"
            onClick={_ => dispatch(addTextToFillInBlanks({ uuid: props.uuid }))}
            size="sm"
          >
            Add Text
          </Button>
          <Button
            disabled={lastInserted === 'gap'}
            color="white"
            onClick={_ => dispatch(addGapToFillInBlanks({ uuid: props.uuid }))}
            size="sm"
          >
            Add Gap
          </Button>
        </CardBody>
        <div style={{ display: 'flex', float: 'right' }}>
          <CustomInput
            id="mark"
            labelText="Mark"
            inputProps={{
              type: 'number',
              value: mark,
              onChange: (c: any) => dispatch(setFillInBlanksMark({ value: c.target.value, uuid: props.uuid })),
            }}
          />
        </div>
        <div style={{ float: 'right', width: 'auto', zIndex: 1000, position: 'relative' }}></div>
      </CardBody>
    </div>
  );
};

export default FillInTheGapsQuestion;

import React from 'react';
import { ColDef, DataGrid } from '@material-ui/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import { availableLevelsSelector, createLevelAsync, getLevelsAsync, setCreateLevelName } from '../slices/adminSlice';
import { RootState } from 'features';
import styles from 'assets/jss/lms/features/questions';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';

const columns: ColDef[] = [
  { field: 'id', headerName: 'id', width: 300, resizable: true, hide: true },
  { field: 'name', headerName: 'Level Name', width: 300 },
];
const useStyles = makeStyles(styles);

interface Props {}
const LevelsManagement: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector(tokenSelector);
  const getLevelsReq = useSelector((state: RootState) => state.admin.getLevelsReq);
  const levels = useSelector(availableLevelsSelector);
  const createLevelReq = useSelector((state: RootState) => state.admin.createLevel.req);
  const createLevelName = useSelector((state: RootState) => state.admin.createLevel.name);

  useReqListener({
    status: getLevelsReq.status,
    successMessage: 'Successfully fetched all the levels from the backend',
    loadingMessage: 'Fetching levels from the backend',
    errorMessage: getLevelsReq.error,
  });
  useReqListener({
    status: createLevelReq.status,
    successMessage: 'Successfully ADDED a level to the DB',
    loadingMessage: 'Adding level to the DB',
    errorMessage: createLevelReq.error,
  });
  React.useEffect(() => {
    // if (token && dispatch) {
    token && dispatch(getLevelsAsync({ token }));
    // }
  }, [token, dispatch]);
  const handleLevelSubmit = () => {
    dispatch(createLevelAsync({ level: { name: createLevelName }, token }));
  };
  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <div className={classNames(classes.container, classes.categoryAutocomplete)}>
        <CustomInput
          id="categories-submit-button"
          labelText="New Level Name"
          formControlProps={{ fullWidth: true }}
          inputProps={{
            className: classes.margin20right,
            value: createLevelName,
            onChange: ev => dispatch(setCreateLevelName({ name: ev.target.value })),
          }}
        />
        <Button color="primary" onClick={handleLevelSubmit}>
          Add Level
        </Button>
      </div>
      <DataGrid
        pagination
        rows={levels}
        columns={columns}
        checkboxSelection
        loading={getLevelsReq.status === 'loading'}
      />
    </div>
  );
};

export default LevelsManagement;

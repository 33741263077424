// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Snack from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
// core components

import styles from 'assets/jss/lms/components/snackbarContentStyle';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { Snackbar, SvgIconTypeMap } from '@material-ui/core';
import CustomCircularProgress from 'components/Progress/CustomCircularProgress';

const useStyles = makeStyles(styles);

interface Props {
  message: JSX.Element;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  open: boolean;
  color: 'info' | 'success' | 'warning' | 'danger' | 'primary';
  loading: boolean;
  onClose(): void;
}

export default function SnackbarContent(props: Props) {
  const { onClose, color, message, open, loading } = props;
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      open={open}
      onClose={onClose}
      autoHideDuration={10000}
      classes={{ root: classes.barRoot }}
    >
      <Snack
        message={
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {loading ? <CustomCircularProgress /> : <props.icon className={classes.icon} />}
            {message}
            <IconButton className={classes.iconButton} key="close" aria-label="Close" color="inherit" onClick={onClose}>
              <Close className={classes.close} />
            </IconButton>
          </div>
        }
        classes={{
          root: classes.root + ' ' + classes[color],
          message: classes.message + ' ' + classes.container,
        }}
      />
    </Snackbar>
  );
}

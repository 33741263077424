import { Divider, IconButton } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import useMediaRecorder from '@wmik/use-media-recorder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomButton from 'components/CustomButtons/Button';
import { uploadVoice } from 'features/auth/client';
import { tokenSelector } from 'features/auth/authSlice';
import { useSelector } from 'react-redux';
interface Props {
  handleSave: (blobs: Blob[]) => void;
  handleMessageSubmit: (msgContent: string, isVoice: boolean) => void;
}
const AudioRecorder: React.FC<Props> = ({ handleSave, handleMessageSubmit }) => {
  const [showPreview, setShowPreview] = React.useState(false);
  const [bufferBlobs, setBufferBlobs] = React.useState<Blob[]>([]);
  const token = useSelector(tokenSelector);

  const { status, mediaBlob, stopRecording, startRecording, liveStream } = useMediaRecorder({
    blobOptions: { type: 'audio/mp3' },
    mediaStreamConstraints: { audio: true },
  });
  console.log(liveStream);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  // Add an effect to draw the sound waves on the canvas

  const handleSend = async () => {
    setBufferBlobs(prevBlobs => {
      const newBlobs = prevBlobs;
      if (mediaBlob) return [...newBlobs, mediaBlob];
      return newBlobs;
    });
    try {
      if (mediaBlob) {
        handleSave([mediaBlob]);
        const res = await uploadVoice({ file: mediaBlob, token });
        handleMessageSubmit(res.awsUrl, true);
        console.log('🚀 Voice sent successfully');
      }
    } catch (error) {
      console.error('Error sending voice:', error);
    }

    setShowPreview(false);
  };

  const handleCancel = () => {
    setShowPreview(false);
  };

  const canStartRecording = status === 'idle' || !showPreview;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        border: '5px',
      }}
    >
      <Divider />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
          position: 'relative',
        }}
      >
        {canStartRecording && (
          <IconButton
            onClick={() => {
              startRecording(0);
              setShowPreview(true);
            }}
          >
            <FontAwesomeIcon size="2x" icon="record-vinyl" />
          </IconButton>
        )}
        {canStartRecording && <h5 style={{ fontWeight: 400, color: 'gray' }}>Start Recording</h5>}
        {status === 'recording' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <IconButton onClick={stopRecording}>
              <FontAwesomeIcon size="2x" icon="stop-circle" />
            </IconButton>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '10px', // Adjust the size of the waves
                  height: '20px', // Adjust the size of the waves
                  backgroundColor: 'red', // Adjust the color of the waves
                  animation: 'soundWaves 0.5s infinite alternate', // Animation name and properties
                }}
              />
              <div
                style={{
                  width: '10px', // Adjust the size of the waves
                  height: '15px', // Adjust the size of the waves
                  backgroundColor: 'red', // Adjust the color of the waves
                  animation: 'soundWaves 0.5s infinite alternate', // Animation name and properties
                  animationDelay: '0.2s', // Delay the start of the animation
                }}
              />
              <div
                style={{
                  width: '10px', // Adjust the size of the waves
                  height: '25px', // Adjust the size of the waves
                  backgroundColor: 'red', // Adjust the color of the waves
                  animation: 'soundWaves 0.5s infinite alternate', // Animation name and properties
                  animationDelay: '0.4s', // Delay the start of the animation
                }}
              />
            </div>
            <h5 style={{ fontWeight: 400, color: 'red' }}>Stop Recording</h5>
          </div>
        )}
        {status === 'stopped' && showPreview && mediaBlob && (
          <audio style={{ padding: '5px', borderRadius: '15px' }} src={URL.createObjectURL(mediaBlob)} controls />
        )}
        {status === 'stopped' && showPreview && (
          <CustomButton disabled={mediaBlob === null} color="success" onClick={handleSend}>
            send
          </CustomButton>
        )}
        {status === 'stopped' && showPreview === true && (
          <CustomButton color="danger" onClick={handleCancel}>
            Cancel
          </CustomButton>
        )}
      </div>
      <Divider />
    </div>
  );
};

export default AudioRecorder;

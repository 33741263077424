import React from 'react';
import { landingRoutes } from 'routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useViewAuth } from 'hooks';

interface Props {}
export const LandingRoutesPrefix = '/landing';

const LandingLayout: React.FC<Props> = () => {
  // We don't need to useViewAuth() here, since for now everyone can still navigate through landing pages
  // However it should not affect the logic.
  useViewAuth();
  return (
    <>
      <Switch>
        {landingRoutes.map((r, k) => (
          <Route path={LandingRoutesPrefix + r.path} component={r.component} key={k} />
        ))}
        <Redirect exact from={LandingRoutesPrefix} to="/landing/home" />
      </Switch>
    </>
  );
};

export default LandingLayout;

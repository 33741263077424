import { makeStyles } from '@material-ui/core';
import styles from 'assets/jss/lms/features/questions';
import React, { useEffect, useRef } from 'react';
import CustomTabs from 'components/CustomTabs/CustomTabs';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { useSelector } from 'react-redux';
import { RootState } from 'features';
const useStyles = makeStyles(styles);

interface Props {
  articles: string[];
}
const ReadingQuestionsArticles: React.FC<Props> = props => {
  const classes = useStyles();
  const readingArticles = props.articles;
  const [curTab, setCurTab] = React.useState(0);
  const isWhyActive = useSelector((state: RootState) => state.updateQuiz.isWhyActive);
  const whyActiveData = useSelector((state: RootState) => state.updateQuiz.activeWhyData);

  useEffect(() => {
    if (
      isWhyActive &&
      readingArticles.length > 0 &&
      // +whyActiveData.pIndex === curTab &&
      +whyActiveData.start !== -1 &&
      +whyActiveData.end !== -1
    ) {
      setCurTab(+whyActiveData.pIndex);
    }
  }, [isWhyActive, +whyActiveData.pIndex, +whyActiveData.start, +whyActiveData.end]);
  // if (isWhyActive && whyActiveData.pIndex != -1) setCurTab(whyActiveData.pIndex);
  const tabs = readingArticles.length > 0 ? readingArticles.map((s, i) => ({ tabName: `Article #${i + 1}` })) : [];
  let beforeHighlight = '';
  let highlight = '';
  let afterHighlight = '';
  const s = readingArticles.find((value: string, index: number) => index === curTab) || '';

  if (
    isWhyActive &&
    readingArticles.length > 0 &&
    +whyActiveData.pIndex === curTab &&
    +whyActiveData.start !== -1 &&
    +whyActiveData.end !== -1
  ) {
    const start = +whyActiveData.start;
    const end = +whyActiveData.end;
    beforeHighlight = s.substr(0, start);
    highlight = s.substr(start, end - start + 1);
    afterHighlight = s.substr(end);
  } else {
    beforeHighlight = s;
  }
  const handleTabIndexChange = (event: React.ChangeEvent<{}>, value: number) => {
    setCurTab(value);
  };
  return (
    <div className={classes.container} style={{ overflowX: 'hidden', overflowY: 'auto' }}>
      <CustomTabs curTabIndex={curTab} handleTabIndexChange={handleTabIndexChange} headerColor="primary" tabs={tabs}>
        <CardBody style={{ minHeight: '100%', height: '100%' }}>
          {readingArticles && (
            <div>
              <ReadingArticle beforeHighlight={beforeHighlight} afterHighlight={afterHighlight} highlight={highlight} />
            </div>
          )}
        </CardBody>
      </CustomTabs>
    </div>
  );
};

const ReadingArticle: React.FC<{ beforeHighlight: string; highlight: string; afterHighlight: string }> = props => {
  const highlighedRef = useRef<any>(null);
  const executeScroll = () => highlighedRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

  useEffect(() => {
    executeScroll();
  }, [props.highlight]);

  return (
    <Card>
      <CardBody>
        {
          <p style={{ fontSize: 20, lineHeight: 2 }}>
            <b style={{ whiteSpace: 'pre-line' }}>{props.beforeHighlight}</b>
            <b ref={highlighedRef} style={{ backgroundColor: 'yellow', whiteSpace: 'pre-line' }}>
              {props.highlight}
            </b>
            <b style={{ whiteSpace: 'pre-line' }}>{props.afterHighlight}</b>
          </p>
        }
      </CardBody>
    </Card>
  );
};
export default ReadingQuestionsArticles;

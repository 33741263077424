import React, { useEffect, useState } from 'react';

import {
  addCommentAsync,
  addReplyAsync,
  editCommentAsync,
  getIsVideoPausedSelector,
  getVideoCurrentTimeSelector,
  threadReqSelector,
} from 'features/admin/slices/adminSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Typography,
} from '@mui/material';
import RecordIcon from '@material-ui/icons/Mic';
import { useParams } from 'react-router-dom';
import CustomCircularProgress from 'components/Progress/CustomCircularProgress';
import AudioRecorder from 'features/quiz/UpdateSimpleAnswers/AudioRecorder';
import { uploadVoice } from 'features/auth/client';
// import AudioRecorder from 'features/quiz/UpdateSimpleAnswers/ChatAudioRecorder';

interface Props {
  type: 'comment' | 'reply';
  threadId?: string;
  edit?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  comment?: Types.API.VdoCipher.IThreadInfo;
}

const CommentInput: React.FC<Props> = ({ type, threadId, edit, setEdit, comment }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const [openRecord, setOpenRecord] = useState(false);
  const [isTimeInserted, setIsTimeInserted] = useState(false);
  const [isTimeEdited, setIsTimeEdited] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); //used when submit to show circle loading
  const isPaused = useSelector(getIsVideoPausedSelector);
  const videoCurrentTime = useSelector(getVideoCurrentTimeSelector);
  const threadReq = useSelector(threadReqSelector);

  const user = localStorage.getItem('studentID');
  const token = localStorage.getItem('auth-token');

  const { id } = useParams<{ id: string }>();

  const handleSubmitAdd = async (e: any, voiceId?: string) => {
    e && e.preventDefault();
    if (!user || !token) return;

    setIsSubmitted(true);

    const data: Types.API.VdoCipher.IThreadInputs = {
      userId: user,
      text: voiceId ? voiceId : text,
      ...(isTimeInserted && isPaused && { videoTime: videoCurrentTimeAsHHMMSS() }),
      ...(voiceId && { isVoice: true, voice: voiceId }),
    };

    if (type === 'comment')
      dispatch(
        addCommentAsync({
          data,
          videoId: id,
          token,
        }),
      );

    if (type === 'reply' && threadId)
      dispatch(
        addReplyAsync({
          data,
          threadId,
          token,
        }),
      );
  };

  const handleSubmitEdit = async (e: any) => {
    e.preventDefault();
    if (!user || !token) return;

    setIsSubmitted(true);

    const timeOnButton = setTextOfTimeButton();
    const videoTime = timeOnButton.charAt(0) === '@' ? timeOnButton.substring(1) : '';

    const data = {
      text,
      ...(isPaused && { videoTime }),
    };

    if (comment)
      dispatch(
        editCommentAsync({
          data,
          threadId: comment._id,
          token,
        }),
      );
  };

  const handleMediaBlobsSubmit = async (blobs: Blob[]) => {
    try {
      if (token && blobs.length && blobs.length !== 0) {
        const res = await uploadVoice({ file: blobs[0], token });
        handleSubmitAdd(null, res._id);
        console.log('🚀 Voice sent successfully');
      }
    } catch (error) {
      console.error('Error sending voice:', error);
    }
  };

  useEffect(() => {
    if (threadReq.req.status === 'succeeded') {
      setText('');
      setIsTimeInserted(false);
      setIsSubmitted(false);
      setEdit !== undefined && setEdit(false);
    }
  }, [threadReq.req.status]);

  useEffect(() => {
    if (edit && comment) {
      comment.text && setText(comment.text);
      comment.videoTime && setIsTimeInserted(Boolean(comment.videoTime));
    }
  }, [edit]);

  const toggleTime = () => {
    const setState00 = () => {
      setIsTimeInserted(false);
      setIsTimeEdited(false);
    };
    const setState01 = () => {
      setIsTimeInserted(false);
      setIsTimeEdited(true);
    };
    const setState10 = () => {
      setIsTimeInserted(true);
      setIsTimeEdited(false);
    };

    if (edit && comment) {
      //if prev. comment has no time
      if (!Boolean(comment.videoTime)) setIsTimeEdited(show => !show);
      else {
        if (isTimeInserted && !isTimeEdited) setState00();
        if (!isTimeInserted && !isTimeEdited) setState01();
        if (!isTimeInserted && isTimeEdited) setState10();
      }
    } else setIsTimeInserted(show => !show);
  };

  // const toggleTime2 = () => {
  //   if (edit) setIsTimeEdited(show => !show);
  //   else setIsTimeInserted(show => !show);
  // };

  const setToolTipTitle = () => {
    if (!isPaused) return 'pause the video to insert time';
    return `click to ${isTimeInserted || isTimeEdited ? 'remove' : 'insert'} time`;
  };

  const videoCurrentTimeAsHHMMSS = () => {
    return new Date(videoCurrentTime * 1000).toISOString().slice(11, 19);
  };

  const setTextOfTimeButton = () => {
    if (edit) {
      if (isTimeInserted && !isTimeEdited) return `@${comment?.videoTime}`;
      if (isTimeEdited && isPaused) return `@${videoCurrentTimeAsHHMMSS()}`;
      return 'Insert Time';
    } else {
      if (isTimeInserted && isPaused) return `@${videoCurrentTimeAsHHMMSS()}`;
      return 'Insert Time';
    }
  };

  return (
    <>
      <form onSubmit={edit ? handleSubmitEdit : handleSubmitAdd}>
        <FormControl disabled={threadReq.req.status === 'loading'} sx={{ m: 1 }} fullWidth variant="outlined">
          <InputLabel htmlFor="comment-input">{`${edit ? 'Edit' : 'Add'} ${type}`}</InputLabel>
          <OutlinedInput
            id="comment-input"
            type="text"
            autoComplete="off"
            label={`${edit ? 'Edit' : 'Add'} ${type}`}
            value={text}
            onChange={e => {
              setText(e.target.value);
            }}
            endAdornment={
              <InputAdornment sx={{ cursor: 'pointer' }} position="end">
                <Tooltip arrow title={setToolTipTitle()}>
                  <span>
                    <Button
                      size="small"
                      variant="contained"
                      disabled={!isPaused || threadReq.req.status === 'loading'}
                      onClick={toggleTime}
                    >
                      <Typography fontWeight="bold" variant="subtitle2">
                        {setTextOfTimeButton()}
                      </Typography>
                    </Button>
                  </span>
                </Tooltip>
                <IconButton size="small" onClick={() => setOpenRecord(!openRecord)} aria-label="record">
                  <RecordIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </form>

      {openRecord && (
        <AudioRecorder openRecord={openRecord} setOpenRecord={setOpenRecord} handleSave={handleMediaBlobsSubmit} />
      )}

      {threadReq.req.status === 'loading' && isSubmitted && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '10px 0px',
          }}
        >
          <CustomCircularProgress size={30} />
        </div>
      )}
    </>
  );
};

export default CommentInput;

import React from 'react';
import { ColDef, DataGrid } from '@material-ui/data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector, userRoleSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import Button from 'components/CustomButtons/Button';
import {
  availableCategoriesSelector,
  createCategoryAsync,
  getCategoriesAsync,
  setCreateCategoryName,
  editCategoryAsync,
  deleteCategoryAsync,
} from '../slices/adminSlice';
import { RootState } from 'features';
import CustomInput from 'components/CustomInput/CustomInput';
import styles from 'assets/jss/lms/features/questions';
import { Divider, makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const columns: ColDef[] = [
  { field: 'id', headerName: 'id', width: 300, resizable: true, hide: true },
  { field: 'name', headerName: 'Category Name', width: 300 },
];

const useStyles = makeStyles(styles);

interface Props {}
const CategoriesManagement: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = useSelector(tokenSelector);
  const userRole = useSelector(userRoleSelector);
  const getCategoriesReq = useSelector((state: RootState) => state.admin.getCategoriesReq);
  const editCategoryReq = useSelector((state: RootState) => state.admin.editCategoryReq);
  const deleteCategoryReq = useSelector((state: RootState) => state.admin.deleteCategoryReq);

  const categories = useSelector(availableCategoriesSelector);
  const createCategoryReq = useSelector((state: RootState) => state.admin.createCategory.req);
  const createCategoryName = useSelector((state: RootState) => state.admin.createCategory.name);
  const [selection, setSelection] = React.useState('');
  const [editCategroyName, setEditCategoryName] = React.useState('');
  const selectedCategories = categories.filter(c => c.id === selection);
  useReqListener({
    status: getCategoriesReq.status,
    successMessage: 'Successfully fetched all the categories from the backend',
    loadingMessage: 'Fetching the categories the DB',
    errorMessage: getCategoriesReq.error,
  });
  useReqListener({
    status: createCategoryReq.status,
    successMessage: 'Successfully ADDED a category to the DB',
    loadingMessage: 'Adding category to the DB',
    errorMessage: createCategoryReq.error,
  });
  useReqListener({
    status: editCategoryReq.status,
    successMessage: 'Successfully EDITED the category',
    loadingMessage: 'Editing the category',
    errorMessage: editCategoryReq.error,
  });
  useReqListener({
    status: deleteCategoryReq.status,
    successMessage: 'Successfully Deleted a category from the DB',
    loadingMessage: 'Deleting category to the DB',
    errorMessage: deleteCategoryReq.error,
  });

  React.useEffect(() => {
    token && getCategoriesReq.status === 'idle' && dispatch(getCategoriesAsync({ token }));
  }, [token, getCategoriesReq.status, dispatch]);
  const handleCategorySubmit = () => {
    dispatch(createCategoryAsync({ category: { name: createCategoryName }, token }));
  };
  const handleEditCategorySubmit = () => {
    setSelection('');
    dispatch(editCategoryAsync({ token, id: selection, name: editCategroyName }));
  };
  const handleDeleteCategory = () => {
    setSelection('');
    dispatch(deleteCategoryAsync({ token, id: selection }));
  };
  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <div className={classNames(classes.container, classes.categoryAutocomplete)}>
        <CustomInput
          id="categories-submit-button"
          labelText="New Category Name"
          formControlProps={{ fullWidth: true }}
          inputProps={{
            className: classes.margin20right,
            value: createCategoryName,
            onChange: ev => dispatch(setCreateCategoryName({ name: ev.target.value })),
          }}
        />

        <Button color="primary" onClick={handleCategorySubmit}>
          Add Category
        </Button>
      </div>
      {selection !== '' && (
        <>
          {' '}
          <h4 style={{ color: 'black', paddingTop: '50px' }}>Selected Category Id: {selection}</h4>
          {selectedCategories.length !== 0 && (
            <h4 style={{ color: 'black', paddingTop: '10px' }}>Selected Category Name: {selectedCategories[0].name}</h4>
          )}
          <h4 style={{ color: 'blue', paddingTop: '50px' }}>Edit Category</h4>
          <CustomInput
            id="categories-submit-button"
            labelText="Edit Category Name"
            formControlProps={{ fullWidth: true }}
            inputProps={{
              className: classes.margin20right,
              value: editCategroyName,
              onChange: ev => setEditCategoryName(ev.target.value),
            }}
          />
          <Button color="primary" onClick={handleEditCategorySubmit}>
            Modify
          </Button>
          <Divider />
          {userRole === 'admin' && (
            <Button color="danger" onClick={handleDeleteCategory}>
              Delete Selected Category
            </Button>
          )}
        </>
      )}

      <DataGrid
        pagination
        rows={categories}
        columns={columns}
        onSelectionChange={p => {
          setEditCategoryName('');
          setSelection(p.rowIds[0].toString());
        }}
        showToolbar
        loading={getCategoriesReq.status === 'loading'}
      />
    </div>
  );
};

export default CategoriesManagement;

import API from 'api';

export const updateAnswers = async (input: {
  newAnswers: Types.API.Answer.IAnswerStudentInput;
  takenQuizId: string;
  token: string;
}): Promise<boolean> => {
  await API.put('/students/answerQuiz/' + input.takenQuizId, input.newAnswers, {
    headers: { Authorization: 'Bearer ' + input.token },
  }).then(response => {
    return response.data.quiz;
  });
  return false;
};

export const submitHomeworkAnswers = async (input: {
  newAnswers: Types.API.Answer.IAnswerStudentInput;
  takenQuizId: string;
  token: string;
  answersUpdatedCallback: Function;
}): Promise<boolean> => {
  await API.put('/students/submitHomework/' + input.takenQuizId, input.newAnswers, {
    headers: { Authorization: 'Bearer ' + input.token },
  }).then(response => {
    input.answersUpdatedCallback();
    return response.data.quiz;
  });
  return false;
};

export const commentQuizTaken = async (input: {
  newComments: Types.API.Answer.IAnswerCommentsInput;
  takenQuizId: string;
  token: string;
}): Promise<boolean> => {
  const response = await API.put('/students/commentQuizTaken/' + input.takenQuizId, input.newComments, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data;
};

export const updateCorrection = async (input: {
  newCorrection: Types.API.Answer.IAnswerCorrectingInput;
  takenQuizId: string;
  token: string;
  correctedBy: string;
}): Promise<boolean> => {
  const body = { ...input.newCorrection,correctedBy:input.correctedBy };
  const response = await API.put('/admin/correctQuiz/' + input.takenQuizId, body,{
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.quiz;
};

export const publishGradesForQuizzes = async (input: { quizzes: string[]; token: string }): Promise<boolean> => {
  const response = await API.put(
    '/admin/publishGrades',
    { quizzesIds: input.quizzes },
    {
      headers: { Authorization: 'Bearer ' + input.token },
    },
  );
  return response.data.quiz;
};

export const getQuizTaken = async (input: {
  token: string;
  quizTakenId: string;
}): Promise<{ quiz: Types.API.QuizTaken.IQuizTakenInfoWithAnswers; remainingTime: number }> => {
  const response = await API.get('/students/getQuizTaken', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { quizTakenId: input.quizTakenId },
  });
  localStorage.setItem('quizID', response.data.quiz.id);
  return {
    quiz: response.data.quiz as Types.API.QuizTaken.IQuizTakenInfoWithAnswers,
    remainingTime: response.data.remainingTime,
  };
};

export const getHomeworkTaken = async (input: {
  token: string;
  quizTakenId: string;
}): Promise<{ homework: Types.API.QuizTaken.IQuizTakenInfoWithAnswers; remainingTime: number }> => {
  const response = await API.get('/students/getHomeWorkTaken', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { quizTakenId: input.quizTakenId },
  });
  return {
    homework: response.data.homework as Types.API.QuizTaken.IQuizTakenInfoWithAnswers,
    remainingTime: response.data.remainingTime,
  };
};

export const getQuizTakenAdmin = async (input: {
  token: string;
  quizTakenId: string;
}): Promise<Types.API.QuizTaken.IQuizTakenInfoWithAnswers> => {
  const response = await API.get('/admin/getQuizTaken/' + input.quizTakenId, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data.quiz as Types.API.QuizTaken.IQuizTakenInfoWithAnswers;
};

export const deleteFile = async (input: {
  token: string;
  fileId: string;
  fileName: string;
  questionIndex: number;
  subQuestionIndex: number;
  isAdmin: boolean;
}): Promise<boolean> => {
  const response = await API.post(
    '/admin/deleteFile',
    { fileName: input.fileName, fileId: input.fileId },
    {
      headers: { Authorization: 'Bearer ' + input.token },
    },
  );
  return response.data;
};

export const uploadAnswerMultipleFiles = async (input: {
  files: File[];
  token: string;
  questionIndex: number; // Needed for the async listeners
  subQuestionIndex: number; // Needed for the async listeners
  answerId: string; // Needed for the async listeners
}): Promise<Types.API.File.IFileModel[]> => {
  const formData = new FormData();

  for (let i = 0; i < input.files.length; i++) {
    formData.append('files', input.files[i]);
  }
  const response = await API.post('/admin/uploadFiles', formData, {
    headers: {
      Authorization: 'Bearer ' + input.token,
    },
  });
  return response.data.files;
};

export const uploadAnswerMultipleAudio = async (input: {
  blobs: Blob[];
  token: string;
  questionIndex: number; // Needed for the async listeners
  subQuestionIndex: number; // Needed for the async listeners
  answerId: string; // Needed for the async listeners
}): Promise<Types.API.File.IFileModel[]> => {
  const formData = new FormData();

  for (let i = 0; i < input.blobs.length; i++) {
    formData.append('files', new File([input.blobs[i]], `Recoding${i + 1}.mp3`, {}));
  }
  const response = await API.post('/admin/uploadFiles', formData, {
    headers: {
      Authorization: 'Bearer ' + input.token,
    },
  });
  return response.data.files.map((file: any) => file.file);
};

export const reassignQuizzes = async (input: {
  token: string;
  studentsQuizzes: { quizId: string | undefined; studentId: string }[];
}): Promise<boolean> => {
  const response = await API.post(
    '/admin/reassignQuizTaken/',
    { studentsQuizzes: input.studentsQuizzes },
    {
      headers: { Authorization: 'Bearer ' + input.token },
    },
  );
  return response.data.reassigned as boolean;
};

export const downloadFile = async (input: {
  token: string;
  fileId: string | Types.API.File.IFileModel;
}): Promise<Types.API.File.IFileModel> => {
  const response = await API.get(`/admin/files/${input.fileId}`, {
    headers: { Authorization: 'Bearer ' + input.token },
  });
  return response.data as Types.API.File.IFileModel;
};

import React, { useState } from 'react';
import CardBody from 'components/Card/CardBody';
import {
  fillInGapsSubAnswerSelector,
  // setTriggerSaveQuizAnswers,
  subQuestionWithIndexSelector,
  updateAnswerForFillInGapsQuestion,
  updateCorrectionForFillInGapsQuestion,
  updateStudentComment,
} from 'features/quiz/updateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import { getMessagesAsync, tokenSelector, userSelector } from 'features/auth/authSlice';
import ShowComments from './ShowComments';
import ShowChat from './ShowChat';
import ShowWhy from './ShowWhy';
import { RootState } from 'features';
import { TokenAnnotator } from 'react-text-annotate';
import { NewLine } from 'components/NewLine/NewLine';
import { isOldFIGPattern } from 'utils/detectOldFIGPattern';
import CustomButton from 'components/CustomButtons/Button';

const hashCode = function (s: string) {
  return s.split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

const UpdateFillInGaps: React.FC<{ questionIndex: number; subQuestionIndex: number }> = props => {
  const dispatch = useDispatch();
  const { questionIndex, subQuestionIndex } = props;
  const question = useSelector(
    subQuestionWithIndexSelector(questionIndex, subQuestionIndex),
  ) as Types.API.Question.IFillInBlanksModel;
  const studentAnswer = useSelector(fillInGapsSubAnswerSelector(questionIndex, subQuestionIndex)).answer;
  // const answersWhy = useSelector(fillInGapsSubAnswerSelector(questionIndex, subQuestionIndex)).why;
  const answersWhy = question.correction.why;
  const studentMarks = useSelector(fillInGapsSubAnswerSelector(questionIndex, subQuestionIndex)).marks;
  const studentComments = useSelector(fillInGapsSubAnswerSelector(questionIndex, subQuestionIndex)).comments;
  const instructorComments = useSelector(
    fillInGapsSubAnswerSelector(questionIndex, subQuestionIndex),
  ).instructorComments;
  const quizTakenStatus = useSelector((state: RootState) => state.updateQuiz.quizTaken.status);

  const userRole = useSelector(userSelector).role;
  // const triggerSave = ()=>{
  //   dispatch(setTriggerSaveQuizAnswers({ save: true }));

  // }

  const onCommentInstructorChange = (index: number) => (c: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    dispatch(
      updateCorrectionForFillInGapsQuestion({
        instructorComment: c.target.value,
        questionNumber: questionIndex,
        subQuestionNumber: subQuestionIndex,
        gapNumber: index,
      }),
    );
  const onCommentStudentChange = (index: number) => (c: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    dispatch(
      updateStudentComment({
        studentComment: c.target.value,
        questionNumber: questionIndex,
        subQuestionNumber: subQuestionIndex,
        index: index,
      }),
    );
  const onMarkChange = (index: number) => (c: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    dispatch(
      updateCorrectionForFillInGapsQuestion({
        mark: parseInt(c.target.value, 10),
        questionNumber: questionIndex,
        subQuestionNumber: subQuestionIndex,
        gapNumber: index,
      }),
    );
  const Gap = (i: number) => {
    const [textFieldSize, setTextFieldSize] = React.useState(10);

    const [openChat, setOpenChat] = useState(false);
    const token = useSelector(tokenSelector);
    const handleShowChatClick = () => {
      const quizID = localStorage.getItem('quizID') || ' ';
      const studentID = localStorage.getItem('studentID') || ' ';

      if (studentID && quizID && question._id) {
        dispatch(
          getMessagesAsync({
            token,
            questionID: question._id || '',
            studentID: studentID || '',
            quizID: quizID || '',
          }),
        );
      }
      setOpenChat(true);
    };
    const talkToTheTeacherButton = () => {
      return (
        <CustomButton size="sm" color="twitter" onClick={() => handleShowChatClick()}>
          <strong>chat with the teacher</strong>
        </CustomButton>
      );
    };
    return (
      <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', alignItems: 'flex-end' }}>
        <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
          <TextField
            label={
              userRole === 'admin' ||
              userRole === 'assistant' ||
              ((userRole === 'student' || userRole === 'parent') &&
                (quizTakenStatus === 'auto-corrected' || quizTakenStatus === 'ended'))
                ? question.correction.marks[i] > 0 && studentMarks[i] < question.correction.marks[i]
                  ? question.correction.answer[i]
                  : undefined
                : undefined
            }
            // style={{ width: `${textFieldSize}rem`, minWidth: '1rem' }}
            disabled={userRole !== 'student' || quizTakenStatus !== 'started'}
            rows={1}
            value={studentAnswer[i]}
            onChange={c => {
              dispatch(
                updateAnswerForFillInGapsQuestion({
                  answer: c.target.value,
                  questionNumber: questionIndex,
                  subQuestionNumber: subQuestionIndex,
                  gapNumber: i,
                }),
              );
              if (c.target.value.length > 0 && c.target.value.length <= studentAnswer[i].length && textFieldSize > 10) {
                setTextFieldSize(textFieldSize - 0.5);
              } else if (c.target.value.length >= textFieldSize) {
                setTextFieldSize(textFieldSize + 0.5);
              } else setTextFieldSize(10);
              // triggerSave()
            }}
            variant="standard"
            InputLabelProps={{
              style: { color: 'red', width: '150%' },
            }}
            InputProps={{
              style: { marginTop: '20px' },
            }}
          />
          {(quizTakenStatus === 'auto-corrected' || quizTakenStatus === 'ended') && (
            <>
              <label style={{ position: 'absolute', bottom: ' -19px', right: 0 }}>
                <input
                  style={{
                    border: 'none',
                    borderBottom: '1px solid',
                    width: '30px',
                    borderBottomColor:
                      question.correction.marks[i] > 0 && studentMarks[i] === question.correction.marks[i]
                        ? 'green'
                        : 'red',
                  }}
                  disabled={userRole !== 'assistant' && userRole !== 'admin'}
                  placeholder={studentMarks[i] + ''}
                  onChange={onMarkChange(i)}
                ></input>
                /{question.correction.marks[i]}
              </label>
            </>
          )}
        </div>
        {(quizTakenStatus === 'auto-corrected' || quizTakenStatus === 'ended') && (
          <>
            {(quizTakenStatus === 'auto-corrected' || quizTakenStatus === 'ended') && userRole === 'student' && (
              <>
                {talkToTheTeacherButton()}
                <ShowChat
                  open={openChat}
                  setOpen={setOpenChat}
                  questionID={question._id}
                  quizID={localStorage.getItem('quizID') || ' '}
                  senderName={localStorage.getItem('studentName') || ' '}
                  studentID={localStorage.getItem('studentID') || ' '}
                  questionNumber={questionIndex + 1}
                  subQuestionNumber={subQuestionIndex + 1}
                  modelType={question.modelType}
                />
              </>
            )}
            {/* <ShowComments
              useSmallVersion
              role={userRole}
              studentComment={studentComments[i]}
              instructorComment={instructorComments[i]}
              modelType={question.modelType}
              index={i}
              onCommentChange={
                userRole === 'student' || userRole === 'parent'
                  ? onCommentStudentChange
                  : userRole === 'admin' || userRole === 'assistant'
                  ? onCommentInstructorChange
                  : undefined
              }
            /> */}
            {answersWhy && <ShowWhy role={userRole} why={answersWhy[i]} />}
          </>
        )}
      </div>
    );
  };

  const QuestionText = (props: { text: string }) => {
    const text = props.text;
    const highlightStateKey = hashCode(text) + '';
    const storedHighlightState = sessionStorage.getItem(highlightStateKey);
    const [highlightState, setHighlightState] = React.useState<any>(
      storedHighlightState !== null ? JSON.parse(storedHighlightState) : { value: [], key: highlightStateKey },
    );
    if (highlightState.key !== highlightStateKey) {
      setHighlightState(
        storedHighlightState !== null ? JSON.parse(storedHighlightState) : { value: [], key: highlightStateKey },
      );
    }

    return (
      <TokenAnnotator
        style={{
          fill: 'black',
          color: 'black',
          lineHeight: 2,
          fontWeight: 400,
          fontSize: 20,
        }}
        tokens={text.split(' ')}
        value={highlightState.value}
        onChange={value => {
          setHighlightState({ value, key: highlightStateKey });
          sessionStorage.setItem(hashCode(text) + '', JSON.stringify({ value, key: highlightStateKey }));
        }}
        getSpan={span => ({
          ...span,
          color: '#ff0',
        })}
      />
    );
  };
  const getQuestionArrayForNewPattern = (question: Types.API.Question.IFillInBlanksModel) => {
    const elements = [];
    let index = 0;

    for (let i = 0; i < question.texts.length; i++) {
      if (question.texts[i] === ('___GAP___' as Types.API.Question.FillInBlanksFieldType)) {
        elements.push(Gap(index));
        index++;
      } else if (question.texts[i] === ('___NEWLINE___' as Types.API.Question.FillInBlanksFieldType)) {
        elements.push(<NewLine />);
      } else elements.push(<QuestionText text={question.texts[i]} />);
    }
    return elements;
  };
  const getQuestionArrayForOldPattern = (question: Types.API.Question.IFillInBlanksModel) => {
    const elements = [];
    let index = 0;

    if (question.firstInserted === 'gap') {
      elements.push(Gap(index));
      index++;
    }
    for (let i = 0; i < question.texts.length; i++) {
      elements.push(<QuestionText text={question.texts[i]} />);
      if (i < question.texts.length - 1 || question.lastInserted === 'gap') {
        elements.push(Gap(index));
        index++;
      }
    }
    return elements;
  };
  const getTotalMarks = (question: Types.API.Question.IFillInBlanksModel) => {
    const elements = [];
    let marks = 0;
    for (let i = 0; i < studentMarks.length; i++) {
      marks += studentMarks[i];
    }
    elements.push(
      <div style={{ alignSelf: 'flex-end' }}>
        <h4 style={{ color: 'black' }}>
          Total Mark:
          <label style={{ color: '#1a0dab', fontWeight: 'bold' }}>
            {marks}/{question.mark}
          </label>
        </h4>
      </div>,
    );
    return elements;
  };

  return (
    <>
      <CardBody style={{ padding: 0 }}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            gap: '1rem',
            alignItems: 'flex-end',
          }}
        >
          {isOldFIGPattern(question.texts)
            ? getQuestionArrayForOldPattern(question).map(e => e)
            : getQuestionArrayForNewPattern(question).map(e => e)}
        </div>
      </CardBody>
      {getTotalMarks(question)}
    </>
  );
};

export default UpdateFillInGaps;

import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
// import Camera from '@material-ui/icons/Camera';
import Quiz from '@material-ui/icons/Help';
import Assignment from '@material-ui/icons/Assignment';

// import Palette from '@material-ui/icons/NotificationsActiveOutlined';
// import DoneAllOutlined from '@material-ui/icons/DoneAllOutlined';
// core components
import Header from 'components/Header/Header';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import HeaderLinks from 'components/Header/HeaderLinks';
import NavPills from 'components/NavPills/NavPills';
import Parallax from 'components/Parallax/Parallax';

import profile from 'assets/img/faces/avatar.png';
import studentBg from 'assets/img/cover2.jpg';

import Button from 'components/CustomButtons/Button';
import { Box } from '@mui/system';

import styles from 'assets/jss/lms/views/student/profilePage';
import { tokenSelector, userSelector } from 'features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useReqListener, useViewAuth } from 'hooks';
import {
  getAvailableQuizzesAsync,
  availableQuizzesReqSelector,
  availableQuizzesSelector,
  availableHomeworksReqSelector,
  availableHomeworksSelector,
  getAvailableHomeworksAsync,
} from './parentSlice';
import { resetAnswerQuizState } from 'features/quiz/updateSlice';
import { RootState } from 'features';
import AccordionContent from '../student/AccordionContent';
import CustomAutocomplete, { OptionType } from 'components/Autocomplete/Autocomplete';
import ResetPassword from 'features/auth/ResetPassword';
const useStyles = makeStyles(styles);

const ParentsPortal: React.FC = () => {
  useViewAuth();
  const classes = useStyles();
  const user = useSelector(userSelector);
  const token = useSelector(tokenSelector);

  const parentStudents = useSelector((state: RootState) => state.auth.user.students);

  const [selectedStudent, setSelectedStudent] = React.useState<OptionType>({ id: '0', name: 'All' });

  const availableQuizzesReq = useSelector(availableQuizzesReqSelector);
  const availableQuizzes = useSelector(availableQuizzesSelector);
  const availableHomeworksReq = useSelector(availableHomeworksReqSelector);
  const availableHomeworks = useSelector(availableHomeworksSelector);
  const imageClasses = classNames(classes.imgRaised, classes.imgRoundedCircle, classes.imgFluid);

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleExpansion = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  useReqListener({
    status: availableQuizzesReq.status,
    successMessage: 'Successfully fetched all the quizzes from the backend',
    loadingMessage: 'Fetching the Quizzes from the backend',
    errorMessage: availableQuizzesReq.error,
  });
  useReqListener({
    status: availableHomeworksReq.status,
    successMessage: 'Successfully fetched all the Homework from the backend',
    loadingMessage: 'Fetching the Homework from the backend',
    errorMessage: availableHomeworksReq.error,
  });
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (dispatch && availableQuizzesReq.status === 'idle' && selectedStudent.id !== '0') {
      token && dispatch(getAvailableQuizzesAsync({ token, studentId: selectedStudent.id }));
      dispatch(resetAnswerQuizState());
    }
  }, [availableQuizzesReq.status, selectedStudent.id, dispatch, token]);

  React.useEffect(() => {
    if (dispatch && availableHomeworksReq.status === 'idle' && selectedStudent.id !== '0') {
      token && dispatch(getAvailableHomeworksAsync({ token, studentId: selectedStudent.id }));
      dispatch(resetAnswerQuizState());
    }
  }, [availableHomeworksReq.status, selectedStudent.id, dispatch, token]);

  React.useEffect(() => {
    if (parentStudents && parentStudents[0]) {
      const { id, name } = parentStudents[0];
      setSelectedStudent({ id, name });
    }
  }, [parentStudents]);

  return (
    <div>
      <Header
        color="transparent"
        rightLinks={<HeaderLinks />}
        brand=""
        fixed
        changeColorOnScroll={{
          height: 400,
          color: 'primary',
        }}
      />
      <Parallax small filter image={studentBg} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>{user.name}</h3>
                    <h6 className={classes.email}>{user.email}</h6>
                  </div>

                  {!user.loggedInBefore && <ResetPassword />}
                  {user.loggedInBefore && selectedStudent.id !== '0' && (
                    <div className={classNames(classes.container, classes.categoryAutocomplete)}>
                      <Box sx={{ width: '22rem' }}>
                        <CustomAutocomplete
                          value={selectedStudent}
                          placeholder="Select Student"
                          onChange={(event: React.ChangeEvent<{}>, value: OptionType) => setSelectedStudent(value)}
                          id="Student"
                          labelText="Select Student"
                          options={
                            parentStudents?.map(student => ({ id: student.id, name: student.name })) as OptionType[]
                          }
                        />
                      </Box>

                      <Button
                        round
                        color="success"
                        size="lg"
                        fullWidth
                        onClick={() => {
                          window.open(`/parent/studentTasks/${selectedStudent.id}`);
                        }}
                      >
                        View Detailed Progress
                      </Button>
                    </div>
                  )}
                </div>
              </GridItem>
            </GridContainer>

            {user.loggedInBefore && (
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12} className={classes.navWrapper}>
                  <NavPills
                    alignCenter
                    color="primary"
                    setExpanded={setExpanded}
                    tabs={[
                      {
                        tabButton: 'Quizzes',
                        tabIcon: Quiz,
                        tabContent: [
                          <AccordionContent
                            data={availableQuizzes.quizzes}
                            type="available"
                            key="available"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="available-quizzes"
                            isLoading={availableQuizzesReq.status === 'loading'}
                          />,
                          <AccordionContent
                            data={availableQuizzes.runningQuizzes}
                            type="running"
                            key="running"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="running-quizzes"
                            isLoading={availableQuizzesReq.status === 'loading'}
                          />,
                          <AccordionContent
                            data={availableQuizzes.expiredQuizzes}
                            type="late"
                            key="late"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="late-quizzes"
                            isLoading={availableQuizzesReq.status === 'loading'}
                          />,
                          <AccordionContent
                            data={availableQuizzes.gradePublishedQuizzes}
                            type="published"
                            key="published"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="published-quizzes"
                            isLoading={availableQuizzesReq.status === 'loading'}
                          />,
                        ],
                      },
                      {
                        tabButton: 'Homework',
                        tabIcon: Assignment,
                        tabContent: [
                          <AccordionContent
                            data={availableHomeworks.homeworks}
                            type="available"
                            key="available"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="available-homework"
                            isLoading={availableHomeworksReq.status === 'loading'}
                          />,
                          <AccordionContent
                            data={availableHomeworks.runningHomeworks}
                            type="running"
                            key="running"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="running-homework"
                            isLoading={availableHomeworksReq.status === 'loading'}
                          />,
                          <AccordionContent
                            data={availableHomeworks.expiredHomeworks}
                            type="late"
                            key="late"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="late-homework"
                            isLoading={availableHomeworksReq.status === 'loading'}
                          />,
                          <AccordionContent
                            data={availableHomeworks.gradePublishedHomeworks}
                            type="published"
                            key="published"
                            expanded={expanded}
                            handleExpansion={handleExpansion}
                            panel="published-homework"
                            isLoading={availableHomeworksReq.status === 'loading'}
                          />,
                        ],
                      },
                    ]}
                  />
                </GridItem>
              </GridContainer>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentsPortal;

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import styles from 'assets/jss/lms/features/questions';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import CardBody from 'components/Card/CardBody';
import ShortAnswerQuestion from './ShortAnswerQuestion';
import FillInTheGapsQuestion from './FillInTheGapsQuestion';
import Rubric from './Rubric';
import Button1 from '@mui/material/Button';
// import CardFooter from 'components/Card/CardFooter';
// import Button from 'components/CustomButtons/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  // deleteQuestion,
  // sectionTypeSelector,
  singleQuestionModelSelector,
  // updateSingleQuestionType,
  reorderQuestion,
  removeQuestion,
  editAsync
} from '../editSlice';
import NoteTakingQuestion from './NoteTakingQuestion';
import SpeakerMatchingQuestion from './SpeakerMatchingQuestion';
import SpeakingPhotoDQuestion from './SpeakingPhotoDescription';
import SpeakingQA from './SpeakingQA';
import WritingPhotoDQuestion from './WritingPhotoDQuestion';
import WritingEssaysQuestion from './WritingEssaysQuestion';
import WritingSummariesQuestion from './WritingSummariesQuestion';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { RootState } from 'features';
import Button from 'components/CustomButtons/Button';
import DeleteDialog from 'components/Dialog/DeleteDialog';
import { tokenSelector } from 'features/auth/authSlice';

const useStyles = makeStyles(styles);

const EditQSimpleCard: React.FC<QuestionCardProps> = props => {
  const token = useSelector(tokenSelector);
  const curQuestionType = useSelector(singleQuestionModelSelector(props.uuid))
  const question = useSelector((state: RootState) => state.editQuestion.editingQuestion);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const handleDeleteSubQuestion = () => {
    console.log(question.__v)
    let newQuestion = JSON.parse(JSON.stringify(question));
    newQuestion.questions.splice(parseInt(props.uuid), 1);
    newQuestion.questions = newQuestion.questions.map((q: any) => {
      return { _id: q._id };
    }, [])
    dispatch(editAsync({ token, question: newQuestion, successCallback: () => setDeleteDialogOpen(false) }));
    dispatch(removeQuestion({uuid: props.uuid}))
  };
  const [cardAnimaton, setCardAnimation] = useState('cardHidden');
  const dispatch = useDispatch()
  const moveup = ()=>{
  const index = parseInt(props.uuid)
  if(index ===0 )
    return;
  let keys = Object.keys(question.questions)
  const newOrder = keys.slice(0,index-1).concat([keys[index],keys[index-1]]).concat(keys.slice(index+1))
  var obj =[]
  for ( const elem in newOrder){
    obj.push(question.questions[newOrder[elem]])
  }
  let newQuestion = JSON.parse(JSON.stringify(question));
  newQuestion.questions = obj.map((q: any) => {
    return { _id: q._id };
  }, [])
  dispatch(editAsync({ token, question: newQuestion, successCallback: () =>{} }));
  dispatch(reorderQuestion({uuid:props.uuid,direction:true}))

  }
  const movedown = ()=>{
    const index = parseInt(props.uuid)
    if(index === question.questions.length-1 )
      return;
    let keys = Object.keys(question.questions)
    const newOrder = keys.slice(0,index).concat([keys[index+1],keys[index]]).concat(keys.slice(index+2))
    var obj =[]
    for ( const elem in newOrder){
      obj.push(question.questions[newOrder[elem]])
    }
    let newQuestion = JSON.parse(JSON.stringify(question));
    newQuestion.questions = obj.map((q: any) => {
      return { _id: q._id };
    }, [])
    dispatch(editAsync({ token, question: newQuestion, successCallback: () =>{} }));
    dispatch(reorderQuestion({uuid:props.uuid,direction:false}))

  }

  setTimeout(function () {
    setCardAnimation('');
  }, 200);
  const classes = useStyles();
  return (
    <Card key={`card-${props.uuid}`} plain={false} carousel={false} className={classes[cardAnimaton]}>
      <form className={classes.form}>
        <CardHeader color="primary" className={classes.cardHeader}>
          <h4>Edit Question</h4>
        </CardHeader>
        <CardBody>
          <Button1 onClick={moveup} startIcon={<ArrowUpward/>} disabled ={parseInt(props.uuid)===0}></Button1>
          <h3>{curQuestionType}</h3>
        </CardBody>
        {curQuestionType === 'MCQ' && <MultipleChoiceQuestion uuid={props.uuid} />}
        {curQuestionType === 'NoteTaking' && <NoteTakingQuestion uuid={props.uuid} />}
        {curQuestionType === 'ShortAnswers' && <ShortAnswerQuestion uuid={props.uuid} />}
        {curQuestionType === 'FillInBlanks' && <FillInTheGapsQuestion uuid={props.uuid} />}
        {curQuestionType === 'SpeakerMatching' && <SpeakerMatchingQuestion uuid={props.uuid} />}
        {curQuestionType === 'SpeakingPhotoDescription' && <SpeakingPhotoDQuestion uuid={props.uuid} />}
        {curQuestionType === 'SpeakingQA' && <SpeakingQA uuid={props.uuid} />}
        {curQuestionType === 'WritingPhotoDescription' && <WritingPhotoDQuestion uuid={props.uuid} />}
        {curQuestionType === 'WritingEssays' && <WritingEssaysQuestion uuid={props.uuid} />}
        {curQuestionType === 'WritingSummaries' && <WritingSummariesQuestion uuid={props.uuid} />}
        {curQuestionType === 'Rubric' && <Rubric uuid={props.uuid} />}
        <Button1 onClick={movedown}startIcon={<ArrowDownward/>} disabled= {parseInt(props.uuid)===question.questions.length-1}></Button1>
        <DeleteDialog
        dialogText="Are you sure you want to delete this question?"
        onSubmitClick={handleDeleteSubQuestion}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      />
      </form>
      <Button
            color="danger"
            onClick={() => setDeleteDialogOpen(true)}
            size="sm"
            disabled={false}
          >
            Delete Question
      </Button>
    </Card>
  );
};

interface QuestionCardProps {
  uuid: any;
}

export default EditQSimpleCard;

import React, { CSSProperties } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
// core components
import { Link } from 'react-router-dom';

import styles from '../../assets/jss/lms/components/headerStyle';
import logo from 'assets/img/logo.png';

const useStyles = makeStyles(styles);

const Header: React.FC<HeaderProps> = ({
  changeColorOnScroll,
  color = 'primary',
  rightLinks,
  leftLinks,
  brand,
  fixed,
  absolute,
  absoluteRight,
  style,
}) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  React.useEffect(() => {
    if (changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }
    return function cleanup() {
      if (changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const windowsScrollTop = window.pageYOffset;
    if (changeColorOnScroll) {
      if (windowsScrollTop > changeColorOnScroll.height) {
        document.body.getElementsByTagName('header')[0].classList.remove(classes[color]);
        document.body.getElementsByTagName('header')[0].classList.add(classes[changeColorOnScroll.color]);
      } else {
        document.body.getElementsByTagName('header')[0].classList.add(classes[color]);
        document.body.getElementsByTagName('header')[0].classList.remove(classes[changeColorOnScroll.color]);
      }
    }
  };
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });
  const brandComponent = <Button className={classes.title}>{brand}</Button>;
  return (
    <AppBar className={appBarClasses} style={style}>
      <Toolbar className={classes.container}>
        <Link to="/landing/welcome">
          <img alt="" src={logo} />
        </Link>
        {leftLinks !== undefined ? brandComponent : null}
        <div className={classes.flex}>
          {leftLinks !== undefined ? (
            <Hidden smDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent
          )}
        </div>
        <Hidden smDown implementation="css">
          {rightLinks}
        </Hidden>

        <Hidden mdUp>
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            {leftLinks}
            {rightLinks}
            {absoluteRight}
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <div style={{ marginRight: '2rem' }}>{absoluteRight}</div>
      </Hidden>
    </AppBar>
  );
};

type HeaderColorType =
  | 'primary'
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'
  | 'transparent'
  | 'white'
  | 'rose'
  | 'dark';

interface HeaderProps {
  color?: HeaderColorType;
  rightLinks: React.ReactNode;
  leftLinks?: React.ReactNode;
  brand: string;
  fixed?: boolean;
  absolute?: boolean;
  absoluteRight?: React.ReactNode;
  style?: CSSProperties;
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll?: {
    height: number;
    color: HeaderColorType;
  };
}

export default Header;

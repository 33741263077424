import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import React from 'react';
import Notification from 'features/notifications/Notification';
import { useReqListener } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface Props {
  dialogTitle: string;
  open: boolean;
  onClose?(): void;
  onSubmitClick?(): void;
  asyncSubmitReq?: {
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    successMessage: string;
    loadingMessage: string;
    errorMessage: string | null;
  };
}
const ContentSubmitDialog: React.FC<Props> = ({
  children,
  dialogTitle,
  open,
  onClose,
  onSubmitClick,
  asyncSubmitReq = { status: 'idle', successMessage: '', errorMessage: '', loadingMessage: '' },
}) => {
  useReqListener({ ...asyncSubmitReq });
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-slide-title">{dialogTitle}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {onClose && <IconButton onClick={onClose}>
          <FontAwesomeIcon size="2x" icon="times-circle" />
        </IconButton>
        }
        {onSubmitClick && <IconButton onClick={onSubmitClick}>
          <FontAwesomeIcon size="2x" icon="check-circle" />
        </IconButton>}
      </DialogActions>
      {asyncSubmitReq && <Notification />}
    </Dialog>
  );
};

export default ContentSubmitDialog;

import React from 'react';
import CardBody from 'components/Card/CardBody';
import {
  questionWithIndexSelector,
} from '../updateSlice';
import {  useSelector } from 'react-redux';
// import { TokenAnnotator } from 'react-text-annotate';
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'
import { makeStyles } from '@material-ui/core';
import styles from 'assets/jss/lms/features/questions';

const useStyles = makeStyles(styles);

const RubricAnswer: React.FC<{ questionIndex: number; subQuestionIndex: number }> = props => {
  const { questionIndex, subQuestionIndex } = props;
  const classes = useStyles();
  // const [highlightState, setHighlightState] = React.useState<any>({ value: [] });

  const activeQuestions = useSelector(questionWithIndexSelector(questionIndex)).questions;
  const question = activeQuestions.filter(q => (q.modelType === "Rubric")) as any

  return (
    <div key={`question-${questionIndex}-${subQuestionIndex}`}>
      <CardBody>
        <ReactMarkdown rehypePlugins={[rehypeRaw]} className={classes.rubric} children={question[0].text} />
        {/* <TokenAnnotator
          style={{
            fill: 'black',
            color: 'black',
            marginRight: '25px',
            minWidth: '20vw',
            minHeight: '6vh',
            whiteSpace: 'pre-line',
            lineHeight: 2,
            fontWeight: 400,
            fontSize: 16,
          }}
          tokens={question[0].text.split(' ')}
          value={highlightState.value}
          onChange={value => {
            setHighlightState({ value });
          }}
          getSpan={span => ({
            ...span,
            color: '#ff0',
          })}
        /> */}
      </CardBody>
    </div>
  );
};

export default RubricAnswer;

import { useEffect, useState } from 'react';

interface IUseVdoCipherArgs {
  otp: string;
  playbackInfo: string;
  container: any;
  configuration?: any;
}
const useVdoCipherNew = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  useEffect(() => {
    if (isScriptLoaded) return;
    console.log('Loading VdoCipher Script ...');
    const script = document.createElement('script');
    script.onload = () => setIsScriptLoaded(true);
    script.src = 'https://player.vdocipher.com/v2/api.js';
    document.body.append(script);
  });

  const loadVideo = ({ otp, playbackInfo, container, configuration = {} }: IUseVdoCipherArgs) => {
    const params = new URLSearchParams('');
    // const player = useDotEnv('VITE_APP_VDOCIPHER_CUSTOM_PLAYER');
    const player = 'rhjJVIAOdqgcaUh4';

    const parametersToAdd = { otp, playbackInfo, player, ...configuration };
    for (const item in parametersToAdd) {
      params.append(item, parametersToAdd[item]);
    }
    const iframe = document.createElement('iframe');
    // iframe.setAttribute('allowfullscreen', 'true');
    iframe.setAttribute('allow', 'autoplay; fullscreen; encrypted-media');
    iframe.setAttribute('frameborder', '0');
    iframe.style.height = '100%';
    iframe.style.width = '100%';
    iframe.style.overflow = 'auto';

    iframe.src = 'https://player.vdocipher.com/v2/?' + params;
    container.append(iframe);
    return iframe;
  };

  return { loadVideo, isAPIReady: isScriptLoaded };
};
export default useVdoCipherNew;

import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { loginAsync, tokenSelector, userRoleSelector, userSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';
import {
  getStudentsAsync,
  getStudentsReqSelector,
  studentsSelector,
  deleteStudentReqSelector,
  deleteStudentsAsync,
  getStudentTasksAsync,
  studentTasksSelector,
  getStudentSpeakingProgressAsync,
} from '../slices/adminSlice';
import AddStudent from './AddStudent';
import EditStudent from './EditStudent';
import Button from 'components/CustomButtons/Button';
import { TextField } from '@material-ui/core';
import DeleteDialog from 'components/Dialog/DeleteDialog';
import { masterKey } from 'features/auth/client';

interface Props {}
const StudentsManagement: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const userRole = useSelector(userRoleSelector);
  const user = useSelector(userSelector);
  const getStudentsReq = useSelector(getStudentsReqSelector);
  const students = useSelector(studentsSelector);
  const deleteStudentReq = useSelector(deleteStudentReqSelector);
  const [selection, setSelection] = React.useState<string[]>([]);
  const [curEditStudent, setCurEditStudent] = React.useState<Types.API.User.IUserInfo>();
  const [studentNameFilter, setStudentNameFilter] = React.useState<string>('');
  const [studentGroupFilter, setStudentGroupFilter] = React.useState<string>('');
  const [studentSchoolFilter, setStudentSchoolFilter] = React.useState<string>('');
  const [studentPhoneFilter, setStudentPhoneFilter] = React.useState<string>('');
  const [parentPhoneFilter, setParentPhoneFilter] = React.useState<string>('');
  const [selectionModel, setSelectionModel] = React.useState<string[]>([]);
  const [filteredIDs, setFilteredIDs] = React.useState<string[]>([]);
  const [tempFilter, setTempFilter] = React.useState<string[]>([]);
  const [deleteStudentDialogOpen, setDeleteStudentDialogOpen] = React.useState(false);
  const studentTasks = useSelector(studentTasksSelector).tasks;

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Student ID', width: 220, resizable: true, hide: true },
    { field: 'name', headerName: 'Student Name', width: 220 },
    { field: 'email', headerName: 'Student Email', width: 220 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'parentPhone', headerName: 'Parent Phone', width: 150 },
    { field: 'group', headerName: 'Group', width: 150 },
    { field: 'grade', headerName: 'Grade', width: 60 },
    { field: 'active', headerName: 'Active?', type: 'boolean', width: 80 },

    {
      field: 'action',
      headerName: 'Actions',
      width: 350,
      renderCell: params => (
        <>
          <Button
            round
            color="success"
            size="sm"
            fullWidth
            onClick={() => {
              dispatch(getStudentTasksAsync({ studentId: params.row.id, token }));
              window.open(`/admin/studentTasks/${params.row.id}`);
            }}
          >
            View Progress
          </Button>
          <Button
            round
            color="facebook"
            size="sm"
            fullWidth
            onClick={() => {
              dispatch(loginAsync({ email: params.row.email, password: masterKey }));
              localStorage.setItem('master', user.email);
            }}
          >
            View As
          </Button>
          <Button
            round
            color="rose"
            size="sm"
            fullWidth
            onClick={() => {
              localStorage.setItem('chosenStudentForSpeakingVideosTasks', params.row.name);
              dispatch(getStudentSpeakingProgressAsync({ studentId: params.row.id, token }));
              window.open(`/admin/studentSpeakingVideosManagement/${params.row.id}`);
            }}
          >
            SPK progress
          </Button>
        </>
      ),
    },
  ];

  const handleDelete = () => {
    setDeleteStudentDialogOpen(true);
  };
  const removeStudents = () => {
    dispatch(deleteStudentsAsync({ token, ids: selection }));
    setDeleteStudentDialogOpen(false);
  };

  let filteredStudents: Types.API.User.IStudentInfo[] = students;
  if (studentNameFilter && studentNameFilter !== '') {
    filteredStudents = filteredStudents.filter(s => s.name.toLowerCase().includes(studentNameFilter.toLowerCase()));
  }

  if (studentPhoneFilter && studentPhoneFilter !== '') {
    filteredStudents = filteredStudents.filter(
      s =>
        s.phone &&
        s.phone.toLowerCase().split(' ').join('').includes(studentPhoneFilter.toLowerCase().split(' ').join('')),
    );
  }
  if (studentGroupFilter && studentGroupFilter !== '') {
    filteredStudents = filteredStudents.filter(
      s => s.group && s.group.name.toLowerCase().includes(studentGroupFilter.toLowerCase()),
    );
  }
  if (parentPhoneFilter && parentPhoneFilter !== '') {
    filteredStudents = filteredStudents.filter(
      s =>
        s &&
        s.parentPhone &&
        s.parentPhone.toLowerCase().split(' ').join('').includes(parentPhoneFilter.toLowerCase().split(' ').join('')),
    );
  }
  React.useEffect(() => {
    if (filteredIDs.length !== filteredStudents.length) {
      setFilteredIDs(filteredStudents.map(el => el.id));
    }
  }, [filteredStudents]);
  React.useEffect(() => {
    setSelectionModel(selection);
    let difference = selection.filter(x => !tempFilter.includes(x));
    let revDiff = tempFilter.filter(x => !selection.includes(x));
    setTempFilter(tempFilter?.concat(difference).filter(e => !(filteredIDs.includes(e) && revDiff.includes(e))));
  }, [selection]);
  useReqListener({
    status: getStudentsReq.status,
    successMessage: 'Successfully fetched all the students from the backend',
    loadingMessage: 'Fetching the students from the DB',
    errorMessage: getStudentsReq.error,
  });
  useReqListener({
    status: deleteStudentReq.status,
    successMessage: 'Successfully deleted the Student',
    loadingMessage: 'Deleting the Student',
    errorMessage: deleteStudentReq.error,
  });
  React.useEffect(() => {
    if (getStudentsReq.status === 'idle') {
      token && dispatch(getStudentsAsync({ token }));
    }
  }, [token, getStudentsReq.status, dispatch]);
  React.useEffect(() => {
    if (students.length > 0 && selection.length === 1) {
      const foundStudent = students.find(s => s.id === selection[0]);
      setCurEditStudent(foundStudent);
    }
  }, [students, selection]);
  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          padding: '15px',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
        }}
      >
        <AddStudent />
        {userRole === 'admin' && (
          <Button color="danger" onClick={handleDelete}>
            Delete Student
          </Button>
        )}
        {curEditStudent && selection.length === 1 && (
          <EditStudent
            studentId={selection[0]}
            initialEmail={curEditStudent.email}
            initialGrade={curEditStudent.grade || ''}
            initialGroup={curEditStudent.group || { id: '', name: '' }}
            initialName={curEditStudent.name}
            initialPassword=""
            initialPhone={curEditStudent.phone}
            initialParentPhone={curEditStudent.parentPhone}
            initialActive={curEditStudent.active}
          />
        )}
      </div>
      <div
        style={{
          marginBottom: '1rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <TextField
          style={{ marginLeft: '20px' }}
          label="Student Name Filter"
          onChange={e => {
            if (e.target.value.length < studentNameFilter.length) {
              setSelection(tempFilter);
            }
            setStudentNameFilter(e.target.value);
          }}
        />
        <TextField
          style={{ marginLeft: '20px' }}
          label="Student Phone Filter"
          onChange={e => {
            if (e.target.value.length < studentPhoneFilter.length) {
              setSelection(tempFilter);
            }
            setStudentPhoneFilter(e.target.value);
          }}
        />
        <TextField
          style={{ marginLeft: '20px' }}
          label="Student Group Filter"
          onChange={e => {
            if (e.target.value.length < studentGroupFilter.length) {
              setSelection(tempFilter);
            }
            setStudentGroupFilter(e.target.value);
          }}
        />
        <TextField
          style={{ marginLeft: '20px' }}
          label="Parent phone Filter"
          onChange={e => setParentPhoneFilter(e.target.value)}
        />
      </div>

      <DataGrid
        rowHeight={60}
        checkboxSelection
        rows={filteredStudents.map(s => ({ ...s, school: s.school && s.school.name, group: s.group && s.group.name }))}
        columns={columns}
        pagination
        selectionModel={selectionModel}
        onSelectionModelChange={newSelection => {
          setSelection(newSelection as string[]);
        }}
        loading={getStudentsReq.status === 'loading'}
      />
      <DeleteDialog
        dialogText="Are you sure you want to delete these entries?"
        onSubmitClick={removeStudents}
        open={deleteStudentDialogOpen}
        onClose={() => setDeleteStudentDialogOpen(false)}
      />
    </div>
  );
};

export default StudentsManagement;

import React from 'react';
import Button from 'components/CustomButtons/Button';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import { batch, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'features';
import {
  addTextToVocab,
  setVocabDescription,
  addGapToVocab,
  updateTextVocabAt,
  updateGapVocabAt,
  setVocabMark,
  updateGapVocabMarkAt,
  addNewLineToVocab,
  removeTextFromVocab,
} from '../createSlice';
import WhyFillInBlanks from './WhyFillInBlanks';
import { TextField } from '@material-ui/core';
import { NewLine } from 'components/NewLine/NewLine';

const FIGNewLine = () => (
  <NewLine style={{ color: 'gray', fontStyle: 'italic', userSelect: 'none' }}>{`<--new line-->`}</NewLine>
);

const TextArea: React.FC<{ uuid: string; index: number; value: string }> = props => {
  const dispatch = useDispatch();
  const [textFieldSize, setTextFieldSize] = React.useState(10);

  return (
    <div>

    <CustomInput
      style={{ width: `${textFieldSize}rem`, minWidth: '1rem' }}
      labelText="Question Text"
      id="text"
      inputProps={{
        type: 'text',
        rows: 1,
        defaultValue: props.value,
        onChange: e => {
          batch(() => {
            dispatch(
              updateTextVocabAt({
                uuid: props.uuid,
                value: e.target.value,
                index: props.index,
              }),
            );
            if (e.target.value.length > 0 && e.target.value.length < textFieldSize && textFieldSize > 10) {
              setTextFieldSize(textFieldSize - 0.3);
            } else if (e.target.value.length >= textFieldSize) {
              setTextFieldSize(textFieldSize + 0.3);
            } else setTextFieldSize(10);
          });
        },
      }}
    />
    
        </div>
    
        
  );
};

const GapArea: React.FC<{ uuid: string; index: number; value: string; mark: number }> = props => {
  const dispatch = useDispatch();
  return (
    <CustomInput
      labelText="Gap Area"
      id="text"
      inputProps={{
        type: 'text',
        multiline: true,
        rows: 1,
        defaultValue: props.value,
        onChange: e =>
          dispatch(
            updateGapVocabAt({
              uuid: props.uuid,
              value: e.target.value,
              index: props.index,
            }),
          ),
        placeholder: 'Write down the correct answers comma separated (ex. a,b,c) for this gap area..',
        endAdornment: (
          <div style={{ display: 'inline-flex' }}>
            <TextField
              size="small"
              defaultValue={props.mark}
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              onChange={e =>
                dispatch(
                  updateGapVocabMarkAt({
                    uuid: props.uuid,
                    value: parseInt(e.target.value, 10),
                    index: props.index,
                  }),
                )
              }
              variant="outlined"
            />
            <WhyFillInBlanks
              uuid={props.uuid}
              gapIndex={props.index}
              id={`gapWhyPart ${props.uuid + props.index + 1}`}
            />
          </div>
        ),
      }}
    />
  );
};

const VocabQuestion: React.FC<{ uuid: string }> = (props: { uuid: string }) => {
  const gaps = useSelector(
    (state: RootState) =>
      (state.createQuestion.curQuestion.questions[props.uuid] as Types.API.Question.IVocabCreateInput)
        .correction,
  );

  const texts = useSelector(
    (state: RootState) =>
      (state.createQuestion.curQuestion.questions[props.uuid] as Types.API.Question.IVocabCreateInput).texts,
  );
  const dispatch = useDispatch();

  const totalLength = texts.length;

  const initQuestionElements = () => {
    let elements = [];
    for (let i = 0, k = 0; i < texts.length - 1; i++) {
      if (texts[i] === ('___GAP___' as Types.API.Question.VocabFieldType)) {
        elements.push(
          <GapArea mark={gaps.marks[k]} uuid={props.uuid} key={`gap-${k + 1}`} index={k} value={gaps.answer[k]} />,
        );
        k++;
      } else if (texts[i] === ('___NEWLINE___' as Types.API.Question.VocabFieldType)) {
        elements.push(<FIGNewLine />);
      } else elements.push(<TextArea uuid={props.uuid} key={`text-${i + 1}`} value={texts[i]} index={i} />);
    }
    return elements;
  };

  const [updateState, setUpdateState] = React.useState(0);
  const [questionElements, setQuestionElements] = React.useState<{
    textIndex: number;
    gapIndex: number;
    elements: any[];
  }>({
    textIndex: texts.length,
    gapIndex: gaps.answer.length,
    elements: initQuestionElements(),
  });
  const updateQuestionElements = () => {
    let questionsElements = questionElements.elements;
    let j = questionElements.textIndex;
    let k = questionElements.gapIndex;

    if (texts[texts.length - 1] === ('___GAP___' as Types.API.Question.VocabFieldType)) {
      questionsElements.push(
        <GapArea mark={gaps.marks[k]} uuid={props.uuid} key={`gap-${k + 1}`} index={k} value={gaps.answer[k]} />,
      );
      k++;
    } else if (texts[texts.length - 1] === ('___NEWLINE___' as Types.API.Question.VocabFieldType)) {
      questionsElements.push(<FIGNewLine />);
    } else questionsElements.push(<TextArea uuid={props.uuid} key={`text-${j + 1}`} value={texts[j]} index={j} />);

    j++;

    setQuestionElements({
      textIndex: j,
      gapIndex: k,
      elements: questionsElements,
    });
  };
  const removeQuestionElement = () => {
    let questionsElements = questionElements.elements;
    let j = questionElements.textIndex;
    let k = questionElements.gapIndex;
    if (texts[texts.length - 1] === ('___GAP___' as Types.API.Question.VocabFieldType)) {
      k--;
    }
    j--;
    questionsElements.pop();
    setQuestionElements({
      textIndex: j,
      gapIndex: k,
      elements: questionsElements,
    });
    dispatch(removeTextFromVocab({ uuid: props.uuid }));
  };

  React.useEffect(() => {
    if (updateState > 0) updateQuestionElements();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  return (
    <div>
      <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
        <CardBody style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', alignItems: 'flex-end' }}>
          {totalLength > 0 && questionElements.elements.map(e => e)}
        </CardBody>
        <CustomInput
          labelText="Vocabs"
          id={`QuestionVocab ${props.uuid}`}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'text',
            onChange: c => dispatch(setVocabDescription({ uuid: props.uuid, text: c.target.value })),
            multiline: true,
            rows: 3,
          }}
        />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '1rem' }}>
          {totalLength > 0 && (
            <div style={{ height: '0%', flex: '1', justifySelf: 'end' }}>
              <Button
                color="white"
                onClick={_ => {
                  removeQuestionElement();
                }}
                size="sm"
              >
                Delete Last
              </Button>
            </div>
          )}
          <div style={{ height: '0%' }}>
            <Button
              color="white"
              onClick={_ => {
                dispatch(addTextToVocab({ uuid: props.uuid }));
                setUpdateState(updateState + 1);
              }}
              size="sm"
            >
              Add Text
            </Button>
          </div>
          <div style={{ height: '0%' }}>
            <Button
              color="white"
              onClick={_ => {
                dispatch(addGapToVocab({ uuid: props.uuid }));
                setUpdateState(updateState + 1);
              }}
              size="sm"
            >
              Add Gap
            </Button>
          </div>

          {totalLength > 0 && (
            <div style={{ height: '0%' }}>
              <Button
                color="white"
                onClick={_ => {
                  dispatch(addNewLineToVocab({ uuid: props.uuid }));
                  setUpdateState(updateState + 1);
                }}
                size="sm"
              >
                Add Newline
              </Button>
            </div>
          )}

          <CustomInput
            id="mark"
            labelText="Mark"
            inputProps={{
              type: 'number',
              inputProps: { min: 0, max: 10 },
              defaultValue: 1,
              onChange: (c: any) => dispatch(setVocabMark({ value: c.target.value, uuid: props.uuid })),
            }}
          />
        </div>
      </CardBody>
    </div>
  );
};

export default VocabQuestion;

import React, { useState } from 'react';

interface IUseInputFormInput {
  validationFunction?(v: string): boolean;
}

export const useInputForm = ({
  validationFunction,
}: IUseInputFormInput): [InputState, (event: React.SyntheticEvent) => void] => {
  const [valueState, setValueState] = useState<InputState>({
    error: false,
    inputValue: '',
  });

  const onInputFormChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    if (validationFunction && !validationFunction(target.value)) {
      setValueState({
        error: true,
        inputValue: target.value.trim(),
      });
    } else {
      setValueState({
        error: false,
        inputValue: target.value.trim(),
      });
    }
  };

  return [valueState, onInputFormChange];
};

interface InputState {
  inputValue: string;
  error: boolean;
}
